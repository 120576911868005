import {CheckCircleIcon} from '@/components/ui/icon/Icon'
import {StyledSuccessView} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from '@/translations/i18n'

export const SuccessView = () => {
    const {t} = useTranslation()

    return (
        <StyledSuccessView>
            <CheckCircleIcon size={40} />
            <Flexbox direction="column" gap={2} align="center">
                <h1>{t('experience_shell:submitted_to_host')}</h1>
                <p>{t('experience_shell:submitted_to_host_description')}</p>
            </Flexbox>
        </StyledSuccessView>
    )
}
