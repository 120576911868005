import {Image} from '@/features/photoset/types.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {Dispatch, SetStateAction} from 'react'

type UseGalleryNeedsReviewShortcuts = {
    selectableImages: Pick<Image, 'id'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
}

export const useGalleryNeedsReviewShortcuts = (args: UseGalleryNeedsReviewShortcuts) => {
    const {currentDetailImageIndex, setCurrentDetailImageIndex, selectableImages, onClose} = args

    useHotkeys(
        'ArrowLeft',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex - 1)
        },
        {enabled: currentDetailImageIndex >= 1},
        [currentDetailImageIndex]
    )

    useHotkeys(
        'ArrowRight',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex + 1)
        },
        {enabled: currentDetailImageIndex < selectableImages.length - 1},
        [currentDetailImageIndex]
    )

    useHotkeys('Esc', onClose)
}
