import {zodResolver} from '@hookform/resolvers/zod'
import {useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchInputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {Stars01Icon, User01Icon} from '@/components/ui/icon/Icon'
import {RefObject} from 'react'
import {useTranslation} from 'react-i18next'
import {OpsVettingMerchFormHeader} from '@/features/ops-vetting/components/ops-vetting-merch-form-header/OpsVettingMerchFormHeader'
import {
    ExperienceMerchandisingInputSchema,
    ExperienceShellMerchOutputSchema
} from '@/features/experience-vetting/formSchemas'
import {Button} from '@/components/ui/button/Button'

interface ExpShellMerchInputFormProps {
    experienceId?: number
    isTraining?: boolean
    aiResponse: ExperienceShellMerchOutputSchema | undefined
    isReadOnly?: boolean
    defaultValues: Partial<ExperienceMerchandisingInputSchema>
    aiSubmissionRef: RefObject<HTMLButtonElement>
    handleSaveCb?: (data: ExperienceMerchandisingInputSchema) => void
    handleAiSubmissionCb: SubmitHandler<ExperienceMerchandisingInputSchema>
}

export const ExpShellMerchInputForm = ({
    isTraining = false,
    isReadOnly = false,
    aiResponse,
    defaultValues,
    aiSubmissionRef,
    handleSaveCb,
    handleAiSubmissionCb
}: ExpShellMerchInputFormProps) => {
    const {t} = useTranslation()
    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
        getValues
    } = useForm<ExperienceMerchandisingInputSchema>({
        resolver: zodResolver(ExperienceMerchandisingInputSchema),
        defaultValues: defaultValues
    })
    const {fields} = useFieldArray({control, name: 'activities'})

    return (
        <StyledServiceVettingMerchInputForm onSubmit={handleSubmit(handleAiSubmissionCb)}>
            <OpsVettingMerchFormHeader
                endSlot={
                    <Flexbox justify={'flex-end'} gap={2}>
                        <Button variant="secondary" size="sm" onClick={() => aiSubmissionRef.current?.click()}>
                            <Stars01Icon />
                            {aiResponse
                                ? t('vetting:merch:commons:regenerate')
                                : t('vetting:merch:commons:improve_with_ai')}
                        </Button>
                        {!isTraining && handleSaveCb && (
                            <Button variant="secondary" size="sm" onClick={() => handleSaveCb(getValues())}>
                                {t('commons:save')}
                            </Button>
                        )}
                    </Flexbox>
                }
                icon={<User01Icon />}
                title={t('vetting:merch:experience:input_shell_form_title')}
                description={t('vetting:merch:experience:input_shell_form_description')}
            />
            <Flexbox direction={'column'} gap={2} fullWidth>
                <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                    <h4>{t('commons:host')}</h4>
                    <InputText
                        disabled={isReadOnly}
                        type={'text'}
                        label={t('vetting:merch:experience:first_name_label')}
                        {...register(`host_first_name`)}
                        errorMessage={errors?.host_first_name?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:experience:expertise_label')}
                        {...register('expertise_summary')}
                        errorMessage={errors?.expertise_summary?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:experience:recognition_general_label')}
                        {...register('recognition_summary')}
                        errorMessage={errors?.recognition_summary?.message}
                    />
                </StyledServiceMerchVettingFormHostSection>

                <Divider direction={'horizontal'} />

                <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                    <h4>{t('commons:experience')}</h4>
                    <InputText
                        disabled={isReadOnly}
                        type={'text'}
                        label={t('vetting:merch:experience:listing_title_label')}
                        {...register(`listing_title`)}
                        errorMessage={errors?.listing_title?.message}
                    />
                    <InputText
                        disabled={isReadOnly}
                        type={'text'}
                        label={t('vetting:merch:experience:byline_label')}
                        {...register(`byline`)}
                        errorMessage={errors?.byline?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:experience:location_description_label')}
                        {...register(`location_description`)}
                        errorMessage={errors?.location_description?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:experience:what_guest_will_do_label')}
                        {...register(`guest_activities`)}
                        errorMessage={errors?.guest_activities?.message}
                    />

                    {fields.map((activity, index) => (
                        <Flexbox fullWidth direction={'column'} gap={2} key={activity.id}>
                            <Flexbox align={'center'} justify={'space-between'} gap={4}>
                                <p>
                                    {t('vetting:merch:experience:activity_input_label', {
                                        index: index + 1
                                    })}
                                </p>
                            </Flexbox>
                            <InputText
                                disabled={isReadOnly}
                                type={'text'}
                                label={t('vetting:merch:experience:activity_title_label')}
                                {...register(`activities.${index}.title`)}
                                errorMessage={errors?.activities?.[index]?.title?.message}
                            />
                            <TextArea
                                disabled={isReadOnly}
                                rows={4}
                                label={t('vetting:merch:experience:activity_description_label')}
                                {...register(`activities.${index}.description`)}
                                errorMessage={errors?.activities?.[index]?.description?.message}
                            />
                        </Flexbox>
                    ))}
                </StyledServiceMerchVettingFormOfferingSection>
            </Flexbox>
            <button type="submit" ref={aiSubmissionRef} style={{display: 'none'}} />
        </StyledServiceVettingMerchInputForm>
    )
}

ExpShellMerchInputForm.displayName = 'ExpShellMerchInputForm'
