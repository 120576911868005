import {FC, ReactElement, useRef} from 'react'
import {Button} from '@components/ui/button/Button'
import {Divider} from '@components/ui/divider/Divider'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledAiPromptModalBody, StyledAiPromptModalDisclaimer} from './style'
import {
    Dialog,
    DialogBody,
    DialogDescription,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider,
    DialogDisclosure
} from '@/components/ui/dialog-atoms/DialogAtoms'
import {Chip} from '@/components/commons/chip/Chip'

interface AiPromptModalProps {
    trigger: ReactElement
    currentPrompt: string
    promptType: 'services' | 'exp_vetting' | 'exp_shell'
    onPromptChange: (prompt: string) => void
    isLoading?: boolean
}

const PROMPT_FIELD_DESCRIPTIONS = {
    services: [
        {field: 'service_title', desc: 'The main title of the service'},
        {field: 'service_description', desc: 'A brief description/tagline of the service'},
        {
            field: 'offerings',
            desc: 'An array of service offerings, each containing "title", "description" and "duration"'
        },
        {field: 'host_experience', desc: "Host's primary expertise"},
        {field: 'host_experience_summary', desc: "Overview of host's background"},
        {field: 'host_experience_highlight', desc: 'Key achievements or specialties'},
        {field: 'host_experience_background', desc: 'Detailed professional background'}
    ],
    exp_shell: [
        {field: 'host_first_name', desc: "Host's first name"},
        {field: 'host_expertise_description', desc: 'Description of host expertise'},
        {field: 'host_recognition', desc: 'Host recognition details'},
        {field: 'experience_title', desc: 'Current experience title'},
        {field: 'experience_byline', desc: 'Experience byline'},
        {field: 'experience_activities_plan', desc: 'Detailed activities plan'},
        {field: 'activities', desc: 'Array of activities with existing titles and descriptions'}
    ],
    exp_vetting: [
        {field: 'host_experience_title', desc: 'Title highlighting primary expertise (32 chars max)'},
        {field: 'host_expertise_description', desc: 'First-person statement about expertise (90 chars max)'},
        {field: 'host_recognition_title', desc: 'Most impressive verifiable achievement (200 chars max)'},
        {field: 'host_recognition_subtitle', desc: 'Supporting context for recognition (200 chars max)'},
        {field: 'experience_title', desc: 'Engaging experience title (50 chars max)'},
        {field: 'experience_description', desc: 'Compelling experience summary (90 chars max)'},
        {field: 'activities', desc: 'Array of activities with id, title, description, and duration'}
    ]
}

export const AiPromptModal: FC<AiPromptModalProps> = ({
    trigger,
    currentPrompt,
    promptType,
    onPromptChange,
    isLoading = false
}) => {
    const promptRef = useRef<HTMLTextAreaElement>(null)

    const handleSave = () => {
        if (promptRef.current) {
            onPromptChange(promptRef.current.value)
        }
    }

    return (
        <DialogProvider>
            <DialogDisclosure render={trigger} />
            <Dialog style={{width: '800px'}}>
                <DialogHeader>
                    <DialogDismiss />
                    <DialogHeading>This is the prompt that will be sent to AI</DialogHeading>
                </DialogHeader>

                <DialogBody>
                    <StyledAiPromptModalBody>
                        <Flexbox direction={'column'} gap={2}>
                            <DialogDescription>
                                You can customize the prompt to fit your needs. The following fields are available into
                                the input:
                            </DialogDescription>
                            <Flexbox gap={1} wrap={'wrap'}>
                                {PROMPT_FIELD_DESCRIPTIONS[promptType].map(({field}) => (
                                    <Chip variant={'info'} key={field} label={field} size="sm" shape="circle" />
                                ))}
                            </Flexbox>
                        </Flexbox>
                        <Divider direction="horizontal" startSpacing={4} endSpacing={4} />
                        <TextArea
                            ref={promptRef}
                            rows={20}
                            label=""
                            placeholder="Customize your prompt here..."
                            defaultValue={currentPrompt}
                        />
                    </StyledAiPromptModalBody>
                    <StyledAiPromptModalDisclaimer>
                        The final part of the prompt is hidden to ensure the output matches the desired format.
                    </StyledAiPromptModalDisclaimer>
                </DialogBody>

                <DialogFooter>
                    <DialogDismiss render={<Button variant="tertiary" disabled={isLoading} />}>Close</DialogDismiss>
                    <DialogDismiss onClick={handleSave} render={<Button variant="primary" disabled={isLoading} />}>
                        Save
                    </DialogDismiss>
                </DialogFooter>
            </Dialog>
        </DialogProvider>
    )
}

AiPromptModal.displayName = 'AiPromptModal'
