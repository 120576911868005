import {useTranslation} from '@/translations/i18n'
import {Content, Footer, YesNoField} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {useExperienceShellFormContext} from '@/features/experience-shell/hooks/useExperienceShellFormContext.ts'
import {Controller, useWatch} from 'react-hook-form'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {StyledDescription} from './style'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {StepKeyName, TransportingGuestsMode} from '../../types'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {useChangeStep} from '@/features/experience-shell/hooks/useChangeStep'
import {FC} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {SubmitModal} from './SubmitModal'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {useDialogStore} from '@ariakit/react'
import {errorHandler} from '@/utilities/helpers'
import {useEditExperience} from '../../queries/useEditExperience'

export const LegalStep: FC<{experienceId: number; galleryImagesCount: number}> = ({
    experienceId,
    galleryImagesCount
}) => {
    const {t} = useTranslation()
    const dialogStore = useDialogStore()
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.legal
    })
    const editExperienceMutation = useEditExperience({
        experienceId,
        options: {
            onError: errorHandler,
            onSuccess: () => dialogStore.setOpen(true)
        }
    })
    const {
        control,
        register,
        setValue,
        getValues,
        formState: {errors}
    } = useExperienceShellFormContext()
    const categoryWatch = useWatch({name: 'experience.categoryKeyName', control})
    const transportingGuestsWatch = useWatch({name: 'legal.host_transports_guests', control})
    const pointOfInterestWatch = useWatch({name: 'legal.involves_point_of_interest', control})
    const foodWatch = useWatch({name: 'legal.host_serve_food', control})
    const alcoholWatch = useWatch({name: 'legal.host_serve_alcohol', control})

    return (
        <>
            <Content title={t('experience_shell:step_legal')}>
                <Flexbox direction="column" gap={6}>
                    {categoryWatch != 'food_drink' && categoryWatch != 'entertainment' && (
                        <>
                            <Controller
                                control={control}
                                name={'legal.involves_national_park'}
                                render={({field}) => (
                                    <Flexbox direction="column" gap={2} fullWidth>
                                        <YesNoField
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            labelTitle={<b>{t('experience_shell:legal_national_park_check')}</b>}
                                        />
                                        {errors.legal?.involves_national_park && (
                                            <InputHelpText error={t('errors:field_required')} />
                                        )}
                                    </Flexbox>
                                )}
                            />
                            <Divider direction="horizontal" />
                            <Controller
                                control={control}
                                name={'legal.involves_point_of_interest'}
                                render={({field}) => (
                                    <Flexbox direction="column" gap={2} fullWidth>
                                        <YesNoField
                                            name={field.name}
                                            value={field.value}
                                            onChange={value => {
                                                field.onChange(value)
                                                if (value) {
                                                    setValue('legal.host_discusses_poi_significance', undefined)
                                                }
                                            }}
                                            labelTitle={<b>{t('experience_shell:legal_point_of_interest_check')}</b>}
                                        />
                                        {errors.legal?.involves_point_of_interest && (
                                            <InputHelpText error={t('errors:field_required')} />
                                        )}
                                    </Flexbox>
                                )}
                            />
                            {pointOfInterestWatch && (
                                <Controller
                                    control={control}
                                    name={'legal.host_discusses_poi_significance'}
                                    render={({field}) => (
                                        <Flexbox direction="column" gap={2} fullWidth>
                                            <YesNoField
                                                direction="column"
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                labelTitle={t('experience_shell:discuss_significance')}
                                            />
                                            {errors.legal?.host_discusses_poi_significance && (
                                                <InputHelpText error={t('errors:field_required')} />
                                            )}
                                        </Flexbox>
                                    )}
                                />
                            )}
                            <Divider direction="horizontal" />
                        </>
                    )}
                    {categoryWatch == 'food_drink' && (
                        <>
                            <Controller
                                control={control}
                                name={'legal.host_serve_food'}
                                render={({field}) => (
                                    <Flexbox direction="column" gap={2} fullWidth>
                                        <YesNoField
                                            name={field.name}
                                            value={field.value}
                                            onChange={value => {
                                                field.onChange(value)
                                                if (value) {
                                                    setValue('legal.prepared_in_restaurant', undefined)
                                                }
                                            }}
                                            labelTitle={<b>{t('experience_shell:legal_food_check')}</b>}
                                        />
                                        {errors.legal?.host_serve_food && (
                                            <InputHelpText error={t('errors:field_required')} />
                                        )}
                                    </Flexbox>
                                )}
                            />
                            {foodWatch && (
                                <Controller
                                    control={control}
                                    name={'legal.prepared_in_restaurant'}
                                    render={({field}) => (
                                        <Flexbox direction="column" gap={2} fullWidth>
                                            <YesNoField
                                                direction="column"
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                labelTitle={t('experience_shell:legal_licensed_food')}
                                            />
                                            {errors.legal?.prepared_in_restaurant && (
                                                <InputHelpText error={t('errors:field_required')} />
                                            )}
                                        </Flexbox>
                                    )}
                                />
                            )}
                            <Divider direction="horizontal" />
                        </>
                    )}
                    {(categoryWatch == 'food_drink' || categoryWatch == 'entertainment') && (
                        <>
                            <Controller
                                control={control}
                                name={'legal.host_serve_alcohol'}
                                render={({field}) => (
                                    <Flexbox direction="column" gap={2} fullWidth>
                                        <YesNoField
                                            name={field.name}
                                            value={field.value}
                                            onChange={value => {
                                                field.onChange(value)
                                                if (value) {
                                                    setValue('legal.is_licensed_establishment', undefined)
                                                }
                                            }}
                                            labelTitle={<b>{t('experience_shell:legal_alcohol_check')}</b>}
                                        />
                                        {errors.legal?.host_serve_alcohol && (
                                            <InputHelpText error={t('errors:field_required')} />
                                        )}
                                    </Flexbox>
                                )}
                            />
                            {alcoholWatch && (
                                <Controller
                                    control={control}
                                    name={'legal.is_licensed_establishment'}
                                    render={({field}) => (
                                        <Flexbox direction="column" gap={2} fullWidth>
                                            <YesNoField
                                                direction="column"
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                labelTitle={t('experience_shell:legal_licensed_alcohol')}
                                            />
                                            {errors.legal?.is_licensed_establishment && (
                                                <InputHelpText error={t('errors:field_required')} />
                                            )}
                                        </Flexbox>
                                    )}
                                />
                            )}
                        </>
                    )}
                    <Controller
                        control={control}
                        name={'legal.host_transports_guests'}
                        render={({field}) => (
                            <Flexbox direction="column" gap={2} fullWidth>
                                <YesNoField
                                    name={field.name}
                                    value={field.value}
                                    onChange={value => {
                                        field.onChange(value)
                                        if (value) {
                                            setValue('legal.host_transporting_guests_method', undefined)
                                        }
                                    }}
                                    labelTitle={<b>{t('experience_shell:legal_transporting_guests_check')}</b>}
                                />
                                <InputHelpText
                                    error={errors.legal?.host_transports_guests && t('errors:field_required')}
                                />
                            </Flexbox>
                        )}
                    />
                    {transportingGuestsWatch && (
                        <Flexbox direction="column" gap={2.5}>
                            <StyledDescription>
                                {t('experience_shell:legal_transporting_guests_mode_description')}
                            </StyledDescription>
                            <Flexbox gap={3}>
                                <AnswerItem
                                    inputSize="sm"
                                    label={t('experience_shell:legal_transporting_guests_mode_car')}
                                    type="checkbox"
                                    value={TransportingGuestsMode.enum.car}
                                    {...register('legal.host_transporting_guests_method')}
                                />
                                <AnswerItem
                                    inputSize="sm"
                                    label={t('experience_shell:legal_transporting_guests_mode_boat')}
                                    type="checkbox"
                                    value={TransportingGuestsMode.enum.boat}
                                    {...register('legal.host_transporting_guests_method')}
                                />
                                <AnswerItem
                                    inputSize="sm"
                                    label={t('experience_shell:legal_transporting_guests_mode_plane')}
                                    type="checkbox"
                                    value={TransportingGuestsMode.enum.plane}
                                    {...register('legal.host_transporting_guests_method')}
                                />
                                <AnswerItem
                                    inputSize="sm"
                                    label={t('experience_shell:legal_transporting_guests_mode_motorcycle')}
                                    type="checkbox"
                                    value={TransportingGuestsMode.enum.motorcycle}
                                    {...register('legal.host_transporting_guests_method')}
                                />
                            </Flexbox>
                            {errors.legal?.host_transporting_guests_method && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                    )}
                    <Divider direction="horizontal" />
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => changeStep.handleChangeStep({isBack: true, stepToGoTo: StepKeyName.enum.settings})}
                    disabled={changeStep.isBackLoading || editExperienceMutation.isPending}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <SubmitModal
                    experienceId={experienceId}
                    store={dialogStore}
                    galleryImagesCount={galleryImagesCount}
                    trigger={
                        <Button
                            size="sm"
                            variant="primarySuccess"
                            onClick={event => {
                                event.preventDefault()
                                editExperienceMutation.mutate(getValues(StepKeyName.enum.legal))
                            }}
                            disabled={editExperienceMutation.isPending}
                        >
                            {t('experience_shell:submit_to_host')}
                            <ArrowRightIcon />
                        </Button>
                    }
                />
            </Footer>
        </>
    )
}
