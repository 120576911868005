import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    DialogDisclosure,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider
} from '@components/ui/dialog-atoms/DialogAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ThumbsDownIcon} from '@components/ui/icon/Icon.tsx'
import {useDialogContext} from '@ariakit/react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useAddServiceGalleryImageFlags} from '@/features/service-vetting/query/useAddServiceGalleryImageFlags.ts'
import {
    OpsVettingRejectDialog,
    OpsVettingRejectDialogBody,
    OpsVettingRejectDialogBodyFlagsItem
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {ServiceFlag, ServiceGalleryImage} from '@/features/service-vetting/types.ts'
import {useGetServiceFlags} from '@/features/service-vetting/query/useGetServiceFlags.ts'
import {useUpdateIsApprovedGalleryImg} from '../../query/useUpdateIsApprovedGalleryImg'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {useRemoveServiceGalleryImageFlags} from '../../query/useRemoveServiceGalleryImageFlags'

interface ServiceVettingGalleryImageRejectDialogProps {
    image: ServiceGalleryImage
    serviceId: number
    galleryId: number
    currentFlags: ServiceFlag[]
    disabled?: boolean
}

export const ServiceVettingGalleryImageRejectDialog = ({
    image,
    serviceId,
    galleryId,
    currentFlags,
    disabled
}: ServiceVettingGalleryImageRejectDialogProps) => {
    const {t} = useTranslation()

    return (
        <DialogProvider>
            <DialogDisclosure
                render={
                    <Button variant="secondary" fullWidth size="sm" disabled={disabled}>
                        <ThumbsDownIcon />
                        {t('vetting:criteria:commons:reject')}
                    </Button>
                }
            />
            <OpsVettingRejectDialog width="600px" hideOnInteractOutside={false}>
                <ServiceVettingGalleryImageRejectDialogBody
                    image={image}
                    serviceId={serviceId}
                    galleryId={galleryId}
                    currentFlags={currentFlags}
                />
            </OpsVettingRejectDialog>
        </DialogProvider>
    )
}

export const ServiceVettingGalleryImageRejectDialogBody: FC<ServiceVettingGalleryImageRejectDialogProps> = ({
    image,
    galleryId,
    currentFlags
}) => {
    const {t} = useTranslation()
    const dialogStore = useDialogContext()
    if (!dialogStore) {
        throw new Error('Dialog must be wrapped in DialogProvider')
    }

    const [selectedFlags, setSelectedFlags] = useState<ServiceFlag[]>(currentFlags)

    const updateIsApprovedGalleryImg = useUpdateIsApprovedGalleryImg({
        serviceId: image.submission_id,
        galleryId,
        imgId: image.id
    })

    const addServiceGalleryImageFlags = useAddServiceGalleryImageFlags({
        serviceId: image.submission_id,
        sectionId: galleryId
    })

    const removeServiceGalleryImageFlags = useRemoveServiceGalleryImageFlags({
        serviceId: image.submission_id,
        sectionId: galleryId
    })

    const {data: flags, isLoading: isFlagsLoading} = useGetServiceFlags({
        types: ['gallery_image'],
        response_type: 'extended'
    })

    const isSubmitting = updateIsApprovedGalleryImg.isPending || addServiceGalleryImageFlags.isPending

    const hasSelectedFlags = selectedFlags.length > 0

    const handleAddFlag = (flag: ServiceFlag) => {
        setSelectedFlags(prev => [...prev, flag])
    }

    const handleRemoveFlag = (flag: ServiceFlag) => {
        if (currentFlags.some(f => f.id === flag.id)) {
            removeServiceGalleryImageFlags.mutate({
                flags: [flag],
                itemId: image.id
            })
        }
        setSelectedFlags(prev => prev.filter(f => f.id !== flag.id))
    }

    const handleReject = async () => {
        try {
            // First, mark the image as rejected
            await updateIsApprovedGalleryImg.mutateAsync({is_approved: false})

            // If there are flags, add them
            if (selectedFlags.length > 0) {
                await addServiceGalleryImageFlags.mutateAsync({
                    flags: selectedFlags,
                    itemId: image.id
                })
            }
        } catch (error) {
            console.error('Error rejecting image:', error)
        } finally {
            dialogStore.hide()
        }
    }

    return (
        <>
            <DialogHeader>
                <DialogDismiss disabled={isSubmitting} />
                <DialogHeading>{t('vetting:criteria:commons:select_flags')}</DialogHeading>
            </DialogHeader>
            <OpsVettingRejectDialogBody>
                <GalleryImageItem src={image.url} />
                <ScrollArea scrollbar={<Scrollbar />}>
                    <Flexbox direction="column" gap={2}>
                        {isFlagsLoading
                            ? Array.from({length: 10}).map((_, index) => (
                                  <Flexbox key={index} gap={2}>
                                      <Skeleton width={20} height={20} />
                                      <Skeleton width="100%" height={20} />
                                  </Flexbox>
                              ))
                            : flags?.map(flag => (
                                  <OpsVettingRejectDialogBodyFlagsItem key={flag.id} gap={2}>
                                      <Checkbox
                                          checked={selectedFlags.some(f => f.id === flag.id)}
                                          onChange={() => {
                                              if (selectedFlags.some(f => f.id === flag.id)) {
                                                  handleRemoveFlag(flag)
                                              } else {
                                                  handleAddFlag(flag)
                                              }
                                          }}
                                      />
                                      <p>{t(`vetting:criteria:service:flags_key_name:${flag.key_name}`)}</p>
                                  </OpsVettingRejectDialogBodyFlagsItem>
                              ))}
                    </Flexbox>
                </ScrollArea>
            </OpsVettingRejectDialogBody>
            <DialogFooter>
                <Button variant="tertiary" onClick={() => dialogStore.hide()} disabled={isSubmitting}>
                    {t('commons:cancel')}
                </Button>

                <Button onClick={handleReject} disabled={isSubmitting || !hasSelectedFlags}>
                    {t('vetting:criteria:commons:reject_image')}
                    {isSubmitting && <Spinner size={20} />}
                </Button>
            </DialogFooter>
        </>
    )
}
