import toast from 'react-hot-toast'
import {RemappedService} from '../../types'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {useSubmitService} from '@/features/service-vetting/query/useSubmitService'
import {useState, useRef, useMemo} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '../../formSchemas'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {
    StyledServiceMerchandisingVettingWrapper,
    StyledServiceVettingFormsWrapper
} from '@/pages/service-vetting/merchandising/style'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AiPromptModal} from '@/components/commons/ai-prompt-modal/AiPromptModal'
import {Button} from '@/components/ui/button/Button'
import {Stars01Icon, Settings01Icon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {
    remapMerchCopyAiSubmittedForm,
    remapMerchCopyHostEdits,
    remapMerchCopyHostEditsDiffs,
    remapMerchCopyInputForm
} from '../../utils'
import {ServiceVettingMerchOutputForm} from '../service-vetting-merch-output-form/ServiceVettingMerchOutputForm'
import {ServiceVettingMerchInputForm} from '../service-vetting-merch-input-form/ServiceVettingMerchInputForm'
import {ServiceVettingMerchCopyDiffs} from '../service-vetting-merch-copy-diffs/ServiceVettingMerchCopyDiffs'
import {Divider} from '@/components/ui/divider/Divider'
import {SERVICES_PROMPT} from '@/features/ops-vetting/prompts/servicePrompt'
import {ServiceVettingMerchSubmitModal} from '../service-vetting-merch-submit-modal/ServiceVettingMerchSubmitModal'
import {errorHandler} from '@/utilities/helpers'
import {useAuthStore} from '@/features/auth/store/store'
import {useTranslation} from '@/translations/i18n'
export const ServiceVettingMerchForms = ({service}: {service: RemappedService}) => {
    //user roles
    const user = useAuthStore(state => state.user)
    const isReadOnlyUser = user?.role === 'read_only'
    const {t} = useTranslation()
    const {
        aiResponse,
        isLoading: isAiLoading,
        onMerchCopyAiCall,
        resetAiResponse
    } = useMerchCopyAi<ServiceMerchandisingOutputSchema>()
    const isApprovalRequired =
        service.status === 'archived_pending_host_approval' || service.status === 'archived_update_required'
    const isServiceApproved =
        service.status === 'approved' ||
        service.status === 'publishing_enabled' ||
        service.status === 'pending_publishing' ||
        service.status === 'completed' ||
        service.status === 'pending_export'
    const isReviewHostEdits = service.is_host_modified
    const isFormReadOnly = isServiceApproved || isApprovalRequired || isReadOnlyUser
    //prompt controls
    const [currentPrompt, setCurrentPrompt] = useState<string>(SERVICES_PROMPT)
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    //submit utils
    const [isSubmitMerchModalOpen, setIsSubmitMerchModalOpen] = useState(false)
    const [requireHostApproval, setRequireHostApproval] = useState(true)
    //submit service mutation
    const {mutate: submitMerchCopy, isPending: isSubmittingMerchCopy} = useSubmitService(
        {serviceId: service.id},
        {
            onSettled: () => setIsSubmitMerchModalOpen(!isSubmitMerchModalOpen),
            onError: errorHandler,
            onSuccess: () => toast.success(t('vetting:merch:service:submit_success'))
        }
    )
    //trigger ai-improvement submission
    const handleAiSubmission: SubmitHandler<ServiceMerchandisingInputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }
    //final submission
    const handleFinalSubmission: SubmitHandler<ServiceMerchandisingOutputSchema> = data => {
        const payload = {
            ...data,
            is_host_approval_needed: requireHostApproval,
            is_waitlisted: false
        }
        submitMerchCopy(payload)
    }

    // Memoize the input form default values
    const inputFormDefaultValues = useMemo(() => remapMerchCopyInputForm(service), [service])
    // Memoize the output form default values
    const outputFormDefaultValues = useMemo(
        () => (aiResponse ? (aiResponse as ServiceMerchandisingOutputSchema) : remapMerchCopyAiSubmittedForm(service)),
        [service, aiResponse]
    )
    // Memoize the host edits default values
    const hostEditsDefaultValues = useMemo(() => remapMerchCopyHostEdits(service), [service])
    // Memoize the diffs map
    const serviceDiffsMap = useMemo(() => remapMerchCopyHostEditsDiffs(service), [service])

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            <StyledServiceMerchandisingVettingWrapper fullWidth>
                <OpsVettingHero fullWidth justify="space-between" align="center">
                    <h3>{t('vetting:merch:service:title')}</h3>
                    {!isFormReadOnly && (
                        <Flexbox gap={2}>
                            {!isReviewHostEdits && !isApprovalRequired && (
                                <>
                                    <AiPromptModal
                                        trigger={
                                            <Button variant="tertiary" size="sm" disabled={isAiLoading}>
                                                <Settings01Icon />
                                                {t('vetting:merch:commons:customize_prompt')}
                                            </Button>
                                        }
                                        currentPrompt={currentPrompt}
                                        onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                        isLoading={isAiLoading}
                                        promptType="services"
                                    />
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => aiSubmissionRef.current?.click()}
                                        disabled={isAiLoading}
                                    >
                                        {isAiLoading ? <Spinner size={16} /> : <Stars01Icon />}
                                        {t('vetting:merch:commons:improve_with_ai')}
                                    </Button>
                                </>
                            )}
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={isAiLoading}
                                onClick={() => setIsSubmitMerchModalOpen(true)}
                            >
                                {t('vetting:merch:commons:submit')}
                            </Button>
                            {isSubmitMerchModalOpen && (
                                <ServiceVettingMerchSubmitModal
                                    isSubmitting={isSubmittingMerchCopy}
                                    type={isReviewHostEdits ? 'host-edits' : 'ai'}
                                    onClose={() => setIsSubmitMerchModalOpen(false)}
                                    submissionRef={finalSubmitRef}
                                    setRequireHostApprovalCb={setRequireHostApproval}
                                />
                            )}
                        </Flexbox>
                    )}
                </OpsVettingHero>

                <StyledServiceVettingFormsWrapper fullWidth>
                    {
                        <>
                            {!isServiceApproved && !isReviewHostEdits && (
                                <ServiceVettingMerchInputForm
                                    isReadOnly={isFormReadOnly}
                                    defaultValues={inputFormDefaultValues}
                                    aiSubmissionRef={aiSubmissionRef}
                                    handleAiSubmissionCb={handleAiSubmission}
                                />
                            )}
                            {(isServiceApproved || isReviewHostEdits) && (
                                <ServiceVettingMerchOutputForm
                                    bgStyle={'light'}
                                    isReadOnly={true}
                                    defaultValues={hostEditsDefaultValues}
                                    finalSubmitRef={finalSubmitRef}
                                    handleFinalSubmissionCb={handleFinalSubmission}
                                />
                            )}
                        </>
                    }
                    {isServiceApproved || isReviewHostEdits ? (
                        <ServiceVettingMerchCopyDiffs serviceDiffsMap={serviceDiffsMap} />
                    ) : (
                        <Divider direction="vertical" />
                    )}
                    <ServiceVettingMerchOutputForm
                        isReadOnly={isFormReadOnly}
                        isHostEdits={isReviewHostEdits || isServiceApproved}
                        isLoading={isAiLoading}
                        defaultValues={outputFormDefaultValues}
                        finalSubmitRef={finalSubmitRef}
                        handleFinalSubmissionCb={handleFinalSubmission}
                    />
                </StyledServiceVettingFormsWrapper>
            </StyledServiceMerchandisingVettingWrapper>
        </ScrollArea>
    )
}
ServiceVettingMerchForms.displayName = 'ServiceVettingMerchForms'
