import {z} from 'zod'

export const UserRoles = z.enum([
    'editor',
    'reviewer',
    'criteria_editor',
    'full_editor',
    'merchandising_editor',
    'read_only',
    'admin',
    'publisher'
])
export type UserRoles = z.infer<typeof UserRoles>

export const User = z.object({
    airbnb_user_id: z.coerce.string(),
    email: z.string().email(),
    first_name: z.string().nullable(),
    id: z.coerce.string(),
    last_name: z.string().nullable(),
    role: UserRoles
})
export type User = z.infer<typeof User>

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>

export const HttpLoginResponse = z.object({
    user: User,
    access_token: z.string(),
    refresh_token: z.string()
})
export type HttpLoginResponse = z.infer<typeof HttpLoginResponse>
