import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetPhotosetSpaces,
    HttpGetPhotosetSpacesOptions,
    HttpGetPhotosetSpacesResponse
} from '@/features/photoset/http/photoset.http'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetPhotosetSpaces = (
    promiseArgs: HttpGetPhotosetSpacesOptions,
    options?: Omit<UseQueryOptions<HttpGetPhotosetSpacesResponse>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PHOTOSET_SPACES, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPhotosetSpaces(promiseArgs),
                responseShape: HttpGetPhotosetSpacesResponse,
                onZodError: captureException
            })
    })
