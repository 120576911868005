import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {z} from 'zod'

const SearchParams = z.object({
    redirect_to: z.string().optional()
})
export const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const {searchParams} = useParsedSearchParams([], SearchParams)

    // Handle the case when accessToken is not available.
    if (!accessToken) {
        return <Outlet />
    }

    return <Navigate to={searchParams.redirect_to ?? routes.WELCOME.path} replace />
}
