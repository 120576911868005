import {z} from 'zod'
import {Experience, ExperienceVettingBaseQuestion, Itinerary} from '@/features/experience-shell/types.ts'
import {ContentDiff} from '../ops-vetting/components/ops-vetting-diffs/OpsVettingMerchDiffs'
import {ExperienceVettingMerchOutputSchema} from './formSchemas'

export const ExperienceActivityTypes = z.enum(['standard'])
export type ExperienceActivityTypes = z.infer<typeof ExperienceActivityTypes>

const RemappedItinerariesVetting = Itinerary.omit({answers: true}).merge(
    z.object({
        answers: z.object({
            title: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }),
            description: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }),
            improved_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            duration: ExperienceVettingBaseQuestion.extend({
                answer: z.number(),
                previousAnswer: z.number().nullable()
            }).optional()
        })
    })
)
export type RemappedItinerariesVetting = z.infer<typeof RemappedItinerariesVetting>

export const RemapExperienceVetting = Experience.omit({expertise: true, itineraries: true}).extend({
    expertise: z.object({
        ...Experience.shape['expertise'].omit({answers: true}).shape,
        answers: z.object({
            expertise_years: ExperienceVettingBaseQuestion.extend({
                answer: z.number()
            }),
            listing_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }).optional(),
            meeting_point_name: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            meeting_point_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            guest_individual_price: ExperienceVettingBaseQuestion.extend({
                answer: z.number()
            }),
            experience_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            profile_photo: ExperienceVettingBaseQuestion.extend({
                answer: z.string().url().nullable()
            }),
            //exp merch vetting/shell input
            host_experience_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            host_expertise_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            host_recognition_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }).optional(),
            host_recognition_subtitle: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }).optional(),
            host_credential: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }).optional(),
            experience_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string()
            }),
            //exp merch vetting output
            improved_host_experience_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_host_expertise_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_host_recognition_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_host_recognition_subtitle: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_host_credential: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_experience_title: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional(),
            improved_experience_description: ExperienceVettingBaseQuestion.extend({
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }).optional()
        })
    }),
    isHostModified: z.boolean().optional(),
    itineraries: z.array(RemappedItinerariesVetting)
})
export type RemapExperienceVetting = z.infer<typeof RemapExperienceVetting>

export type ExperienceDiffsMap = {
    [K in keyof ExperienceVettingMerchOutputSchema]: K extends 'activities' ? ContentDiff[][] : ContentDiff
}

export const EditExperienceFlagsSectionUrlParam = z.enum(['expertises', 'itineraries', 'galleries'])
export type EditExperienceFlagsSectionUrlParam = z.infer<typeof EditExperienceFlagsSectionUrlParam>

export const EditExperienceFlagsItemUrlParam = z.enum(['images', 'answers'])
export type EditExperienceFlagsItemUrlParam = z.infer<typeof EditExperienceFlagsItemUrlParam>
