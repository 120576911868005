import {useTranslation} from '@/translations/i18n'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {Fieldset} from '../atoms/Atoms'
import {Controller} from 'react-hook-form'
import {StyledCancellationAnswerItem} from './style'

export const CancellationPolicySection = () => {
    const {t} = useTranslation()
    const {control} = useExperienceShellFormContext()

    return (
        <Fieldset title={t('experience_shell:cancellation_policy')}>
            <Controller
                control={control}
                name="settings.cancellation_policy"
                render={({field}) => (
                    <StyledCancellationAnswerItem
                        label={t('experience_shell:cancellation_policy1')}
                        type="radio"
                        checked={field.value == '7_days'}
                        onChange={() => field.onChange('7_days')}
                    />
                )}
            />
            <Controller
                control={control}
                name="settings.cancellation_policy"
                render={({field}) => (
                    <StyledCancellationAnswerItem
                        label={t('experience_shell:cancellation_policy2')}
                        type="radio"
                        checked={field.value == '24_hours'}
                        onChange={() => field.onChange('24_hours')}
                    />
                )}
            />
        </Fieldset>
    )
}
