import {Fieldset, SectionDescription} from '../atoms/Atoms'
import {useTranslation} from '@/translations/i18n'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {Controller} from 'react-hook-form'
import {SelectItem, SelectList, SelectPopover, SelectTrigger} from '@/components/ui/select-atoms/SelectAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Label} from '@/components/ui/label/Label'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {SelectProvider} from '@ariakit/react'

export const SizeSection = () => {
    const {t} = useTranslation()
    const {
        control,
        formState: {errors}
    } = useExperienceShellFormContext()

    return (
        <Fieldset title={t('experience_shell:maximum_group_size')} gap={1.5}>
            <SectionDescription>{t('experience_shell:maximum_group_size_description')}</SectionDescription>
            <Controller
                control={control}
                name="settings.maximum_public_group_size"
                render={({field}) => (
                    <SelectProvider defaultValue="" value={field.value?.toString()} setValue={field.onChange}>
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('experience_shell:maximum_public_group_size')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('experience_shell:choose_a_number_of_guests')}
                                displayValue={value => value}
                            />
                            <InputHelpText helpText={t('experience_shell:maximum_public_group_size_description')} />
                            {errors.settings?.maximum_public_group_size && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                        <SelectPopover>
                            <SelectList>
                                {Array.from({length: 200}, (_, index) => {
                                    const value = index + 1
                                    return (
                                        <SelectItem key={value} value={value.toString()}>
                                            {value}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </SelectPopover>
                    </SelectProvider>
                )}
            />
            <Controller
                control={control}
                name="settings.maximum_private_group_size"
                render={({field}) => (
                    <SelectProvider defaultValue="" value={field.value?.toString()} setValue={field.onChange}>
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('experience_shell:maximum_private_group_size')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('experience_shell:choose_a_number_of_guests')}
                                displayValue={value => value}
                            />
                            <InputHelpText helpText={t('experience_shell:maximum_private_group_size_description')} />
                            {errors.settings?.maximum_private_group_size && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                        <SelectPopover>
                            <SelectList>
                                {Array.from({length: 30}, (_, index) => {
                                    const value = index + 1
                                    return (
                                        <SelectItem key={value} value={value.toString()}>
                                            {value}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </SelectPopover>
                    </SelectProvider>
                )}
            />
        </Fieldset>
    )
}
