import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {
    StyledServiceVettingFormsWrapper,
    StyledServiceMerchandisingVettingWrapper
} from '@/pages/service-vetting/merchandising/style'
import {ServiceVettingMerchFormSkeleton} from '@/features/service-vetting/components/service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {StyledServiceVettingMerchInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/style'
import {OpsVettingMerchFormHeader} from '@/features/ops-vetting/components/ops-vetting-merch-form-header/OpsVettingMerchFormHeader'
import {useGetExperienceVetting} from '@/features/experience-vetting/queries/useGetExperienceVetting'
import {ExpVettingMerchForms} from '@/features/experience-vetting/components/exp-vetting-merch-forms/ExpVettingMerchForms'

export const ExperienceMerchandisingVettingPage = () => {
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {remappedData: experience, isPending} = useGetExperienceVetting(urlParams.id)

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            {isPending || !experience ? (
                <ExperienceMerchandisingVettingPageSkeleton />
            ) : (
                <ExpVettingMerchForms experience={experience} />
            )}
        </ScrollArea>
    )
}
ExperienceMerchandisingVettingPage.displayName = 'ExperienceMerchandisingVettingPage'

const ExperienceMerchandisingVettingPageSkeleton = () => {
    const {t} = useTranslation()
    return (
        <StyledServiceMerchandisingVettingWrapper fullWidth>
            <OpsVettingHero fullWidth justify="space-between" align="center">
                <h3>{t('vetting:merch:service:title')}</h3>
                <Flexbox gap={2}>
                    <Skeleton width={100} height={24} />
                    <Skeleton width={100} height={24} />
                    <Skeleton width={100} height={24} />
                </Flexbox>
            </OpsVettingHero>
            <StyledServiceVettingFormsWrapper fullWidth>
                {[0, 1].map(index => (
                    <StyledServiceVettingMerchInputForm key={index}>
                        <OpsVettingMerchFormHeader
                            icon={<Skeleton width={24} height={24} />}
                            title={<Skeleton width={100} height={24} />}
                            description={<Skeleton width={200} height={24} />}
                        />
                        <ServiceVettingMerchFormSkeleton />
                    </StyledServiceVettingMerchInputForm>
                ))}
            </StyledServiceVettingFormsWrapper>
        </StyledServiceMerchandisingVettingWrapper>
    )
}
