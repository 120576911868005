import {
    DialogBody,
    DialogDescription,
    DialogDisclosure,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider
} from '@/components/ui/dialog-atoms/DialogAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from '@/translations/i18n'
import {DialogStore} from '@ariakit/react'
import {FC, ReactElement} from 'react'
import {StyledDialog, StyledStep} from './style'
import {Button} from '@/components/ui/button/Button'
import {ShellStepKeyName} from '../../types'
import {stepToIcon, stepToLabel} from '../../utils'
import {Progress} from '../progress/Progress'
import {useTheme} from 'styled-components'
import {useStoreExperienceSubmission} from '../../queries/useStoreExperienceSubmission'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {errorHandler} from '@/utilities/helpers'

export const SubmitModal: FC<{
    trigger: ReactElement
    store: DialogStore
    experienceId: number
    galleryImagesCount: number
}> = ({trigger, store, experienceId, galleryImagesCount}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const storeExperienceSubmissionMutation = useStoreExperienceSubmission({
        experienceId,
        options: {onError: errorHandler}
    })

    return (
        <DialogProvider store={store}>
            <DialogDisclosure render={trigger} />
            <StyledDialog
                width={'680px'}
                hideOnEscape={!storeExperienceSubmissionMutation.isPending}
                hideOnInteractOutside={!storeExperienceSubmissionMutation.isPending}
            >
                <DialogHeader>
                    <DialogDismiss disabled={storeExperienceSubmissionMutation.isPending} />
                    <DialogHeading>{t('experience_shell:submit_to_host')}</DialogHeading>
                </DialogHeader>
                <DialogBody>
                    <Flexbox direction="column" gap={2}>
                        <h2>{t('experience_shell:submit_to_host')}</h2>
                        <DialogDescription>{t('experience_shell:submit_to_host_description')}</DialogDescription>
                    </Flexbox>
                    <Flexbox direction="column" fullWidth>
                        {ShellStepKeyName.options.map(step => {
                            const StepIcon = stepToIcon[step]

                            return (
                                <StyledStep key={step}>
                                    <Flexbox align="center" gap={2}>
                                        <StepIcon size={24} fill={theme.palette.neutral[600]} />
                                        <h3>{t(stepToLabel[step])}</h3>
                                    </Flexbox>
                                    <Progress
                                        experienceId={experienceId}
                                        step={step}
                                        isItineraryLocked={false}
                                        isLegalLocked={false}
                                        galleryImagesCount={galleryImagesCount}
                                    />
                                </StyledStep>
                            )
                        })}
                    </Flexbox>
                </DialogBody>
                <DialogFooter>
                    <DialogDismiss
                        render={<Button size="sm" variant="tertiary" />}
                        disabled={storeExperienceSubmissionMutation.isPending}
                    >
                        {t('commons:cancel')}
                    </DialogDismiss>
                    <Button
                        size="sm"
                        onClick={() => storeExperienceSubmissionMutation.mutate()}
                        disabled={storeExperienceSubmissionMutation.isPending}
                    >
                        {t('experience_shell:submit_to_host')}
                        {storeExperienceSubmissionMutation.isPending && <Spinner />}
                    </Button>
                </DialogFooter>
            </StyledDialog>
        </DialogProvider>
    )
}
