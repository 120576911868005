import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {HttpUpdateExperienceCoverPhotoOptions, httpUpdateExperienceCoverPhoto} from '../http/experienceVetting.http'

export const useUpdateExperiencVettingCoverPhoto = (
    urlParams: Omit<HttpUpdateExperienceCoverPhotoOptions['urlParams'], 'imageId'>,
    options?: UseMutationOptions<
        unknown,
        unknown,
        HttpUpdateExperienceCoverPhotoOptions['payload'] &
            Pick<HttpUpdateExperienceCoverPhotoOptions['urlParams'], 'imageId'>
    >
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExperienceCoverPhoto({
                urlParams: {...urlParams, imageId: payload.imageId},
                payload,
                params: {
                    responseType: 'extended'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
