import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useDropzone} from 'react-dropzone'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {useBulkPresignedServicePhotos} from '@/features/service-vetting/query/useBulkPresignedServicePhotos.ts'
import {useState} from 'react'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {GalleryItem, useServicePhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {httpConfirmUploadPhoto} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import toast from 'react-hot-toast'
import {useUploadPhotos} from '@hooks/useUploadPhotos.ts'
import {FileToUpload} from '@/types/commons.ts'
import {VETTING_MAX_FILE_TO_UPLOAD} from '@/features/ops-vetting/costants.ts'
import {ServiceGalleryImage} from '../../types'

export const ServiceVettingCriteriaGalleryUploadButton = ({
    serviceId,
    galleryId,
    disabled
}: {
    serviceId: number
    galleryId: number
    disabled?: boolean
}) => {
    const {t} = useTranslation()
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
    const setUploadingItemsStatus = useServicePhotoUpload(store => store.setUploadingItemsStatus)
    const addUploadProgressValues = useServicePhotoUpload(store => store.addUploadProgressValues)
    const addGalleryItems = useServicePhotoUpload(store => store.addGalleryItems)
    const deleteGalleryItem = useServicePhotoUpload(store => store.deleteGalleryItem)
    const queryClient = useQueryClient()
    const uploadPhotos = useUploadPhotos({
        storeAction: {setUploadingItemsStatus, addUploadProgressValues},
        onConfirm: async imageId => {
            const response = await httpConfirmUploadPhoto({
                serviceId: serviceId,
                mediaId: imageId,
                galleryId: galleryId
            })
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES]})
            const queryKey = [QUERY_KEYS.SERVICE_GALLERY_IMAGES, serviceId]
            await queryClient.cancelQueries({queryKey})
            const previousData = queryClient.getQueryData<ServiceGalleryImage[]>(queryKey)
            queryClient.setQueriesData<ServiceGalleryImage[]>(
                {queryKey},
                previousData ? [...previousData, response] : previousData
            )
            deleteGalleryItem(imageId)
        }
    })

    const bulkPresignedQuery = useBulkPresignedServicePhotos({
        urlParams: {serviceId, galleryId},
        options: {
            onSuccess: data => {
                const galleryItems: GalleryItem[] = data.map((item, index) => ({
                    id: item.media.id,
                    url: URL.createObjectURL(acceptedFiles[index]),
                    status: 'pending',
                    name: item.media.name,
                    thumbnails: null //TODO: add thumbnails
                }))
                const uploadingItems: FileToUpload[] = data.map((item, index) => ({
                    id: item.media.id,
                    presignedUrl: item.presigned_url,
                    file: acceptedFiles[index]
                }))
                addGalleryItems(galleryItems)
                uploadPhotos(uploadingItems)
            }
        }
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: VETTING_MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name), is_offering_image: false})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > VETTING_MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: VETTING_MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    return (
        <Dropzone state={dropzoneState}>
            <Button size={'sm'} variant={'secondary'} disabled={bulkPresignedQuery.isPending || disabled}>
                {bulkPresignedQuery.isPending ? <Spinner size={17} /> : <PlusIcon />}
                {t('vetting:criteria:service:gallery:upload_photos')}
            </Button>
        </Dropzone>
    )
}
