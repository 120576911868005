import {GalleryItem, useExperienceShellPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {CircularProgressBar} from '@/features/service-vetting/components/circular-progress-bar/CircularProgressBar.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon, DotsHorizontalIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {Button} from '@components/ui/button/Button.tsx'
import {DropdownContent, DropdownItem} from '@components/ui/dropdown-atoms/DropdownAtoms.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useDeleteExperienceShellPhotos} from '@/features/experience-shell/queries/useDeleteExperienceShellPhotos.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useEditExperienceShellPhotoCover} from '@/features/experience-shell/queries/useEditExperienceShellPhotoCover.ts'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
export const PhotosStepGalleryItem = ({
    image,
    galleryId,
    experienceId
}: {
    image: GalleryItem
    galleryId: number
    experienceId: number
}) => {
    const uploadProgressValues = useExperienceShellPhotoUpload(store => store.uploadProgressValues)
    const deleteGalleryItem = useExperienceShellPhotoUpload(store => store.deleteGalleryItem)
    const setGalleryItemIsCover = useExperienceShellPhotoUpload(store => store.setGalleryItemIsCover)
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const deletePhoto = useDeleteExperienceShellPhotos(
        {galleryId, experienceId},
        {
            onSuccess: () => {
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_SHELL_GALLERY_IMAGES, experienceId]})
                deleteGalleryItem(image.id)
            }
        }
    )

    const editPhotoCover = useEditExperienceShellPhotoCover(
        {galleryId, experienceId, imagesId: image.id},
        {
            onSuccess: () => {
                setGalleryItemIsCover({id: image.id, isCover: !image.isCover})
            }
        }
    )

    const onClickDeletePhoto = () => {
        image.status == 'uploaded' ? deletePhoto.mutate({imageIds: [image.id]}) : deleteGalleryItem(image.id)
    }

    const onClickSetAsCover = () => {
        editPhotoCover.mutate({isCover: !image.isCover})
    }

    return (
        <GalleryImageItem
            src={image.thumbnails?.xs || image.url}
            hasBlur={image.status != 'uploaded'}
            centerSlot={
                image.status == 'pending' ? (
                    <CircularProgressBar progress={uploadProgressValues[image.id] || 0} />
                ) : (
                    image.status == 'error' && <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                )
            }
            topRightSlot={
                image.status == 'uploaded' && (
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                            <Button size="xs" iconOnly variant="secondary">
                                <DotsHorizontalIcon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownContent side="bottom" align="end" sideOffset={4}>
                                <DropdownItem disabled={editPhotoCover.isPending} onClick={onClickSetAsCover}>
                                    <Checkbox
                                        disabled={editPhotoCover.isPending}
                                        defaultChecked={image.isCover}
                                        label={t('experience_shell:set_as_cover')}
                                    />
                                </DropdownItem>
                                <DropdownItem
                                    disabled={deletePhoto.isPending}
                                    onClick={event => {
                                        event.preventDefault()
                                        onClickDeletePhoto()
                                    }}
                                >
                                    <Flexbox align={'center'} gap={2}>
                                        {deletePhoto.isPending ? <Spinner size={20} /> : <Trash01Icon size={20} />}
                                        {t('commons:delete')}
                                    </Flexbox>
                                </DropdownItem>
                            </DropdownContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                )
            }
            topLeftSlot={
                image.status == 'uploaded' &&
                image.isCover && <Chip label={t('experience_shell:cover')} variant={'neutral'} shape={'circle'} />
            }
        />
    )
}
