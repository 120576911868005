import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {Experience} from '../types'
import {QUERY_KEYS} from '@/queryClient'
import {httpEditExperience, httpEditExperienceOptions} from '../http/experienceShell.http'

export const useEditExperience = ({
    experienceId,
    options
}: {
    experienceId: number
    options?: Omit<UseMutationOptions<Experience, unknown, httpEditExperienceOptions['payload']>, 'mutationFn'>
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: payload =>
            httpEditExperience({urlParams: {experienceId}, payload, params: {responseType: 'extended'}}),
        ...options,
        onSuccess: (data, variables, context) => {
            queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
