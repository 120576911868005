import {
    Offering,
    OfferingTypes,
    RemappedService,
    Service,
    ServiceBaseAnswer,
    ServiceDiffsMap,
    ServiceFlag,
    ServiceGalleryImage
} from '@/features/service-vetting/types.ts'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from './formSchemas'
import {ObjectValues} from '@utilities/helpers.ts'
import {TKey} from '@/translations/i18n'

export const remapServiceExpertise = (expertise: Service['expertise']) =>
    expertise.question_answer.reduce(
        (previousValue, currentValue) => {
            if (currentValue.question_key_name == 'experience_years' && currentValue.answer) {
                return {
                    ...previousValue,
                    experience_years: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'summary' && currentValue.answer) {
                return {
                    ...previousValue,
                    summary: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'highlights' && currentValue.answer) {
                return {
                    ...previousValue,
                    highlights: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'inspiration' && currentValue.answer) {
                return {
                    ...previousValue,
                    inspiration: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'background' && currentValue.answer) {
                return {
                    ...previousValue,
                    background: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'links' && currentValue.answer) {
                return {
                    ...previousValue,
                    links: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'profile_photo' && currentValue.answer) {
                return {
                    ...previousValue,
                    profile_photo: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'location' && currentValue.answer) {
                return {
                    ...previousValue,
                    location: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'cities' && currentValue.answer) {
                return {
                    ...previousValue,
                    cities: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_experience_title' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_experience_title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_experience_description' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_experience_description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_education_title' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_education_title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_education_description' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_education_description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_career_highlight_title' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_career_highlight_title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'host_career_highlight_description' && currentValue.answer) {
                return {
                    ...previousValue,
                    host_career_highlight_description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'service_title' && currentValue.answer) {
                return {
                    ...previousValue,
                    service_title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            if (currentValue.question_key_name == 'service_description' && currentValue.answer) {
                return {
                    ...previousValue,
                    service_description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer || ''
                    }
                }
            }
            return previousValue
        },
        {
            experience_years: {} as ServiceBaseAnswer,
            previus_answer: {} as ServiceBaseAnswer,
            summary: {} as ServiceBaseAnswer,
            highlights: {} as ServiceBaseAnswer,
            inspiration: {} as ServiceBaseAnswer,
            background: {} as ServiceBaseAnswer,
            accolades: {} as ServiceBaseAnswer,
            links: {} as {answer: string[]; id: number; flags: ServiceFlag[]; previous_answer: string},
            location: {} as ServiceBaseAnswer,
            profile_photo: {} as ServiceBaseAnswer,
            cities: {} as {answer: string[]; id: number; flags: ServiceFlag[]; previous_answer: string},
            host_experience_title: {} as ServiceBaseAnswer,
            host_experience_description: {} as ServiceBaseAnswer,
            host_education_title: {} as ServiceBaseAnswer,
            host_education_description: {} as ServiceBaseAnswer,
            host_career_highlight_title: {} as ServiceBaseAnswer,
            host_career_highlight_description: {} as ServiceBaseAnswer,
            service_title: {} as ServiceBaseAnswer,
            service_description: {} as ServiceBaseAnswer
        }
    )

export const remapServiceOfferings = (offering: Offering) =>
    offering.question_answer.reduce(
        (previousValue, currentValue) => {
            if (currentValue.question_key_name == 'title') {
                return {
                    ...previousValue,
                    title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'price_per_guest') {
                return {
                    ...previousValue,
                    price_per_guest: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'price_per_group') {
                return {
                    ...previousValue,
                    price_per_group: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'currency') {
                return {
                    ...previousValue,
                    currency: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'duration') {
                return {
                    ...previousValue,
                    duration: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'description') {
                return {
                    ...previousValue,
                    description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'main_photo') {
                return {
                    ...previousValue,
                    main_photo: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'improved_title') {
                return {
                    ...previousValue,
                    improved_title: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }
            if (currentValue.question_key_name == 'improved_description') {
                return {
                    ...previousValue,
                    improved_description: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags,
                        previous_answer: currentValue.previous_answer ?? ''
                    }
                }
            }

            return previousValue
        },
        {
            title: {} as ServiceBaseAnswer,
            price_per_guest: {} as ServiceBaseAnswer,
            duration: {} as ServiceBaseAnswer,
            description: {} as ServiceBaseAnswer,
            currency: {} as ServiceBaseAnswer,
            improved_title: {} as ServiceBaseAnswer,
            improved_description: {} as ServiceBaseAnswer
        }
    )

export const remapServiceForVetting = (service: Service): RemappedService => {
    return {
        ...service,
        expertise: {...service.expertise, question_answer: remapServiceExpertise(service.expertise)},
        offerings: service.offerings.map(offering => ({...offering, question_answer: remapServiceOfferings(offering)}))
    }
}

export const groupByCountry = (cities: Service['submission_cities']): Record<string, string[]> => {
    return cities.reduce<Record<string, string[]>>((acc, entry) => {
        const country = entry.city.country
        const name = entry.city.name

        if (!acc[country]) {
            acc[country] = []
        }
        acc[country].push(name)

        return acc
    }, {})
}

export const checkErrors = ({service, images}: {service: RemappedService; images: ServiceGalleryImage[]}) => {
    const expertiseErrors: TKey[] = []
    const itemErrors: {itemId: number; error: TKey}[] = []
    const galleryErrors: TKey[] = []
    const rejectedImagesErrors: TKey[] = []
    const remapGallery = images.filter(img => !img.is_offering_image && !img.is_host_approval_needed && !img.is_cover)

    if (!service.expertise.status) {
        expertiseErrors.push('vetting:criteria:service:validation_errors:make_a_decision')
    } else if (
        service.expertise.status == 'declined' &&
        service.expertise.flags &&
        service.expertise.flags.length == 0
    ) {
        expertiseErrors.push('vetting:criteria:service:validation_errors:select_at_least_one_flag')
    } else if (service.expertise.status == 'back_to_draft') {
        if (ObjectValues(service.expertise.question_answer).every(value => !value.flags || value.flags.length == 0)) {
            expertiseErrors.push('vetting:criteria:service:validation_errors:flag_at_least_one_field')
        }
    }
    if (service.expertise.status != 'declined') {
        service.offerings.forEach(offering => {
            if (!offering.status) {
                itemErrors.push({
                    itemId: offering.id,
                    error: 'vetting:criteria:service:validation_errors:make_a_decision'
                })
            } else if (offering.status == 'declined' && offering.flags?.length == 0) {
                itemErrors.push({
                    itemId: offering.id,
                    error: 'vetting:criteria:service:validation_errors:select_at_least_one_flag'
                })
            } else if (offering.status == 'back_to_draft') {
                if (ObjectValues(offering.question_answer).every(value => value?.flags?.length === 0)) {
                    itemErrors.push({
                        itemId: offering.id,
                        error: 'vetting:criteria:service:validation_errors:flag_at_least_one_field'
                    })
                }
            }
        })
        if (remapGallery.some(value => value.is_approved === null)) {
            galleryErrors.push('vetting:criteria:service:validation_errors:select_accept_reject')
        }
        if (remapGallery.filter(value => value.is_approved === false).some(value => value.flags.length == 0)) {
            rejectedImagesErrors.push('vetting:criteria:service:validation_errors:select_flag_for_rejected_image')
        }
    }
    return {
        errors: {expertiseErrors, itemErrors, galleryErrors, rejectedImagesErrors},
        isValid: ObjectValues({
            expertiseErrors,
            itemErrors,
            galleryErrors,
            rejectedImagesErrors
        }).every(error => error.length == 0)
    }
}

//merch copy input form defaultValues remapping
export const remapMerchCopyInputForm = (service: RemappedService): ServiceMerchandisingInputSchema => {
    return {
        host_name: service.host.first_name,
        host_experience: service.expertise.question_answer.experience_years.answer,
        host_experience_summary: service.expertise.question_answer.summary.answer,
        host_experience_highlight: service.expertise.question_answer.highlights.answer,
        host_experience_background: service.expertise.question_answer.background.answer,
        service_title: service.expertise.question_answer.inspiration.answer,
        service_byline: '',
        offerings: service.offerings.map(offering => ({
            id: offering.id,
            title: offering.question_answer.title.answer,
            description: offering.question_answer.description.answer,
            duration: offering.question_answer.duration.answer,
            type: offering.type
        }))
    }
}

//merch copy AI-output form defaultValues remapping
export const remapMerchCopyAiSubmittedForm = (service: RemappedService): ServiceMerchandisingOutputSchema => {
    return {
        host_experience_title: service.expertise.question_answer.host_experience_title.answer,
        host_experience_description: service.expertise.question_answer.host_experience_description.answer,
        host_education_title: service.expertise.question_answer.host_education_title.answer,
        host_education_description: service.expertise.question_answer.host_education_description.answer,
        host_career_highlight_title: service.expertise.question_answer.host_career_highlight_title.answer,
        host_career_highlight_description: service.expertise.question_answer.host_career_highlight_description.answer,
        service_title: service.expertise.question_answer.service_title.answer,
        service_description: service.expertise.question_answer.service_description.answer,
        offerings: service.offerings.map(offering => ({
            id: offering.id,
            title: offering.question_answer.title.answer,
            description: offering.question_answer.description.answer,
            duration: offering.question_answer.duration.answer,
            improved_title: offering.question_answer.improved_title.answer,
            improved_description: offering.question_answer.improved_description.answer,
            type: offering.type
        }))
    }
}

//merch copy host edits defaultValues remapping
export const remapMerchCopyHostEdits = (service: RemappedService): ServiceMerchandisingOutputSchema => {
    return {
        host_experience_title: service.expertise.question_answer.host_experience_title.previous_answer,
        host_experience_description: service.expertise.question_answer.host_experience_description.previous_answer,
        host_education_title: service.expertise.question_answer.host_education_title.previous_answer,
        host_education_description: service.expertise.question_answer.host_education_description.previous_answer,
        host_career_highlight_title: service.expertise.question_answer.host_career_highlight_title.previous_answer,
        host_career_highlight_description:
            service.expertise.question_answer.host_career_highlight_description.previous_answer,
        service_title: service.expertise.question_answer.service_title.previous_answer,
        service_description: service.expertise.question_answer.service_description.previous_answer,
        offerings: service.offerings.map(offering => ({
            id: offering.id,
            title: offering.question_answer.title.previous_answer,
            description: offering.question_answer.description.previous_answer,
            duration: offering.question_answer.duration.previous_answer,
            improved_title: offering.question_answer.improved_title.previous_answer,
            improved_description: offering.question_answer.improved_description.previous_answer,
            type: offering.type
        }))
    }
}

export const remapMerchCopyHostEditsDiffs = (service: RemappedService): ServiceDiffsMap => {
    return {
        service_title: {
            hasDiff:
                service.expertise.question_answer.service_title.previous_answer !==
                service.expertise.question_answer.service_title.answer,
            fieldSize: 'sm'
        },
        service_description: {
            hasDiff:
                service.expertise.question_answer.service_description.previous_answer !==
                service.expertise.question_answer.service_description.answer,
            fieldSize: 'md'
        },
        host_experience_title: {
            hasDiff:
                service.expertise.question_answer.host_experience_title.previous_answer !==
                service.expertise.question_answer.host_experience_title.answer,
            fieldSize: 'sm'
        },
        host_experience_description: {
            hasDiff:
                service.expertise.question_answer.host_experience_description.previous_answer !==
                service.expertise.question_answer.host_experience_description.answer,
            fieldSize: 'md'
        },
        host_education_title: {
            hasDiff:
                service.expertise.question_answer.host_education_title.previous_answer !==
                service.expertise.question_answer.host_education_title.answer,
            fieldSize: 'sm'
        },
        host_education_description: {
            hasDiff:
                service.expertise.question_answer.host_education_description.previous_answer !==
                service.expertise.question_answer.host_education_description.answer,
            fieldSize: 'md'
        },
        host_career_highlight_title: {
            hasDiff:
                service.expertise.question_answer.host_career_highlight_title.previous_answer !==
                service.expertise.question_answer.host_career_highlight_title.answer,
            fieldSize: 'sm'
        },
        host_career_highlight_description: {
            hasDiff:
                service.expertise.question_answer.host_career_highlight_description.previous_answer !==
                service.expertise.question_answer.host_career_highlight_description.answer,
            fieldSize: 'md'
        },
        offerings: service.offerings.map(offering => [
            {
                hasDiff:
                    offering.question_answer.improved_title.previous_answer !==
                    offering.question_answer.improved_title.answer,
                fieldSize: 'sm'
            },
            {
                hasDiff:
                    offering.question_answer.improved_description.previous_answer !==
                    offering.question_answer.improved_description.answer,
                fieldSize: 'lg'
            }
        ])
    }
}

export const getOfferingTypeLabel = (type: OfferingTypes): string => {
    if (!type) return 'vetting:merch:service:offering_type:default'

    const typeLabels: Record<OfferingTypes, string> = {
        basic: 'vetting:merch:service:offering_type:basic',
        standard: 'vetting:merch:service:offering_type:standard',
        luxe: 'vetting:merch:service:offering_type:luxe',
        exclusive: 'vetting:merch:service:offering_type:exclusive',
        other: 'vetting:merch:service:offering_type:other'
    }

    const label = typeLabels[type] || 'vetting:merch:service:offering_type:default'
    return label
}
