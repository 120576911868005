import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {httpAddServiceItemFlags, HttpEditItemFlags} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {ServiceFlag, ServiceGalleryImage} from '@/features/service-vetting/types.ts'

export const useAddServiceGalleryImageFlags = (
    urlParams: Omit<HttpEditItemFlags['urlParams'], 'section' | 'item' | 'itemId'>,
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ServiceFlag[]; itemId: number}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpAddServiceItemFlags({
                urlParams: {...urlParams, section: 'gallery', item: 'images', itemId: payload.itemId},
                payload: {flags: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<ServiceGalleryImage[]>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<ServiceGalleryImage[]>(
                    {queryKey},
                    previousData.map(image => {
                        if (image.id == variables.itemId) {
                            return {
                                ...image,
                                flags: [...image.flags, ...variables.flags]
                            }
                        }
                        return image
                    })
                )
            }
            return previousData
        },
        ...options
    })
}
