import {DialogDismiss, DialogFooter, DialogHeader, DialogHeading} from '@components/ui/dialog-atoms/DialogAtoms.tsx'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {useGetExperienceShellGalleryImages} from '@/features/experience-shell/queries/useGetExperienceShellGalleryImages.ts'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {StyledGalleryItemSkeleton} from '@components/commons/gallery-image-item/style.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useState} from 'react'
import {
    OpsVettingCheckIconContainer,
    OpsVettingChoosePhotoDialog,
    OpsVettingChoosePhotoDialogBody
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'

export const SelectItineraryPhotoDialogBody = ({
    experienceId,
    galleryId,
    onClose,
    defaultSelectedImageId,
    onConfirm
}: {
    experienceId: number
    galleryId: number
    onClose: () => void
    defaultSelectedImageId: number | null
    onConfirm: (id: number) => void
}) => {
    const {t} = useTranslation()
    const {data: images, isPending, isError} = useGetExperienceShellGalleryImages({experienceId, galleryId})
    const [selectedImageId, setSelectedImageId] = useState<number | null>(defaultSelectedImageId)

    const disableConfirm = !selectedImageId || selectedImageId == defaultSelectedImageId
    return (
        <OpsVettingChoosePhotoDialog onClose={onClose} width="680px">
            <DialogHeader>
                <DialogDismiss />
                <DialogHeading>{t('commons:choose_a_photo')}</DialogHeading>
            </DialogHeader>
            <ScrollArea scrollbar={<Scrollbar />}>
                <OpsVettingChoosePhotoDialogBody>
                    {isPending ? (
                        Array.from({length: 6}).map((_, index) => <StyledGalleryItemSkeleton key={index} />)
                    ) : isError ? (
                        <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                    ) : (
                        images?.map(image => (
                            <GalleryImageItem
                                key={image.id}
                                onClick={() => setSelectedImageId(image.id)}
                                topLeftSlot={
                                    selectedImageId == image.id && (
                                        <OpsVettingCheckIconContainer>
                                            <CheckIcon />
                                        </OpsVettingCheckIconContainer>
                                    )
                                }
                                src={image.url}
                            />
                        ))
                    )}
                </OpsVettingChoosePhotoDialogBody>
            </ScrollArea>
            <DialogFooter>
                <DialogDismiss render={<Button variant="tertiary" />}>{t('commons:cancel')}</DialogDismiss>
                <DialogDismiss
                    render={
                        <Button
                            disabled={disableConfirm}
                            onClick={() => selectedImageId && onConfirm(selectedImageId)}
                        />
                    }
                >
                    {t('commons:confirm')}
                </DialogDismiss>
            </DialogFooter>
        </OpsVettingChoosePhotoDialog>
    )
}
