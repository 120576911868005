import {zodResolver} from '@hookform/resolvers/zod'
import {FormProvider, useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {
    SERVICE_OUTPUT_CHARACTER_LIMITS,
    ServiceMerchandisingOutputSchema
} from '@/features/service-vetting/formSchemas.ts'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchOutputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {ArrowRightIcon, Stars01Icon, User01Icon} from '@/components/ui/icon/Icon'
import {OpsVettingMerchFormHeader} from '../../../ops-vetting/components/ops-vetting-merch-form-header/OpsVettingMerchFormHeader'
import {RefObject, useEffect} from 'react'
import {ServiceVettingMerchFormSkeleton} from '../service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {useTranslation} from 'react-i18next'
import {getOfferingTypeLabel} from '../../utils'
import {CharCounter} from '@/components/commons/char-counter/CharCounter'
import {Button} from '@/components/ui/button/Button'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {useSaveServiceMerchVetting} from '../../query/useSaveServiceMerchVetting'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'

interface ServiceVettingMerchOutputFormProps {
    isLoading?: boolean
    isReadOnly?: boolean
    isHostEdits?: boolean
    bgStyle?: 'light' | 'dark'
    defaultValues?: ServiceMerchandisingOutputSchema
    finalSubmitRef: RefObject<HTMLButtonElement>
    handleFinalSubmissionCb: SubmitHandler<ServiceMerchandisingOutputSchema>
}

interface HeaderConfig {
    variant: 'base' | 'blue'
    icon: JSX.Element
    title: string
    description: string
    showSaveButton: boolean
}

export const ServiceVettingMerchOutputForm = ({
    isLoading = false,
    isReadOnly = false,
    isHostEdits = false,
    bgStyle = 'dark',
    defaultValues,
    finalSubmitRef,
    handleFinalSubmissionCb
}: ServiceVettingMerchOutputFormProps) => {
    const {t} = useTranslation()
    const params = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const serviceId = params?.urlParams.id
    const methods = useForm<ServiceMerchandisingOutputSchema>({
        resolver: zodResolver(ServiceMerchandisingOutputSchema),
        defaultValues: defaultValues
    })

    const {
        register,
        control,
        reset,
        getValues,
        formState: {errors}
    } = methods
    const {fields} = useFieldArray({control, name: 'offerings'})

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues])

    const {mutate: saveServiceMerchVetting, isPending: isSavingServiceMerchVetting} = useSaveServiceMerchVetting(
        {serviceId},
        {
            onSuccess: () => toast.success(t('vetting:merch:service:generated_content_saved_tooltip')),
            onError: errorHandler
        }
    )

    const handleSaveServiceMerchVetting = () => {
        const payload = {
            ...getValues(),
            is_host_approval_needed: false,
            is_waitlisted: false
        }
        saveServiceMerchVetting(payload)
    }

    const getHeaderConfig = (): HeaderConfig => {
        if (isReadOnly && !isHostEdits) {
            return {
                variant: 'base',
                icon: <ArrowRightIcon />,
                title: t('vetting:merch:service:output_form_title_readonly'),
                description: t('vetting:merch:service:output_form_description_readonly'),
                showSaveButton: false
            }
        }

        if (isHostEdits) {
            return {
                variant: 'blue',
                icon: <User01Icon />,
                title: t('vetting:merch:service:output_form_title_host_edits'),
                description: t('vetting:merch:service:output_form_description_host_edits'),
                showSaveButton: !isReadOnly && !!serviceId
            }
        }

        return {
            variant: 'blue',
            icon: <Stars01Icon />,
            title: t('vetting:merch:service:output_form_title'),
            description: t('vetting:merch:service:output_form_description'),
            showSaveButton: !!serviceId
        }
    }

    const headerConfig = getHeaderConfig()

    const SaveButton = () => (
        <Button
            variant={'secondary'}
            size={'sm'}
            onClick={handleSaveServiceMerchVetting}
            disabled={isSavingServiceMerchVetting || isLoading}
        >
            {isSavingServiceMerchVetting && <Spinner />}
            {t('common:save')}
        </Button>
    )

    return (
        <FormProvider {...methods}>
            <StyledServiceVettingMerchOutputForm
                $bgStyle={bgStyle}
                onSubmit={methods.handleSubmit(handleFinalSubmissionCb)}
            >
                <OpsVettingMerchFormHeader
                    variant={headerConfig.variant}
                    icon={headerConfig.icon}
                    title={headerConfig.title}
                    description={headerConfig.description}
                    endSlot={headerConfig.showSaveButton ? <SaveButton /> : undefined}
                />

                {isLoading ? (
                    <ServiceVettingMerchFormSkeleton />
                ) : (
                    <Flexbox direction={'column'} gap={2} fullWidth>
                        <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                            <h4>{t('vetting:merch:service:form_host_section_title')}</h4>
                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_experience_title')}
                                    {...register('host_experience_title')}
                                    errorMessage={errors?.host_experience_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_experience_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_experience_description')}
                                    {...register('host_experience_description')}
                                    errorMessage={errors?.host_experience_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_experience_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.DESCRIPTION}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_education_title')}
                                    {...register('host_education_title')}
                                    errorMessage={errors?.host_education_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_education_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_education_description')}
                                    {...register('host_education_description')}
                                    errorMessage={errors?.host_education_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_education_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.DESCRIPTION}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_career_highlight_title')}
                                    {...register('host_career_highlight_title')}
                                    errorMessage={errors?.host_career_highlight_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_career_highlight_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_career_highlight_description')}
                                    {...register('host_career_highlight_description')}
                                    errorMessage={errors?.host_career_highlight_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_career_highlight_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.DESCRIPTION}
                                />
                            </Flexbox>
                        </StyledServiceMerchVettingFormHostSection>

                        <Divider direction={'horizontal'} />

                        <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                            <h4>{t('vetting:merch:service:form_offering_section_title')}</h4>
                            <Flexbox direction="column" fullWidth>
                                <Flexbox direction="column" fullWidth>
                                    <InputText
                                        disabled={isReadOnly}
                                        type={'text'}
                                        label={t('vetting:merch:service:service_title')}
                                        {...register('service_title')}
                                    />
                                    <CharCounter<ServiceMerchandisingOutputSchema>
                                        name="service_title"
                                        maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.TITLE}
                                    />
                                </Flexbox>
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:service_description')}
                                    {...register('service_description')}
                                    errorMessage={errors?.service_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="service_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.DESCRIPTION}
                                />
                            </Flexbox>

                            {fields.map((offering, index) => (
                                <Flexbox fullWidth direction={'column'} gap={2} key={offering.id}>
                                    <p>
                                        {t(getOfferingTypeLabel(offering.type))}
                                        {t('vetting:merch:service:offering_duration', {
                                            duration: offering.duration ?? '-'
                                        })}
                                    </p>
                                    <Flexbox direction="column" fullWidth>
                                        <InputText
                                            disabled={isReadOnly}
                                            type={'text'}
                                            label={t('vetting:merch:service:offering_title')}
                                            {...register(`offerings.${index}.improved_title`)}
                                        />
                                        <CharCounter<ServiceMerchandisingOutputSchema>
                                            name={`offerings.${index}.improved_title`}
                                            maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.TITLE}
                                        />
                                    </Flexbox>

                                    <Flexbox direction="column" fullWidth>
                                        <TextArea
                                            disabled={isReadOnly}
                                            rows={4}
                                            label={t('vetting:merch:service:offering_description_label')}
                                            {...register(`offerings.${index}.improved_description`)}
                                        />
                                        <CharCounter<ServiceMerchandisingOutputSchema>
                                            name={`offerings.${index}.improved_description`}
                                            maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.DESCRIPTION}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </StyledServiceMerchVettingFormOfferingSection>
                    </Flexbox>
                )}
                <button type="submit" ref={finalSubmitRef} style={{display: 'none'}} />
            </StyledServiceVettingMerchOutputForm>
        </FormProvider>
    )
}
