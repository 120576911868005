import {Trans, useTranslation} from '@/translations/i18n'
import {Content, Footer, SectionDescription} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon, InfoCircleIcon} from '@/components/ui/icon/Icon'
import {StyledActivitiesTitle, StyledBanner} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ActivityCard} from './ActivityCard'
import {Divider} from '@/components/ui/divider/Divider'
import {Fragment} from 'react/jsx-runtime'
import {Experience, StepKeyName} from '@/features/experience-shell/types.ts'
import {useChangeStep} from '@/features/experience-shell/hooks/useChangeStep.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const ItineraryStep = ({
    experienceId,
    galleryId,
    itineraries
}: {
    experienceId: number
    galleryId: number
    itineraries: Experience['itineraries']
}) => {
    const {t} = useTranslation()

    const {editStepMutation} = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.itinerary
    })
    return (
        <>
            <Content title={t('experience_shell:step_itinerary')} gap={4}>
                <Flexbox direction="column" gap={6}>
                    <Flexbox direction="column" gap={4}>
                        <SectionDescription>{t('experience_shell:itinerary_description')}</SectionDescription>
                        <StyledBanner>
                            <div>
                                <InfoCircleIcon size={20} />
                            </div>
                            <p>
                                <Trans
                                    i18nKey="experience_shell:itinerary_banner_text"
                                    components={{
                                        u: <u onClick={() => editStepMutation.mutate(StepKeyName.enum.merchandising)} />
                                    }}
                                />
                            </p>
                        </StyledBanner>
                    </Flexbox>
                    <StyledActivitiesTitle>
                        {t('experience_shell:x_activities', {count: itineraries.length})}
                    </StyledActivitiesTitle>
                    {itineraries.map(item => (
                        <Fragment key={item.id}>
                            <ActivityCard itinerary={item} experienceId={experienceId} galleryId={galleryId} />
                            <Divider direction="horizontal" />
                        </Fragment>
                    ))}
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    disabled={editStepMutation.isPending}
                    onClick={() => editStepMutation.mutate(StepKeyName.enum.photos)}
                    size="sm"
                    variant="ghost"
                >
                    {t('commons:back')}
                    {editStepMutation.isPending && editStepMutation.variables == StepKeyName.enum.photos && <Spinner />}
                </Button>
                <Button
                    disabled={editStepMutation.isPending}
                    size="sm"
                    onClick={() => editStepMutation.mutate('pricing-discounts')}
                >
                    {t('commons:continue')}
                    {editStepMutation.isPending && editStepMutation.variables == 'pricing-discounts' ? (
                        <Spinner />
                    ) : (
                        <ArrowRightIcon />
                    )}
                </Button>
            </Footer>
        </>
    )
}
