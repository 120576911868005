import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '@utilities/helpers.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpDeleteBulkExperienceShellPhoto,
    HttpDeleteBulkExperienceShellPhotoOptions
} from '@/features/experience-shell/http/experienceShell.http.ts'

export const useDeleteExperienceShellPhotos = (
    urlParams: HttpDeleteBulkExperienceShellPhotoOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpDeleteBulkExperienceShellPhotoOptions['params']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: params => httpDeleteBulkExperienceShellPhoto({urlParams, params}),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
