import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {Fieldset} from '../atoms/Atoms'
import {StyledRadioAnswers} from './style'
import {useTranslation} from '@/translations/i18n'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {ActivityLevel} from '../../types'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export const ActivityLevelSection = () => {
    const {t} = useTranslation()
    const {
        register,
        formState: {errors}
    } = useExperienceShellFormContext()

    return (
        <Fieldset title={t('experience_shell:activity_level')}>
            <StyledRadioAnswers>
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:activity_level_light')}
                    value={ActivityLevel.enum.light}
                    {...register('settings.activity_level')}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:activity_level_moderate')}
                    value={ActivityLevel.enum.moderate}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:activity_level_strenuous')}
                    value={ActivityLevel.enum.strenuous}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:activity_level_extreme')}
                    value={ActivityLevel.enum.extreme}
                />
            </StyledRadioAnswers>
            {errors.settings?.activity_level && <InputHelpText error={t('errors:field_required')} />}
        </Fieldset>
    )
}
