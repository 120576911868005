import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {HttpUpdateServices, httpUpdateServices} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {Service} from '@/features/service-vetting/types.ts'

export const useUpdateService = (
    urlParams: Omit<HttpUpdateServices['urlParams'], 'section'>,
    options?: Omit<UseMutationOptions<unknown, unknown, HttpUpdateServices['payload']>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpUpdateServices({
                urlParams,
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Service>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Service>(
                    {queryKey},
                    {
                        ...previousData,
                        is_pro_photography_required: variables.is_pro_photography_required
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
