import {FileSearch01Icon} from '@/components/ui/icon/Icon'
import {ReactElement, ReactNode} from 'react'
import {useTheme} from 'styled-components'
import {StyledPhotosetIndexContainer, StyledPhotosetIndexCopyWrapper, StyledPhotosetIndexTitle} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const PhotosetIndex = ({
    title,
    endSlot,
    isLoading
}: {
    title: string | ReactElement
    endSlot?: ReactNode
    isLoading?: boolean
}) => {
    const {palette} = useTheme()

    return (
        <StyledPhotosetIndexContainer fullWidth>
            {isLoading ? (
                <Spinner size={48} fill={palette.neutral['500']} />
            ) : (
                <>
                    <FileSearch01Icon fill={palette.neutral['600']} width={48} height={48} />
                    <StyledPhotosetIndexCopyWrapper direction="column" gap={6} align="center">
                        <StyledPhotosetIndexTitle>{title}</StyledPhotosetIndexTitle>
                        {endSlot}
                    </StyledPhotosetIndexCopyWrapper>
                </>
            )}
        </StyledPhotosetIndexContainer>
    )
}
