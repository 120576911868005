import {StyledCopyTrainingContainer, StyledCopyTrainingFormsWrapper, StyledCopyTrainingWrapper} from '../style'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Settings01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {useRef, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AiPromptModal} from '@/components/commons/ai-prompt-modal/AiPromptModal'
import {EXPERIENCE_VETTING_PROMPT} from '@/features/ops-vetting/prompts/experienceVettingPrompt'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {ExpVettingMerchOutputForm} from '@/features/experience-vetting/components/exp-vetting-merch-output-form/ExpVettingMerchOutputForm'
import {
    ExperienceShellMerchOutputSchema,
    ExperienceVettingMerchOutputSchema
} from '@/features/experience-vetting/formSchemas'
import {ExpShellMerchOutputForm} from '@/features/experience-vetting/components/exp-shell-merch-output-form/ExpShellMerchOutputForm'

// const DEFAULT_TEST_VALUES: ExperienceShellMerchOutputSchema = {
//     host_experience_title: 'Learn the art of Japanese tea djfif oakfdoaskfoak dfsoksdofksdfksof oskfok',
//     host_expertise_description: "I'm a certified tea master with 15 years of experience in traditional Japanese tea.",
//     host_recognition_title: 'Featured in Japan travel magazine',
//     host_recognition_subtitle: "Recognized as one of Tokyo's top cultural experience hosts",
//     host_credential: 'Licensed instructor from the Urasenke tea school and Tokyo cultural ambassador 2022',
//     experience_title: 'Join an authentic tea ceremony in a historic Kyoto',
//     experience_description: 'Step into a 150-year-old tea house and immerse yourself in the art of tea ceremony.',
//     activities: [
//         {
//             id: 1,
//             title: 'Introduction to tea ceremony',
//             description: 'Learn about the basics of tea ceremony',
//             duration: '45 minutes'
//         },
//         {
//             id: 2,
//             title: 'Practice tea preparation',
//             description: 'Learn how to make and serve tea properly',
//             duration: '60 minutes'
//         }
//     ]
// }

export const ExpVettingCopyTraining = () => {
    const {t} = useTranslation()
    const {
        aiResponse,
        isLoading: isAiLoading,
        onMerchCopyAiCall,
        resetAiResponse
    } = useMerchCopyAi<ExperienceVettingMerchOutputSchema>()
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    const [currentPrompt, setCurrentPrompt] = useState<string>(EXPERIENCE_VETTING_PROMPT)

    const handleAiSubmission: SubmitHandler<ExperienceShellMerchOutputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }

    const handleFinalSubmission: SubmitHandler<ExperienceVettingMerchOutputSchema> = data => {
        console.log('handleFinalSubmission', data)
    }

    return (
        <StyledCopyTrainingWrapper>
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledCopyTrainingContainer fullWidth>
                    <OpsVettingHero fullWidth justify="space-between" align="center">
                        <Flexbox direction="column" gap={2}>
                            <h3>Exp vetting Copy Training</h3>
                            <p>Activities can be added and deleted only into the training tool just as a sample.</p>
                        </Flexbox>
                        <Flexbox gap={2}>
                            <AiPromptModal
                                trigger={
                                    <Button variant="tertiary" size="sm" disabled={isAiLoading}>
                                        <Settings01Icon />
                                        {t('vetting:merch:commons:customize_prompt')}
                                    </Button>
                                }
                                currentPrompt={currentPrompt}
                                onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                isLoading={isAiLoading}
                                promptType="exp_vetting"
                            />
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => aiSubmissionRef.current?.click()}
                                disabled={isAiLoading}
                            >
                                <Stars01Icon />
                                {t('vetting:merch:commons:improve_with_ai')}
                            </Button>
                        </Flexbox>
                    </OpsVettingHero>

                    <StyledCopyTrainingFormsWrapper fullWidth>
                        <ExpShellMerchOutputForm
                            isOriginalContent={true}
                            isTraining={true}
                            isReadOnly={false}
                            isHostEdits={false}
                            isLoading={false}
                            defaultValues={undefined}
                            finalSubmitRef={aiSubmissionRef}
                            handleFinalSubmissionCb={handleAiSubmission}
                        />
                        <ExpVettingMerchOutputForm
                            isTraining={true}
                            isReadOnly={false}
                            isHostEdits={true}
                            isLoading={isAiLoading}
                            defaultValues={aiResponse}
                            finalSubmitRef={finalSubmitRef}
                            handleFinalSubmissionCb={handleFinalSubmission}
                        />
                    </StyledCopyTrainingFormsWrapper>
                </StyledCopyTrainingContainer>
            </ScrollArea>
        </StyledCopyTrainingWrapper>
    )
}

ExpVettingCopyTraining.displayName = 'ExpVettingCopyTraining'
