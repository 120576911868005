import {
    OpsVettingDisabledContainer,
    OpsVettingGalleryGrid,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {RemapExperienceVetting} from '@/features/experience-vetting/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ExperienceVettingCriteriaGalleryItem} from '@/features/experience-vetting/components/experience-vetting-criteria-gallery-item/ExperienceVettingCriteriaGalleryItem.tsx'
import {ExperienceVettingCriteriaUploadButton} from '@/features/experience-vetting/components/experience-vetting-criteria-upload-button/ExperienceVettingCriteriaUploadButton.tsx'
import {useMemo, useState, useEffect} from 'react'
import {useExperienceVettingPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {ExpVettingCriteriaGalleryUploadedItem} from '@/features/experience-vetting/components/exp-vetting-criteria-gallery-uploaded-item/ExpVettingCriteriaGalleryUploadedItem.tsx'
import {ExperienceVettingCriteriaCoverPhoto} from '../experience-vetting-criteria-cover-photo/ExperienceVettingCriteriaCoverPhoto'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'
import {AlertTriangleIcon, CheckIcon, MoveIcon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {useAddExperienceGalleryFlags} from '../../queries/useAddExperienceGalleryFlags'
import {useRemoveExperienceGalleryFlags} from '../../queries/useRemoveExperienceGalleryFlags'
import {useGetExperiencesFlags} from '../../http/useGetExperiencesFlags'
import {useTheme} from 'styled-components'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {ExperienceGalleryImage} from '@/features/experience-shell/types'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {ExpVettingCriteriaGalleryUploadingItem} from '../exp-vetting-criteria-gallery-uploading-item/ExpVettingCriteriaGalleryUploadingItem'
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    MouseSensor,
    TouchSensor,
    useSensors,
    useSensor,
    DragStartEvent
} from '@dnd-kit/core'
import {SortableContext, arrayMove, rectSortingStrategy} from '@dnd-kit/sortable'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {Button} from '@/components/ui/button/Button'
import {useGalleryNeedsReviewShortcuts} from '@/features/photoset/hooks/useGalleryNeedsReviewShortcuts'
import {useUpdateExperienceVettingGalleryImagesPositions} from '../../queries/useUpdateExperienceVettingGalleryImagesPositions'
type FlagKeyName = 'lack_photo_variety' | 'requires_pro_photography'

export const ExperienceVettingCriteriaGallery = ({
    experience,
    isReadOnly
}: {
    experience: RemapExperienceVetting
    isReadOnly: boolean
}) => {
    const {t} = useTranslation()
    const {gallery} = experience
    const {palette} = useTheme()
    const isDeclined = experience.expertise.status === 'declined'
    const isDisabled = isReadOnly || isDeclined
    const [activeId, setActiveId] = useState<number | string | null>(null)
    const [orderedImages, setOrderedImages] = useState(gallery.images)

    useEffect(() => {
        setOrderedImages(gallery.images)
    }, [gallery.images])

    const flagsQuery = useGetExperiencesFlags({
        scopes: ['gallery']
    })

    const addGalleryFlags = useAddExperienceGalleryFlags({
        experienceId: experience.id,
        itemId: experience.gallery.id
    })
    const removeGalleryFlags = useRemoveExperienceGalleryFlags({
        experienceId: experience.id,
        itemId: experience.gallery.id
    })
    const updateExperienceVettingGalleryImagesPositions = useUpdateExperienceVettingGalleryImagesPositions({
        experienceId: experience.id,
        galleryId: experience.gallery.id
    })

    const [expandedImageIndex, setExpandedImageIndex] = useState<number | null>(null)
    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const errors = useExperienceVettingError(state => state.errors)
    const {galleryItems: uploadingItems} = useExperienceVettingPhotoUpload()

    const coverPhoto = useMemo(() => orderedImages.find(image => image.isCover), [orderedImages])

    const filteredGalleryImages = useMemo(
        () => orderedImages.filter(image => !image.isCover && !image.isItineraryImage && image.isApproved != false),
        [orderedImages]
    )

    const sortedGalleryImages = useMemo(() => {
        const filteredImages = orderedImages.filter(
            image => image.isCover || (!image.isItineraryImage && image.isApproved != false)
        )

        // Sort the images according to the specified priority
        return filteredImages.sort((a, b) => {
            // Cover images come first
            if (a.isCover && !b.isCover) return -1
            if (!a.isCover && b.isCover) return 1

            // If all criteria are the same, maintain original order
            return 0
        })
    }, [orderedImages])

    const pendingImagesCount = useMemo(
        () => filteredGalleryImages.filter(image => image.isApproved === null && !image.isHostApprovalNeeded).length,
        [filteredGalleryImages]
    )

    const handleFlagChange = (flagKeyName: FlagKeyName, isChecked: boolean) => {
        const flag = flagsQuery.data?.find(flag => flag.keyName === flagKeyName)
        if (!flag) return

        const mutationFunction = isChecked ? addGalleryFlags : removeGalleryFlags
        mutationFunction.mutate({flags: [flag]})
    }

    const handleClickExpandedImage = (imageId: ExperienceGalleryImage['id']) => {
        const index = sortedGalleryImages.findIndex(image => image.id === imageId)
        setExpandedImageIndex(index)
        setShowGalleryModal(true)
    }

    const handleCloseModal = () => {
        setExpandedImageIndex(null)
        setShowGalleryModal(false)
    }

    const isFlagChecked = (flagKeyName: FlagKeyName) => gallery.flags.some(flag => flag.keyName === flagKeyName)

    const renderStatusMessage = () => {
        if (pendingImagesCount > 0) {
            return t('vetting:criteria:commons:gallery:images_remaining', {
                value: pendingImagesCount
            })
        }

        return (
            <Flexbox render="span" gap={1}>
                <CheckIcon size={20} color={palette.success[600]} />
                {t('vetting:criteria:commons:gallery:images_reviewed')}
            </Flexbox>
        )
    }

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id)
    }
    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event

        if (!over) {
            setActiveId(null)
            return
        }

        const oldIndex = orderedImages.findIndex(image => image.id === active.id)
        const newIndex = orderedImages.findIndex(image => image.id === over.id)

        if (oldIndex !== newIndex) {
            // Update local state immediately for responsive UI
            const newOrderedImages = arrayMove([...orderedImages], oldIndex, newIndex)
            setOrderedImages(newOrderedImages)

            updateExperienceVettingGalleryImagesPositions.mutate(
                newOrderedImages.map((image, index) => ({id: image.id, position: index}))
            )
        }

        setActiveId(null)
    }
    const handleDragCancel = () => {
        setActiveId(null)
    }

    useGalleryNeedsReviewShortcuts({
        selectableImages: sortedGalleryImages,
        currentDetailImageIndex: expandedImageIndex || 0,
        setCurrentDetailImageIndex: setExpandedImageIndex,
        onClose: handleCloseModal
    })

    return (
        <OpsVettingSectionWrapper fullWidth direction="column" gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align="center">
                    <OpsVettingSectionTitle>{t('vetting:criteria:service:gallery:title')}</OpsVettingSectionTitle>

                    <ExperienceVettingCriteriaUploadButton
                        experienceId={experience.id}
                        galleryId={experience.gallery.id}
                        disabled={isDisabled}
                    />

                    <Checkbox
                        id="lack_photo_variety"
                        checked={isFlagChecked('lack_photo_variety')}
                        disabled={isDisabled}
                        label={t('vetting:criteria:experience:flags_key_name:lack_photo_variety')}
                        onChange={e => handleFlagChange('lack_photo_variety', e.currentTarget.checked)}
                    />

                    <Checkbox
                        id="requires_pro_photography"
                        checked={isFlagChecked('requires_pro_photography')}
                        disabled={isDisabled}
                        label={t('vetting:criteria:commons:requires_pro_photography')}
                        onChange={e => handleFlagChange('requires_pro_photography', e.currentTarget.checked)}
                    />
                </Flexbox>

                <Flexbox gap={4} align="center">
                    {errors.galleryErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant="danger"
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}

                    <OpsVettingParagraph>{renderStatusMessage()}</OpsVettingParagraph>
                </Flexbox>
            </Flexbox>

            <Divider direction="horizontal" />

            <OpsVettingDisabledContainer $isDisabled={isDeclined}>
                <OpsVettingGalleryGrid>
                    <ExperienceVettingCriteriaCoverPhoto
                        coverPhoto={coverPhoto}
                        hideAction={isReadOnly}
                        experienceId={experience.id}
                        galleryId={gallery.id}
                        onClickExpand={() => {
                            if (coverPhoto) {
                                handleClickExpandedImage(coverPhoto.id)
                            }
                        }}
                    />

                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                    >
                        <SortableContext items={filteredGalleryImages} strategy={rectSortingStrategy}>
                            {filteredGalleryImages.map(image => {
                                return image.isHostApprovalNeeded ? (
                                    <ExpVettingCriteriaGalleryUploadedItem
                                        key={image.id}
                                        image={image}
                                        galleryId={gallery.id}
                                        experienceId={experience.id}
                                        hideAction={isReadOnly}
                                        onClickExpand={() => handleClickExpandedImage(image.id)}
                                    />
                                ) : (
                                    <ExperienceVettingCriteriaGalleryItem
                                        key={image.id}
                                        image={image}
                                        experienceId={experience.id}
                                        galleryId={gallery.id}
                                        hideAction={isReadOnly}
                                        onClickExpand={() => handleClickExpandedImage(image.id)}
                                    />
                                )
                            })}
                        </SortableContext>
                        <DragOverlay style={{transformOrigin: '0 0'}}>
                            {activeId ? (
                                <GalleryImageItem
                                    style={{
                                        cursor: 'grabbing'
                                    }}
                                    src={
                                        filteredGalleryImages.find(image => image.id === activeId)?.thumbnails?.xs ||
                                        filteredGalleryImages.find(image => image.id === activeId)?.url ||
                                        ''
                                    }
                                    topLeftSlot={
                                        <Button variant="secondary" iconOnly className="show-on-hover" size="sm">
                                            <MoveIcon size={16} />
                                        </Button>
                                    }
                                />
                            ) : null}
                        </DragOverlay>
                    </DndContext>

                    {uploadingItems.map(image => (
                        <ExpVettingCriteriaGalleryUploadingItem key={image.id} image={image} hideAction={isReadOnly} />
                    ))}
                </OpsVettingGalleryGrid>
            </OpsVettingDisabledContainer>

            {showGalleryModal && expandedImageIndex !== null && (
                <GalleryModal
                    currentImage={sortedGalleryImages[expandedImageIndex]}
                    thumbnails={{
                        l: sortedGalleryImages[expandedImageIndex].url || '',
                        m: '',
                        s: '',
                        xs: ''
                    }}
                    onClose={handleCloseModal}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={sortedGalleryImages[expandedImageIndex]}
                            imagesCount={sortedGalleryImages.length}
                            onClose={handleCloseModal}
                        />
                    }
                    thumbnailsSlot={
                        <GalleryModalThumbnails
                            currentImageId={sortedGalleryImages[expandedImageIndex].id}
                            thumbnails="thumbnails"
                            images={sortedGalleryImages.map(image => ({
                                id: image.id,
                                name: image.name,
                                thumbnails: {
                                    l: image.url || '',
                                    m: image.url || '',
                                    s: image.url || '',
                                    xs: image.url || ''
                                }
                            }))}
                            onSelect={(_, index) => {
                                setExpandedImageIndex(index)
                            }}
                        />
                    }
                />
            )}
        </OpsVettingSectionWrapper>
    )
}
