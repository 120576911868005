import {
    OpsVettingCardGrid,
    OpsVettingDisabledContainer,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {RemapExperienceVetting} from '@/features/experience-vetting/types.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ExperienceVettingCriteriaItineraryCard} from '@/features/experience-vetting/components/experience-vetting-criteria-itinerary-card/ExperienceVettingCriteriaItineraryCard.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'

export const ExperienceVettingCriteriaItinerary = ({
    experience,
    isReadOnly
}: {
    experience: RemapExperienceVetting
    isReadOnly: boolean
}) => {
    const {t} = useTranslation()

    const isDeclined = experience.expertise.status === 'declined'

    const itineraryNotReviewed = experience.itineraries.filter(itinerary => itinerary.status === null)

    const {palette} = useTheme()

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <OpsVettingSectionTitle>{t('vetting:criteria:experience:itinerary:title')}</OpsVettingSectionTitle>
                <OpsVettingParagraph>
                    {itineraryNotReviewed.length > 0 ? (
                        t('vetting:criteria:experience:itinerary:x_remaining', {
                            count: itineraryNotReviewed.length
                        })
                    ) : (
                        <Flexbox render={'span'} gap={1}>
                            <CheckIcon size={20} color={palette.success[600]} />
                            {t('vetting:criteria:commons:reviewed')}
                        </Flexbox>
                    )}
                </OpsVettingParagraph>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingDisabledContainer $isDisabled={isDeclined}>
                <OpsVettingCardGrid>
                    {experience.itineraries.map((itinerary, index) => (
                        <ExperienceVettingCriteriaItineraryCard
                            key={index}
                            itinerary={itinerary}
                            index={index}
                            galleryId={experience.gallery.id}
                            isReadOnly={isReadOnly}
                        />
                    ))}
                </OpsVettingCardGrid>
            </OpsVettingDisabledContainer>
        </OpsVettingSectionWrapper>
    )
}
