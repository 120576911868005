import styled, {css} from 'styled-components'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'

export const StyledBanner = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        width: 100%;
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        padding: ${spacing * 4}px;
        border-radius: 12px;
        ${typography.textSm};
        color: ${palette.neutral[900]};
        border: 1px solid ${palette.neutral[300]};

        & > div {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${palette.blue[500]};
            color: ${palette.neutral.white};
            flex-shrink: 0;
        }
        & u {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)

export const StyledActivitiesTitle = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledActivityCard = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        width: 100%;
        display: flex;
        gap: ${spacing * 5}px;
        color: ${palette.neutral[900]};
        & h3 {
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral[700]};
        }
        & button {
            width: 240px;
        }
    `
)

export const StyledActivityCardCover = styled.div(
    ({theme: {palette, spacing, typography}}) => css`
        width: 108px;
        height: 108px;
        border-radius: 16px;
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: 1px solid ${palette.neutral[300]};
        & p {
            color: ${palette.neutral[900]};
            ${typography.textXs};
            font-weight: 500;
        }
        & svg {
            width: 24px;
            height: 24px;
            fill: ${palette.neutral[600]};
        }
    `
)

export const StyledActivityCardCoverWrapper = styled(GalleryImageItem)(
    () => css`
        width: 108px;
        height: 108px;
        border-radius: 16px;
    `
)
