import {css, styled} from 'styled-components'

export const StyledLargeGroupDiscounts = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 40px;
        gap: ${spacing * 4}px;
        align-items: end;
    `
)
