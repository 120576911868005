import {useTranslation} from '@/translations/i18n'
import {ColumnFields, Content, Fieldset, Footer, InputPrice, YesNoField} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon, PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputText} from '@/components/commons/input-text/InputText'
import {Divider} from '@/components/ui/divider/Divider'
import {useExperienceShellFormContext} from '@/features/experience-shell/hooks/useExperienceShellFormContext.ts'
import {Controller, useFieldArray, useWatch} from 'react-hook-form'
import {StyledLargeGroupDiscounts} from './style'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {FC} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Experience, StepKeyName} from '../../types'
import {checkIsItineraryLocked} from '@/features/experience-shell/utils.ts'

export const PricingDiscountStep: FC<{experienceId: number; itineraries: Experience['itineraries']}> = ({
    experienceId,
    itineraries
}) => {
    const {t} = useTranslation()
    const {
        control,
        register,
        formState: {errors},
        setValue
    } = useExperienceShellFormContext()
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'pricing_discounts.discounts'
    })
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.pricing_discounts
    })
    const perInstancePrivateGroupWatch = useWatch({control, name: 'pricing_discounts.instance_private_groups'})

    return (
        <>
            <Content title={t('experience_shell:step_pricing_discount')}>
                <Flexbox direction="column" gap={6}>
                    <Fieldset title={t('experience_shell:individual_rate')}>
                        <ColumnFields>
                            <InputPrice
                                step={'1'}
                                label={t('experience_shell:each_guest_pays')}
                                errorMessage={
                                    errors.pricing_discounts?.guest_individual_price
                                        ? t('errors:integer_error')
                                        : undefined
                                }
                                {...register('pricing_discounts.guest_individual_price', {
                                    setValueAs: value =>
                                        value == '' || value == 0 || value == null ? undefined : parseFloat(value)
                                })}
                            />
                        </ColumnFields>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} fullWidth>
                        <Controller
                            name="pricing_discounts.instance_private_groups"
                            control={control}
                            render={({field}) => (
                                <Flexbox direction="column" gap={2} fullWidth>
                                    <YesNoField
                                        labelTitle={<b>{t('experience_shell:per_instance_private_groups')}</b>}
                                        name={field.name}
                                        value={field.value}
                                        onChange={value => {
                                            field.onChange(value)
                                            if (!value) {
                                                setValue('pricing_discounts.group_minimum_price', undefined)
                                                setValue('pricing_discounts.always_require_minimum_price', undefined)
                                            }
                                        }}
                                    />
                                    <InputHelpText
                                        error={
                                            errors.pricing_discounts?.instance_private_groups
                                                ? t('errors:field_required')
                                                : undefined
                                        }
                                    />
                                </Flexbox>
                            )}
                        />
                        {perInstancePrivateGroupWatch && (
                            <>
                                <ColumnFields>
                                    <InputPrice
                                        label={t('experience_shell:minimum_price')}
                                        errorMessage={
                                            errors.pricing_discounts?.group_minimum_price
                                                ? t('experience_shell:price_error')
                                                : undefined
                                        }
                                        {...register('pricing_discounts.group_minimum_price', {
                                            setValueAs: value =>
                                                value == '' || value == 0 || value == null
                                                    ? undefined
                                                    : parseFloat(value)
                                        })}
                                    />
                                </ColumnFields>
                                <Controller
                                    name="pricing_discounts.always_require_minimum_price"
                                    control={control}
                                    render={({field}) => (
                                        <Flexbox direction="column" gap={2} fullWidth>
                                            <YesNoField
                                                labelDescription={
                                                    <b>{t('experience_shell:always_require_minimum_price')}</b>
                                                }
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            <InputHelpText
                                                error={
                                                    errors.pricing_discounts?.always_require_minimum_price
                                                        ? t('errors:field_required')
                                                        : undefined
                                                }
                                            />
                                        </Flexbox>
                                    )}
                                />
                            </>
                        )}
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Controller
                        name="pricing_discounts.new_listing_discount"
                        control={control}
                        render={({field}) => (
                            <Flexbox direction="column" gap={2} fullWidth>
                                <YesNoField
                                    labelTitle={<b>{t('experience_shell:new_listing_discount')}</b>}
                                    labelDescription={t('experience_shell:get_reviews')}
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                <InputHelpText
                                    error={
                                        errors.pricing_discounts?.new_listing_discount
                                            ? t('errors:field_required')
                                            : undefined
                                    }
                                />
                            </Flexbox>
                        )}
                    />
                    <Divider direction="horizontal" />
                    <Controller
                        name="pricing_discounts.early_bird_discount"
                        control={control}
                        render={({field}) => (
                            <Flexbox direction="column" gap={2} fullWidth>
                                <YesNoField
                                    labelTitle={<b>{t('experience_shell:early_bird_discount')}</b>}
                                    labelDescription={t('experience_shell:20_off_guests')}
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                <InputHelpText
                                    error={
                                        errors.pricing_discounts?.early_bird_discount
                                            ? t('errors:field_required')
                                            : undefined
                                    }
                                />
                            </Flexbox>
                        )}
                    />
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} fullWidth>
                        <Controller
                            name="pricing_discounts.offer_large_group_discount"
                            control={control}
                            render={({field}) => (
                                <Flexbox direction="column" gap={2} fullWidth>
                                    <YesNoField
                                        labelTitle={<b>{t('experience_shell:large_group_discount')}</b>}
                                        labelDescription={t('experience_shell:attract_larger_groups')}
                                        value={field.value}
                                        onChange={value => {
                                            field.onChange(value)
                                            value
                                                ? append({groupSize: undefined, discountInPercentage: undefined})
                                                : remove()
                                        }}
                                    />
                                    {errors.pricing_discounts?.offer_large_group_discount && (
                                        <InputHelpText error={t('errors:field_required')} />
                                    )}
                                </Flexbox>
                            )}
                        />
                        {fields.length >= 1 && (
                            <Flexbox direction="column" gap={4} fullWidth>
                                {fields.map((field, index) => (
                                    <Flexbox direction="column" gap={2} fullWidth key={field.id}>
                                        <StyledLargeGroupDiscounts>
                                            <InputText
                                                inputSize="sm"
                                                type="text"
                                                label={t('experience_shell:minimum_number_guests')}
                                                {...register(`pricing_discounts.discounts.${index}.groupSize`)}
                                            />
                                            <InputText
                                                {...register(
                                                    `pricing_discounts.discounts.${index}.discountInPercentage`
                                                )}
                                                typeIcon={<span>%</span>}
                                                inputSize="sm"
                                                type="text"
                                                label={t('experience_shell:discount')}
                                            />
                                            {fields.length >= 2 && (
                                                <Button iconOnly variant="tertiary" onClick={() => remove(index)}>
                                                    <Trash01Icon />
                                                </Button>
                                            )}
                                        </StyledLargeGroupDiscounts>
                                        {Array.isArray(errors.pricing_discounts?.discounts) &&
                                            !!errors.pricing_discounts.discounts[index] && (
                                                <StyledLargeGroupDiscounts>
                                                    {errors.pricing_discounts.discounts[index]?.groupSize ? (
                                                        <InputHelpText error={t('errors:integer_error')} />
                                                    ) : (
                                                        <span />
                                                    )}
                                                    {errors.pricing_discounts.discounts[index]
                                                        ?.discountInPercentage && (
                                                        <InputHelpText error={t('errors:percentage_error')} />
                                                    )}
                                                </StyledLargeGroupDiscounts>
                                            )}
                                    </Flexbox>
                                ))}
                                <Button
                                    size="sm"
                                    variant="tertiary"
                                    onClick={() => append({groupSize: undefined, discountInPercentage: undefined})}
                                >
                                    <PlusIcon />
                                    {t('experience_shell:add_large_group_discount')}
                                </Button>
                            </Flexbox>
                        )}
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() =>
                        changeStep.handleChangeStep({
                            isBack: true,
                            stepToGoTo: checkIsItineraryLocked(itineraries)
                                ? StepKeyName.enum.photos
                                : StepKeyName.enum.itinerary
                        })
                    }
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    onClick={() => changeStep.handleChangeStep({stepToGoTo: StepKeyName.enum.settings})}
                    disabled={changeStep.isContinueLoading || changeStep.isBackLoading}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
