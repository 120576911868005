import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {captureException} from '@sentry/react'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpSubmitService, HttpSubmitService} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {Service} from '@/features/service-vetting/types.ts'
import {errorHandler, parseAxiosPromise} from '@utilities/helpers.ts'

export const useSubmitService = (
    urlParams: HttpSubmitService['urlParams'],
    options?: UseMutationOptions<Service, unknown, HttpSubmitService['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpSubmitService({
                    urlParams,
                    payload,
                    params: {
                        response_type: 'extended'
                    }
                }),
                responseShape: Service,
                onZodError: captureException
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VETTING]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
