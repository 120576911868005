import {Outlet} from 'react-router-dom'
import {StyledExperienceVettingWrapper} from './style'
import {Container} from '@/components/ui/container/Container'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon/Icon'
import {ErrorBoundary} from '@/ErrorBoundary'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {OpsVettingHeader} from '@/features/ops-vetting/components/ops-vetting-header/OpsVettingHeader'
import {ExperienceVettingSteps} from '@utilities/constants/routes.tsx'
import {useGetExperienceVetting} from '@/features/experience-vetting/queries/useGetExperienceVetting.ts'
import {ExpVettingHeaderEndSlot} from '@/features/experience-vetting/components/exp-vetting-header-endslot/ExpVettingHeaderEndSlot.tsx'

export const ExperienceVetting = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(OpsVettingURLParams)

    return urlParams.success ? (
        <OpsVettingDataInitializer experienceId={urlParams.data.id}>
            <ErrorBoundary>
                <StyledExperienceVettingWrapper>
                    <Container fullWidth>
                        <OpsVettingHeader
                            title={t('vetting:experience_vetting')}
                            steps={ExperienceVettingSteps}
                            endSlot={<ExpVettingHeaderEndSlot experienceId={urlParams.data.id} />}
                            vettingId={urlParams.data.id}
                        />
                    </Container>
                    <Outlet context={{urlParams: urlParams.data}} />
                </StyledExperienceVettingWrapper>
            </ErrorBoundary>
        </OpsVettingDataInitializer>
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

const OpsVettingDataInitializer = ({
    experienceId,
    children
}: {
    experienceId: OpsVettingURLParams['id']
    children: ReactNode
}) => {
    useGetExperienceVetting(experienceId)
    return children
}
