import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {Photoset, PhotosetURLParams} from '@/features/photoset/types'
import {httpGetPhotoset} from '@/features/photoset/http/photoset.http'
import {QUERY_KEYS} from '@/queryClient'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetPhotoset = (
    urlParams: PhotosetURLParams,
    options?: Omit<UseQueryOptions<Photoset>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PHOTOSET, urlParams],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPhotoset({
                    urlParams: {id: urlParams.id},
                    params: {response_type: 'extended'}
                }),
                responseShape: Photoset,
                onZodError: captureException
            })
    })
