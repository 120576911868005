import {create} from 'zustand'
import {ImageThumbnails} from '@/types.ts'

export type GalleryItem = {
    id: number
    url: string
    name: string
    status: 'pending' | 'error' | 'uploaded'
    thumbnails: ImageThumbnails
    isCover?: boolean
}

export interface ServicePhotoUploadStore {
    uploadProgressValues: Record<number, number>
    galleryItems: GalleryItem[]
    galleryCounter: number
    addGalleryItems: (uploadingItems: GalleryItem[]) => void
    clearGallery: () => void
    addUploadProgressValues: ({id, progressValue}: {id: number; progressValue: number}) => void
    setUploadingItemsStatus: ({id, status}: {id: number; status: GalleryItem['status']}) => void
    setGalleryItemUrl: ({id, url}: {id: number; url: GalleryItem['url']}) => void
    setGalleryItemIsCover: ({id, isCover}: {id: number; isCover: GalleryItem['isCover']}) => void
    deleteGalleryItem: (id: number) => void
}

const createPhotoUploadStore = () =>
    create<ServicePhotoUploadStore>((setState, getState) => ({
        uploadingFiles: {},
        uploadProgressValues: {},
        galleryItems: [],
        galleryCounter: 0,
        addUploadProgressValues: ({id, progressValue}) => {
            const prevUploadProgressValues = getState().uploadProgressValues
            setState({
                uploadProgressValues: {...prevUploadProgressValues, [id]: progressValue}
            })
        },
        addGalleryItems: (galleryItems: GalleryItem[]) => {
            const prevUploadingItems = getState().galleryItems
            const prevGalleryCounter = getState().galleryCounter
            setState({
                galleryItems: [...prevUploadingItems, ...galleryItems],
                galleryCounter: prevGalleryCounter + galleryItems.length
            })
        },
        setUploadingItemsStatus: ({id, status}) => {
            const prevUploadingItems = getState().galleryItems
            const prevGalleryCounter = getState().galleryCounter
            const prevUploadProgressValues = getState().uploadProgressValues
            if (status != 'pending') {
                delete prevUploadProgressValues[id]
            }
            setState({
                galleryItems: prevUploadingItems.map(item => {
                    if (item.id == id) {
                        return {...item, status: status}
                    }
                    return item
                }),
                uploadProgressValues: prevUploadProgressValues,
                galleryCounter: status == 'error' ? prevGalleryCounter - 1 : prevGalleryCounter
            })
        },
        setGalleryItemUrl: ({id, url}) => {
            const prevUploadingItems = getState().galleryItems
            setState({
                galleryItems: prevUploadingItems.map(item => {
                    if (item.id == id) {
                        return {...item, url: url}
                    }
                    return item
                })
            })
        },
        setGalleryItemIsCover: ({id, isCover}) => {
            const prevUploadingItems = getState().galleryItems
            setState({
                galleryItems: prevUploadingItems.map(item => {
                    if (item.id == id) {
                        return {...item, isCover: isCover}
                    }
                    return {...item, isCover: false}
                })
            })
        },
        clearGallery: () => setState({galleryItems: [], galleryCounter: 0, uploadProgressValues: {}}),
        deleteGalleryItem: (id: number) => {
            const prevUploadingItems = getState().galleryItems
            const prevGalleryCount = getState().galleryCounter
            const filteredGalleryItems = prevUploadingItems.filter(item => item.id != id)
            setState({galleryItems: filteredGalleryItems, galleryCounter: prevGalleryCount - 1})
        }
    }))

export const useServicePhotoUpload = createPhotoUploadStore()
export const useExperienceVettingPhotoUpload = createPhotoUploadStore()
export const useExperienceShellPhotoUpload = createPhotoUploadStore()
