import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {BulkPresignedExperienceShellPhotosResponse} from '@/features/experience-shell/http/experienceShell.http.ts'
import {
    httpStorePresignBulkExperienceVettingPhotos,
    HttpStorePresignBulkExperienceVettingPhotosOptions
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const useBulkPresignedExperienceVettingPhotos = ({
    urlParams,
    options
}: {
    urlParams: HttpStorePresignBulkExperienceVettingPhotosOptions['urlParams']
    options?: UseMutationOptions<
        BulkPresignedExperienceShellPhotosResponse,
        unknown,
        Omit<HttpStorePresignBulkExperienceVettingPhotosOptions['payload'], 'mutationFn'>
    >
}) => {
    return useMutation({
        ...options,
        mutationFn: payload => httpStorePresignBulkExperienceVettingPhotos({urlParams, payload}),
        onError: errorHandler
    })
}
