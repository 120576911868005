import {useTranslation} from 'react-i18next'
import {
    OpsVettingDisabledContainer,
    OpsVettingDragButton,
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Expand01Icon, MoveIcon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'
import {memo, useMemo} from 'react'
import {ExperienceGalleryImage} from '@/features/experience-shell/types.ts'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {useUpdateExperienceVettingImageIsApproved} from '@/features/experience-vetting/queries/useUpdateExperienceVettingImageIsApproved.ts'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {ExperienceVettingGalleryImageRejectDialog} from '@/features/experience-vetting/components/experience-vetting-gallery-image-reject-dialog/ExperienceVettingGalleryImageRejectDialog.tsx'

export const ExperienceVettingCriteriaGalleryItem = memo(
    ({
        image,
        hideAction,
        experienceId,
        galleryId,
        onClickExpand
    }: {
        image: ExperienceGalleryImage
        hideAction?: boolean
        experienceId: number
        galleryId: number
        onClickExpand?: () => void
    }) => {
        const {t} = useTranslation()

        const updateExperienceVettingImageIsApproved = useUpdateExperienceVettingImageIsApproved({
            experienceId: experienceId,
            galleryId: galleryId,
            imageId: image.id
        })

        const {attributes, setNodeRef, transform, transition, isDragging, listeners} = useSortable({id: image.id})

        const style = {
            transform: CSS.Translate.toString(transform),
            transition
        }

        const isApproved = useMemo(() => image.isApproved === true, [image.isApproved])

        return (
            <OpsVettingDisabledContainer $isDisabled={isDragging} ref={setNodeRef} style={style} {...attributes}>
                <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                    <GalleryImageItem
                        src={image.thumbnails?.xs || image.url}
                        bottomRightSlot={
                            !hideAction && (
                                <Button
                                    className="show-on-hover"
                                    variant="secondary"
                                    iconOnly
                                    size="sm"
                                    onClick={onClickExpand}
                                >
                                    <Expand01Icon />
                                </Button>
                            )
                        }
                        topLeftSlot={
                            !hideAction ? (
                                <OpsVettingDragButton
                                    $isDragging={isDragging}
                                    {...listeners}
                                    variant="secondary"
                                    iconOnly
                                    className="show-on-hover"
                                    size="sm"
                                >
                                    <MoveIcon size={16} />
                                </OpsVettingDragButton>
                            ) : null
                        }
                    />
                    <Flexbox gap={2} direction="column" fullWidth>
                        <OpsVettingImageTitle>{image.name}</OpsVettingImageTitle>
                        <Flexbox gap={3} fullWidth>
                            <Button
                                variant={isApproved ? 'primarySuccess' : 'secondary'}
                                fullWidth
                                size={'sm'}
                                disabled={hideAction}
                                onClick={() =>
                                    !isApproved && updateExperienceVettingImageIsApproved.mutate({isApproved: true})
                                }
                            >
                                <ThumbsUpIcon />
                                {t('vetting:criteria:service:gallery:accept')}
                            </Button>
                            <ExperienceVettingGalleryImageRejectDialog
                                image={image}
                                experienceId={experienceId}
                                galleryId={galleryId}
                                currentFlags={image.flags}
                                disabled={hideAction}
                            />
                        </Flexbox>
                    </Flexbox>
                </OpsVettingGalleryItemsContainer>
            </OpsVettingDisabledContainer>
        )
    }
)
