import {CheckCircleIcon, XCircleIcon} from '@/components/ui/icon/Icon'
import {ReactElement, ReactNode} from 'react'
import {useTheme} from 'styled-components'
import {
    StyledPhotosetFinalContainer,
    StyledPhotosetFinalCopyWrapper,
    StyledPhotosetFinalParapraph,
    StyledPhotosetFinalTitle
} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const PhotosetFinal = ({
    title,
    subtitle,
    status,
    endSlot,
    icon,
    isLoading
}: {
    title: string | ReactElement
    subtitle: string | ReactElement
    status?: 'success' | 'error'
    icon?: ReactNode
    endSlot?: ReactNode
    isLoading?: boolean
}) => {
    const {palette} = useTheme()

    return (
        <StyledPhotosetFinalContainer fullWidth>
            {isLoading ? (
                <Spinner size={48} fill={palette.neutral['500']} />
            ) : (
                <>
                    {!icon ? (
                        status == 'error' ? (
                            <XCircleIcon fill={palette.danger[600]} size={40} />
                        ) : (
                            <CheckCircleIcon fill={palette.success['600']} width={48} height={48} />
                        )
                    ) : (
                        icon
                    )}
                    <StyledPhotosetFinalCopyWrapper direction="column" gap={6} align="center">
                        <Flexbox direction="column" gap={2} align="center">
                            <StyledPhotosetFinalTitle>{title}</StyledPhotosetFinalTitle>
                            <StyledPhotosetFinalParapraph>{subtitle}</StyledPhotosetFinalParapraph>
                        </Flexbox>
                        {endSlot}
                    </StyledPhotosetFinalCopyWrapper>
                </>
            )}
        </StyledPhotosetFinalContainer>
    )
}
