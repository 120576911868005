import {FC, Fragment} from 'react'
import {ExperienceGalleryImage} from '@/features/experience-shell/types.ts'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {ExpVettingCriteriaChoosePhotoDialog} from '../exp-vetting-criteria-choose-photo-dialog/ExpVettingCriteriaChoosePhotoDialog'
import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle,
    OpsVettingNoCoverPhotoContainer
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {Edit02Icon, Expand01Icon, Image01Icon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from '@/translations/i18n'
import {useDialogStore} from '@ariakit/react'
import {Button} from '@/components/ui/button/Button'
import {useUpdateExperiencVettingCoverPhoto} from '@/features/experience-vetting/queries/useUpdateExperiencVettingCoverPhoto'
import {Spinner} from '@/components/ui/spinner/Spinner'
interface ExperienceVettingCriteriaCoverPhotoProps {
    coverPhoto?: ExperienceGalleryImage
    hideAction: boolean
    experienceId: number
    galleryId: number
    onClickExpand?: () => void
}

export const ExperienceVettingCriteriaCoverPhoto: FC<ExperienceVettingCriteriaCoverPhotoProps> = ({
    coverPhoto,
    experienceId,
    hideAction,
    galleryId,
    onClickExpand
}) => {
    const {t} = useTranslation()

    const dialog = useDialogStore()
    const {mutate: updateExperienceCoverPhoto, isPending} = useUpdateExperiencVettingCoverPhoto({
        experienceId,
        galleryId
    })

    const handleConfirm = (id: number) => {
        updateExperienceCoverPhoto({isCover: true, imageId: id})
    }
    return (
        <Fragment>
            {coverPhoto ? (
                <OpsVettingGalleryItemsContainer direction={'column'} gap={4}>
                    <GalleryImageItem
                        src={coverPhoto.thumbnails?.xs || coverPhoto.url}
                        topRightSlot={
                            !hideAction && (
                                <Button
                                    disabled={isPending}
                                    onClick={() => dialog.toggle()}
                                    variant={'secondary'}
                                    iconOnly
                                >
                                    {isPending ? <Spinner /> : <Edit02Icon />}
                                </Button>
                            )
                        }
                        bottomRightSlot={
                            !hideAction && (
                                <Button
                                    className="show-on-hover"
                                    variant="secondary"
                                    iconOnly
                                    size="sm"
                                    onClick={onClickExpand}
                                >
                                    <Expand01Icon />
                                </Button>
                            )
                        }
                    />
                    <OpsVettingImageTitle>{t('vetting:criteria:commons:gallery:cover_photo')}</OpsVettingImageTitle>
                </OpsVettingGalleryItemsContainer>
            ) : (
                <OpsVettingGalleryItemsContainer>
                    <OpsVettingNoCoverPhotoContainer direction={'column'} gap={2} align={'center'} justify={'center'}>
                        {isPending ? <Spinner size={32} /> : <Image01Icon size={32} />}
                        <Flexbox direction={'column'} align={'center'}>
                            <h4>{t('vetting:criteria:service:gallery:no_cover_photo')}</h4>
                            {!hideAction && (
                                <Button disabled={isPending} onClick={dialog.toggle} variant={'tertiary'} size={'sm'}>
                                    {t('commons:choose_a_photo')}
                                </Button>
                            )}
                        </Flexbox>
                    </OpsVettingNoCoverPhotoContainer>
                </OpsVettingGalleryItemsContainer>
            )}

            <ExpVettingCriteriaChoosePhotoDialog
                onConfirm={handleConfirm}
                defaultSelectedImageId={coverPhoto?.id}
                experienceId={experienceId}
                galleryId={galleryId}
                store={dialog}
            />
        </Fragment>
    )
}
