import axios from '@/axiosInstance.ts'
import {z} from 'zod'

export const HttpGenerateMerchVettingCopyOptions = z.object({
    prompt: z.string(),
    model: z.enum(['palmyra-x-004', 'palmyra-x-003-instruct']).optional()
})
type HttpGenerateMerchVettingCopyOptions = z.infer<typeof HttpGenerateMerchVettingCopyOptions>

export const httpGenerateMerchVettingCopy = ({prompt, model}: HttpGenerateMerchVettingCopyOptions) =>
    axios.post(`/node/v1/experience/ai-copy-enhancement`, {prompt, model})
