import {
    OpsVettingDisabledContainer,
    OpsVettingGalleryGrid,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ServiceVettingCriteriaGalleryItem} from '@/features/service-vetting/components/service-vetting-criteria-gallery-item/ServiceVettingCriteriaGalleryItem.tsx'
import {FC, useMemo, useState} from 'react'
import {useGetServiceFlags} from '@/features/service-vetting/query/useGetServiceFlags.ts'
import {useAddServiceGalleryFlags} from '@/features/service-vetting/query/useAddServiceGalleryFlags.ts'
import {useRemoveServiceGalleryFlags} from '@/features/service-vetting/query/useRemoveServiceGalleryFlags.ts'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {ServiceVettingCriteriaGalleryUploadButton} from '@/features/service-vetting/components/service-vetting-criteria-gallery-upload-button/ServiceVettingCriteriaGalleryUploadButton.tsx'
import {useServicePhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {ServiceVettingCriteriaCoverPhoto} from '../service-vetting-criteria-cover-photo/ServiceVettingCriteriaCoverPhoto'
import {useTranslation} from '@/translations/i18n'
import {useUpdateService} from '@/features/service-vetting/query/useUpdateService.ts'
import {ServiceVettingCriteriaUploadingItem} from '../service-vetting-criteria-uploading-item/ServiceVettingCriteriaUploadingItem'
import {ServiceVettingCriteriaUploadedItem} from '../service-vetting-criteria-uploaded-item/ServiceVettingCriteriaUploaded'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {ServiceGalleryImage} from '../../types'
import {useGalleryNeedsReviewShortcuts} from '@/features/photoset/hooks/useGalleryNeedsReviewShortcuts'

interface ServiceVettingCriteriaGalleryProps {
    hideAction: boolean
    isExpertiseDeclined: boolean
    galleryId: number
    galleryImages: ServiceGalleryImage[]
    serviceId: number
    isProPhotographyRequired: boolean
    lackPhotoVariety: boolean
}

export const ServiceVettingCriteriaGallery: FC<ServiceVettingCriteriaGalleryProps> = ({
    hideAction,
    isExpertiseDeclined,
    galleryId,
    galleryImages,
    serviceId,
    isProPhotographyRequired,
    lackPhotoVariety
}) => {
    const {t} = useTranslation()

    const [expandedImageIndex, setExpandedImageIndex] = useState<number | null>(null)
    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const filteredGalleryImages = useMemo(
        () => galleryImages.filter(img => !img.is_offering_image && img.is_approved !== false) || [],
        [galleryImages]
    )

    const coverPhoto = useMemo(() => galleryImages.find(img => img.is_cover), [galleryImages])

    const uploadingItems = useServicePhotoUpload(store => store.galleryItems)

    const sortedGalleryImages = useMemo(() => {
        const filteredImages =
            galleryImages.filter(
                image => image.is_cover || (!image.is_offering_image && image.is_approved !== false)
            ) || []

        // Sort the images according to the specified priority
        return filteredImages.sort((a, b) => {
            // Cover images come first
            if (a.is_cover && !b.is_cover) return -1
            if (!a.is_cover && b.is_cover) return 1

            // If all criteria are the same, maintain original order
            return 0
        })
    }, [galleryImages])

    const errors = useServiceVettingError(state => state.errors)

    const flagsQuery = useGetServiceFlags({
        response_type: 'extended',
        types: ['gallery']
    })
    const addServiceGalleryFlags = useAddServiceGalleryFlags({serviceId: serviceId, itemId: galleryId})
    const removeServiceGalleryFlags = useRemoveServiceGalleryFlags({serviceId: serviceId, itemId: galleryId})
    const updateService = useUpdateService({serviceId: serviceId})

    const onChangeCheckBox = (isChecked: boolean) => {
        const flag = flagsQuery.data?.find(flag => flag.key_name == 'lack_photo_variety')
        const mutationFunction = isChecked ? addServiceGalleryFlags : removeServiceGalleryFlags
        if (flag) {
            mutationFunction.mutate({flags: [flag]})
        }
    }
    const handleClickExpandedImage = (imageId: ServiceGalleryImage['id']) => {
        const index = sortedGalleryImages.findIndex(image => image.id === imageId)
        setExpandedImageIndex(index)
        setShowGalleryModal(true)
    }

    const handleCloseModal = () => {
        setShowGalleryModal(false)
        setExpandedImageIndex(null)
    }

    const {palette} = useTheme()

    useGalleryNeedsReviewShortcuts({
        selectableImages: sortedGalleryImages,
        currentDetailImageIndex: expandedImageIndex || 0,
        setCurrentDetailImageIndex: setExpandedImageIndex,
        onClose: handleCloseModal
    })

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingSectionTitle>{t('vetting:criteria:service:gallery:title')}</OpsVettingSectionTitle>
                    {!hideAction && (
                        <ServiceVettingCriteriaGalleryUploadButton
                            serviceId={serviceId}
                            galleryId={galleryId}
                            disabled={isExpertiseDeclined}
                        />
                    )}
                    <Checkbox
                        checked={lackPhotoVariety}
                        onChange={e => onChangeCheckBox(e.currentTarget.checked)}
                        id={'lack_photo_variety'}
                        label={t('vetting:criteria:service:flags_key_name:lack_photo_variety')}
                        disabled={hideAction || isExpertiseDeclined}
                    />
                    <Checkbox
                        id={'requires_pro_photography'}
                        checked={isProPhotographyRequired}
                        disabled={isExpertiseDeclined}
                        label={t('vetting:criteria:commons:requires_pro_photography')}
                        onChange={e => updateService.mutate({is_pro_photography_required: e.currentTarget.checked})}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.galleryErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
                    <OpsVettingParagraph>
                        {filteredGalleryImages.filter(image => image.is_approved === null).length > 0 ? (
                            t('vetting:criteria:commons:gallery:images_remaining', {
                                value: filteredGalleryImages.filter(image => image.is_approved === null).length
                            })
                        ) : (
                            <Flexbox render={'span'} gap={1}>
                                <CheckIcon size={20} color={palette.success[600]} />
                                {t('vetting:criteria:commons:gallery:images_reviewed')}
                            </Flexbox>
                        )}
                    </OpsVettingParagraph>
                </Flexbox>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingDisabledContainer $isDisabled={isExpertiseDeclined}>
                <OpsVettingGalleryGrid>
                    <ServiceVettingCriteriaCoverPhoto
                        serviceId={serviceId}
                        galleryId={galleryId}
                        coverPhoto={coverPhoto}
                        hideAction={hideAction}
                        onClickExpand={() => handleClickExpandedImage(coverPhoto?.id || 0)}
                    />
                    {filteredGalleryImages.map((image, index) =>
                        image.is_host_approval_needed ? (
                            <ServiceVettingCriteriaUploadedItem
                                image={image}
                                serviceId={serviceId}
                                key={index}
                                galleryId={galleryId}
                                hideAction={hideAction}
                                onClickExpand={() => handleClickExpandedImage(image.id)}
                            />
                        ) : (
                            <ServiceVettingCriteriaGalleryItem
                                key={index}
                                image={image}
                                serviceId={serviceId}
                                galleryId={galleryId}
                                hideAction={hideAction}
                                onClickExpand={() => handleClickExpandedImage(image.id)}
                            />
                        )
                    )}
                    {uploadingItems.map((image, index) => (
                        <ServiceVettingCriteriaUploadingItem key={index} image={image} />
                    ))}
                </OpsVettingGalleryGrid>
            </OpsVettingDisabledContainer>
            {showGalleryModal && expandedImageIndex !== null && (
                <GalleryModal
                    currentImage={sortedGalleryImages[expandedImageIndex]}
                    thumbnails={{
                        l: sortedGalleryImages[expandedImageIndex].url || '',
                        m: '',
                        s: '',
                        xs: ''
                    }}
                    onClose={handleCloseModal}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={sortedGalleryImages[expandedImageIndex]}
                            imagesCount={sortedGalleryImages.length}
                            onClose={handleCloseModal}
                        />
                    }
                    thumbnailsSlot={
                        <GalleryModalThumbnails
                            currentImageId={sortedGalleryImages[expandedImageIndex].id}
                            thumbnails="thumbnails"
                            images={sortedGalleryImages.map(image => ({
                                id: image.id,
                                name: image.name,
                                thumbnails: {
                                    l: image.url || '',
                                    m: image.url || '',
                                    s: image.url || '',
                                    xs: image.url || ''
                                }
                            }))}
                            onSelect={(_, index) => {
                                setExpandedImageIndex(index)
                            }}
                        />
                    }
                />
            )}
        </OpsVettingSectionWrapper>
    )
}
