import {
    DiffSection,
    FieldsDiffsSeparator,
    DiffSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-diffs/OpsVettingMerchDiffs'
import {Fragment} from 'react/jsx-runtime'
import {ServiceDiffsMap} from '../../types'

interface ServiceVettingMerchCopyDiffsProps {
    serviceDiffsMap: ServiceDiffsMap
}
export const ServiceVettingMerchCopyDiffs = ({serviceDiffsMap}: ServiceVettingMerchCopyDiffsProps) => {
    return (
        <DiffSectionWrapper>
            <DiffSection
                size={serviceDiffsMap.host_experience_title.fieldSize}
                hasDiff={serviceDiffsMap.host_experience_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.host_experience_title.hasDiff}
                hasLowerDiff={serviceDiffsMap.host_experience_description.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.host_experience_description.fieldSize}
                hasDiff={serviceDiffsMap.host_experience_description.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.host_experience_description.hasDiff}
                hasLowerDiff={serviceDiffsMap.host_education_title.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.host_education_title.fieldSize}
                hasDiff={serviceDiffsMap.host_education_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.host_education_title.hasDiff}
                hasLowerDiff={serviceDiffsMap.host_education_description.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.host_education_description.fieldSize}
                hasDiff={serviceDiffsMap.host_education_description.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.host_education_description.hasDiff}
                hasLowerDiff={serviceDiffsMap.host_career_highlight_title.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.host_career_highlight_title.fieldSize}
                hasDiff={serviceDiffsMap.host_career_highlight_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.host_career_highlight_title.hasDiff}
                hasLowerDiff={serviceDiffsMap.host_career_highlight_description.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.host_career_highlight_description.fieldSize}
                hasDiff={serviceDiffsMap.host_career_highlight_description.hasDiff}
            />
            <FieldsDiffsSeparator fieldType="section" />
            <DiffSection
                size={serviceDiffsMap.service_title.fieldSize}
                hasDiff={serviceDiffsMap.service_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={serviceDiffsMap.service_title.hasDiff}
                hasLowerDiff={serviceDiffsMap.service_description.hasDiff}
            />
            <DiffSection
                size={serviceDiffsMap.service_description.fieldSize}
                hasDiff={serviceDiffsMap.service_description.hasDiff}
            />
            <FieldsDiffsSeparator fieldType="sub-section" />
            {serviceDiffsMap.offerings.map((offering, index) => {
                const isLastItem = index === serviceDiffsMap.offerings.length - 1
                return (
                    <Fragment key={index}>
                        <DiffSection size={offering[0].fieldSize} hasDiff={offering[0].hasDiff} />
                        <FieldsDiffsSeparator
                            fieldType="consecutive"
                            hasUpperDiff={offering[0].hasDiff}
                            hasLowerDiff={offering[1].hasDiff}
                        />
                        <DiffSection size={offering[1].fieldSize} hasDiff={offering[1].hasDiff} />
                        {!isLastItem && <FieldsDiffsSeparator fieldType="sub-section" />}
                    </Fragment>
                )
            })}
        </DiffSectionWrapper>
    )
}
ServiceVettingMerchCopyDiffs.displayName = 'ServiceVettingMerchCopyDiffs'
