import {css, styled} from 'styled-components'

export const StyledTooltipLink = styled.button(
    ({theme: {spacing}}) => css`
        cursor: pointer;
        text-decoration: underline;
        display: inline-flex;
        align-items: center;
        gap: ${spacing}px;
        &:disabled {
            cursor: not-allowed;
        }
    `
)
