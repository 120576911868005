import {useTranslation} from '@/translations/i18n'
import {Fieldset, YesNoField} from '../atoms/Atoms'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {Controller, useFieldArray} from 'react-hook-form'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Trash01Icon, PlusIcon} from '@/components/ui/icon/Icon'
import {InputText} from '@/components/commons/input-text/InputText'
import {Button} from '@/components/ui/button/Button'
import {StyledItemField} from './style'

export const GuestNeedBringSomethingStep = () => {
    const {t} = useTranslation()
    const {
        control,
        register,
        formState: {errors}
    } = useExperienceShellFormContext()
    const {fields, append, remove} = useFieldArray({control, name: 'settings.guest_bringing_items'})

    return (
        <Fieldset>
            <Flexbox direction="column" gap={4} fullWidth>
                <Controller
                    control={control}
                    name="settings.guest_bringing_item"
                    render={({field}) => (
                        <Flexbox direction="column" gap={2} fullWidth>
                            <YesNoField
                                labelTitle={<b>{t('experience_shell:guests_need_bring_something')}</b>}
                                name={field.name}
                                value={field.value}
                                onChange={value => {
                                    field.onChange(value)
                                    value ? append({item: ''}) : remove()
                                }}
                            />
                            {errors.settings?.guest_bringing_item && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                    )}
                />
                {fields.length >= 1 && (
                    <Flexbox direction="column" gap={4} fullWidth>
                        {fields.map((field, index) => (
                            <Flexbox direction="column" gap={2} fullWidth key={field.id}>
                                <StyledItemField>
                                    <InputText
                                        inputSize="sm"
                                        type="text"
                                        label={t('experience_shell:item')}
                                        {...register(`settings.guest_bringing_items.${index}.item`)}
                                    />
                                    {fields.length >= 2 && (
                                        <Button iconOnly variant="tertiary" onClick={() => remove(index)}>
                                            <Trash01Icon />
                                        </Button>
                                    )}
                                </StyledItemField>
                                {errors.settings?.guest_bringing_items?.[index]?.item && (
                                    <InputHelpText error={t('errors:field_required')} />
                                )}
                            </Flexbox>
                        ))}
                        <Button size="sm" variant="tertiary" onClick={() => append({item: ''})}>
                            <PlusIcon />
                            {t('experience_shell:add_item')}
                        </Button>
                    </Flexbox>
                )}
            </Flexbox>
        </Fieldset>
    )
}
