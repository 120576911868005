import {
    OpsVettingCardBodyContainer,
    OpsVettingCardContainer,
    OpsVettingCardTitle,
    OpsVettingLabel,
    OpsVettingParagraph
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {VettingSectionStatusSelector} from '@/features/ops-vetting/components/vetting-section-status-selector/VettingSectionStatusSelector.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {EmptyImagePlaceholder} from '@components/commons/gallery-image-item/EmptyImagePlaceholder.tsx'
import {RemappedItinerariesVetting} from '@/features/experience-vetting/types.ts'
import {useUpdateExperienceItineraryStatus} from '@/features/experience-vetting/queries/useUpdateExperienceItineraryStatus.ts'
import {useAddExperienceItineraryAnswerFlags} from '../../queries/useAddExperienceItineraryAnswerFlags'
import {useRemoveExperienceItineraryAnswerFlags} from '../../queries/useRemoveExperienceItineraryAnswerFlags'
import {ExperienceVettingCriteriaFlags} from '../experience-vetting-criteria-flags/ExperienceVettingCriteriaFlags'
import {raise} from '@/utilities/helpers'
import {Chip} from '@/components/commons/chip/Chip'
import {AlertTriangleIcon, Expand01Icon} from '@/components/ui/icon/Icon'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'
import {ExpVettingCriteriaChoosePhotoDialog} from '../exp-vetting-criteria-choose-photo-dialog/ExpVettingCriteriaChoosePhotoDialog'
import {Button} from '@/components/ui/button/Button'
import {useUpdateExperienceVettingCriteriaItineraryCoveryImage} from '../../queries/useUpdateExperienceVettingCriteriaItineraryCoveryImage'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useRestoreExperienceVettingCriteriaItineraryCoveryImage} from '../../queries/useRestoreExperienceVettingCriteriaItineraryCoveryImage'
import {useAddExperienceItineraryImageFlags} from '../../queries/useAddExperienceItineraryImageFlags'
import {useRemoveExperienceItineraryImageFlags} from '../../queries/useRemoveExperienceItineraryImageFlags'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {StyledTooltipContent} from '@/features/service-vetting/components/service-vetting-criteria-offering-card/style'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {useState} from 'react'
import {useHotkeys} from 'react-hotkeys-hook'

export const ExperienceVettingCriteriaItineraryCard = ({
    itinerary,
    index,
    galleryId,
    isReadOnly
}: {
    itinerary: RemappedItinerariesVetting
    index: number
    galleryId: number
    isReadOnly: boolean
}) => {
    const {t} = useTranslation()
    const {mutate: updateItineraryStatus} = useUpdateExperienceItineraryStatus({
        experienceId: itinerary.submissionId,
        itineraryId: itinerary.id
    })
    const errors = useExperienceVettingError(state => state.errors)
    const isEditedImage =
        itinerary.originalCoverImageId != null && itinerary.originalCoverImageId !== itinerary.coverImageId

    const addItineraryQuestionFlags = useAddExperienceItineraryAnswerFlags({
        experienceId: itinerary.submissionId,
        sectionId: itinerary.id
    })
    const removeItineraryQuestionFlags = useRemoveExperienceItineraryAnswerFlags({
        experienceId: itinerary.submissionId,
        sectionId: itinerary.id
    })

    const {mutate: updateCoveryImage, isPending: isUpdatingCoveryImage} =
        useUpdateExperienceVettingCriteriaItineraryCoveryImage({
            experienceId: itinerary.submissionId,
            itineraryId: itinerary.id
        })
    const {mutate: restoreCoveryImage, isPending: isRestoringCoveryImage} =
        useRestoreExperienceVettingCriteriaItineraryCoveryImage({
            experienceId: itinerary.submissionId,
            itineraryId: itinerary.id
        })

    const addItineraryImageFlags = useAddExperienceItineraryImageFlags({
        experienceId: itinerary.submissionId,
        sectionId: galleryId,
        itineraryId: itinerary.id
    })

    const removeItineraryImageFlags = useRemoveExperienceItineraryImageFlags({
        experienceId: itinerary.submissionId,
        sectionId: galleryId,
        itineraryId: itinerary.id
    })

    const isAccepted = itinerary.status == 'accepted'
    const isBackToDraft = itinerary.status == 'back_to_draft'

    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const handleClickExpandImage = () => {
        setShowGalleryModal(true)
    }

    useHotkeys('Esc', () => setShowGalleryModal(false), {enabled: showGalleryModal})

    return (
        <OpsVettingCardContainer direction="column" gap={2}>
            <Flexbox fullWidth justify={'space-between'} align={'center'} gap={2}>
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingCardTitle>
                        {t('vetting:criteria:experience:itinerary:activity_n', {count: index + 1})}
                    </OpsVettingCardTitle>
                    <Tooltip
                        maxWidth={'214px'}
                        content={
                            <StyledTooltipContent>
                                <h4>{t('vetting:criteria:experience:itinerary:edited_photo_tooltip:title')}</h4>{' '}
                                <p>{t('vetting:criteria:experience:itinerary:edited_photo_tooltip:description')}</p>
                            </StyledTooltipContent>
                        }
                        triggerProps={{asChild: true}}
                        rootProps={{open: !isEditedImage ? false : undefined}}
                    >
                        <div>
                            <VettingSectionStatusSelector
                                value={itinerary.status}
                                onChange={status => updateItineraryStatus({status})}
                                hideDeclined
                                disabled={isReadOnly || isEditedImage}
                            />
                        </div>
                    </Tooltip>
                </Flexbox>
                {errors.itemErrors
                    .filter(error => error.itemId == itinerary.id)
                    .map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error.error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
            </Flexbox>
            <OpsVettingCardBodyContainer>
                <Flexbox direction="column" fullWidth gap={2.5}>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:criteria:experience:itinerary:question_title')}</OpsVettingLabel>
                        <OpsVettingParagraph>{itinerary.answers.title?.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={itinerary.answers.title.flags}
                                addFlag={flag =>
                                    addItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.title.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.title.id
                                    })
                                }
                                scopes={['itinerary_title']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>
                            {t('vetting:criteria:experience:itinerary:question_description')}
                        </OpsVettingLabel>
                        <OpsVettingParagraph>{itinerary.answers.description.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={itinerary.answers.description.flags}
                                addFlag={flag =>
                                    addItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.description.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.description.id
                                    })
                                }
                                scopes={['itinerary_description']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>
                            {t('vetting:criteria:experience:itinerary:question_duration')}
                        </OpsVettingLabel>
                        <OpsVettingParagraph>
                            {itinerary.answers.duration?.answer &&
                                t('vetting:criteria:experience:itinerary:x_minutes', {
                                    count: itinerary.answers.duration?.answer
                                })}
                        </OpsVettingParagraph>
                        {/* {isBackToDraft && itinerary.answers.duration && (
                            <ExperienceVettingCriteriaFlags
                                flags={itinerary.answers.duration?.flags || []}
                                addFlag={flag =>
                                    addItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.duration?.id || raise('Duration ID is required')
                                    })
                                }
                                removeFlag={flag =>
                                    removeItineraryQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: itinerary.answers.duration?.id || raise('Duration ID is required')
                                    })
                                }
                                hideAction={isReadOnly}
                            />
                        )}
                    */}{' '}
                    </Flexbox>
                </Flexbox>
                <Flexbox direction="column" fullWidth gap={2.5}>
                    {itinerary.coverImage ? (
                        <GalleryImageItem
                            src={itinerary.coverImage?.thumbnails?.xs || itinerary.coverImage?.url}
                            bottomRightSlot={
                                !isReadOnly && (
                                    <Button
                                        variant="secondary"
                                        iconOnly
                                        className="show-on-hover"
                                        size="sm"
                                        onClick={handleClickExpandImage}
                                    >
                                        <Expand01Icon />
                                    </Button>
                                )
                            }
                        />
                    ) : (
                        <EmptyImagePlaceholder />
                    )}
                    {isBackToDraft && itinerary.coverImage && (
                        <ExperienceVettingCriteriaFlags
                            flags={itinerary.coverImage?.flags || []}
                            addFlag={flag =>
                                addItineraryImageFlags.mutate({
                                    flags: [flag],
                                    itemId: itinerary.coverImage?.id || raise('Cover image ID is required')
                                })
                            }
                            removeFlag={flag =>
                                removeItineraryImageFlags.mutate({
                                    flags: [flag],
                                    itemId: itinerary.coverImage?.id || raise('Cover image ID is required')
                                })
                            }
                            scopes={['photo_gallery']}
                            hideAction={isReadOnly}
                        />
                    )}
                    {isAccepted && !isReadOnly && (
                        <Flexbox justify={'space-between'} fullWidth>
                            <ExpVettingCriteriaChoosePhotoDialog
                                onConfirm={coverImageId => updateCoveryImage({coverImageId})}
                                experienceId={itinerary.submissionId}
                                galleryId={galleryId}
                                defaultSelectedImageId={itinerary.coverImageId || undefined}
                                trigger={
                                    <Button disabled={isUpdatingCoveryImage || isReadOnly} variant={'tertiary'}>
                                        {t('commons:edit')} {isUpdatingCoveryImage && <Spinner />}
                                    </Button>
                                }
                            />
                            {isEditedImage && !isReadOnly && (
                                <Button
                                    disabled={isRestoringCoveryImage}
                                    variant={'tertiary'}
                                    onClick={() => restoreCoveryImage()}
                                >
                                    {t('vetting:criteria:service:offerings:restore_photo')}
                                    {isRestoringCoveryImage && <Spinner />}
                                </Button>
                            )}
                        </Flexbox>
                    )}
                </Flexbox>
            </OpsVettingCardBodyContainer>
            {showGalleryModal && itinerary.coverImage && (
                <GalleryModal
                    currentImage={itinerary.coverImage}
                    thumbnails={{l: itinerary.coverImage?.url || '', m: '', s: '', xs: ''}} //TODO: add real thumbnails
                    onClose={() => setShowGalleryModal(false)}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={itinerary.coverImage}
                            onClose={() => setShowGalleryModal(false)}
                        />
                    }
                />
            )}
        </OpsVettingCardContainer>
    )
}
