import {useMemo} from 'react'
import {useGetGalleryImages} from '@/features/service-vetting/query/useGetGalleryImages'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from '@/translations/i18n'
import {ServiceVettingCriteriaRejectedImages} from '../service-vetting-criteria-rejected-images/ServiceVettingCriteriaRejectedImages'
import {ServiceVettingCriteriaGallery} from '../service-vetting-criteria-gallery/ServiceVettingCriteriaGallery'
import {ServiceVettingCriteriaGallerySkeleton} from '../service-vetting-criteria-gallery-skeleton/ServiceVettingCriteriaGallerySkeleton'

type ServiceVettingCriteriaGalleryDataInitializerProps = {
    serviceId: number
    galleryId: number
    hideAction: boolean
    isExpertiseDeclined: boolean
    isProPhotographyRequired: boolean
    lackPhotoVariety: boolean
}

export const ServiceVettingCriteriaGalleryContainer = ({
    serviceId,
    galleryId,
    hideAction,
    isExpertiseDeclined,
    isProPhotographyRequired,
    lackPhotoVariety
}: ServiceVettingCriteriaGalleryDataInitializerProps) => {
    const {t} = useTranslation()

    // Fetch the gallery images
    const {data, isLoading, isError} = useGetGalleryImages({
        serviceId,
        galleryId: galleryId
    })

    // Handle gallery images data
    const galleryImages = useMemo(() => {
        return data || []
    }, [data])

    const rejectedImages = useMemo(() => {
        return galleryImages.filter(image => image.is_approved === false) || []
    }, [galleryImages])

    if (isLoading) {
        return <ServiceVettingCriteriaGallerySkeleton />
    }

    if (isError) {
        return <ErrorBox title={t('errors:default')} icon={<AlertTriangleIcon />} />
    }

    return (
        <Flexbox direction="column" gap={6} fullWidth>
            <ServiceVettingCriteriaGallery
                serviceId={serviceId}
                galleryId={galleryId}
                hideAction={hideAction}
                isExpertiseDeclined={isExpertiseDeclined}
                isProPhotographyRequired={isProPhotographyRequired}
                galleryImages={galleryImages}
                lackPhotoVariety={lackPhotoVariety}
            />
            {rejectedImages.length > 0 && (
                <ServiceVettingCriteriaRejectedImages
                    rejectedImages={rejectedImages}
                    hideAction={hideAction}
                    isExpertiseDeclined={isExpertiseDeclined}
                    galleryId={galleryId}
                />
            )}
        </Flexbox>
    )
}
