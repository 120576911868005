import {z} from 'zod'
import {
    Category,
    CategoryFormat,
    SkillLevel,
    ActivityLevel,
    StepKeyName,
    TransportingGuestsCheckAndMode,
    FormFieldKeyName,
    AccessibilityFeatures,
    TransportingGuestsCheckAndModePartial
} from './types'
import {ExperienceShellMerchOutputSchema, ExperienceMerchandisingInputSchema} from '../experience-vetting/formSchemas'

export const ExperienceFormSchema = z.object({
    [FormFieldKeyName.enum.categoryKeyName]: Category,
    [FormFieldKeyName.enum.subCategoryKeyName]: CategoryFormat
})

export const BackgroundFormSchema = z.object({
    [FormFieldKeyName.enum.expertise_years]: z.coerce.number().int(),
    [FormFieldKeyName.enum.online_profile_links]: z
        .array(z.object({link: z.string().url().or(z.literal(''))}))
        .min(1)
        .optional(),
    [FormFieldKeyName.enum.residential_address]: z.string(),
    [FormFieldKeyName.enum.residential_country_region]: z.string().min(1),
    [FormFieldKeyName.enum.residential_address_extras]: z.string().optional(),
    [FormFieldKeyName.enum.residential_city]: z.string().optional(),
    [FormFieldKeyName.enum.residential_state]: z.string().optional(),
    [FormFieldKeyName.enum.residential_zip]: z.string().optional(),
    [FormFieldKeyName.enum.hosting_as_business]: z.boolean().default(false),
    [FormFieldKeyName.enum.business_name]: z.string().optional()
})

export const LocationFormSchema = z.object({
    [FormFieldKeyName.enum.country_region]: z.string(),
    [FormFieldKeyName.enum.location_address]: z.string(),
    [FormFieldKeyName.enum.location_address_extras]: z.string().optional(),
    [FormFieldKeyName.enum.location_city]: z.string(),
    [FormFieldKeyName.enum.location_state]: z.string(),
    [FormFieldKeyName.enum.location_zip]: z.string(),
    [FormFieldKeyName.enum.meeting_point_name]: z.string(),
    [FormFieldKeyName.enum.meeting_point_description]: z.string()
})

export const SettingsFormSchema = z.object({
    [FormFieldKeyName.enum.skill_level]: SkillLevel.nullable(),
    [FormFieldKeyName.enum.activity_level]: ActivityLevel.nullable(),
    [FormFieldKeyName.enum.minimum_age]: z.coerce.number().int().positive(),
    [FormFieldKeyName.enum.parents_kids_under_2]: z.boolean(),
    [FormFieldKeyName.enum.maximum_public_group_size]: z.coerce.number().int().positive(),
    [FormFieldKeyName.enum.maximum_private_group_size]: z.coerce.number().int().positive(),
    [FormFieldKeyName.enum.cancellation_policy]: z.enum(['7_days', '24_hours']).optional(),
    [FormFieldKeyName.enum.accessibility_feature]: z.boolean().default(false),
    [FormFieldKeyName.enum.accessibility_features]: z.array(AccessibilityFeatures),
    [FormFieldKeyName.enum.accessibility_additional_information]: z.string(),
    [FormFieldKeyName.enum.accessibility_additional_qualifications]: z.string(),
    [FormFieldKeyName.enum.unlisted_datetimes_allowed]: z.boolean().default(false),
    [FormFieldKeyName.enum.experience_start_time]: z.string().min(1),
    [FormFieldKeyName.enum.additional_guests_cutoff_time]: z.string().min(1),
    [FormFieldKeyName.enum.first_guests_cutoff_time]: z.string().min(1),
    [FormFieldKeyName.enum.guest_bringing_item]: z.boolean().default(false),
    [FormFieldKeyName.enum.guest_bringing_items]: z.array(z.object({item: z.string()})).optional(),
    [FormFieldKeyName.enum.guest_bring_something]: z.boolean().default(false),
    [FormFieldKeyName.enum.main_language]: z.string().min(1),
    [FormFieldKeyName.enum.additional_languages]: z.array(z.string().min(1)).optional()
})

export const PricingDiscountsBaseFormSchema = z.object({
    [FormFieldKeyName.enum.guest_individual_price]: z.coerce.number().int().positive(),
    [FormFieldKeyName.enum.new_listing_discount]: z.coerce.boolean(),
    [FormFieldKeyName.enum.early_bird_discount]: z.coerce.boolean()
})

export const PricingDiscountsLargeGroupDiscountFormSchema = z.object({
    [FormFieldKeyName.enum.offer_large_group_discount]: z.coerce.boolean().optional(),
    [FormFieldKeyName.enum.discounts]: z.array(
        z.object({
            groupSize: z.coerce.number().int().positive().optional(),
            discountInPercentage: z.coerce.number().int().positive().max(100).optional()
        })
    )
})

export const PricingDiscountsNoLargeGroupDiscountFormSchema = z.object({
    [FormFieldKeyName.enum.offer_large_group_discount]: z.literal(false).optional(),
    [FormFieldKeyName.enum.discounts]: z.undefined()
})

export const PricingDiscountsInstancePrivateGroupsFormSchema = z.object({
    [FormFieldKeyName.enum.instance_private_groups]: z.literal(true).optional(),
    [FormFieldKeyName.enum.group_minimum_price]: z.coerce.number().multipleOf(0.01),
    [FormFieldKeyName.enum.always_require_minimum_price]: z.boolean()
})

export const PricingDiscountsNoInstancePrivateGroupsFormSchema = z.object({
    [FormFieldKeyName.enum.instance_private_groups]: z.literal(false).optional(),
    [FormFieldKeyName.enum.group_minimum_price]: z.undefined(),
    [FormFieldKeyName.enum.always_require_minimum_price]: z.undefined()
})

export const LegalFoodDrinkEntertainmentFormSchema = z.object({
    [FormFieldKeyName.enum.categoryKeyName]: Category.exclude([Category.enum.food_drink, Category.enum.entertainment]),
    [FormFieldKeyName.enum.involves_national_park]: z.boolean(),
    [FormFieldKeyName.enum.host_serve_alcohol]: z.undefined(),
    [FormFieldKeyName.enum.is_licensed_establishment]: z.undefined(),
    [FormFieldKeyName.enum.host_serve_food]: z.undefined(),
    [FormFieldKeyName.enum.prepared_in_restaurant]: z.undefined(),
    [FormFieldKeyName.enum.involves_point_of_interest]: z.boolean(),
    [FormFieldKeyName.enum.host_discusses_poi_significance]: z.boolean().optional()
})

export const LegalFoodDrinkFormSchema = z.object({
    [FormFieldKeyName.enum.categoryKeyName]: z.literal(Category.enum.food_drink),
    [FormFieldKeyName.enum.host_serve_food]: z.boolean(),
    [FormFieldKeyName.enum.prepared_in_restaurant]: z.boolean(),
    [FormFieldKeyName.enum.host_serve_alcohol]: z.boolean(),
    [FormFieldKeyName.enum.is_licensed_establishment]: z.boolean(),
    [FormFieldKeyName.enum.involves_national_park]: z.undefined(),
    [FormFieldKeyName.enum.involves_point_of_interest]: z.undefined(),
    [FormFieldKeyName.enum.host_discusses_poi_significance]: z.undefined()
})

export const LegalEntertainmentFormSchema = z.object({
    [FormFieldKeyName.enum.categoryKeyName]: z.literal(Category.enum.entertainment),
    [FormFieldKeyName.enum.host_serve_alcohol]: z.boolean(),
    [FormFieldKeyName.enum.is_licensed_establishment]: z.boolean(),
    [FormFieldKeyName.enum.involves_national_park]: z.undefined(),
    [FormFieldKeyName.enum.involves_point_of_interest]: z.undefined(),
    [FormFieldKeyName.enum.host_discusses_poi_significance]: z.undefined(),
    [FormFieldKeyName.enum.host_serve_food]: z.undefined(),
    [FormFieldKeyName.enum.prepared_in_restaurant]: z.undefined()
})

export const MerchandisingFormSchema = z.object({
    ...ExperienceShellMerchOutputSchema.shape,
    ...ExperienceMerchandisingInputSchema.shape
})

export const ItineraryFormSchema = z.object({})

export const ShellPartialFormSchema = z.object({
    [StepKeyName.enum.experience]: ExperienceFormSchema.partial(),
    [StepKeyName.enum.background]: BackgroundFormSchema.partial(),
    [StepKeyName.enum.pricing_discounts]: PricingDiscountsBaseFormSchema.partial()
        .and(
            PricingDiscountsLargeGroupDiscountFormSchema.partial().or(
                PricingDiscountsNoLargeGroupDiscountFormSchema.partial()
            )
        )
        .and(
            PricingDiscountsInstancePrivateGroupsFormSchema.partial().or(
                PricingDiscountsNoInstancePrivateGroupsFormSchema.partial()
            )
        ),
    [StepKeyName.enum.merchandising]: MerchandisingFormSchema.partial(),
    [StepKeyName.enum.location]: LocationFormSchema.partial(),
    [StepKeyName.enum.itinerary]: ItineraryFormSchema.partial(),
    [StepKeyName.enum.settings]: SettingsFormSchema.partial(),
    [StepKeyName.enum.legal]: TransportingGuestsCheckAndModePartial.and(
        LegalFoodDrinkEntertainmentFormSchema.partial()
            .or(LegalFoodDrinkFormSchema.partial())
            .or(LegalEntertainmentFormSchema.partial())
    )
})
export type ShellPartialFormSchema = z.infer<typeof ShellPartialFormSchema>
export type ShellFormDefaultValues = {
    [ShellFormSchemaKey in keyof ShellPartialFormSchema]: {
        [Key in keyof ShellPartialFormSchema[ShellFormSchemaKey]]:
            | ShellPartialFormSchema[ShellFormSchemaKey][Key]
            | undefined
    }
}

export const ShellFormSchema = z.object({
    [StepKeyName.enum.experience]: ExperienceFormSchema,
    [StepKeyName.enum.background]: BackgroundFormSchema,
    [StepKeyName.enum.pricing_discounts]: PricingDiscountsBaseFormSchema.and(
        PricingDiscountsLargeGroupDiscountFormSchema.or(PricingDiscountsNoLargeGroupDiscountFormSchema)
    ).and(PricingDiscountsInstancePrivateGroupsFormSchema.or(PricingDiscountsNoInstancePrivateGroupsFormSchema)),
    [StepKeyName.enum.merchandising]: MerchandisingFormSchema,
    [StepKeyName.enum.location]: LocationFormSchema,
    [StepKeyName.enum.itinerary]: ItineraryFormSchema,
    [StepKeyName.enum.settings]: SettingsFormSchema,
    [StepKeyName.enum.legal]: TransportingGuestsCheckAndMode.and(
        LegalFoodDrinkEntertainmentFormSchema.or(LegalFoodDrinkFormSchema).or(LegalEntertainmentFormSchema)
    )
})
export type ShellFormSchema = z.infer<typeof ShellFormSchema>

export type EditExperiencePayload = Partial<ShellPartialFormSchema[keyof ShellPartialFormSchema]>
