import {z} from 'zod'

export const ResponseType = z.enum(['small', 'regular', 'extended'])
export type ResponseType = z.infer<typeof ResponseType>

export const ResponseTypeParam = z.object({response_type: ResponseType})

export const ImageThumbnails = z
    .object({
        l: z.string().url(),
        m: z.string().url(),
        s: z.string().url(),
        xs: z.string().url()
    })
    .nullable()
export type ImageThumbnails = z.infer<typeof ImageThumbnails>

export const PaginationResponse = z.object({
    total: z.number(),
    lastPage: z.number(),
    perPage: z.number(),
    currentPage: z.number()
})
export type PaginationResponse = z.infer<typeof PaginationResponse>

export const PaginationParams = z.object({
    page: z.number(),
    limit: z.number()
})
export type PaginationParams = z.infer<typeof PaginationParams>
