import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckCircleIcon, DownloadCloud02Icon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {PhotosetURLParams} from '@/features/photoset/types'
import {
    StyledCompletedCopyWrapper,
    StyledCompletedStepsContainer,
    StyledCompletedStepsParapraph,
    StyledCompletedStepsTitle
} from '@/pages/photoset/photoset-completed-steps/style'
import {useTranslation} from 'react-i18next'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {useTheme} from 'styled-components'
import {useGetPhotosetImagesList} from '@/features/photoset/queries/useGetPhotosetImagesList'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToCuration} from '@/features/photoset/queries/useUpdateStepToCuration'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'

export const PhotosetCompletedSteps = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {palette} = useTheme()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: images, isFetching} = useGetPhotosetImagesList(
        {urlParams: {photosetId: urlParams.id}},
        {enabled: false}
    )
    const updateStepToCuration = useUpdateStepToCuration({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
            navigate('../curation')
        }
    })

    return (
        <StyledCompletedStepsContainer>
            {isFetching ? (
                <Spinner size={48} fill={palette.neutral['500']} />
            ) : (
                <>
                    <CheckCircleIcon fill={palette.success['600']} width={48} height={48} />

                    <StyledCompletedCopyWrapper direction="column" gap={2} align="center">
                        <StyledCompletedStepsTitle>{t('photoset:completed:title')}</StyledCompletedStepsTitle>
                        <StyledCompletedStepsParapraph>
                            {t('photoset:completed:paragraph')}
                        </StyledCompletedStepsParapraph>
                    </StyledCompletedCopyWrapper>

                    <Flexbox gap={3} direction="column" align="center">
                        <Button size="lg">
                            <DownloadCloud02Icon />
                            {t('photoset:completed:download_image', {
                                count: images?.filter(image => image.is_final_select).length
                            })}
                        </Button>
                        <Button variant="tertiary" size="sm" onClick={() => updateStepToCuration.mutate(urlParams.id)}>
                            {t('photoset:completed:back')}
                        </Button>
                    </Flexbox>
                </>
            )}
        </StyledCompletedStepsContainer>
    )
}

PhotosetCompletedSteps.displayName = 'PhotosetCompletedSteps'
