import axios from '@/axiosInstance.ts'
import {ResponseType} from '@/types.ts'
import {
    EditFlagsItemUrlParam,
    EditFlagsSectionUrlParam,
    ServiceGalleryImage,
    ServiceFlag
} from '@/features/service-vetting/types.ts'
import axiosOriginalInstance, {AxiosProgressEvent} from 'axios'
import {z} from 'zod'
import {captureException} from '@sentry/react'
import {VettingItemStatus} from '@/features/ops-vetting/types.ts'
import {parseResponseData} from '@utilities/helpers.ts'

/**
 * httpGetAdminService
 * Get informations from a single submission
 */
type HttpGetService = {
    urlParams: {serviceId: number}
    params: {response_type: ResponseType}
}

export const httpGetService = ({urlParams, params}: HttpGetService) =>
    axios.get(`/luminate/services/${urlParams.serviceId}`, {
        params
    })

export type HttpUpdateExpertiseStatus = {
    urlParams: {serviceId: number; expertiseId: number}
    payload: {status: VettingItemStatus}
    params: {response_type: ResponseType}
}

export const httpUpdateExpertiseStatus = ({urlParams, payload, params}: HttpUpdateExpertiseStatus) =>
    axios.patch(`/luminate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-status`, payload, {
        params
    })

export type HttpUpdateOfferingStatus = {
    urlParams: {serviceId: number; offeringId: number}
    payload: {status: VettingItemStatus}
    params: {response_type: ResponseType}
}

export const httpUpdateOfferingStatus = ({urlParams, payload, params}: HttpUpdateOfferingStatus) =>
    axios.patch(`/luminate/services/${urlParams.serviceId}/offering/${urlParams.offeringId}/update-status`, payload, {
        params
    })

export type HttpUpdateIsApprovedGalleryImg = {
    urlParams: {serviceId: number; galleryId: number; imgId: number}
    payload: {is_approved: boolean}
    params: {response_type: ResponseType}
}

export const httpUpdateIsApprovedGalleryImg = ({urlParams, payload, params}: HttpUpdateIsApprovedGalleryImg) =>
    axios.patch(
        `/luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/${urlParams.imgId}/is-approved`,
        payload,
        {params}
    )

export type HttpGetServiceFlags = {
    params: {
        types?: ServiceFlag['type'][] | null
        response_type: ResponseType
    }
}

export const httpGetServiceFlags = ({params}: HttpGetServiceFlags) => axios.get(`/luminate/services/flags`, {params})

export type HttpEditItemFlags = {
    urlParams: {
        serviceId: number
        sectionId?: number
        itemId: number
        section: EditFlagsSectionUrlParam
        item: EditFlagsItemUrlParam
    }
    payload: {flags: number[]}
    params: {response_type: ResponseType}
}

export const httpAddServiceItemFlags = ({params, payload, urlParams}: HttpEditItemFlags) =>
    axios.post(
        `luminate/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        payload,
        {params}
    )

export const httpRemoveServiceItemFlags = ({params, payload, urlParams}: HttpEditItemFlags) =>
    axios.delete(
        `luminate/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        {
            params: {
                ...params,
                ...payload
            }
        }
    )

export type HttpEditServiceSectionFlags = {
    urlParams: {
        serviceId: number
        itemId: number
        section: EditFlagsSectionUrlParam
    }
    payload: {flags: number[]}
    params: {response_type: ResponseType}
}

export const httpAddServiceSectionFlags = ({params, payload, urlParams}: HttpEditServiceSectionFlags) =>
    axios.post(`luminate/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.itemId}/flags`, payload, {
        params
    })

export const httpRemoveServiceSectionFlags = ({params, payload, urlParams}: HttpEditServiceSectionFlags) =>
    axios.delete(`luminate/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.itemId}/flags`, {
        params: {
            ...params,
            ...payload
        }
    })

export type HttpSubmitService = {
    urlParams: {
        serviceId: number
    }
    payload: {is_waitlisted: boolean; is_host_approval_needed: boolean}
    params: {response_type: ResponseType}
}

export const httpSubmitService = ({params, payload, urlParams}: HttpSubmitService) =>
    axios.post(`luminate/services/${urlParams.serviceId}/submit`, payload, {params})

//save service merch
export const httpSaveServiceMerch = ({params, payload, urlParams}: HttpSubmitService) =>
    axios.patch(`luminate/services/${urlParams.serviceId}/merchandising-update`, payload, {params})

export type HttpGetAdminServiceVersions = {
    urlParams: {serviceId: number}
    params: {response_type: ResponseType}
}

export const httpGetAdminServiceVersions = ({urlParams, params}: HttpGetAdminServiceVersions) =>
    axios.get(`luminate/services/${urlParams.serviceId}/versions`, {
        params
    })

interface HttpUploadFileOptions {
    url: string
    payload: File | Blob
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export const httpUploadFile = ({url, payload, onUploadProgress}: HttpUploadFileOptions) =>
    axiosOriginalInstance.put(url, payload, {onUploadProgress, headers: {'Content-Type': 'multipart/form-data'}})

export interface HttpGetPresignBulkPhotosOptions {
    urlParams: {serviceId: number; galleryId: number}
    payload: {medias: string[]; is_offering_image: boolean}
}
export const httpGetPresignBulkPhotos = ({payload, urlParams}: HttpGetPresignBulkPhotosOptions) =>
    axios.post(`luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/bulk`, payload)

/*Confirm upload photo*/
interface HttpConfirmUploadPhotosOptions {
    mediaId: number
    serviceId: number
    galleryId: number
}
export const httpConfirmUploadPhoto = async (urlParams: HttpConfirmUploadPhotosOptions) => {
    const response = await axios.patch(
        `luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/${urlParams.mediaId}/upload-completed`
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ServiceGalleryImage,
        onZodError: captureException
    })
}

/*Delete photo*/
export interface HttpDeleteServicePhotoOptions {
    mediaId: number
    serviceId: number
    galleryId: number
}
export const httpDeleteServicePhoto = (urlParams: HttpDeleteServicePhotoOptions) =>
    axios.delete(`luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/${urlParams.mediaId}`)

export type HttpUpdateIsCoverGalleryImg = {
    urlParams: {serviceId: number; galleryId: number; imgId: number}
    payload: {is_cover: boolean}
    params: {response_type: ResponseType}
}

export const httpUpdateIsCoverGalleryImg = ({urlParams, payload, params}: HttpUpdateIsCoverGalleryImg) =>
    axios.patch(
        `/luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/${urlParams.imgId}/is-cover`,
        payload,
        {params}
    )

export type HttpUpdateOfferingMainPhoto = {
    urlParams: {serviceId: number; offeringId: number; imgId: number}
    params: {response_type: ResponseType}
}

export const httpUpdateOfferingMainPhoto = ({urlParams, params}: HttpUpdateOfferingMainPhoto) =>
    axios.patch(
        `/luminate/services/${urlParams.serviceId}/offering/${urlParams.offeringId}/images/${urlParams.imgId}/change-main-photo`,
        {params}
    )

export type HttpRestoreOfferingMainPhoto = {
    urlParams: {serviceId: number; offeringId: number}
    params: {response_type: ResponseType}
}

export const httpRestoreOfferingMainPhoto = ({urlParams, params}: HttpRestoreOfferingMainPhoto) =>
    axios.patch(
        `/luminate/services/${urlParams.serviceId}/offering/${urlParams.offeringId}/images/restore-main-photo`,
        {params}
    )

export type HttpGetServiceGalleryImagesOptions = {
    urlParams: {serviceId: number; galleryId: number}
    params: {response_type: ResponseType}
}

export const HttpGetServiceGalleryImagesResponse = z.array(ServiceGalleryImage)
export type HttpGetServiceGalleryImagesResponse = z.infer<typeof HttpGetServiceGalleryImagesResponse>

export const httpGetServiceGalleryImages = ({urlParams, params}: HttpGetServiceGalleryImagesOptions) =>
    axios.get(`/luminate/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images`, {params})

export type HttpUpdateServices = {
    urlParams: {serviceId: number}
    payload: {is_pro_photography_required: boolean}
    params: {response_type: ResponseType}
}

export const httpUpdateServices = ({urlParams, payload, params}: HttpUpdateServices) =>
    axios.patch(`/luminate/services/${urlParams.serviceId}`, payload, {params})
