import {boolean, z} from 'zod'
import {ImageThumbnails} from '@/types.ts'
import {VettingItemStatus} from '@/features/ops-vetting/types.ts'

export const ExperienceStatus = z.enum([
    'pending_id_verification',
    'archived_pending_host_approval',
    'archived_update_required',
    'canceled',
    'declined',
    'in_progress',
    'pending_export',
    'pending_merchandising',
    'pending_publishing',
    'pending_shell_creation',
    'pending_shell_submission',
    'publishing_enabled',
    'under_review',
    'update_required',
    'approval_required',
    'approved'
])
export type ExperienceStatus = z.infer<typeof ExperienceStatus>

export const StepKeyName = z.enum([
    'experience',
    'background',
    'merchandising',
    'location',
    'photos',
    'itinerary',
    'pricing_discounts',
    'settings',
    'legal',
    'home',
    'category',
    'about_you',
    'gallery',
    'pricing',
    'discounts',
    'availability',
    'guests',
    'accessibility',
    'terms',
    'about_you_more_info',
    'suggestions',
    'enhance_your_experience',
    'enhance_about_you',
    'enhance_gallery'
])
export type StepKeyName = z.infer<typeof StepKeyName>

export const ShellStepKeyName = z.enum([
    'experience',
    'background',
    'merchandising',
    'location',
    'photos',
    'itinerary',
    'pricing_discounts',
    'settings',
    'legal'
])
export type ShellStepKeyName = z.infer<typeof ShellStepKeyName>

export const Category = z.enum([
    'art_design',
    'entertainment',
    'fitness_wellness',
    'food_drink',
    'generic',
    'history_culture',
    'nature_outdoors'
])
export type Category = z.infer<typeof Category>

export const CategoryFormat = z.enum([
    'landmark_tour',
    'museum_tour',
    'cultural_tour',
    'food_tour',
    'cooking_classes',
    'tasting',
    'dining',
    'outdoor',
    'water_sports',
    'flying',
    'wildlife',
    'gallery_tour',
    'art_workshop',
    'architecture_tour',
    'shopping_fashion',
    'concerts',
    'theater_dance',
    'nightlife',
    'games',
    'workouts',
    'wellness',
    'beauty',
    'wildfire_animals',
    'live_performances',
    'catering',
    'chef',
    'prepared_meals',
    'photography',
    'massage',
    'hair_styling',
    'makeup',
    'nails',
    'personal_training',
    'spa_treatments'
])
export type CategoryFormat = z.infer<typeof CategoryFormat>

export const SkillLevel = z.enum(['beginner', 'intermediate', 'advanced', 'expert'])
export type SkillLevel = z.infer<typeof SkillLevel>

export const ActivityLevel = z.enum(['light', 'moderate', 'strenuous', 'extreme'])
export type ActivityLevel = z.infer<typeof ActivityLevel>

export const ItineraryQuestionKeyName = z.enum([
    'title',
    'description',
    'duration',
    'main_photo_url',
    'improved_title',
    'improved_description'
])
export type ItineraryQuestionKeyName = z.infer<typeof ItineraryQuestionKeyName>

export const QuestionKeyName = z.enum([
    'accessibility_additional_information',
    'accessibility_additional_qualifications',
    'accessibility_feature',
    'accessibility_features',
    'activity_level',
    'additional_guests_cutoff_time',
    'additional_languages',
    'always_require_minimum_price',
    'cancellation_policy',
    'country_region',
    'early_bird_discount',
    'experience_start_time',
    'expertise_years',
    'first_guests_cutoff_time',
    'guest_bringing_item',
    'guest_bringing_items',
    'group_minimum_price',
    'guest_individual_price',
    'host_discusses_poi_significance',
    'host_serve_alcohol',
    'host_serve_food',
    'host_transporting_guests_method',
    'host_transports_guests',
    'instance_private_groups',
    'involves_national_park',
    'involves_point_of_interest',
    'is_licensed_establishment',
    'discounts',
    'listing_title',
    'location_address',
    'location_city',
    'location_state',
    'location_zip',
    'main_language',
    'maximum_private_group_size',
    'maximum_public_group_size',
    'meeting_point_description',
    'meeting_point_name',
    'minimum_age',
    'new_listing_discount',
    'offer_large_group_discount',
    'online_profile_links',
    'parents_kids_under_2',
    'prepared_in_restaurant',
    'residential_city',
    'residential_country_region',
    'residential_state',
    'residential_address',
    'residential_address_extras',
    'residential_zip',
    'skill_level',
    'unlisted_datetimes_allowed',

    // Not used
    'activity_description',
    'host_last_name',
    'activity_duration',
    'activity_main_photo',
    'activity_title',
    'byline',
    'guest_activities',
    'credentials_summary',
    'expertise_summary',
    'host_first_name',
    'host_name',
    'recognition_summary',
    'location_address_extras',
    'location_description',
    'minimum_guests_number',
    //new remapped
    'business_name',
    'description',
    'duration',
    'experience_description',
    'title',
    'experience_title',
    'guest_bring_something',
    'have_accessibility_features',
    'host_credential',
    'host_experience_title',
    'host_expertise_description',
    'host_recognition_subtitle',
    'host_recognition_title',
    'hosting_as_business',
    'main_photo_url',
    //improved answers
    'improved_host_experience_title',
    'improved_host_expertise_description',
    'improved_host_recognition_title',
    'improved_host_recognition_subtitle',
    'improved_host_credential',
    'improved_experience_title',
    'improved_experience_description',
    'improved_title',
    'improved_description',
    'profile_photo',
    //legal answers
    'service_in_national_park'
])
export type QuestionKeyName = z.infer<typeof QuestionKeyName>

export const FormFieldKeyName = z.enum([
    ...QuestionKeyName.options,
    //fields that are not questions
    'categoryKeyName',
    'subCategoryKeyName',
    'itineraries'
])
export type FormFieldKeyName = z.infer<typeof FormFieldKeyName>

export const TransportingGuestsMode = z.enum(['car', 'boat', 'plane', 'motorcycle'])
export type TransportingGuestsMode = z.infer<typeof TransportingGuestsMode>

export const TransportingGuestsCheckAndMode = z
    .object({
        [FormFieldKeyName.enum.host_transports_guests]: z.literal(true),
        [FormFieldKeyName.enum.host_transporting_guests_method]: TransportingGuestsMode.array()
    })
    .or(
        z.object({
            [FormFieldKeyName.enum.host_transports_guests]: z.literal(false),
            [FormFieldKeyName.enum.host_transporting_guests_method]: z.undefined()
        })
    )
export type TransportingGuestsCheckAndMode = z.infer<typeof TransportingGuestsCheckAndMode>

export const TransportingGuestsCheckAndModePartial = z
    .object({
        [FormFieldKeyName.enum.host_transports_guests]: z.literal(true),
        [FormFieldKeyName.enum.host_transporting_guests_method]: TransportingGuestsMode.array()
    })
    .partial()
    .or(
        z
            .object({
                [FormFieldKeyName.enum.host_transports_guests]: z.literal(false),
                [FormFieldKeyName.enum.host_transporting_guests_method]: z.undefined()
            })
            .partial()
    )
export type TransportingGuestsCheckAndModePartial = z.infer<typeof TransportingGuestsCheckAndModePartial>

export const AccessibilityFeatures = z.enum([
    'sign_language_options',
    'designated_sighted_guide',
    'step_free_access',
    'step_free_access_description',
    'step_free_access_bathroom',
    'disabled_parking_spot',
    'entrances_wider_32_inch',
    'mainly_flat_leveled_ground',
    'no_extreme_sensory_stimuli',
    'quiet_retreat_space_available',
    'free_admission_access_providers'
])
export type AccessibilityFeatures = z.infer<typeof AccessibilityFeatures>

export const ExperienceFlag = z.object({
    id: z.number().int().positive(),
    scope: z.enum([
        'experience_title',
        'experience_description',
        'host_title',
        'host_expertise',
        'itinerary_title',
        'itinerary_description',
        'profile_picture',
        'photo_gallery',
        'location',
        'decline_expertise',
        'gallery'
    ]),
    keyName: z.enum([
        'spelling_grammar_or_formatting_issues',
        'not_representative_of_activity',
        'not_descriptive_enough',
        'spelling_or_grammatical_errors',
        'more_details_needed',
        'lack_of_relevance_to_location',
        'lack_of_cultural_component',
        'experience_missing_or_unrelated_to_activity',
        'insufficient_details',
        'add_intro_activity',
        'add_final_activity',
        'merge_repetitive_activities',
        'separate_activities_long_duration',
        'photo_is_missing',
        'photo_is_low_quality_or_irrelevant',
        'photo_includes_multiple_people',
        'photo_is_a_passport_id_photo',
        'photo_is_low_quality',
        'photos_do_not_match_activity_or_place_description',
        'photos_lack_variety',
        'photos_do_not_show_unposed_guest_interaction',
        'photos_do_not_clearly_convey_who_the_host_is',
        'photos_do_not_show_activity_highlights',
        'photos_appear_to_be_screenshots',
        'photos_show_a_policy_violation',
        'more_details_on_location',
        'venue_is_not_suitable_for_activity',
        'not_defined_or_too_many_options_shared',
        'test_or_spam',
        'minimum_quality_violation',
        'its_a_service',
        'no_sufficient_cultural_component',
        'unhosted_violation',
        'how_to_host_violation',
        'animal_policy_violation',
        'prohibited_outdoor_activities',
        'drug_policy_violation',
        'adult_theme_policy_violation',
        'music_copyright_violations',
        'border_crossing',
        'minors_policy_violation',
        'includes_accommodation',
        'political_activities',
        'projectile_weapons_violation',
        'poverty_tourism',
        'other_safety_or_policy_violation',
        'lack_photo_variety',
        'requires_pro_photography'
    ])
})
export type ExperienceFlag = z.infer<typeof ExperienceFlag>

export type ExperienceFlagWithLabel = ExperienceFlag & {label: string}

export const ExperienceVettingBaseQuestion = z.object({
    id: z.number(),
    flags: z.array(ExperienceFlag)
})

export const ExperienceGalleryImage = z.object({
    name: z.string(),
    id: z.number().int().positive(),
    url: z.string().url(),
    thumbnails: ImageThumbnails,
    isCover: boolean(),
    isHostApprovalNeeded: z.boolean(),
    isItineraryImage: z.boolean(),
    flags: z.array(ExperienceFlag),
    isApproved: z.boolean().nullable(),
    position: z.number().nullable()
})
export type ExperienceGalleryImage = z.infer<typeof ExperienceGalleryImage>

export const Itinerary = z.object({
    id: z.number(),
    submissionId: z.number().int().positive(),
    title: z.string(),
    status: VettingItemStatus.nullable(),
    description: z.string(),
    coverImage: ExperienceGalleryImage.nullable(),
    coverImageId: z.number().int().positive().nullable(),
    originalCoverImageId: z.number().int().positive().nullable(),
    durationInMinutes: z.number().int().positive().nullable(),
    answers: z.array(
        //We need to use literal instead of enum to simplify the remap util (it doesn't work with z.enum)
        z.discriminatedUnion('questionKeyName', [
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.title),
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }),
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.description),
                answer: z.string(),
                previousAnswer: z.string().nullable()
            }),
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.duration),
                answer: z.number(),
                previousAnswer: z.number().nullable()
            }),
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.main_photo_url),
                answer: z.string().nullable()
            }),
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.improved_title),
                answer: z.string().nullable(),
                previousAnswer: z.string().nullable()
            }),
            ExperienceVettingBaseQuestion.extend({
                questionKeyName: z.literal(ItineraryQuestionKeyName.enum.improved_description),
                answer: z.string().nullable(),
                previousAnswer: z.string().nullable()
            })
        ])
    )
})
export type Itinerary = z.infer<typeof Itinerary>

export const Experience = z.object({
    airtableLink: z.string().url().nullish(),
    canAirbnbImportContent: z.boolean(),
    id: z.number().int().positive(),
    status: ExperienceStatus,
    step: z.object({keyName: StepKeyName}),
    isHostModified: z.boolean().optional(),
    host: z.object({
        firstName: z.string().nullable(),
        profilePhotoUrl: z.string().url().nullable()
    }),
    itineraries: z.array(Itinerary),
    gallery: z.object({
        id: z.number().int().positive(),
        submissionId: z.number().int().positive(),
        flags: ExperienceFlag.array(),
        images: z.array(ExperienceGalleryImage)
    }),
    category: z.object({
        category: z
            .object({
                id: z.number(),
                keyName: Category
            })
            .nullable(),
        subCategory: z
            .object({
                id: z.number(),
                keyName: CategoryFormat
            })
            .nullable()
    }),
    questions: z.array(
        z.object({
            keyName: QuestionKeyName
        })
    ),
    discounts: z.array(
        z.object({
            groupSize: z.number().int().positive(),
            discountInPercentage: z.number()
        })
    ),
    expertise: z.object({
        id: z.number(),
        status: VettingItemStatus.nullable(),
        flags: z.array(ExperienceFlag),
        answers: z.array(
            //We need to use literal instead of enum to simplify the remap util (it doesn't work with z.enum)
            z.discriminatedUnion('questionKeyName', [
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_address),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_country_region),
                    answer: z.string()
                }),

                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_city),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_state),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_zip),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.profile_photo),
                    answer: z.string().url().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.country_region),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_address),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_city),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_state),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_zip),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.meeting_point_name),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.meeting_point_description),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.listing_title),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_start_time),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.additional_guests_cutoff_time),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.expertise_years),
                    answer: z.coerce.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_individual_price),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.group_minimum_price),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.minimum_age),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.online_profile_links),
                    answer: z.array(z.string())
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_bringing_items),
                    answer: z.array(z.string().min(1))
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.main_language),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.additional_languages),
                    answer: z.array(z.string().min(1))
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.discounts),
                    answer: z.array(
                        ExperienceVettingBaseQuestion.extend({
                            groupSize: z.number().int().positive(),
                            discountInPercentage: z.number()
                        })
                    )
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.skill_level),
                    answer: SkillLevel
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_level),
                    answer: ActivityLevel
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.instance_private_groups),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.always_require_minimum_price),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.new_listing_discount),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.early_bird_discount),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.involves_national_park),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.involves_point_of_interest),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_discusses_poi_significance),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_transports_guests),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_serve_food),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_serve_alcohol),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.prepared_in_restaurant),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.is_licensed_establishment),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.offer_large_group_discount),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.parents_kids_under_2),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_feature),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_transporting_guests_method),
                    answer: TransportingGuestsMode.array()
                }),

                // Not used
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_description),
                    answer: z.string().array()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_duration),
                    answer: z.string().array()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_main_photo),
                    answer: z.string().array()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_title),
                    answer: z.string().array()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_activities),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.credentials_summary),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.expertise_summary),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_first_name),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_name),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.recognition_summary),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_address_extras),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_description),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.minimum_guests_number),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_address_extras),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.byline),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_additional_information),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_additional_qualifications),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_features),
                    answer: z.array(AccessibilityFeatures)
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.cancellation_policy),
                    answer: z.enum(['7_days', '24_hours'])
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.first_guests_cutoff_time),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_bringing_item),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.maximum_private_group_size),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.maximum_public_group_size),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.unlisted_datetimes_allowed),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.business_name),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.description),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.duration),
                    answer: z.number()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_description),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.title),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_title),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_bring_something),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.have_accessibility_features),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_credential),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_experience_title),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_expertise_description),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_recognition_subtitle),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_recognition_title),
                    answer: z.string()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.hosting_as_business),
                    answer: z.boolean()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.main_photo_url),
                    answer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_last_name),
                    answer: z.string()
                }),
                //improved questions
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_expertise_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_recognition_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_recognition_subtitle),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_credential),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_experience_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                //legal answers
                ExperienceVettingBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.service_in_national_park),
                    answer: z.boolean()
                })
            ])
        )
    })
})
export type Experience = z.infer<typeof Experience>
