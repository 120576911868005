import {ButtonHTMLAttributes, HTMLAttributes} from 'react'
import {
    StyledModalBody,
    StyledModalFooter,
    StyledModalHeader,
    StyledModalParagraph,
    StyledModalTitle
} from '@/components/ui/modal-atoms/style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/Icon'

/**
 * @deprecated Use Dialog instead
 */
export const ModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalHeader {...props} />
/**
 * @deprecated Use Dialog instead
 */
export const ModalBody = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalBody {...props} />
/**
 * @deprecated Use Dialog instead
 */
export const ModalFooter = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalFooter {...props} />
/**
 * @deprecated Use Dialog instead
 */
export const ModalTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledModalTitle {...props} />
/**
 * @deprecated Use Dialog instead
 */
export const ModalParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => <StyledModalParagraph {...props} />
/**
 * @deprecated Use Dialog instead
 */
export const ModalXCloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button variant="ghost" size="sm" iconOnly {...props}>
        <XCloseIcon />
    </Button>
)
