import {
    Experience,
    ExperienceFlag,
    ExperienceStatus,
    FormFieldKeyName,
    ItineraryQuestionKeyName,
    QuestionKeyName
} from '@/features/experience-shell/types.ts'
import {ExperienceDiffsMap, RemapExperienceVetting} from '@/features/experience-vetting/types.ts'
import {ExperienceVettingMerchOutputSchema, ExperienceShellMerchOutputSchema} from './formSchemas'
import {ObjectValues} from '@/utilities/helpers'
import {TKey} from '@/translations/i18n'

export const remapExperienceForVetting = (experience: Experience) => {
    const findAnswer = <TQuestionKeyName extends QuestionKeyName>(questionKeyName: TQuestionKeyName) =>
        experience.expertise.answers.find(answer => answer.questionKeyName == questionKeyName) as
            | Extract<Experience['expertise']['answers'][number], {questionKeyName: TQuestionKeyName}>
            | undefined

    const findItineraryAnswer = <TQuestionKeyName extends ItineraryQuestionKeyName>(
        itineraryId: number,
        questionKeyName: TQuestionKeyName
    ) =>
        experience.itineraries
            .find(item => item.id == itineraryId)
            ?.answers.find(answer => answer.questionKeyName == questionKeyName) as
            | Extract<Experience['expertise']['answers'][number], {questionKeyName: TQuestionKeyName}>
            | undefined

    const remappedExperience = {
        ...experience,
        expertise: {
            ...experience.expertise,
            answers: {
                [FormFieldKeyName.enum.expertise_years]: findAnswer(QuestionKeyName.enum.expertise_years),
                [FormFieldKeyName.enum.listing_title]: findAnswer(QuestionKeyName.enum.listing_title),
                [FormFieldKeyName.enum.meeting_point_name]: findAnswer(QuestionKeyName.enum.meeting_point_name),
                [FormFieldKeyName.enum.guest_individual_price]: findAnswer(QuestionKeyName.enum.guest_individual_price),
                [FormFieldKeyName.enum.experience_description]: findAnswer(QuestionKeyName.enum.experience_description),
                [FormFieldKeyName.enum.host_experience_title]: findAnswer(QuestionKeyName.enum.host_experience_title),
                [FormFieldKeyName.enum.host_expertise_description]: findAnswer(
                    QuestionKeyName.enum.host_expertise_description
                ),
                [FormFieldKeyName.enum.profile_photo]: findAnswer(QuestionKeyName.enum.profile_photo),
                [FormFieldKeyName.enum.host_recognition_title]: findAnswer(QuestionKeyName.enum.host_recognition_title),
                [FormFieldKeyName.enum.host_recognition_subtitle]: findAnswer(
                    QuestionKeyName.enum.host_recognition_subtitle
                ),
                [FormFieldKeyName.enum.host_credential]: findAnswer(QuestionKeyName.enum.host_credential),
                [FormFieldKeyName.enum.experience_title]: findAnswer(QuestionKeyName.enum.experience_title),
                [FormFieldKeyName.enum.improved_host_experience_title]: findAnswer(
                    QuestionKeyName.enum.improved_host_experience_title
                ),
                [FormFieldKeyName.enum.improved_host_expertise_description]: findAnswer(
                    QuestionKeyName.enum.improved_host_expertise_description
                ),
                [FormFieldKeyName.enum.improved_host_recognition_title]: findAnswer(
                    QuestionKeyName.enum.improved_host_recognition_title
                ),
                [FormFieldKeyName.enum.improved_host_recognition_subtitle]: findAnswer(
                    QuestionKeyName.enum.improved_host_recognition_subtitle
                ),
                [FormFieldKeyName.enum.improved_host_credential]: findAnswer(
                    QuestionKeyName.enum.improved_host_credential
                ),
                [FormFieldKeyName.enum.improved_experience_title]: findAnswer(
                    QuestionKeyName.enum.improved_experience_title
                ),
                [FormFieldKeyName.enum.improved_experience_description]: findAnswer(
                    QuestionKeyName.enum.improved_experience_description
                ),
                [FormFieldKeyName.enum.meeting_point_description]: findAnswer(
                    QuestionKeyName.enum.meeting_point_description
                )
            }
        },
        itineraries: experience.itineraries.map(itinerary => ({
            ...itinerary,
            answers: {
                [ItineraryQuestionKeyName.enum.title]: findItineraryAnswer(
                    itinerary.id,
                    ItineraryQuestionKeyName.enum.title
                ),
                [ItineraryQuestionKeyName.enum.description]: findItineraryAnswer(
                    itinerary.id,
                    ItineraryQuestionKeyName.enum.description
                ),
                [ItineraryQuestionKeyName.enum.duration]: findItineraryAnswer(
                    itinerary.id,
                    ItineraryQuestionKeyName.enum.duration
                ),
                [ItineraryQuestionKeyName.enum.improved_title]: findItineraryAnswer(
                    itinerary.id,
                    ItineraryQuestionKeyName.enum.improved_title
                ),
                [ItineraryQuestionKeyName.enum.improved_description]: findItineraryAnswer(
                    itinerary.id,
                    ItineraryQuestionKeyName.enum.improved_description
                )
            }
        }))
    }
    return RemapExperienceVetting.parse(remappedExperience)
}

//merch copy host edits diffs remapping
export const remapMerchCopyHostEditsDiffs = (experience: RemapExperienceVetting): ExperienceDiffsMap => {
    return {
        improved_host_experience_title: {
            hasDiff:
                experience.expertise.answers.improved_host_experience_title?.previousAnswer !==
                experience.expertise.answers.improved_host_experience_title?.answer,
            fieldSize: 'sm'
        },

        improved_host_expertise_description: {
            hasDiff:
                experience.expertise.answers.improved_host_expertise_description?.previousAnswer !==
                experience.expertise.answers.improved_host_expertise_description?.answer,
            fieldSize: 'md'
        },
        improved_host_recognition_title: {
            hasDiff:
                experience.expertise.answers.improved_host_recognition_title?.previousAnswer !==
                experience.expertise.answers.improved_host_recognition_title?.answer,
            fieldSize: 'sm'
        },
        improved_host_recognition_subtitle: {
            hasDiff:
                experience.expertise.answers.improved_host_recognition_subtitle?.previousAnswer !==
                experience.expertise.answers.improved_host_recognition_subtitle?.answer,
            fieldSize: 'sm'
        },
        improved_experience_title: {
            hasDiff:
                experience.expertise.answers.improved_experience_title?.previousAnswer !==
                experience.expertise.answers.improved_experience_title?.answer,
            fieldSize: 'sm'
        },
        improved_experience_description: {
            hasDiff:
                experience.expertise.answers.improved_experience_description?.previousAnswer !==
                experience.expertise.answers.improved_experience_description?.answer,
            fieldSize: 'md'
        },
        activities: experience.itineraries.map(itinerary => [
            {
                hasDiff: itinerary.answers.improved_title?.previousAnswer !== itinerary.answers.improved_title?.answer,
                fieldSize: 'sm'
            },
            {
                hasDiff:
                    itinerary.answers.improved_description?.previousAnswer !==
                    itinerary.answers.improved_description?.answer,
                fieldSize: 'md'
            }
        ])
    }
}

//merch copy input form defaultValues remapping
export const remapExperienceMerchCopyInputForm = (
    experience: RemapExperienceVetting
): ExperienceShellMerchOutputSchema => {
    return {
        host_experience_title: experience.expertise.answers.host_experience_title?.answer ?? '',
        host_expertise_description: experience.expertise.answers.host_expertise_description?.answer ?? '',
        host_recognition_title: experience.expertise.answers.host_recognition_title?.answer ?? '',
        host_recognition_subtitle: experience.expertise.answers.host_recognition_subtitle?.answer ?? '',
        experience_title: experience.expertise.answers.experience_title?.answer ?? '',
        experience_description: experience.expertise.answers.experience_description?.answer ?? '',
        activities: experience.itineraries.map(itinerary => ({
            id: itinerary.id,
            title: itinerary.answers.title?.answer ?? '',
            description: itinerary.answers.description?.answer ?? '',
            duration: itinerary.answers.duration?.answer ? String(itinerary.answers.duration.answer) : ''
        }))
    }
}

export const remapExperienceMerchCopyOutputForm = (
    experience: RemapExperienceVetting
): ExperienceVettingMerchOutputSchema => {
    return {
        improved_host_experience_title: experience.expertise.answers.improved_host_experience_title?.answer ?? '',
        improved_host_expertise_description:
            experience.expertise.answers.improved_host_expertise_description?.answer ?? '',
        improved_host_recognition_title: experience.expertise.answers.improved_host_recognition_title?.answer ?? '',
        improved_host_recognition_subtitle:
            experience.expertise.answers.improved_host_recognition_subtitle?.answer ?? '',
        improved_experience_title: experience.expertise.answers.improved_experience_title?.answer ?? '',
        improved_experience_description: experience.expertise.answers.improved_experience_description?.answer ?? '',
        activities: experience.itineraries.map(itinerary => ({
            id: itinerary.id,
            improved_title: itinerary.answers.improved_title?.answer ?? '',
            improved_description: itinerary.answers.improved_description?.answer ?? '',
            duration: itinerary.answers.duration?.answer ? String(itinerary.answers.duration.answer) : ''
        }))
    }
}

export const remapExperienceMerchCopyOutputFormHostEdits = (
    experience: RemapExperienceVetting
): ExperienceVettingMerchOutputSchema => {
    return {
        improved_host_experience_title:
            experience.expertise.answers.improved_host_experience_title?.previousAnswer ?? '',
        improved_host_expertise_description:
            experience.expertise.answers.improved_host_expertise_description?.previousAnswer ?? '',
        improved_host_recognition_title:
            experience.expertise.answers.improved_host_recognition_title?.previousAnswer ?? '',
        improved_host_recognition_subtitle:
            experience.expertise.answers.improved_host_recognition_subtitle?.previousAnswer ?? '',
        improved_experience_title: experience.expertise.answers.improved_experience_title?.previousAnswer ?? '',
        improved_experience_description:
            experience.expertise.answers.improved_experience_description?.previousAnswer ?? '',
        activities: experience.itineraries.map(itinerary => ({
            id: itinerary.id,
            improved_title: itinerary.answers.improved_title?.previousAnswer ?? '',
            improved_description: itinerary.answers.improved_description?.previousAnswer ?? '',
            duration: itinerary.answers.duration?.previousAnswer
                ? String(itinerary.answers.duration.previousAnswer)
                : ''
        }))
    }
}

export const checkExperienceErrors = (experience: RemapExperienceVetting) => {
    const expertiseErrors: TKey[] = []
    const itemErrors: {itemId: number; error: TKey}[] = []
    const galleryErrors: TKey[] = []
    const rejectedImagesErrors: TKey[] = []
    const remapImages = experience.gallery.images.filter(
        image => !image.isCover && !image.isHostApprovalNeeded && !image.isItineraryImage
    )

    if (!experience.expertise.status) {
        expertiseErrors.push('vetting:criteria:service:validation_errors:make_a_decision')
    } else if (
        experience.expertise.status == 'declined' &&
        experience.expertise.flags &&
        experience.expertise.flags.length == 0
    ) {
        expertiseErrors.push('vetting:criteria:service:validation_errors:select_at_least_one_flag')
    } else if (experience.expertise.status == 'back_to_draft') {
        if (ObjectValues(experience.expertise.answers).every(value => !value?.flags || value?.flags.length == 0)) {
            expertiseErrors.push('vetting:criteria:service:validation_errors:flag_at_least_one_field')
        }
    }

    if (experience.expertise.status != 'declined') {
        experience.itineraries.forEach(itinerary => {
            if (!itinerary.status) {
                itemErrors.push({
                    itemId: itinerary.id,
                    error: 'vetting:criteria:service:validation_errors:make_a_decision'
                })
            } else if (itinerary.status == 'back_to_draft') {
                if (ObjectValues(itinerary.answers).every(value => value?.flags.length === 0)) {
                    itemErrors.push({
                        itemId: itinerary.id,
                        error: 'vetting:criteria:service:validation_errors:flag_at_least_one_field'
                    })
                }
            }
        })

        if (remapImages.some(value => value.isApproved === null)) {
            galleryErrors.push('vetting:criteria:service:validation_errors:select_accept_reject')
        }
        if (remapImages.filter(value => value.isApproved === false).some(value => value.flags.length == 0)) {
            rejectedImagesErrors.push('vetting:criteria:service:validation_errors:select_flag_for_rejected_image')
        }
    }

    return {
        errors: {expertiseErrors, itemErrors, galleryErrors, rejectedImagesErrors},
        isValid: ObjectValues({
            expertiseErrors,
            itemErrors,
            galleryErrors,
            rejectedImagesErrors
        }).every(error => error.length == 0)
    }
}

export const experiencesFlagsMapToLabel = {
    spelling_grammar_or_formatting_issues:
        'vetting:criteria:experience:flags_key_name:spelling_grammar_or_formatting_issues',
    not_representative_of_activity: 'vetting:criteria:experience:flags_key_name:not_representative_of_activity',
    not_descriptive_enough: 'vetting:criteria:experience:flags_key_name:not_descriptive_enough',
    spelling_or_grammatical_errors: 'vetting:criteria:experience:flags_key_name:spelling_or_grammatical_errors',
    more_details_needed: 'vetting:criteria:experience:flags_key_name:more_details_needed',
    lack_of_relevance_to_location: 'vetting:criteria:experience:flags_key_name:lack_of_relevance_to_location',
    lack_of_cultural_component: 'vetting:criteria:experience:flags_key_name:lack_of_cultural_component',
    experience_missing_or_unrelated_to_activity:
        'vetting:criteria:experience:flags_key_name:experience_missing_or_unrelated_to_activity',
    insufficient_details: 'vetting:criteria:experience:flags_key_name:insufficient_details',
    add_intro_activity: 'vetting:criteria:experience:flags_key_name:add_intro_activity',
    add_final_activity: 'vetting:criteria:experience:flags_key_name:add_final_activity',
    merge_repetitive_activities: 'vetting:criteria:experience:flags_key_name:merge_repetitive_activities',
    separate_activities_long_duration: 'vetting:criteria:experience:flags_key_name:separate_activities_long_duration',
    photo_is_missing: 'vetting:criteria:experience:flags_key_name:photo_is_missing',
    photo_is_low_quality_or_irrelevant: 'vetting:criteria:experience:flags_key_name:photo_is_low_quality_or_irrelevant',
    photo_includes_multiple_people: 'vetting:criteria:experience:flags_key_name:photo_includes_multiple_people',
    photo_is_a_passport_id_photo: 'vetting:criteria:experience:flags_key_name:photo_is_a_passport_id_photo',
    photo_is_low_quality: 'vetting:criteria:experience:flags_key_name:photo_is_low_quality',
    photos_do_not_match_activity_or_place_description:
        'vetting:criteria:experience:flags_key_name:photos_do_not_match_activity_or_place_description',
    photos_lack_variety: 'vetting:criteria:experience:flags_key_name:photos_lack_variety',
    photos_do_not_show_unposed_guest_interaction:
        'vetting:criteria:experience:flags_key_name:photos_do_not_show_unposed_guest_interaction',
    photos_do_not_clearly_convey_who_the_host_is:
        'vetting:criteria:experience:flags_key_name:photos_do_not_clearly_convey_who_the_host_is',
    photos_do_not_show_activity_highlights:
        'vetting:criteria:experience:flags_key_name:photos_do_not_show_activity_highlights',
    photos_appear_to_be_screenshots: 'vetting:criteria:experience:flags_key_name:photos_appear_to_be_screenshots',
    photos_show_a_policy_violation: 'vetting:criteria:experience:flags_key_name:photos_show_a_policy_violation',
    more_details_on_location: 'vetting:criteria:experience:flags_key_name:more_details_on_location',
    venue_is_not_suitable_for_activity: 'vetting:criteria:experience:flags_key_name:venue_is_not_suitable_for_activity',
    not_defined_or_too_many_options_shared:
        'vetting:criteria:experience:flags_key_name:not_defined_or_too_many_options_shared',
    test_or_spam: 'vetting:criteria:experience:flags_key_name:test_or_spam',
    minimum_quality_violation: 'vetting:criteria:experience:flags_key_name:minimum_quality_violation',
    its_a_service: 'vetting:criteria:experience:flags_key_name:its_a_service',
    no_sufficient_cultural_component: 'vetting:criteria:experience:flags_key_name:no_sufficient_cultural_component',
    unhosted_violation: 'vetting:criteria:experience:flags_key_name:unhosted_violation',
    how_to_host_violation: 'vetting:criteria:experience:flags_key_name:how_to_host_violation',
    animal_policy_violation: 'vetting:criteria:experience:flags_key_name:animal_policy_violation',
    prohibited_outdoor_activities: 'vetting:criteria:experience:flags_key_name:prohibited_outdoor_activities',
    drug_policy_violation: 'vetting:criteria:experience:flags_key_name:drug_policy_violation',
    adult_theme_policy_violation: 'vetting:criteria:experience:flags_key_name:adult_theme_policy_violation',
    music_copyright_violations: 'vetting:criteria:experience:flags_key_name:music_copyright_violations',
    border_crossing: 'vetting:criteria:experience:flags_key_name:border_crossing',
    minors_policy_violation: 'vetting:criteria:experience:flags_key_name:minors_policy_violation',
    includes_accommodation: 'vetting:criteria:experience:flags_key_name:includes_accommodation',
    political_activities: 'vetting:criteria:experience:flags_key_name:political_activities',
    projectile_weapons_violation: 'vetting:criteria:experience:flags_key_name:projectile_weapons_violation',
    poverty_tourism: 'vetting:criteria:experience:flags_key_name:poverty_tourism',
    other_safety_or_policy_violation: 'vetting:criteria:experience:flags_key_name:other_safety_or_policy_violation',
    lack_photo_variety: 'vetting:criteria:experience:flags_key_name:lack_photo_variety',
    requires_pro_photography: 'vetting:criteria:commons:requires_pro_photography'
} as const satisfies Record<ExperienceFlag['keyName'], TKey>

export const experienceStatusToLabel = {
    archived_pending_host_approval: 'vetting:criteria:experience:experience_status:archived_pending_host_approval',
    archived_update_required: 'vetting:criteria:experience:experience_status:archived_update_required',
    canceled: 'vetting:criteria:experience:experience_status:canceled',
    declined: 'vetting:criteria:experience:experience_status:declined',
    in_progress: 'vetting:criteria:experience:experience_status:in_progress',
    pending_export: 'vetting:criteria:experience:experience_status:pending_export',
    pending_merchandising: 'vetting:criteria:experience:experience_status:pending_merchandising',
    pending_publishing: 'vetting:criteria:experience:experience_status:pending_publishing',
    pending_shell_creation: 'vetting:criteria:experience:experience_status:pending_shell_creation',
    pending_shell_submission: 'vetting:criteria:experience:experience_status:pending_shell_submission',
    publishing_enabled: 'vetting:criteria:experience:experience_status:publishing_enabled',
    under_review: 'vetting:criteria:experience:experience_status:under_review',
    update_required: 'vetting:criteria:experience:experience_status:update_required',
    approval_required: 'vetting:criteria:experience:experience_status:approval_required',
    pending_id_verification: 'vetting:criteria:experience:experience_status:pending_id_verification',
    approved: 'vetting:criteria:experience:experience_status:approved'
} as const satisfies Record<ExperienceStatus, TKey>
