import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {useDropzone} from 'react-dropzone'
import toast from 'react-hot-toast'
import {VETTING_MAX_FILE_TO_UPLOAD} from '@/features/ops-vetting/costants.ts'
import {GalleryItem, useExperienceVettingPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {FileToUpload} from '@/types/commons.ts'
import {useBulkPresignedExperienceVettingPhotos} from '@/features/experience-vetting/queries/useBulkPresignedExperienceVettingPhotos.ts'
import {useState} from 'react'
import {useUploadPhotos} from '@hooks/useUploadPhotos.ts'
import {httpExperienceServiceConfirmUploadPhoto} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {Experience} from '@/features/experience-shell/types'
export const ExperienceVettingCriteriaUploadButton = ({
    experienceId,
    galleryId,
    disabled
}: {
    experienceId: number
    galleryId: number
    disabled?: boolean
}) => {
    const {t} = useTranslation()
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
    const setUploadingItemsStatus = useExperienceVettingPhotoUpload(store => store.setUploadingItemsStatus)
    const addUploadProgressValues = useExperienceVettingPhotoUpload(store => store.addUploadProgressValues)
    const deleteGalleryItem = useExperienceVettingPhotoUpload(store => store.deleteGalleryItem)
    const addGalleryItems = useExperienceVettingPhotoUpload(store => store.addGalleryItems)
    const queryClient = useQueryClient()
    const bulkPresignedQuery = useBulkPresignedExperienceVettingPhotos({
        urlParams: {experienceId, galleryId},
        options: {
            onSuccess: data => {
                const galleryItems: GalleryItem[] = data.map((item, index) => ({
                    id: item.media.id,
                    url: URL.createObjectURL(acceptedFiles[index]),
                    status: 'pending',
                    name: item.media.name,
                    thumbnails: null //TODO: add thumbnails
                }))
                const uploadingItems: FileToUpload[] = data.map((item, index) => ({
                    id: item.media.id,
                    presignedUrl: item.presignedUrl,
                    file: acceptedFiles[index]
                }))
                addGalleryItems(galleryItems)
                uploadPhotos(uploadingItems)
            }
        }
    })

    const uploadPhotos = useUploadPhotos({
        storeAction: {setUploadingItemsStatus, addUploadProgressValues},
        onConfirm: async imageId => {
            const response = await httpExperienceServiceConfirmUploadPhoto({
                urlParams: {experienceId, galleryId, mediaId: imageId},
                params: {responseType: 'extended'}
            })
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_GALLERY_IMAGES]})
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]
            await queryClient.cancelQueries({queryKey})
            const previousData = queryClient.getQueryData<Experience>(queryKey)
            queryClient.setQueriesData<Experience>(
                {queryKey},
                previousData
                    ? {
                          ...previousData,
                          gallery: {
                              ...previousData.gallery,
                              images: [...previousData.gallery.images, response]
                          }
                      }
                    : previousData
            )
            deleteGalleryItem(imageId)
        }
    })

    const dropzoneState = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: VETTING_MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > VETTING_MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: VETTING_MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    return (
        <Dropzone state={dropzoneState}>
            <Button size={'sm'} variant={'secondary'} disabled={bulkPresignedQuery.isPending || disabled}>
                {bulkPresignedQuery.isPending ? <Spinner size={17} /> : <PlusIcon />}
                {t('vetting:criteria:service:gallery:upload_photos')}
            </Button>
        </Dropzone>
    )
}
