import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    HttpGetExperienceFlags,
    httpGetExperienceFlags
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {ExperienceFlag} from '@/features/experience-shell/types.ts'

export const useGetExperiencesFlags = (
    params: Omit<HttpGetExperienceFlags['params'], 'response_type'>,
    options?: Omit<UseQueryOptions<ExperienceFlag[]>, 'queryKey' | 'queryFn'>
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCES_FLAGS, params],
        queryFn: () => httpGetExperienceFlags({params: {...params, response_type: 'extended'}}),
        ...options
    })
}
