import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateExperienceItineraryStatus,
    httpUpdateExperienceItineraryStatus
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience} from '@/features/experience-shell/types.ts'

export const useUpdateExperienceItineraryStatus = (
    {experienceId, itineraryId}: {experienceId: number; itineraryId: number},
    options?: UseMutationOptions<unknown, unknown, HttpUpdateExperienceItineraryStatus['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExperienceItineraryStatus({
                urlParams: {experienceId: experienceId, itineraryId: itineraryId},
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        itineraries: previousData.itineraries.map(itinerary =>
                            itinerary.id === itineraryId ? {...itinerary, status: variables.status} : itinerary
                        )
                    }
                )
            }
            return previousData
        }
    })
}
