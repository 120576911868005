import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {Image, OnAllocateAction, PhotosetURLParams} from '@/features/photoset/types'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import {useDeallocateImages} from '@/features/photoset/queries/useDeallocateImages'
import {errorHandler} from '@/utilities/helpers'
import {useGalleryAllocationShortcuts} from '@/features/photoset/hooks/useGalleryAllocationShortcuts'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {AllocateDropdown} from '@/features/photoset/components/allocate-dropdown/AllocateDropdown'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {GalleryAllocationSidebar} from '@/features/photoset/components/gallery-photoset-modal/gallery-allocation-modal/GalleryAllocationSidebar'
import {useReallocateImages} from '@/features/photoset/queries/useReallocateImages'
import {StyledGalleryAllocation} from '@/features/photoset/components/gallery-photoset-modal/gallery-allocation-modal/style'

type GalleryAllocationModal = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
}

export const GalleryAllocationModal: FC<GalleryAllocationModal> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose
}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const selectedImages = usePhotosetStore(store => store.selectedImages)
    const selectImages = usePhotosetStore(store => store.selectImages)
    const unselectImages = usePhotosetStore(store => store.unselectImages)
    const allocateImages = usePhotosetStore(state => state.allocateImages)
    const deallocateImages = usePhotosetStore(store => store.deallocateImages)
    const undoLatestAction = usePhotosetStore(store => store.undoLatestAction)
    const allocationHistory = usePhotosetStore(store => store.allocationHistory)
    const unselectAll = usePhotosetStore(store => store.unselectAll)
    const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null)
    const deallocateImagesMutation = useDeallocateImages()
    const allocateImagesMutation = useReallocateImages()

    // Handle allocate action
    const onAllocate = async ({
        photosetSpaceId,
        imagesIds = selectedImages,
        isShortcut = false,
        keepSelected = false,
        trackInHistory = false
    }: OnAllocateAction) => {
        try {
            await allocateImagesMutation.mutateAsync({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: photosetSpaceId
                },
                payload: imagesIds
            })

            if (isShortcut && !trackInHistory) {
                undoLatestAction(keepSelected)
            } else {
                allocateImages({
                    imagesIds,
                    photosetSpaceId,
                    keepSelected,
                    trackInHistory
                })
            }

            const nextImage = selectableImages[currentDetailImageIndex + 1] ?? null
            unselectAll()

            if (nextImage) {
                selectImages([nextImage.id])
            } else {
                onClose()
            }
        } catch (error) {
            errorHandler(error)
        }
    }

    // Handle allocate action
    const onDeallocate = async ({
        photosetSpaceId,
        imagesIds,
        isShortcut = false,
        keepSelected = false,
        trackInHistory = false
    }: OnAllocateAction) => {
        try {
            await deallocateImagesMutation.mutateAsync({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: photosetSpaceId
                },
                payload: imagesIds
            })

            if (isShortcut) {
                undoLatestAction(keepSelected)
                return
            }

            deallocateImages({
                imagesIds,
                photosetSpaceId,
                keepSelected,
                trackInHistory
            })
        } catch (error) {
            errorHandler(error)
        }
    }

    // Handle allocate or deallocate action
    const onUndo = ({imagesIds, photosetSpaceId, isShortcut}: OnAllocateAction) => {
        // Get the latest allocate action from the history timeline
        const imageAllocationHistory = allocationHistory.find(
            history => history.imagesIds.includes(imagesIds[0]) && photosetSpaceId == history.photosetSpaceId
        )

        if (imageAllocationHistory?.type == 'allocation') {
            onDeallocate({
                photosetSpaceId,
                imagesIds,
                isShortcut,
                keepSelected: true,
                trackInHistory: false
            })
        }

        if (imageAllocationHistory?.type == 'deallocation') {
            onAllocate({
                photosetSpaceId,
                imagesIds,
                isShortcut,
                keepSelected: false,
                trackInHistory: false
            })
        }
    }

    useGalleryAllocationShortcuts({
        currentDetailImageIndex,
        selectableImages,
        onAllocate,
        onUndo,
        onClose,
        setCurrentDetailImageIndex,
        isPending: allocateImagesMutation.isPending || deallocateImagesMutation.isPending
    })

    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex] || selectableImages[currentDetailImageIndex - 1],
        [currentDetailImageIndex, selectableImages, selectedImages]
    )

    return (
        <StyledGalleryAllocation
            ref={setModalRef}
            currentImage={currentImage}
            thumbnails={currentImage.thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    currentImage={currentImage}
                    imagesCount={selectableImages.length}
                    endSlot={<AllocateDropdown container={modalRef} onAllocate={onAllocate} />}
                    onClose={onClose}
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImageId={currentImage.id}
                    thumbnails={'thumbnails'}
                    images={selectableImages}
                    onSelect={(image, index) => {
                        unselectImages([selectableImages[currentDetailImageIndex].id])
                        selectImages([image.id])
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('photoset:allocation:sidebar_title')}
                    content={<GalleryAllocationSidebar onAllocate={onAllocate} />}
                />
            }
        />
    )
}
