import {ResponseType, PaginationResponse, PaginationParams} from '@/types.ts'
import axios from '@/axiosInstanceNode.ts'
import {parseResponseData} from '@utilities/helpers.ts'
import {
    Category,
    CategoryFormat,
    Experience,
    ExperienceFlag,
    ExperienceGalleryImage,
    ExperienceStatus
} from '@/features/experience-shell/types.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {Media} from '@/features/service-vetting/types.ts'
import {
    EditExperienceFlagsItemUrlParam,
    EditExperienceFlagsSectionUrlParam
} from '@/features/experience-vetting/types.ts'
import {VettingItemStatus} from '@/features/ops-vetting/types.ts'

interface httpGetExperienceOptions {
    urlParams: {
        experienceId: number
    }
    params: {responseType: ResponseType}
}
export const httpGetExperienceVetting = async ({urlParams, params}: httpGetExperienceOptions) => {
    const response = await axios.get(`v1/experience/shell/submissions/${urlParams.experienceId}`, {params})

    return parseResponseData({responseData: response.data, responseShape: Experience, onZodError: captureException})
}

export interface HttpStorePresignBulkExperienceVettingPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    payload: {medias: string[]}
}

const BulkPresignedExperienceVettingPhotosResponse = z.array(z.object({media: Media, presignedUrl: z.string().url()}))
export type BulkPresignedExperienceVettingPhotosResponse = z.infer<typeof BulkPresignedExperienceVettingPhotosResponse>

export const httpStorePresignBulkExperienceVettingPhotos = async ({
    payload,
    urlParams
}: HttpStorePresignBulkExperienceVettingPhotosOptions) => {
    const response = await axios.post(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/upload`,
        payload
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: BulkPresignedExperienceVettingPhotosResponse,
        onZodError: captureException
    })
}

/*Confirm upload photo*/
interface HttpExperienceServiceConfirmUploadPhotoOptions {
    urlParams: {
        experienceId: number
        galleryId: number
        mediaId: number
    }
    params: {
        responseType: ResponseType
    }
}
export const httpExperienceServiceConfirmUploadPhoto = async ({
    urlParams,
    params
}: HttpExperienceServiceConfirmUploadPhotoOptions) => {
    const response = await axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.mediaId}/confirm`,
        undefined,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceGalleryImage,
        onZodError: captureException
    })
}

/*Delete photo*/
export interface HttpDeleteBulkExperienceVettingPhotoOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {imageIds: number[]}
}
export const httpDeleteBulkExperienceVettingPhoto = ({
    urlParams,
    params
}: HttpDeleteBulkExperienceVettingPhotoOptions) =>
    axios.delete(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/delete`,
        {params}
    )

export type HttpGetExperienceFlags = {
    params: {
        scopes?: ExperienceFlag['scope'][] | null
        response_type: ResponseType
    }
}

export const httpGetExperienceFlags = async ({params}: HttpGetExperienceFlags) => {
    const response = await axios.get(`/v1/experience/vetting/flags`, {params})
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceFlag.array(),
        onZodError: captureException
    })
}

export type HttpUpdateCategorySubCategoryOptions = {
    urlParams: {experienceId: number}
    payload: {categoryKeyName: Category; subCategoryKeyName: CategoryFormat}
    params: {
        responseType: ResponseType
    }
}

export const httpUpdateCategorySubCategory = async ({
    urlParams,
    payload,
    params
}: HttpUpdateCategorySubCategoryOptions) => {
    const response = await axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/category-sub-category`,
        payload,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: Experience,
        onZodError: captureException
    })
}

export type HttpEditExperienceSectionFlags = {
    urlParams: {
        experienceId: number
        itemId: number
        section: EditExperienceFlagsSectionUrlParam
    }
    payload: {flagIds: number[]}
    params: {response_type: ResponseType}
}

export const httpAddExperienceSectionFlags = ({params, payload, urlParams}: HttpEditExperienceSectionFlags) =>
    axios.post(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/${urlParams.section}/${urlParams.itemId}/flags`,
        payload,
        {
            params
        }
    )

export const httpRemoveExperienceSectionFlags = ({params, payload, urlParams}: HttpEditExperienceSectionFlags) =>
    axios.delete(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/${urlParams.section}/${urlParams.itemId}/flags`,
        {
            params: {
                ...params,
                ...payload
            }
        }
    )

export type HttpEditExperienceItemFlags = {
    urlParams: {
        experienceId: number
        sectionId?: number
        itemId: number
        section: EditExperienceFlagsSectionUrlParam
        item: EditExperienceFlagsItemUrlParam
    }
    payload: {flagIds: number[]}
    params: {response_type: ResponseType}
}

export const httpAddExperienceItemFlags = ({params, payload, urlParams}: HttpEditExperienceItemFlags) =>
    axios.post(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        payload,
        {params}
    )

export const httpRemoveExperienceItemFlags = ({params, payload, urlParams}: HttpEditExperienceItemFlags) =>
    axios.delete(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        {
            params: {
                ...params,
                ...payload
            }
        }
    )

export type HttpUpdateExperienceExpertiseStatus = {
    urlParams: {experienceId: number; expertiseId: number}
    payload: {status: VettingItemStatus}
    params: {response_type: ResponseType}
}

export const httpUpdateExperienceExpertiseStatus = ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceExpertiseStatus) =>
    axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/expertises/${urlParams.expertiseId}`,
        payload,
        {
            params
        }
    )

export type HttpGetExperienceVettingGalleryImagesOptions = {
    urlParams: {experienceId: number; galleryId: number}
    params: {response_type: ResponseType}
}

export const HttpGetExperienceVettingGalleryImagesResponse = z.array(ExperienceGalleryImage.omit({flags: true}))
export type HttpGetExperienceVettingGalleryImagesResponse = z.infer<
    typeof HttpGetExperienceVettingGalleryImagesResponse
>

export const httpGetExperienceVettingGalleryImages = async ({
    urlParams,
    params
}: HttpGetExperienceVettingGalleryImagesOptions) => {
    const response = await axios.get(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images`,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpGetExperienceVettingGalleryImagesResponse,
        onZodError: captureException
    })
}

export const HttpGetExperienceVettingCategoryFormatsResponse = z.object({
    data: z.array(
        z.object({
            id: z.number(),
            keyName: CategoryFormat
        })
    ),
    meta: PaginationResponse
})
export type HttpGetExperienceVettingCategoryFormatsResponse = z.infer<
    typeof HttpGetExperienceVettingCategoryFormatsResponse
>

export type HttpGetExperienceVettingCategoryFormatsParams = PaginationParams & {
    categoryKeyName: Category
}

export const httpGetExperienceVettingCategoryFormats = async (
    params: HttpGetExperienceVettingCategoryFormatsParams
) => {
    const response = await axios.get(`/v1/experience/sub-categories`, {params})
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpGetExperienceVettingCategoryFormatsResponse,
        onZodError: captureException
    })
}

export type HttpUpdateExperienceItineraryStatus = {
    urlParams: {experienceId: number; itineraryId: number}
    payload: {status: VettingItemStatus}
    params: {response_type: ResponseType}
}

export const httpUpdateExperienceItineraryStatus = ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceItineraryStatus) =>
    axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/itineraries/${urlParams.itineraryId}/status`,
        payload,
        {
            params
        }
    )

export type HttpUpdateExperienceCoverPhotoOptions = {
    urlParams: {experienceId: number; galleryId: number; imageId: number}
    payload: {isCover: boolean}
    params: {responseType: ResponseType}
}

export const httpUpdateExperienceCoverPhoto = async ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceCoverPhotoOptions) => {
    const response = await axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.imageId}/cover`,
        payload,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: Experience,
        onZodError: captureException
    })
}

export type HttpSubmitExperienceVettingOptions = {
    urlParams: {experienceId: number}
    payload: {isWaitlisted: boolean; isHostApprovalNeeded: boolean}
    params: {responseType: ResponseType}
}

export const httpSubmitExperienceVetting = ({urlParams, payload, params}: HttpSubmitExperienceVettingOptions) =>
    axios.post(`v1/experience/vetting/submissions/${urlParams.experienceId}/submit-to-airbnb`, payload, {params})

export type HttpUpdateExperienceVettingImageIsApprovedOptions = {
    urlParams: {experienceId: number; galleryId: number; imageId: number}
    payload: {isApproved: boolean}
    params: {responseType: ResponseType}
}

export const httpUpdateExperienceVettingImageIsApproved = ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceVettingImageIsApprovedOptions) =>
    axios.patch(
        `v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.imageId}/approval`,
        payload,
        {params}
    )

export type HttpExperienceVettingSaveMerchCopyOptions = {
    urlParams: {experienceId: number}
    payload: {isWaitlisted: boolean; isHostApprovalNeeded: boolean}
    params: {responseType: ResponseType}
}

export const httpExperienceVettingSaveMerchCopy = ({
    urlParams,
    payload,
    params
}: HttpExperienceVettingSaveMerchCopyOptions) => {
    return axios.patch(`/v1/experience/vetting/submissions/${urlParams.experienceId}/merchandising-update`, payload, {
        params
    })
}

export type HttpExperienceVettingCriteriaItineraryUpdateCoverPhotoOptions = {
    urlParams: {experienceId: number; itineraryId: number}
    payload: {coverImageId: number}
    params: {responseType: ResponseType}
}

export const httpExperienceVettingCriteriaItineraryUpdateCoverPhoto = ({
    urlParams,
    payload,
    params
}: HttpExperienceVettingCriteriaItineraryUpdateCoverPhotoOptions) =>
    axios.patch(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/itineraries/${urlParams.itineraryId}`,
        payload,
        {params}
    )

export type HttpUpdateExperienceVettingCriteriaItineraryCoveryImageOptions = {
    urlParams: {experienceId: number; itineraryId: number}
    payload: {coverImageId: number}
    params: {responseType: ResponseType}
}

export const httpUpdateExperienceVettingCriteriaItineraryCoveryImage = async ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceVettingCriteriaItineraryCoveryImageOptions) => {
    const response = await axios.patch(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/itineraries/${urlParams.itineraryId}/cover-image`,
        payload,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: Experience,
        onZodError: captureException
    })
}

export type HttpRestoreExperienceVettingCriteriaItineraryCoveryImageOptions = {
    urlParams: {experienceId: number; itineraryId: number}
    params: {responseType: ResponseType}
}

export const httpRestoreExperienceVettingCriteriaItineraryCoveryImage = async ({
    urlParams,
    params
}: HttpRestoreExperienceVettingCriteriaItineraryCoveryImageOptions) => {
    const response = await axios.patch(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/itineraries/${urlParams.itineraryId}/revert-cover-image`,
        undefined,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: Experience,
        onZodError: captureException
    })
}

export type HttpGetExperienceVettingVersionsOptions = {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
}

export const HttpGetExperienceVettingVersionsResponse = z.object({
    id: z.number(),
    status: ExperienceStatus,
    createdAt: z.string()
})
export type HttpGetExperienceVettingVersionsResponse = z.infer<typeof HttpGetExperienceVettingVersionsResponse>

export const httpGetExperienceVettingVersions = async ({
    urlParams,
    params
}: HttpGetExperienceVettingVersionsOptions) => {
    const response = await axios.get(`/v1/experience/vetting/submissions/${urlParams.experienceId}/versions`, {params})
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpGetExperienceVettingVersionsResponse.array(),
        onZodError: captureException
    })
}

export type HttpUpdateExperienceVettingGalleryImagesPositionsOptions = {
    urlParams: {experienceId: number; galleryId: number}
    payload: {id: number; position: number}[]
    params: {responseType: ResponseType}
}

export const httpUpdateExperienceVettingGalleryImagesPositions = ({
    urlParams,
    payload,
    params
}: HttpUpdateExperienceVettingGalleryImagesPositionsOptions) =>
    axios.patch(
        `/v1/experience/vetting/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/update-positions`,
        payload,
        {params}
    )
