import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {Experience, ExperienceFlag} from '@/features/experience-shell/types.ts'
import {
    HttpEditExperienceItemFlags,
    httpRemoveExperienceItemFlags
} from '@/features/experience-vetting/http/experienceVetting.http.ts'

export const useRemoveExperienceItineraryAnswerFlags = (
    urlParams: Omit<HttpEditExperienceItemFlags['urlParams'], 'section' | 'item' | 'itemId'>,
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ExperienceFlag[]; itemId: number}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpRemoveExperienceItemFlags({
                urlParams: {...urlParams, section: 'itineraries', item: 'answers', itemId: payload.itemId},
                payload: {flagIds: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        itineraries: previousData.itineraries.map(offering => {
                            if (offering.id == urlParams.sectionId) {
                                return {
                                    ...offering,
                                    answers: offering.answers.map(question => {
                                        if (question.id == variables.itemId) {
                                            return {
                                                ...question,
                                                flags: question.flags.filter(
                                                    flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                                )
                                            }
                                        }
                                        return question
                                    })
                                }
                            }
                            return offering
                        })
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
