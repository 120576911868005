import {useTranslation} from 'react-i18next'
import {FC, RefObject, useState} from 'react'
import {Dialog, DialogFooter, DialogDismiss, DialogProvider} from '@components/ui/dialog-atoms/DialogAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Radio} from '@/components/commons/radio/Radio'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledSubmissionDialogBody, StyledSubmissionDialogHeader} from './style'

interface ExperienceVettingMerchSubmitModalProps {
    isSubmitting: boolean
    type: 'ai' | 'host-edits'
    onClose: () => void
    submissionRef: RefObject<HTMLButtonElement>
    setRequireHostApprovalCb: (value: boolean) => void
    isOpen: boolean
}

export const ExperienceVettingMerchSubmitModal: FC<ExperienceVettingMerchSubmitModalProps> = ({
    isSubmitting,
    onClose,
    submissionRef,
    type,
    setRequireHostApprovalCb,
    isOpen
}) => {
    const {t} = useTranslation()
    const [hasSelectedOption, setHasSelectedOption] = useState(false)

    const handleRadioChange = (value: boolean) => {
        setHasSelectedOption(true)
        setRequireHostApprovalCb(value)
    }

    const handleSubmit = () => {
        submissionRef.current?.click()
    }

    switch (type) {
        case 'ai':
            return (
                <DialogProvider>
                    <Dialog
                        open={isOpen}
                        onClose={onClose}
                        style={{width: '480px'}}
                        hideOnInteractOutside={!isSubmitting}
                    >
                        <StyledSubmissionDialogHeader>
                            <DialogDismiss disabled={isSubmitting} />
                            <p>{t('vetting:merch:commons:submission_modal_header')}</p>
                        </StyledSubmissionDialogHeader>
                        <StyledSubmissionDialogBody>
                            <h3>{t('vetting:merch:service:confirmation_ai_modal:title')}</h3>
                            <p>{t('vetting:merch:service:confirmation_ai_modal:description')}</p>
                        </StyledSubmissionDialogBody>
                        <DialogFooter>
                            <Button variant={'tertiary'} disabled={isSubmitting} onClick={onClose}>
                                {t('commons:cancel')}
                            </Button>

                            <Button variant={'primary'} onClick={handleSubmit} disabled={isSubmitting}>
                                {t('commons:confirm')}
                                {isSubmitting && <Spinner size={20} />}
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </DialogProvider>
            )

        case 'host-edits':
            return (
                <DialogProvider>
                    <Dialog open={isOpen} onClose={onClose} style={{width: '480px'}}>
                        <StyledSubmissionDialogHeader>
                            <DialogDismiss />
                            <p>{t('vetting:merch:commons:submission_modal_header')}</p>
                        </StyledSubmissionDialogHeader>

                        <StyledSubmissionDialogBody>
                            <Flexbox direction="column" gap={4}>
                                <h3>{t('vetting:merch:service:confirmation_edits_modal:title')}</h3>
                                <Radio
                                    name="require-host-approval"
                                    onChange={() => handleRadioChange(true)}
                                    label={
                                        <Flexbox direction="column" gap={1}>
                                            <p>
                                                {t(
                                                    'vetting:merch:service:confirmation_edits_modal:radio_label_require_host_approval'
                                                )}
                                            </p>
                                            <small>
                                                {t(
                                                    'vetting:merch:service:confirmation_edits_modal:radio_label_require_host_approval_description'
                                                )}
                                            </small>
                                        </Flexbox>
                                    }
                                />
                                <Radio
                                    name="require-host-approval"
                                    onChange={() => handleRadioChange(false)}
                                    label={
                                        <Flexbox direction="column" gap={1}>
                                            <p>
                                                {t(
                                                    'vetting:merch:service:confirmation_edits_modal:radio_label_no_edits'
                                                )}
                                            </p>
                                            <small>
                                                {t(
                                                    'vetting:merch:service:confirmation_edits_modal:radio_label_no_edits_description'
                                                )}
                                            </small>
                                        </Flexbox>
                                    }
                                />
                            </Flexbox>
                        </StyledSubmissionDialogBody>

                        <DialogFooter>
                            <Button variant={'tertiary'} disabled={isSubmitting} onClick={onClose}>
                                {t('commons:cancel')}
                            </Button>

                            <Button
                                variant={'primary'}
                                onClick={handleSubmit}
                                disabled={isSubmitting || !hasSelectedOption}
                            >
                                {t('commons:confirm')}
                                {isSubmitting && <Spinner size={20} />}
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </DialogProvider>
            )
    }
}
