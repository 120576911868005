import {Dialog} from '@/components/ui/dialog-atoms/DialogAtoms'
import {styled, css} from 'styled-components'

export const StyledDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
    `
)

export const StyledDialog = styled(Dialog)(
    ({theme: {typography}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;
        & h2 {
            ${typography.displayXs};
            font-weight: 500;
        }
    `
)

export const StyledStep = styled.div(
    ({theme: {spacing, palette}}) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing * 4}px ${spacing * 2}px;
        &:not(:last-child) {
            border-bottom: 1px solid ${palette.neutral[300]};
        }
        & h3 {
            font-weight: 500;
            text-decoration: underline;
        }
    `
)
