import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {
    httpUpdateCategorySubCategory,
    HttpUpdateCategorySubCategoryOptions
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience} from '@/features/experience-shell/types.ts'
import {errorHandler} from '@/utilities/helpers'

export const useUpdateCategorySubCategory = ({
    experienceId,
    options
}: {
    experienceId: number
    options?: Omit<
        UseMutationOptions<Experience, unknown, HttpUpdateCategorySubCategoryOptions['payload']>,
        'mutationFn'
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: payload =>
            httpUpdateCategorySubCategory({urlParams: {experienceId}, payload, params: {responseType: 'extended'}}),
        ...options,
        onSuccess: (data, variables, context) => {
            queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]})
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        },
        onError: (error, variables, context) => {
            options?.onError?.(error, variables, context)
            errorHandler(error)
        }
    })
}
