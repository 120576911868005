import {ComboboxProvider, Select, SelectProvider} from '@ariakit/react'
import {SelectComboboxList} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {useTranslation} from 'react-i18next'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {AlertTriangleIcon, PlusIcon} from '@components/ui/icon/Icon.tsx'
import {
    OpsVettingFlagsSelectItem,
    OpsVettingFlagsSelectSearchInput,
    OpsVettingFlagsSelectStyledPopover
} from '@/features/ops-vetting/components/ops-vetting-flags-select-atoms/OpsVettingFlagsSelectAtoms.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {FC, useMemo, useState} from 'react'
import {useGetExperiencesFlags} from '@/features/experience-vetting/http/useGetExperiencesFlags.ts'
import {ExperienceFlag, ExperienceFlagWithLabel} from '@/features/experience-shell/types.ts'
import {experiencesFlagsMapToLabel} from '../../utils'

export interface ExperienceVettingCriteriaFlagsSelectProps {
    addFlag: (Flag: ExperienceFlag) => void
    removeFlag: (Flag: ExperienceFlag) => void
    selectedFlagsId: number[]
    disabled?: boolean
    scopes?: ExperienceFlag['scope'][]
}

export const ExperienceVettingCriteriaFlagsSelect: FC<ExperienceVettingCriteriaFlagsSelectProps> = ({
    addFlag,
    removeFlag,
    selectedFlagsId,
    disabled,
    scopes
}) => {
    const {t} = useTranslation()
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const flagsQuery = useGetExperiencesFlags({scopes}, {enabled: isOpen})

    const searchFlagByLabel = (flags: ExperienceFlagWithLabel[], inputSearch: string) =>
        flags.filter(flag => flag.label.toLowerCase().includes(inputSearch.toLowerCase()))

    const flagsList = useMemo(() => {
        const remappedFlags = flagsQuery.data
            ? flagsQuery.data?.map(data => ({
                  ...data,
                  label: t(experiencesFlagsMapToLabel[data.keyName])
              }))
            : []
        return searchFlagByLabel(remappedFlags, search)
    }, [flagsQuery])

    const handleUpdateFlag = (checked: boolean, flag: ExperienceFlag) => {
        checked ? addFlag(flag) : removeFlag(flag)
    }

    return (
        <ComboboxProvider resetValueOnHide value={search} setValue={search => setSearch(search)} setOpen={setIsOpen}>
            <SelectProvider>
                <Select disabled={disabled}>
                    <Chip startSlot={<PlusIcon />} label={'Flags'} isClickable />
                </Select>
                <OpsVettingFlagsSelectStyledPopover unmountOnHide>
                    <OpsVettingFlagsSelectSearchInput placeholder={t('photoset:search_flags')} />
                    <Divider direction="horizontal" />
                    <SelectComboboxList>
                        {flagsQuery.isLoading ? (
                            <Flexbox direction="column" gap={1}>
                                {Array.from({length: 4}, (_, index) => (
                                    <OpsVettingFlagsSelectItem key={index}>
                                        <Skeleton height={20} width={20} />
                                        <Skeleton height={20} />
                                    </OpsVettingFlagsSelectItem>
                                ))}
                            </Flexbox>
                        ) : flagsQuery.isError ? (
                            <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                        ) : flagsList.length == 0 ? (
                            <OpsVettingFlagsSelectItem>
                                <InputHelpText helpText={t('commons:no_results')} />
                            </OpsVettingFlagsSelectItem>
                        ) : (
                            flagsList.map(option => (
                                <OpsVettingFlagsSelectItem key={option.id}>
                                    <Checkbox
                                        id={option.id.toString()}
                                        checked={selectedFlagsId.includes(option.id)}
                                        onChange={event => handleUpdateFlag(event.target.checked, option)}
                                    />
                                    <p>{option.label}</p>
                                </OpsVettingFlagsSelectItem>
                            ))
                        )}
                    </SelectComboboxList>
                </OpsVettingFlagsSelectStyledPopover>
            </SelectProvider>
        </ComboboxProvider>
    )
}
