import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {ServiceGalleryImage} from '@/features/service-vetting/types.ts'
import {
    HttpUpdateIsApprovedGalleryImg,
    httpUpdateIsApprovedGalleryImg
} from '@/features/service-vetting/http/servicesVetting.http.ts'

export const useUpdateIsApprovedGalleryImg = (
    urlParams: HttpUpdateIsApprovedGalleryImg['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpUpdateIsApprovedGalleryImg['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateIsApprovedGalleryImg({
                urlParams,
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<ServiceGalleryImage[]>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<ServiceGalleryImage[]>(
                    {queryKey},
                    previousData.map(image => {
                        if (image.id == urlParams.imgId) {
                            return {...image, is_approved: variables.is_approved}
                        }
                        return image
                    })
                )
            }
            return previousData
        }
    })
}
