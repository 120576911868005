import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {NavLink, generatePath} from 'react-router-dom'
import {ReactNode} from 'react'
import {StyledOpsVettingHeader, StyledOpsVettingTabs} from './style'

export const OpsVettingHeader = ({
    endSlot,
    vettingId,
    title,
    steps
}: {
    endSlot: ReactNode
    vettingId: number
    title: string
    steps: {label: string; path: string}[]
}) => {
    return (
        <StyledOpsVettingHeader gap={2} direction="column">
            <Flexbox fullWidth gap={2} justify="space-between" direction="row" align="center">
                <h1>{title}</h1>
                <span>{endSlot}</span>
            </Flexbox>
            <StyledOpsVettingTabs fullWidth gap={2}>
                {steps.map(step => (
                    <NavLink key={step.label} to={generatePath(step.path, {id: vettingId})}>
                        {step.label}
                    </NavLink>
                ))}
            </StyledOpsVettingTabs>
        </StyledOpsVettingHeader>
    )
}
