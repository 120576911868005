import {CircularProgress} from '@/components/commons/circular-progress/CicularProgress'
import {Lock03Icon, CheckCircleIcon, XCircleIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {Trans} from '@/translations/i18n'
import {FC} from 'react'
import {ObjectKeys} from '@/utilities/helpers'
import {ShellStepKeyName} from '../../types'
import {ShellPartialFormSchema} from '../../formSchemas'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {useChangeStep} from '../../hooks/useChangeStep'
import {StyledTooltipLink} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'

// Helper to check if a value is empty
const isEmpty = (value: unknown): boolean => {
    if (value === undefined || value === null || value === '') return true
    if (Array.isArray(value)) {
        // Check if array is empty or if all its objects have empty values
        return value.length === 0 || value.every(item => isEmpty(item))
    }
    if (typeof value === 'object') {
        // For objects, check if all values are empty
        return Object.values(value).every(v => isEmpty(v))
    }
    // For numbers or other primitives, they are not empty
    if (typeof value === 'number') return false
    return false
}

// Helper to check if all values in an object are empty
const isSomeEmpty = (obj: unknown): boolean => {
    if (!obj || typeof obj !== 'object') return true

    // For objects, check each value
    const values = Object.entries(obj)
    return values.some(([key, value]) => {
        // Skip checking specific fields that should not be considered for emptiness
        if (key === 'expertise_years' || key === 'residential_country_region') return false
        return isEmpty(value)
    })
}

export const Progress: FC<{
    experienceId: number
    isItineraryLocked: boolean
    isLegalLocked: boolean
    step: ShellStepKeyName
    galleryImagesCount: number
}> = ({experienceId, isItineraryLocked, isLegalLocked, step, galleryImagesCount}) => {
    const theme = useTheme()
    const {watch} = useExperienceShellFormContext()
    const changeStep = useChangeStep({experienceId, currentStep: step})

    if (step == ShellStepKeyName.enum.photos) {
        return galleryImagesCount >= 1 ? (
            <CheckCircleIcon size={18} fill={theme.palette.success[500]} />
        ) : (
            <CircularProgress
                progress={0}
                size={18}
                background={theme.palette.primary['300']}
                strokeColor={theme.palette.blue['500']}
            />
        )
    }

    if (isItineraryLocked) {
        return (
            <Tooltip
                contentProps={{align: 'start'}}
                content={
                    <p>
                        <Trans
                            i18nKey="experience_shell:itinerary_lock_text"
                            components={{
                                button: (
                                    <StyledTooltipLink
                                        disabled={changeStep.editExperienceMutation.isPending}
                                        onClick={() =>
                                            changeStep.handleChangeStep({
                                                stepToGoTo: ShellStepKeyName.enum.merchandising,
                                                isBack: true
                                            })
                                        }
                                    />
                                ),
                                spinner: changeStep.isBackLoading ? <Spinner size={14} /> : <></>
                            }}
                        />
                    </p>
                }
            >
                <Lock03Icon />
            </Tooltip>
        )
    }
    if (isLegalLocked) {
        return (
            <Tooltip
                contentProps={{align: 'start'}}
                content={
                    <p>
                        <Trans
                            i18nKey="experience_shell:legal_lock_text"
                            components={{
                                button: (
                                    <StyledTooltipLink
                                        disabled={changeStep.editExperienceMutation.isPending}
                                        onClick={() =>
                                            changeStep.handleChangeStep({
                                                stepToGoTo: ShellStepKeyName.enum.experience,
                                                isBack: true
                                            })
                                        }
                                    />
                                ),
                                spinner: !changeStep.isBackLoading ? <Spinner size={14} /> : <></>
                            }}
                        />
                    </p>
                }
            >
                <Lock03Icon />
            </Tooltip>
        )
    }

    const stepValues = watch(step)
    const parsedValues = ShellPartialFormSchema.shape[step].safeParse(stepValues)
    const errorsCount = parsedValues.success ? 0 : ObjectKeys(parsedValues.error.format()).length

    if (errorsCount > 0) {
        return <XCircleIcon size={18} fill={theme.palette.danger[500]} />
    }

    if (isSomeEmpty(stepValues)) {
        return (
            <CircularProgress
                progress={0}
                size={18}
                background={theme.palette.primary['300']}
                strokeColor={theme.palette.blue['500']}
            />
        )
    }

    return <CheckCircleIcon size={18} fill={theme.palette.success[500]} />
}
