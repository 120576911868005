import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {ServiceVettingCriteriaHero} from '@/features/service-vetting/components/service-vetting-criteria-hero/ServiceVettingCriteriaHero.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ServiceVettingCriteriaHostExpertise} from '@/features/service-vetting/components/service-vetting-criteria-host-expertise/ServiceVettingCriteriaHostExpertise.tsx'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types.ts'
import {useGetService} from '@/features/service-vetting/query/useGetService.ts'
import {AlertTriangleIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ServiceVettingCriteriaOfferings} from '@/features/service-vetting/components/service-vetting-criteria-offerings/ServiceVettingCriteriaOfferings.tsx'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {useAuthStore} from '@/features/auth/store/store'
import {ServiceVettingCriteriaGalleryContainer} from '@/features/service-vetting/components/service-vetting-criteria-gallery-container/ServiceVettingCriteriaGalleryContainer'
import {ServiceVettingCriteriaSkeleton} from '@/features/service-vetting/components/service-vetting-criteria-skeleton/ServiceVettingCriteriaSkeleton'

export const ServiceCriteriaVetting = () => {
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {t} = useTranslation()
    const serviceQuery = useGetService(urlParams.id)

    const showBanner = useServiceVettingError(state => state.showBanner)
    const user = useAuthStore(state => state.user)

    const hideAction =
        serviceQuery.remappedData?.status != 'under_review' ||
        user?.role == 'read_only' ||
        serviceQuery.remappedData?.is_host_modified

    const isExpertiseDeclined = serviceQuery.remappedData?.expertise.status == 'declined'
    const isProPhotographyRequired = !!serviceQuery.remappedData?.is_pro_photography_required
    const lackPhotoVariety = !!serviceQuery.remappedData?.gallery.flags.some(
        flag => flag.key_name == 'lack_photo_variety'
    )
    if (serviceQuery.isPending) {
        return <ServiceVettingCriteriaSkeleton />
    }

    if (serviceQuery.isError) {
        return (
            <Container fullWidth>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            {serviceQuery.remappedData && (
                <>
                    <Container fullWidth>
                        <ServiceVettingCriteriaHero service={serviceQuery.remappedData} />
                    </Container>
                    {showBanner && (
                        <SubHeaderBanner
                            title={t('vetting:criteria:commons:error_banner:title')}
                            subtitle={t('vetting:criteria:commons:error_banner:subtitle')}
                            type={'danger'}
                        />
                    )}
                    <Container fullWidth>
                        <Flexbox direction="column" gap={6} fullWidth>
                            <ServiceVettingCriteriaHostExpertise service={serviceQuery.remappedData} />
                            <ServiceVettingCriteriaOfferings service={serviceQuery.remappedData} />
                            <ServiceVettingCriteriaGalleryContainer
                                serviceId={urlParams.id}
                                galleryId={serviceQuery.remappedData.gallery.id}
                                hideAction={hideAction}
                                isExpertiseDeclined={isExpertiseDeclined}
                                isProPhotographyRequired={isProPhotographyRequired}
                                lackPhotoVariety={lackPhotoVariety}
                            />
                        </Flexbox>
                    </Container>
                </>
            )}
        </ScrollArea>
    )
}
