import {TKey} from '@/translations/i18n'
import {create} from 'zustand'

type Errors = {
    expertiseErrors: TKey[]
    itemErrors: {itemId: number; error: TKey}[]
    galleryErrors: TKey[]
    rejectedImagesErrors: TKey[]
}

export type AdminServiceVettingError = {
    errors: Errors
    isTouched: boolean
    setIsTouched: (isTouched: boolean) => void
    setErrors: (errors: Errors) => void
    reset: () => void
    showBanner: boolean
    setShowBanner: (showBanner: boolean) => void
}

const createVettingError = () =>
    create<AdminServiceVettingError>(set => ({
        errors: {expertiseErrors: [], itemErrors: [], galleryErrors: [], rejectedImagesErrors: []},
        isTouched: false,
        setIsTouched: (isTouched: boolean) => set({isTouched}),
        showBanner: false,
        setErrors: errors => {
            set({errors})
        },
        reset: () =>
            set({
                isTouched: false,
                errors: {expertiseErrors: [], itemErrors: [], galleryErrors: [], rejectedImagesErrors: []}
            }),
        setShowBanner: showBanner => set({showBanner})
    }))

export const useServiceVettingError = createVettingError()
export const useExperienceVettingError = createVettingError()
