import {z} from 'zod'
import {Experience, ExperienceGalleryImage, Itinerary, StepKeyName} from '../types'
import axios from '@/axiosInstanceNode'
import {ResponseType} from '@/types'
import {captureException} from '@sentry/react'
import {EditExperiencePayload} from '../formSchemas'
import {parseResponseData} from '@utilities/helpers.ts'
import {Media} from '@/features/service-vetting/types.ts'

/* Get Experience */
interface httpGetExperienceOptions {
    urlParams: {
        experienceId: number
    }
    params: {responseType: ResponseType}
}
export const httpGetExperience = async ({urlParams, params}: httpGetExperienceOptions) => {
    const response = await axios.get(`v1/experience/shell/submissions/${urlParams.experienceId}`, {params})

    return parseResponseData({responseData: response.data, responseShape: Experience, onZodError: captureException})
}

/* Edit Step */
export interface HttpEditStepOptions {
    urlParams: {
        experienceId: number
        stepKeyName: Exclude<StepKeyName, 'pricing_discounts'> | 'pricing-discounts'
    }
    params: {responseType: ResponseType}
}
export const httpEditStep = async ({urlParams, params}: HttpEditStepOptions) => {
    const response = await axios.patch(
        `v1/experience/shell/submissions/${urlParams.experienceId}/update-step-to/${urlParams.stepKeyName}`,
        undefined,
        {params}
    )
    return parseResponseData({responseData: response.data, responseShape: Experience, onZodError: captureException})
}

export interface httpEditExperienceOptions {
    urlParams: {
        experienceId: number
    }
    payload: EditExperiencePayload
    params: {responseType: ResponseType}
}
export const httpEditExperience = async ({urlParams, payload, params}: httpEditExperienceOptions) => {
    const response = await axios.post(
        `v1/experience/shell/submissions/${urlParams.experienceId}/partial-submit`,
        payload,
        {params}
    )
    return parseResponseData({responseData: response.data, responseShape: Experience, onZodError: captureException})
}

export interface httpStoreExperienceSubmissionOptions {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
}
export const httpStoreExperienceSubmission = async ({params, urlParams}: httpStoreExperienceSubmissionOptions) => {
    const response = await axios.post(
        `v1/experience/shell/submissions/${urlParams.experienceId}/submit-to-host`,
        undefined,
        {params}
    )
    return parseResponseData({responseData: response.data, responseShape: Experience, onZodError: captureException})
}

/* Get Countries */
const HttpGetCountriesResponse = z.object({
    data: z.array(z.object({code: z.string(), name: z.string()}))
})
export const httpGetCountries = async ({params}: {params: {responseType?: ResponseType; name?: string}}) => {
    const response = await axios.get(`v1/countries`, {params: {...params, limit: 300}})
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpGetCountriesResponse,
        onZodError: captureException
    })
}

export interface HttpStorePresignBulkExperienceShellPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    payload: {medias: string[]}
}

const BulkPresignedExperienceShellPhotosResponse = z.array(z.object({media: Media, presignedUrl: z.string().url()}))
export type BulkPresignedExperienceShellPhotosResponse = z.infer<typeof BulkPresignedExperienceShellPhotosResponse>

export const httpStorePresignBulkExperienceShellPhotos = async ({
    payload,
    urlParams
}: HttpStorePresignBulkExperienceShellPhotosOptions) => {
    const response = await axios.post(
        `v1/experience/shell/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/upload`,
        payload
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: BulkPresignedExperienceShellPhotosResponse,
        onZodError: captureException
    })
}

/*Confirm upload photo*/
interface HttpConfirmUploadPhotosOptions {
    mediaId: number
    experienceId: number
    galleryId: number
}
export const httpExperienceShellConfirmUploadPhoto = async (urlParams: HttpConfirmUploadPhotosOptions) => {
    const response = await axios.patch(
        `v1/experience/shell/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.mediaId}/confirm`
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceGalleryImage.omit({flags: true}),
        onZodError: captureException
    })
}

/*Delete photo*/
export interface HttpDeleteBulkExperienceShellPhotoOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {imageIds: number[]}
}
export const httpDeleteBulkExperienceShellPhoto = ({urlParams, params}: HttpDeleteBulkExperienceShellPhotoOptions) =>
    axios.delete(
        `v1/experience/shell/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/delete`,
        {params}
    )

/*Set photo cover*/
export interface HttpEditExperienceShellPhotoCoverOptions {
    urlParams: {experienceId: number; galleryId: number; imagesId: number}
    payload: {isCover: boolean}
}
export const httpEditExperienceShellPhotoCover = ({urlParams, payload}: HttpEditExperienceShellPhotoCoverOptions) =>
    axios.patch(
        `v1/experience/shell/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.imagesId}/cover`,
        payload
    )

export type HttpGetExperienceShellGalleryImagesOptions = {
    urlParams: {experienceId: number; galleryId: number}
    params: {response_type: ResponseType}
}

export const HttpGetExperienceShellGalleryImagesResponse = z.array(ExperienceGalleryImage.omit({flags: true}))
export type HttpGetExperienceShellGalleryImagesResponse = z.infer<typeof HttpGetExperienceShellGalleryImagesResponse>

export const httpGetExperienceShellGalleryImages = async ({
    urlParams,
    params
}: HttpGetExperienceShellGalleryImagesOptions) => {
    const response = await axios.get(
        `/v1/experience/shell/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images`,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpGetExperienceShellGalleryImagesResponse,
        onZodError: captureException
    })
}

export type HttpEditExperienceShellItineraryOptions = {
    urlParams: {experienceId: number; itineraryId: number}
    payload: Partial<Omit<Itinerary, 'id'>>
    params: {responseType: ResponseType}
}

export const HttpEditExperienceShellItineraryResponse = Experience
export type HttpEditExperienceShellItineraryResponse = z.infer<typeof HttpEditExperienceShellItineraryResponse>

export const httpEditExperienceShellItinerary = async ({
    urlParams,
    params,
    payload
}: HttpEditExperienceShellItineraryOptions) => {
    const response = await axios.patch(
        `/v1/experience/shell/submissions/${urlParams.experienceId}/itineraries/${urlParams.itineraryId}`,
        payload,
        {params}
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpEditExperienceShellItineraryResponse,
        onZodError: captureException
    })
}
