import {StyledCharCounter, StyledCharCounterWrapper} from './style'
import {useFormContext, Path, FieldValues} from 'react-hook-form'

interface CharCounterProps<T extends FieldValues> {
    name: Path<T>
    maxLength: number
    isDisabled?: boolean
}

export const CharCounter = <T extends FieldValues>({name, maxLength, isDisabled = false}: CharCounterProps<T>) => {
    const {watch} = useFormContext<T>()
    const value = watch(name) || ''
    const currentLength = value.length
    const isExceeded = currentLength > maxLength

    return (
        <StyledCharCounterWrapper>
            <StyledCharCounter $isExceeded={isExceeded} $isDisabled={isDisabled}>
                {currentLength}/{maxLength}
            </StyledCharCounter>
        </StyledCharCounterWrapper>
    )
}
