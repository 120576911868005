import {useTranslation} from '@/translations/i18n'
import {useGetExperienceVettingVersions} from '../../queries/useGetExperienceVettingVersions'
import dayjs from 'dayjs'
import {SelectItem, SelectList, SelectPopover} from '@/components/ui/select-atoms/SelectAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {SelectProvider} from '@ariakit/react'
import {routes} from '@/utilities/constants/routes'
import {generatePath, useNavigate} from 'react-router-dom'
import {StyledSelectTrigger} from '@/features/service-vetting/components/service-vetting-criteria-version-select/style'
import {experienceStatusToLabel} from '../../utils'
import {Chip} from '@/components/commons/chip/Chip'
import {ExperienceStatus} from '@/features/experience-shell/types'

export const ExpVettingVersionsSelect = ({experienceId}: {experienceId: number}) => {
    const {t} = useTranslation()
    const {data, isLoading} = useGetExperienceVettingVersions({experienceId})
    const navigate = useNavigate()

    const experienceVersionStatusToBadge = (status: ExperienceStatus) => {
        const label = t(experienceStatusToLabel[status])
        switch (status) {
            case 'approved':
                return <Chip label={label} variant={'success'} isActive shape={'circle'} />
            case 'publishing_enabled':
                return <Chip label={label} variant={'success'} isActive shape={'circle'} />
            case 'pending_publishing':
                return <Chip label={label} variant={'success'} isActive shape={'circle'} />
            case 'pending_export':
                return <Chip label={label} variant={'success'} isActive shape={'circle'} />
            case 'declined':
                return <Chip label={label} variant={'danger'} isActive shape={'circle'} />
            case 'update_required':
                return <Chip label={label} variant={'warning'} isActive shape={'circle'} />
            case 'approval_required':
                return <Chip label={label} variant={'warning'} isActive shape={'circle'} />
            case 'under_review':
                return <Chip label={label} variant={'info'} isActive shape={'circle'} />
            default:
                return <Chip label={label} variant={'neutral'} isActive shape={'circle'} />
        }
    }

    return (
        <SelectProvider
            defaultValue={experienceId.toString()}
            setValue={value => navigate(generatePath(routes.EXPERIENCE_VETTING_CRITERIA.path, {id: value}))}
        >
            {isLoading ? (
                <Flexbox gap={2} align={'center'}>
                    <Flexbox gap={2}>
                        <Skeleton height={20} width={100} />
                        <Skeleton height={20} width={100} />
                    </Flexbox>
                    <Skeleton height={14} width={14} />
                </Flexbox>
            ) : (
                <StyledSelectTrigger
                    variant={'ghost'}
                    displayValue={value => {
                        const version = data?.find(version => version.id.toString() == value)
                        const index = data?.findIndex(version => version.id.toString() == value)
                        return (
                            <Flexbox>
                                {version && index != undefined && (
                                    <Flexbox gap={2} align={'center'}>
                                        <h4>{t('vetting:criteria:service:submission_number', {value: index + 1})}</h4>
                                        {experienceVersionStatusToBadge(version.status)}
                                    </Flexbox>
                                )}
                            </Flexbox>
                        )
                    }}
                />
            )}
            <SelectPopover sameWidth={false} unmountOnHide>
                <SelectList>
                    {data &&
                        data.map((value, index) => (
                            <SelectItem value={value.id.toString()} key={value.id}>
                                <Flexbox gap={2} fullWidth justify={'space-between'}>
                                    <Flexbox gap={2}>
                                        <h4>{t('vetting:criteria:service:submission_number', {value: index + 1})}</h4>
                                        <p>{dayjs(value.createdAt).format('ll')}</p>
                                    </Flexbox>
                                    {experienceVersionStatusToBadge(value.status)}
                                </Flexbox>
                            </SelectItem>
                        ))}
                </SelectList>
            </SelectPopover>
        </SelectProvider>
    )
}
