import {useMemo} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {
    OpsVettingGalleryGrid,
    OpsVettingGalleryItemsContainer,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'

export const ServiceVettingCriteriaGallerySkeleton = () => {
    // Generate an array of items for the skeleton
    const skeletonItems = useMemo(() => Array.from({length: 7}), [])

    return (
        <OpsVettingSectionWrapper fullWidth direction="column" gap={6}>
            {/* Header section */}
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align="center">
                    {/* Title skeleton */}
                    <Skeleton width={180} height={24} />

                    {/* Upload button skeleton */}
                    <Skeleton width={120} height={36} />

                    {/* Checkbox skeletons */}
                    <Flexbox gap={2} align="center">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={150} height={20} />
                    </Flexbox>

                    <Flexbox gap={2} align="center">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={180} height={20} />
                    </Flexbox>
                </Flexbox>

                {/* Status section */}
                <Skeleton width={140} height={20} />
            </Flexbox>

            <Divider direction="horizontal" />

            {/* Gallery grid */}
            <OpsVettingGalleryGrid>
                {/* Cover photo skeleton (first item, slightly different) */}
                <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                    <Skeleton width="100%" height="100%" style={{borderRadius: '8px', aspectRatio: '1/1'}} />
                    <Flexbox gap={2} direction="column" fullWidth>
                        <Skeleton width="70%" height={18} />
                    </Flexbox>
                </OpsVettingGalleryItemsContainer>

                {/* Regular gallery items */}
                {skeletonItems.map((_, index) => (
                    <OpsVettingGalleryItemsContainer key={index} direction="column" gap={4}>
                        <Skeleton width="100%" height="100%" style={{borderRadius: '8px', aspectRatio: '1/1'}} />
                        <Flexbox gap={2} direction="column" fullWidth>
                            <Skeleton height={18} />
                            <Flexbox gap={2}>
                                <Skeleton height={38} />
                                <Skeleton height={38} />
                            </Flexbox>
                        </Flexbox>
                    </OpsVettingGalleryItemsContainer>
                ))}
            </OpsVettingGalleryGrid>
        </OpsVettingSectionWrapper>
    )
}
