import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateIsCoverGalleryImg,
    httpUpdateIsCoverGalleryImg
} from '@/features/service-vetting/http/servicesVetting.http.ts'

export const useUpdateIsCoverGalleryImg = (
    urlParams: Omit<HttpUpdateIsCoverGalleryImg['urlParams'], 'imgId'>,
    options?: UseMutationOptions<
        unknown,
        unknown,
        HttpUpdateIsCoverGalleryImg['payload'] & Pick<HttpUpdateIsCoverGalleryImg['urlParams'], 'imgId'>
    >
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateIsCoverGalleryImg({
                urlParams: {...urlParams, imgId: payload.imgId},
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
