import {useMutation, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpRestoreExperienceVettingCriteriaItineraryCoveryImage,
    HttpRestoreExperienceVettingCriteriaItineraryCoveryImageOptions
} from '@/features/experience-vetting/http/experienceVetting.http.ts'

export const useRestoreExperienceVettingCriteriaItineraryCoveryImage = ({
    experienceId,
    itineraryId
}: HttpRestoreExperienceVettingCriteriaItineraryCoveryImageOptions['urlParams']) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: () =>
            httpRestoreExperienceVettingCriteriaItineraryCoveryImage({
                urlParams: {experienceId, itineraryId},
                params: {
                    responseType: 'extended'
                }
            }),
        onError: error => {
            errorHandler(error)
        },
        onSuccess: data => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]}, data)
        }
    })
}
