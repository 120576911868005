import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {css, styled} from 'styled-components'

const SM_HEIGHT = 92
const MD_HEIGHT = 146
const LG_HEIGHT = 158

const CONSECUTIVE_SEPARATOR_HEIGHT = 16
const SUB_SECTION_SEPARATOR_HEIGHT = 52
const SECTION_SEPARATOR_HEIGHT = 112

export const StyledVettingMerchCopyDiffsWrapper = styled(Flexbox)`
    width: 2px;
    height: 100%;
    position: relative;
    overflow: visible;
`

export const StyledVettingMerchCopyDiffs = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 45}px 0 ${spacing * 12}px 0;
        background-color: ${palette.neutral[200]};
        width: 2px;
        height: 100%;
    `
)

export const StyledDiffSection = styled(Flexbox)<{$hasDiff: boolean; $size: 'sm' | 'md' | 'lg'}>(
    ({theme: {palette}, $hasDiff, $size}) => css`
        position: relative;
        width: 2px;
        height: ${$size === 'sm' ? SM_HEIGHT : $size === 'md' ? MD_HEIGHT : LG_HEIGHT}px;
        background-color: ${$hasDiff ? palette.warning[600] : palette.success[600]};
        overflow: visible;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    `
)

export const StyledDiffIndicator = styled(Flexbox)<{$size: number; $hasDiff: boolean}>(
    ({theme: {palette}, $size = 24, $hasDiff}) => css`
        color: ${palette.neutral.white};
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${$size}px;
        height: ${$size}px;
        border-radius: 50%;
        position: absolute;
        background-color: ${$hasDiff ? palette.warning[600] : palette.success[600]};
    `
)

export const StyledFieldsDiffsSeparator = styled(Flexbox)<{$fieldType: 'consecutive' | 'sub-section' | 'section'}>(
    ({$fieldType = 'consecutive'}) => css`
        height: ${$fieldType === 'consecutive'
            ? CONSECUTIVE_SEPARATOR_HEIGHT
            : $fieldType === 'sub-section'
              ? SUB_SECTION_SEPARATOR_HEIGHT
              : SECTION_SEPARATOR_HEIGHT}px;
    `
)

export const StyledFieldsDiffsSeparatorSegment = styled(Flexbox)<{$hasDiff: boolean}>(
    ({theme: {palette}, $hasDiff}) => css`
        width: 2px;
        height: 100%;
        background-color: ${$hasDiff ? palette.warning[600] : palette.success[600]};
    `
)
