import {Chip, ChipProps} from '@components/commons/chip/Chip.tsx'
import {FC} from 'react'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {OpsVettingFlagsContainer} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {
    ExperienceVettingCriteriaFlagsSelect,
    ExperienceVettingCriteriaFlagsSelectProps
} from '@/features/experience-vetting/components/experience-vetting-criteria-flags-select/ExperienceVettingCriteriaFlagsSelect.tsx'
import {ExperienceFlag} from '@/features/experience-shell/types.ts'
import {useTranslation} from '@/translations/i18n'
import {experiencesFlagsMapToLabel} from '../../utils'

interface ServiceVettingCriteriaFlagsProps extends Omit<ExperienceVettingCriteriaFlagsSelectProps, 'selectedFlagsId'> {
    flags: ExperienceFlag[]
    flagPosition?: 'left' | 'right'
    badgeVariant?: ChipProps['variant']
    hideAction?: boolean
}
export const ExperienceVettingCriteriaFlags: FC<ServiceVettingCriteriaFlagsProps> = ({
    flags,
    addFlag,
    removeFlag,
    badgeVariant = 'info',
    scopes,
    hideAction,
    flagPosition = 'right'
}) => {
    const {t} = useTranslation()
    return (
        <OpsVettingFlagsContainer $flagPosition={flagPosition} gap={2}>
            {!hideAction && (
                <ExperienceVettingCriteriaFlagsSelect
                    selectedFlagsId={flags.map(flag => flag.id)}
                    addFlag={addFlag}
                    removeFlag={removeFlag}
                    scopes={scopes}
                />
            )}
            {flags.map(flag => (
                <Chip
                    key={flag.id}
                    label={t(experiencesFlagsMapToLabel[flag.keyName])}
                    variant={badgeVariant}
                    isActive
                    endSlot={
                        !hideAction && (
                            <button tabIndex={-1} onClick={() => removeFlag(flag)}>
                                <XCloseIcon />
                            </button>
                        )
                    }
                />
            ))}
        </OpsVettingFlagsContainer>
    )
}
