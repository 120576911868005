import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetNeedManualRetouchingSpaces,
    HttpGetNeedManualRetouchingSpacesResponse,
    HttpGetPhotosetSpacesOptions
} from '@/features/photoset/http/photoset.http'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetNeedManualRetouchingSpaces = (
    promiseArgs: HttpGetPhotosetSpacesOptions,
    options?: Omit<UseQueryOptions<HttpGetNeedManualRetouchingSpacesResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.MANUAL_RETOUCHING_SPACES, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetNeedManualRetouchingSpaces(promiseArgs),
                responseShape: HttpGetNeedManualRetouchingSpacesResponse,
                onZodError: captureException
            })
    })
}
