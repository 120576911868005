import styled, {css} from 'styled-components'

export const StyledTooltipContent = styled.div(
    ({theme: {typography, spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        & h4 {
            font-weight: 500;
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral[300]};
        }
    `
)
