import {useTranslation} from '@/translations/i18n'
import {Content, Footer} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {Settings01Icon, ArrowRightIcon} from '@/components/ui/icon/Icon'
import {ExpShellMerchOutputForm} from '@/features/experience-vetting/components/exp-shell-merch-output-form/ExpShellMerchOutputForm'
import {AiPromptModal} from '@/components/commons/ai-prompt-modal/AiPromptModal'
import {ExpShellMerchInputForm} from '@/features/experience-vetting/components/exp-shell-merch-input-form/ExpShellMerchInputForm'
import {
    ExperienceMerchandisingInputSchema,
    ExperienceShellMerchOutputSchema
} from '@/features/experience-vetting/formSchemas'
import {useMemo, useRef, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {StepKeyName} from '../../types'
import {useChangeStep} from '../../hooks/useChangeStep'
import {EXPERIENCE_SHELL_PROMPT} from '@/features/ops-vetting/prompts/experienceShellPrompt'
import {StyledShellMerchandisingStepWrapper} from './style'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {toast} from 'react-hot-toast'

export const MerchandisingStep = ({experienceId}: {experienceId: number}) => {
    const {t} = useTranslation()
    const {getValues, setValue} = useExperienceShellFormContext()
    const {activities, ...rest} = useMemo(() => getValues('merchandising'), [getValues])
    const originalDefaultValues = {...rest}
    const {
        aiResponse,
        isLoading: isAiLoading,
        onMerchCopyAiCall,
        resetAiResponse
    } = useMerchCopyAi<ExperienceShellMerchOutputSchema>()
    const improvedDefaultValues = useMemo(() => (aiResponse ? aiResponse : {activities, ...rest}), [aiResponse])
    //prompt controls
    const [currentPrompt, setCurrentPrompt] = useState<string>(EXPERIENCE_SHELL_PROMPT)
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    //trigger ai-improvement submission
    const handleAiSubmission: SubmitHandler<ExperienceMerchandisingInputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }

    //change step & edit experience
    const {editExperienceMutation, isBackLoading, isContinueLoading, handleChangeStep} = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.merchandising
    })

    const handleSaveOriginalMerchCopy = (data: ExperienceMerchandisingInputSchema) => {
        const dataToSave = {...data}
        delete dataToSave.activities

        editExperienceMutation.mutate(dataToSave, {
            onSuccess: () => {
                setValue('merchandising', {...getValues('merchandising'), ...dataToSave})
                toast.success(t('experience_shell:merchandising_save_success'))
            }
        })
    }

    const handleSaveAiMerchCopy = (data: ExperienceShellMerchOutputSchema) => {
        editExperienceMutation.mutate(
            {
                ...data
            },
            {
                onSuccess: () => {
                    setValue('merchandising', {...getValues('merchandising'), ...data})
                    toast.success(t('experience_shell:merchandising_save_success'))
                }
            }
        )
    }

    return (
        <>
            <Content
                fullWidth
                title={t('experience_shell:step_merchandising')}
                titleEndSlot={
                    <AiPromptModal
                        trigger={
                            <Button
                                variant="tertiary"
                                size="sm"
                                disabled={isAiLoading || editExperienceMutation.isPending}
                            >
                                <Settings01Icon />
                                {t('vetting:merch:commons:customize_prompt')}
                            </Button>
                        }
                        currentPrompt={currentPrompt}
                        onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                        isLoading={isAiLoading}
                        promptType="exp_shell"
                    />
                }
            >
                <StyledShellMerchandisingStepWrapper fullWidth direction={'row'} gap={2}>
                    <ExpShellMerchInputForm
                        isReadOnly={false}
                        defaultValues={originalDefaultValues}
                        aiSubmissionRef={aiSubmissionRef}
                        handleAiSubmissionCb={handleAiSubmission}
                        handleSaveCb={handleSaveOriginalMerchCopy}
                        aiResponse={aiResponse}
                    />
                    <ExpShellMerchOutputForm
                        isReadOnly={false}
                        isHostEdits={false}
                        isLoading={isAiLoading}
                        defaultValues={improvedDefaultValues as ExperienceShellMerchOutputSchema}
                        finalSubmitRef={finalSubmitRef}
                        handleSaveCb={handleSaveAiMerchCopy}
                        handleFinalSubmissionCb={() => console.log('finalSubmissionCb')}
                    />
                </StyledShellMerchandisingStepWrapper>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleChangeStep({isBack: true, stepToGoTo: StepKeyName.enum.background})}
                    disabled={isBackLoading || isContinueLoading}
                >
                    {t('commons:back')}
                    {isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    onClick={() => handleChangeStep({stepToGoTo: StepKeyName.enum.location})}
                    disabled={isContinueLoading || isBackLoading}
                >
                    {t('commons:continue')}
                    {isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
