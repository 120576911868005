import {ModalFooter, ModalHeader} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC, useState} from 'react'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {CheckIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {OpsVettingImage} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {
    StyledCheckIconContainer,
    StyledModal,
    StyledPhotoGrid,
    StyledPhotoWrapper
} from '@/features/service-vetting/components/service-vetting-criteria-choose-photo-modal/style.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useGetGalleryImages} from '@/features/service-vetting/query/useGetGalleryImages.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

interface ServiceVettingCriteriaChoosePhotoModalProps {
    title: string
    onClose: () => void
    onConfirm: (imageId: number) => void
    isPending?: boolean
    currentSelectedImageId?: number
    serviceId: number
    galleryId: number
}
export const ServiceVettingCriteriaChoosePhotoModal: FC<ServiceVettingCriteriaChoosePhotoModalProps> = ({
    title,
    onClose,
    onConfirm,
    isPending,
    currentSelectedImageId,
    serviceId,
    galleryId
}) => {
    const {t} = useTranslation()
    const imagesQuery = useGetGalleryImages({serviceId, galleryId})
    const [selectedImgId, setSelectedImgId] = useState<number | null>(currentSelectedImageId || null)
    return (
        <StyledModal width={'680'}>
            <ModalHeader>
                <Button iconOnly variant={'ghost'} size={'sm'} onClick={onClose}>
                    <XCloseIcon />
                </Button>
                {title}
            </ModalHeader>
            <Divider direction={'horizontal'} />
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledPhotoGrid>
                    {imagesQuery.isPending
                        ? Array.from({length: 9}).map((_, index) => <Skeleton key={index} />)
                        : imagesQuery.data?.map(image => (
                              <StyledPhotoWrapper
                                  key={image.id}
                                  onClick={() => setSelectedImgId(image.id)}
                                  role={'button'}
                              >
                                  <OpsVettingImage src={image.url} />
                                  {selectedImgId == image.id && (
                                      <StyledCheckIconContainer>
                                          <CheckIcon />
                                      </StyledCheckIconContainer>
                                  )}
                              </StyledPhotoWrapper>
                          ))}
                </StyledPhotoGrid>
            </ScrollArea>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose} disabled={isPending}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    disabled={(selectedImgId && selectedImgId == currentSelectedImageId) || isPending}
                    onClick={() => selectedImgId && onConfirm(selectedImgId)}
                >
                    {t('commons:confirm')} {isPending && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </StyledModal>
    )
}
