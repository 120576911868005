import {
    DialogBody,
    DialogDescription,
    DialogDisclosure,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider
} from '@/components/ui/dialog-atoms/DialogAtoms'
import {Button} from '@/components/ui/button/Button'
import {useTranslation} from '@/translations/i18n'
import {Fieldset} from '../atoms/Atoms'
import {StyledAccessibilityCheckbox, StyledDialog} from './style'
import {
    AccessibilityIcon,
    AccessibilityWideEntranceIcon,
    GlassesIcon,
    NoFlashIcon,
    NoStairsIcon,
    ParkingIcon,
    ToiletUprightIcon,
    VolunteerIcon,
    YogaIcon
} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {Divider} from '@/components/ui/divider/Divider'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {AccessibilityFeatures} from '../../types'
import {useEditExperience} from '../../queries/useEditExperience'
import {useDialogStore} from '@ariakit/react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useForm} from 'react-hook-form'
import {ShellPartialFormSchema} from '../../formSchemas'

export const AccessibilityFeaturesModal = ({experienceId}: {experienceId: number}) => {
    const {t} = useTranslation()
    const {
        setValue,
        watch,
        formState: {errors}
    } = useExperienceShellFormContext()
    const accessibilityFeaturesDialogStore = useDialogStore()
    const {register, handleSubmit, reset} = useForm<ShellPartialFormSchema['settings']>({
        defaultValues: watch('settings')
    })
    const editExperienceMutation = useEditExperience({
        experienceId,
        options: {
            onSuccess: (_, variables) => {
                if ('accessibility_features' in variables) {
                    setValue('settings.accessibility_features', variables.accessibility_features)
                }
                'accessibility_additional_information' in variables &&
                    setValue(
                        'settings.accessibility_additional_information',
                        variables.accessibility_additional_information
                    )
                'accessibility_additional_qualifications' in variables &&
                    setValue(
                        'settings.accessibility_additional_qualifications',
                        variables.accessibility_additional_qualifications
                    )
                accessibilityFeaturesDialogStore.hide()
            }
        }
    })

    const onSubmit = handleSubmit(data => {
        editExperienceMutation.mutate({
            ...data,
            guest_bringing_items: data.guest_bringing_items?.filter(item => !!item.item).map(item => item.item)
        })
    })

    return (
        <DialogProvider store={accessibilityFeaturesDialogStore}>
            <DialogDisclosure render={<Button variant="tertiary" />}>{t('commons:edit')}</DialogDisclosure>
            {/* Esc and outside click are disabled because we don't have a solution to reset the form when the dialog is closed through these functions */}
            <StyledDialog hideOnEscape={false} hideOnInteractOutside={false} width="680px">
                <DialogHeader>
                    <DialogDismiss onClick={() => reset()} />
                    <DialogHeading>{t('experience_shell:accessibility_features')}</DialogHeading>
                </DialogHeader>
                <DialogBody gap={4}>
                    <Fieldset title={t('experience_shell:auditory_and_visual_support')}>
                        <StyledAccessibilityCheckbox>
                            <VolunteerIcon />
                            <div>
                                <h3>{t('experience_shell:sign_language_title')}</h3>
                                <p>{t('experience_shell:sign_language_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.sign_language_options}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <GlassesIcon />
                            <div>
                                <h3>{t('experience_shell:designated_sighted_guide_title')}</h3>
                                <p>{t('experience_shell:designated_sighted_guide_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.designated_sighted_guide}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:mobility_accessibility_features')}>
                        <StyledAccessibilityCheckbox>
                            <NoStairsIcon />
                            <div>
                                <h3>{t('experience_shell:step_free_access_title')}</h3>
                                <p>{t('experience_shell:step_free_access_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.step_free_access}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <ToiletUprightIcon />
                            <div>
                                <h3>{t('experience_shell:step_free_bathroom_available_title')}</h3>
                                <p>{t('experience_shell:step_free_bathroom_available_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.step_free_access_bathroom}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <ParkingIcon />
                            <div>
                                <h3>{t('experience_shell:disabled_parking_spot_title')}</h3>
                                <p>{t('experience_shell:disabled_parking_spot_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.disabled_parking_spot}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <AccessibilityWideEntranceIcon />
                            <div>
                                <h3>{t('experience_shell:entrances_wider_32_inches_title')}</h3>
                                <p>{t('experience_shell:entrances_wider_32_inches_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.entrances_wider_32_inch}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <AccessibilityIcon />
                            <div>
                                <h3>{t('experience_shell:mainly_flat_leveled_ground_title')}</h3>
                                <p>{t('experience_shell:mainly_flat_leveled_ground_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.mainly_flat_leveled_ground}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:sensory_considerations')}>
                        <StyledAccessibilityCheckbox>
                            <NoFlashIcon />
                            <div>
                                <h3>{t('experience_shell:no_extreme_sensory_stimuli_title')}</h3>
                                <p>{t('experience_shell:no_extreme_sensory_stimuli_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.no_extreme_sensory_stimuli}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                        <StyledAccessibilityCheckbox>
                            <YogaIcon />
                            <div>
                                <h3>{t('experience_shell:quiet_retreat_space_available_title')}</h3>
                                <p>{t('experience_shell:quiet_retreat_space_available_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.quiet_retreat_space_available}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:support_professionals')}>
                        <StyledAccessibilityCheckbox $hasIcon={false}>
                            <div>
                                <h3>{t('experience_shell:free_admission_for_access_providers_title')}</h3>
                                <p>{t('experience_shell:free_admission_for_access_providers_description')}</p>
                            </div>
                            <Checkbox
                                value={AccessibilityFeatures.enum.free_admission_access_providers}
                                {...register('accessibility_features')}
                            />
                        </StyledAccessibilityCheckbox>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:additional_information')} contentGap={8}>
                        <Flexbox direction="column" gap={4}>
                            <DialogDescription>
                                {t('experience_shell:additional_information_description1')}
                            </DialogDescription>
                            <TextArea
                                errorMessage={
                                    errors.settings?.accessibility_additional_information
                                        ? t('errors:field_required')
                                        : undefined
                                }
                                rows={6}
                                {...register('accessibility_additional_information')}
                            />
                        </Flexbox>
                        <Flexbox direction="column" gap={4}>
                            <DialogDescription>
                                {t('experience_shell:additional_information_description2')}
                            </DialogDescription>
                            <TextArea
                                errorMessage={
                                    errors.settings?.accessibility_additional_qualifications
                                        ? t('errors:field_required')
                                        : undefined
                                }
                                rows={6}
                                {...register('accessibility_additional_qualifications')}
                            />
                        </Flexbox>
                    </Fieldset>
                </DialogBody>
                <DialogFooter>
                    <DialogDismiss
                        onClick={() => reset()}
                        render={<Button variant="tertiary" />}
                        disabled={editExperienceMutation.isPending}
                    >
                        {t('commons:cancel')}
                    </DialogDismiss>
                    <Button onClick={onSubmit} disabled={editExperienceMutation.isPending}>
                        {t('commons:confirm')}
                        {editExperienceMutation.isPending && <Spinner />}
                    </Button>
                </DialogFooter>
            </StyledDialog>
        </DialogProvider>
    )
}
