import {ScrollArea} from '@components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar'
import {Container} from '@components/ui/container/Container'
import {Flexbox} from '@components/ui/flexbox/FlexBox'
import {Divider} from '@components/ui/divider/Divider'
import {Skeleton} from '@components/ui/skeleton/Skeleton'
import {
    OpsVettingCardGrid,
    OpsVettingGalleryGrid,
    OpsVettingQuestionsGrid,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper,
    OpsVettingHero,
    OpsVettingCardContainer,
    OpsVettingCardBodyContainer,
    OpsVettingGalleryItemsContainer
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {useMemo} from 'react'

// Hero Section Skeleton
const ExperienceVettingCriteriaHeroSkeleton = () => {
    return (
        <OpsVettingHero justify="space-between" fullWidth>
            <Skeleton width={200} height={30} />
            <Flexbox gap={4} align="center">
                <Flexbox gap={2} align="center">
                    <Skeleton width={20} height={20} />
                    <Skeleton width={100} height={20} />
                </Flexbox>
                <Skeleton width={100} height={38} />
            </Flexbox>
        </OpsVettingHero>
    )
}

// Expertise Section Skeleton
const ExperienceVettingCriteriaExpertiseSkeleton = () => {
    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align={'center'}>
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingSectionTitle>
                        <Skeleton width={150} height={28} />
                    </OpsVettingSectionTitle>
                    <Flexbox gap={2} align="center">
                        <Skeleton width={36} height={36} />
                        <Skeleton width={36} height={36} />
                        <Skeleton width={36} height={36} />
                    </Flexbox>
                </Flexbox>
                <Skeleton width={140} height={24} />
            </Flexbox>

            <Divider direction="horizontal" />

            <OpsVettingQuestionsGrid>
                <Flexbox direction={'column'} gap={6} fullWidth>
                    {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                        <Flexbox key={index} direction="column" gap={2}>
                            <Skeleton width={140} height={20} />
                            <Skeleton width="100%" height={24} />
                        </Flexbox>
                    ))}
                </Flexbox>
                <Divider direction={'vertical'} />
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <Skeleton width={180} height={20} />
                        <Skeleton width={56} height={56} style={{borderRadius: '50%'}} />
                    </Flexbox>
                    {[1, 2, 3, 4].map((_, index) => (
                        <Flexbox key={index} direction="column" gap={2}>
                            <Skeleton width={140} height={20} />
                            <Skeleton width="100%" height={24} />
                        </Flexbox>
                    ))}
                </Flexbox>
            </OpsVettingQuestionsGrid>
        </OpsVettingSectionWrapper>
    )
}

// Itinerary Section Skeleton
const ExperienceVettingCriteriaItinerarySkeleton = () => {
    // Generate an array of items for the itinerary cards
    const skeletonItems = useMemo(() => Array.from({length: 2}), [])

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <OpsVettingSectionTitle>
                    <Skeleton width={200} height={24} />
                </OpsVettingSectionTitle>
                <Skeleton width={140} height={20} />
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingCardGrid>
                {skeletonItems.map((_, index) => (
                    <OpsVettingCardContainer key={index} direction="column" gap={2}>
                        <Flexbox fullWidth justify="space-between" align="center" gap={2}>
                            <Flexbox gap={8} align="center">
                                <Skeleton width={150} height={22} />
                                <Flexbox gap={2}>
                                    <Skeleton width={36} height={36} />
                                    <Skeleton width={36} height={36} />
                                    <Skeleton width={36} height={36} />
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>

                        <OpsVettingCardBodyContainer>
                            <Flexbox direction="column" fullWidth gap={2.5}>
                                {[1, 2, 3, 4].map((_, fieldIndex) => (
                                    <Flexbox key={fieldIndex} direction="column" gap={2} fullWidth>
                                        <Skeleton width={120} height={20} />
                                        <Skeleton width="100%" height={20} />
                                    </Flexbox>
                                ))}
                            </Flexbox>

                            <Skeleton width="100%" height={200} style={{borderRadius: '8px', aspectRatio: '1/1'}} />
                        </OpsVettingCardBodyContainer>
                    </OpsVettingCardContainer>
                ))}
            </OpsVettingCardGrid>
        </OpsVettingSectionWrapper>
    )
}

// Gallery Section Skeleton
const ExperienceVettingCriteriaGallerySkeleton = () => {
    // Generate an array of items for the gallery
    const skeletonItems = useMemo(() => Array.from({length: 9}), [])

    return (
        <OpsVettingSectionWrapper fullWidth direction="column" gap={6}>
            {/* Header section */}
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align="center">
                    {/* Title skeleton */}
                    <Skeleton width={180} height={24} />

                    {/* Upload button skeleton */}
                    <Skeleton width={120} height={36} />

                    {/* Checkbox skeletons */}
                    <Flexbox gap={2} align="center">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={150} height={20} />
                    </Flexbox>

                    <Flexbox gap={2} align="center">
                        <Skeleton width={20} height={20} />
                        <Skeleton width={180} height={20} />
                    </Flexbox>
                </Flexbox>

                {/* Status section */}
                <Skeleton width={140} height={20} />
            </Flexbox>

            <Divider direction="horizontal" />

            {/* Gallery grid */}
            <OpsVettingGalleryGrid>
                {/* Cover photo skeleton (first item, slightly different) */}
                <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                    <Skeleton width="100%" height="100%" style={{borderRadius: '8px', aspectRatio: '1/1'}} />
                    <Flexbox gap={2} direction="column" fullWidth>
                        <Skeleton width="70%" height={18} />
                    </Flexbox>
                </OpsVettingGalleryItemsContainer>

                {/* Regular gallery items */}
                {skeletonItems.map((_, index) => (
                    <OpsVettingGalleryItemsContainer key={index} direction="column" gap={4}>
                        <Skeleton width="100%" height="100%" style={{borderRadius: '8px', aspectRatio: '1/1'}} />
                        <Flexbox gap={2} direction="column" fullWidth>
                            <Skeleton height={18} />
                            <Flexbox gap={2}>
                                <Skeleton height={38} />
                                <Skeleton height={38} />
                            </Flexbox>
                        </Flexbox>
                    </OpsVettingGalleryItemsContainer>
                ))}
            </OpsVettingGalleryGrid>
        </OpsVettingSectionWrapper>
    )
}

// Rejected Images Section Skeleton

// Main Skeleton Component for the entire page
export const ExperienceVettingCriteriaSkeleton = () => {
    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            <Container fullWidth>
                <ExperienceVettingCriteriaHeroSkeleton />
            </Container>

            <Container fullWidth>
                <Flexbox direction="column" gap={6} fullWidth>
                    <ExperienceVettingCriteriaExpertiseSkeleton />
                    <ExperienceVettingCriteriaItinerarySkeleton />
                    <ExperienceVettingCriteriaGallerySkeleton />
                </Flexbox>
            </Container>
        </ScrollArea>
    )
}

ExperienceVettingCriteriaSkeleton.displayName = 'ExperienceVettingCriteriaSkeleton'
