import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {css, styled} from 'styled-components'

export const StyledOpsVettingMerchFormHeader = styled(Flexbox)<{$variant?: 'base' | 'blue'}>(
    ({theme: {typography, palette}, $variant}) => css`
        color: ${$variant === 'blue' ? palette.blue['700'] : palette.neutral['900']};
        & h3 {
            ${typography.textLg};
            font-weight: 600;
        }

        & small {
            ${typography.textMd};
            color: ${palette.neutral['900']};
        }
    `
)
