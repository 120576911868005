import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateExperienceVettingImageIsApprovedOptions,
    httpUpdateExperienceVettingImageIsApproved
} from '../http/experienceVetting.http'
import {Experience} from '@/features/experience-shell/types'

export const useUpdateExperienceVettingImageIsApproved = (
    urlParams: HttpUpdateExperienceVettingImageIsApprovedOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpUpdateExperienceVettingImageIsApprovedOptions['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExperienceVettingImageIsApproved({
                urlParams: {...urlParams},
                payload,
                params: {
                    responseType: 'small'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        gallery: {
                            ...previousData.gallery,
                            images: previousData.gallery.images.map(image =>
                                image.id === urlParams.imageId ? {...image, isApproved: variables.isApproved} : image
                            )
                        }
                    }
                )
            }
            return previousData
        }
    })
}
