import {
    SelectItemProps,
    SelectPopoverProps,
    Role,
    Select,
    useSelectContext,
    Combobox,
    ComboboxProps,
    ComboboxListProps,
    SelectListProps
} from '@ariakit/react'
import {
    StyledSelectArrow,
    StyledSelectComboboxList,
    StyledSelectComboboxPopover,
    StyledSelectItem,
    StyledSelectList,
    StyledSelectPopover,
    StyledSelectTrigger,
    StyledSelectVirtualList
} from '@components/ui/select-atoms/style'
import {forwardRef, ReactNode} from 'react'
import {ButtonProps} from '@components/ui/button/Button.tsx'
import {ChevronDownIcon} from '@components/ui/icon/Icon.tsx'
import {raise} from '@utilities/helpers.ts'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {VirtuosoProps} from 'react-virtuoso'

export const SelectPopover = (props: SelectPopoverProps) => <StyledSelectPopover gutter={4} sameWidth {...props} />

export const SelectComboboxPopover = (props: SelectPopoverProps) => (
    <StyledSelectComboboxPopover gutter={4} sameWidth {...props} />
)

export const SelectList = (props: SelectListProps) => (
    <ScrollArea overflowX="hidden" scrollbar={<Scrollbar />}>
        <StyledSelectList {...props} />
    </ScrollArea>
)

export const SelectComboboxList = (props: ComboboxListProps) => (
    <ScrollArea overflowX="hidden" scrollbar={<Scrollbar />}>
        <StyledSelectComboboxList {...props} />
    </ScrollArea>
)

export const SelectItem = (props: SelectItemProps) => <StyledSelectItem {...props} />

export const SelectComboboxInput = (props: ComboboxProps) => (
    <Combobox autoSelect render={<InputText type="text" inputSize="sm" />} {...props} />
)

interface SelectTriggerProps extends Omit<ButtonProps, 'children'> {
    placeholder?: ReactNode
    displayValue: (value: string | string[]) => ReactNode
}

export const SelectTrigger = forwardRef<HTMLSelectElement, SelectTriggerProps>(
    ({placeholder, displayValue, ...rest}, ref) => {
        const selectContext = useSelectContext() ?? raise('SelectTrigger must be in a SelectProvider')
        const value = selectContext.useState('value')
        const isOpen = selectContext.useState('open')
        const children = value.length >= 1 ? displayValue(value) : undefined

        return (
            <Select
                render={
                    <Role.select
                        ref={ref}
                        render={
                            <StyledSelectTrigger $hasChildren={!!children} type="button" variant="secondary" {...rest}>
                                {children || placeholder}
                                <StyledSelectArrow $isOpen={isOpen} render={<ChevronDownIcon />} />
                            </StyledSelectTrigger>
                        }
                    />
                }
            />
        )
    }
)

export const SelectVirtualList = <T,>(props: VirtuosoProps<T, unknown>) => <StyledSelectVirtualList {...props} />
