import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    DialogDisclosure,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider
} from '@components/ui/dialog-atoms/DialogAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ThumbsDownIcon} from '@components/ui/icon/Icon.tsx'
import {useDialogContext} from '@ariakit/react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperienceGalleryImage, ExperienceFlag} from '@/features/experience-shell/types.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateExperienceVettingImageIsApproved} from '@/features/experience-vetting/queries/useUpdateExperienceVettingImageIsApproved.ts'
import {useAddExperienceGalleryImageFlags} from '@/features/experience-vetting/queries/useAddExperienceGalleryImageFlags.ts'
import {
    OpsVettingRejectDialog,
    OpsVettingRejectDialogBody,
    OpsVettingRejectDialogBodyFlagsItem
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {useGetExperiencesFlags} from '../../http/useGetExperiencesFlags'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {experiencesFlagsMapToLabel} from '../../utils'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {useRemoveExperienceGalleryImageFlags} from '../../queries/useRemoveExperienceGalleryImageFlags'
interface ExperienceVettingGalleryImageRejectDialogProps {
    image: ExperienceGalleryImage
    experienceId: number
    galleryId: number
    currentFlags: ExperienceFlag[]
    disabled?: boolean
}

export const ExperienceVettingGalleryImageRejectDialog = ({
    image,
    experienceId,
    galleryId,
    currentFlags,
    disabled
}: ExperienceVettingGalleryImageRejectDialogProps) => {
    const {t} = useTranslation()

    return (
        <DialogProvider>
            <DialogDisclosure
                render={
                    <Button variant="secondary" fullWidth size="sm" disabled={disabled}>
                        <ThumbsDownIcon />
                        {t('vetting:criteria:commons:reject')}
                    </Button>
                }
            />
            <OpsVettingRejectDialog width="600px" hideOnInteractOutside={false}>
                <ExperienceVettingGalleryImageRejectDialogBody
                    image={image}
                    experienceId={experienceId}
                    galleryId={galleryId}
                    currentFlags={currentFlags}
                />
            </OpsVettingRejectDialog>
        </DialogProvider>
    )
}

export const ExperienceVettingGalleryImageRejectDialogBody: FC<ExperienceVettingGalleryImageRejectDialogProps> = ({
    image,
    experienceId,
    galleryId,
    currentFlags
}) => {
    const {t} = useTranslation()
    const dialogStore = useDialogContext()
    if (!dialogStore) {
        throw new Error('Dialog must be wrapped in DialogProvider')
    }

    const [selectedFlags, setSelectedFlags] = useState<ExperienceFlag[]>(currentFlags)

    const updateExperienceVettingImageIsApproved = useUpdateExperienceVettingImageIsApproved({
        experienceId,
        galleryId,
        imageId: image.id
    })

    const addExperienceGalleryImageFlags = useAddExperienceGalleryImageFlags({
        experienceId,
        sectionId: galleryId
    })
    const removeExperienceGalleryImageFlags = useRemoveExperienceGalleryImageFlags({
        experienceId,
        sectionId: galleryId
    })

    const {data: flags, isLoading: isFlagsLoading} = useGetExperiencesFlags({
        scopes: ['photo_gallery']
    })

    const isSubmitting = updateExperienceVettingImageIsApproved.isPending || addExperienceGalleryImageFlags.isPending

    const hasSelectedFlags = selectedFlags.length > 0

    const handleAddFlag = (flag: ExperienceFlag) => {
        setSelectedFlags(prev => [...prev, flag])
    }

    const handleRemoveFlag = (flag: ExperienceFlag) => {
        if (currentFlags.some(f => f.id === flag.id)) {
            removeExperienceGalleryImageFlags.mutate({
                flags: [flag],
                itemId: image.id
            })
        }
        setSelectedFlags(prev => prev.filter(f => f.id !== flag.id))
    }

    const handleReject = async () => {
        try {
            // First, mark the image as rejected
            await updateExperienceVettingImageIsApproved.mutateAsync({isApproved: false})

            // If there are flags, add them
            if (selectedFlags.length > 0) {
                await addExperienceGalleryImageFlags.mutateAsync({
                    flags: selectedFlags,
                    itemId: image.id
                })
            }
        } catch (error) {
            console.error('Error rejecting image:', error)
        } finally {
            dialogStore.hide()
        }
    }

    return (
        <>
            <DialogHeader>
                <DialogDismiss disabled={isSubmitting} />
                <DialogHeading>{t('vetting:criteria:commons:select_flags')}</DialogHeading>
            </DialogHeader>
            <OpsVettingRejectDialogBody>
                <GalleryImageItem src={image.url} />
                <ScrollArea scrollbar={<Scrollbar />}>
                    <Flexbox direction="column" gap={2}>
                        {isFlagsLoading
                            ? Array.from({length: 10}).map((_, index) => (
                                  <Flexbox key={index} gap={2}>
                                      <Skeleton width={20} height={20} />
                                      <Skeleton width="100%" height={20} />
                                  </Flexbox>
                              ))
                            : flags?.map(flag => (
                                  <OpsVettingRejectDialogBodyFlagsItem key={flag.id} gap={2}>
                                      <Checkbox
                                          checked={selectedFlags.some(f => f.id === flag.id)}
                                          onChange={() => {
                                              if (selectedFlags.some(f => f.id === flag.id)) {
                                                  handleRemoveFlag(flag)
                                              } else {
                                                  handleAddFlag(flag)
                                              }
                                          }}
                                      />
                                      <p>{t(experiencesFlagsMapToLabel[flag.keyName])}</p>
                                  </OpsVettingRejectDialogBodyFlagsItem>
                              ))}
                    </Flexbox>
                </ScrollArea>
            </OpsVettingRejectDialogBody>
            <DialogFooter>
                <Button variant="tertiary" onClick={() => dialogStore.hide()} disabled={isSubmitting}>
                    {t('commons:cancel')}
                </Button>

                <Button onClick={handleReject} disabled={isSubmitting || !hasSelectedFlags}>
                    {t('vetting:criteria:commons:reject_image')}
                    {isSubmitting && <Spinner size={20} />}
                </Button>
            </DialogFooter>
        </>
    )
}
