import {StepsNavigation} from '@/features/experience-shell/components/steps-navigation/StepsNavigation'
import {StyledExperienceShell} from './style'
import {Header} from '@/components/commons/header/Header'
import {BackgroundStep} from '@/features/experience-shell/components/background-step/BackgroundStep'
import {FC, ReactElement} from 'react'
import {Experience, ExperienceStatus, ShellStepKeyName} from '@/features/experience-shell/types'
import {ExperienceStep} from '@/features/experience-shell/components/experience-step/ExperienceStep'
import {MerchandisingStep} from '@/features/experience-shell/components/merchandising-step/MerchandisingStep'
import {LocationStep} from '@/features/experience-shell/components/location-step/LocationStep'
import {PhotosStep} from '@/features/experience-shell/components/photos-step/PhotosStep'
import {ItineraryStep} from '@/features/experience-shell/components/itinerary-step/ItineraryStep'
import {PricingDiscountStep} from '@/features/experience-shell/components/pricing-discount-step/PricingDiscountStep'
import {SettingsStep} from '@/features/experience-shell/components/settings-step/SettingsStep'
import {LegalStep} from '@/features/experience-shell/components/legal-step/LegalStep'
import {FormProvider, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ShellFormDefaultValues, ShellPartialFormSchema} from '@/features/experience-shell/formSchemas'
import {useGetExperience} from '@/features/experience-shell/queries/useGetExperience'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {z} from 'zod'
import {raise} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertCircleIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from '@/translations/i18n'
import {makeShellFormDefaultValues} from '@/features/experience-shell/utils'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {SuccessView} from '@/features/experience-shell/components/success-view/SuccessView'

export const ExperienceShell = () => {
    const {t} = useTranslation()
    const params = useURLParsedParams(z.object({id: z.coerce.number().int().positive()}))
    const experienceQuery = useGetExperience(params.success ? params.data.id : raise('id is nullish'))

    return experienceQuery.isPending ? (
        <Flexbox justify="center" align="center" fullWidth style={{height: '100%'}}>
            <Spinner size={32} />
        </Flexbox>
    ) : experienceQuery.isError ? (
        <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
    ) : (
        <ExperienceShellContent experience={experienceQuery.data} />
    )
}

const ExperienceShellContent: FC<{experience: Experience}> = ({experience}) => {
    const form = useForm<ShellFormDefaultValues, unknown, ShellPartialFormSchema>({
        mode: 'onChange',
        resolver: zodResolver(ShellPartialFormSchema),
        defaultValues: makeShellFormDefaultValues(experience)
    })
    const isShellInProgress =
        experience.status == ExperienceStatus.enum.pending_shell_submission ||
        experience.status == ExperienceStatus.enum.pending_shell_creation ||
        experience.status == ExperienceStatus.enum.pending_merchandising

    const stepKeyName = ShellStepKeyName.catch('experience').parse(experience.step.keyName)
    const getStepComponent = (stepKeyName: ShellStepKeyName): ReactElement => {
        switch (stepKeyName) {
            case 'background':
                return <BackgroundStep experienceId={experience.id} />
            case 'merchandising':
                return <MerchandisingStep experienceId={experience.id} />
            case 'location':
                return <LocationStep experienceId={experience.id} />
            case 'photos':
                return <PhotosStep gallery={experience.gallery} itineraries={experience.itineraries} />
            case 'itinerary':
                return (
                    <ItineraryStep
                        itineraries={experience.itineraries}
                        experienceId={experience.id}
                        galleryId={experience.gallery.id}
                    />
                )
            case 'pricing_discounts':
                return <PricingDiscountStep experienceId={experience.id} itineraries={experience.itineraries} />
            case 'settings':
                return <SettingsStep experienceId={experience.id} />
            case 'legal':
                return <LegalStep experienceId={experience.id} galleryImagesCount={experience.gallery.images.length} />
            default:
                return <ExperienceStep experienceId={experience.id} />
        }
    }

    return (
        <FormProvider {...form}>
            <StyledExperienceShell>
                <Header
                /* endSlot={
                        experience.airtableLink && (
                            <ButtonLink variant="tertiary" to={experience.airtableLink} target="_blank">
                                <AirtableIcon fill={palette.neutral['400']} />
                                {t('commons:airtable_cta')}
                            </ButtonLink>
                        )
                    } */
                />
                {isShellInProgress && (
                    <>
                        <StepsNavigation
                            experienceId={experience.id}
                            currentStep={stepKeyName}
                            galleryImagesCount={experience.gallery.images.length}
                            itineraries={experience.itineraries}
                        />
                        {getStepComponent(stepKeyName)}
                    </>
                )}
                {!isShellInProgress && <SuccessView />}
            </StyledExperienceShell>
        </FormProvider>
    )
}
