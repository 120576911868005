import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {httpGetExperienceVetting} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {remapExperienceForVetting} from '@/features/experience-vetting/utils.ts'

export const useGetExperienceVetting = (experienceId: number) => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId],
        queryFn: () => httpGetExperienceVetting({urlParams: {experienceId}, params: {responseType: 'extended'}}),
        staleTime: 1000 * 60 * 60 // keep data fresh for 1 hour
    })

    const remappedData = query.data && remapExperienceForVetting(query.data)
    return {...query, remappedData}
}
