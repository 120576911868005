import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {OpsVettingGalleryItemsContainer} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Expand01Icon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {memo} from 'react'
import {ServiceGalleryImage} from '../../types'
import {useDeleteServicePhotos} from '../../query/useDeleteServicePhotos'

export const ServiceVettingCriteriaUploadedItem = memo(
    ({
        image,
        galleryId,
        serviceId,
        hideAction,
        onClickExpand
    }: {
        image: ServiceGalleryImage
        galleryId: number
        serviceId: number
        hideAction: boolean
        onClickExpand?: (image: ServiceGalleryImage) => void
    }) => {
        const {t} = useTranslation()
        const queryClient = useQueryClient()

        const deletePhoto = useDeleteServicePhotos(
            {galleryId, serviceId, mediaId: image.id},
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES]})
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VETTING, serviceId]})
                }
            }
        )

        const onClickDeletePhoto = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            deletePhoto.mutate()
        }

        return (
            <OpsVettingGalleryItemsContainer>
                <GalleryImageItem
                    src={image.thumbnails?.xs || image.url}
                    bottomLeftSlot={
                        <Chip label={t('vetting:criteria:service:gallery:internal_upload')} variant={'neutral'} />
                    }
                    topRightSlot={
                        <Button
                            variant={'secondary'}
                            iconOnly
                            disabled={deletePhoto.isPending || hideAction}
                            onClick={onClickDeletePhoto}
                        >
                            {deletePhoto.isPending ? <Spinner /> : <Trash01Icon />}
                        </Button>
                    }
                    bottomRightSlot={
                        !hideAction && (
                            <Button
                                variant="secondary"
                                iconOnly
                                disabled={deletePhoto.isPending}
                                className="show-on-hover"
                                size="sm"
                                onClick={() => onClickExpand?.(image)}
                            >
                                <Expand01Icon />
                            </Button>
                        )
                    }
                />
            </OpsVettingGalleryItemsContainer>
        )
    }
)
