import {ServicePhotoUploadStore} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {httpUploadFile} from '@/features/service-vetting/http/servicesVetting.http.ts'

interface useUpdatePhotosOptions {
    storeAction: Pick<ServicePhotoUploadStore, 'addUploadProgressValues' | 'setUploadingItemsStatus'>
    onConfirm: (imageId: number) => Promise<void>
}
type FileToUpload = {
    id: number
    presignedUrl: string
    file: File
}
export const useUploadPhotos = ({onConfirm, storeAction}: useUpdatePhotosOptions) => {
    const {addUploadProgressValues, setUploadingItemsStatus} = storeAction

    const uploadFile = async (fileToUpload: FileToUpload, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: fileToUpload.presignedUrl,
                payload: fileToUpload.file,
                onUploadProgress: progressEvent =>
                    addUploadProgressValues({
                        id: fileToUpload.id,
                        progressValue: progressEvent.total
                            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            : 100
                    })
            })
            await onConfirm(fileToUpload.id)
        } catch (error) {
            setUploadingItemsStatus({id: fileToUpload.id, status: 'error'})
        } finally {
            uploadNextGroup()
        }
    }

    return (filesToUpload: FileToUpload[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [filesToUpload.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < filesToUpload.length; index += othersGroupSize) {
            queue = queue.concat([filesToUpload.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (filesToUpload: FileToUpload[]) => {
            filesToUpload.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }
}
