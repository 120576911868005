import {useQuery} from '@tanstack/react-query'
import {httpGetExperienceVettingVersions, HttpGetExperienceVettingVersionsOptions} from '../http/experienceVetting.http'
import {QUERY_KEYS} from '@/queryClient'

export const useGetExperienceVettingVersions = ({
    experienceId
}: HttpGetExperienceVettingVersionsOptions['urlParams']) => {
    return useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_VERSIONS, experienceId],
        queryFn: () => httpGetExperienceVettingVersions({urlParams: {experienceId}, params: {responseType: 'extended'}})
    })
}
