import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledDiffIndicator,
    StyledDiffSection,
    StyledVettingMerchCopyDiffsWrapper,
    StyledVettingMerchCopyDiffs,
    StyledFieldsDiffsSeparator,
    StyledFieldsDiffsSeparatorSegment
} from './style'
import {CheckIcon, User01Icon} from '@/components/ui/icon/Icon'

export type ContentDiff = {
    hasDiff: boolean
    fieldSize: 'sm' | 'md' | 'lg'
}

//DiffIndicator Atoms
export const DiffSectionWrapper = ({children}: {children: React.ReactNode}) => {
    return (
        <StyledVettingMerchCopyDiffsWrapper>
            <StyledVettingMerchCopyDiffs direction="column" fullWidth>
                <Flexbox direction="column" fullWidth>
                    {children}
                </Flexbox>
            </StyledVettingMerchCopyDiffs>
        </StyledVettingMerchCopyDiffsWrapper>
    )
}

//DiffIndicator Atoms
interface DiffSectionProps {
    hasDiff: boolean
    size: 'sm' | 'md' | 'lg'
}
export const DiffSection = ({hasDiff = false, size = 'md'}: DiffSectionProps) => {
    return (
        <StyledDiffSection $hasDiff={hasDiff} $size={size}>
            <DiffIndicator hasDiff={hasDiff} />
        </StyledDiffSection>
    )
}

interface DiffIndicatorProps {
    hasDiff: boolean
    size?: number
}
export const DiffIndicator = ({hasDiff, size = 24}: DiffIndicatorProps) => {
    return (
        <StyledDiffIndicator $size={size} $hasDiff={hasDiff}>
            {hasDiff ? <User01Icon size={16} /> : <CheckIcon size={16} />}
        </StyledDiffIndicator>
    )
}
DiffIndicator.displayName = 'DiffIndicator'

type FieldsDiffsSeparatorProps =
    | {
          fieldType: 'consecutive'
          hasUpperDiff: boolean
          hasLowerDiff: boolean
      }
    | {
          fieldType: 'section' | 'sub-section'
          hasUpperDiff?: undefined
          hasLowerDiff?: undefined
      }

export const FieldsDiffsSeparator = ({fieldType, hasUpperDiff, hasLowerDiff}: FieldsDiffsSeparatorProps) => {
    return (
        <StyledFieldsDiffsSeparator $fieldType={fieldType} direction="column">
            {fieldType == 'consecutive' && (
                <>
                    <StyledFieldsDiffsSeparatorSegment $hasDiff={hasUpperDiff} />
                    <StyledFieldsDiffsSeparatorSegment $hasDiff={hasLowerDiff} />
                </>
            )}
        </StyledFieldsDiffsSeparator>
    )
}
FieldsDiffsSeparator.displayName = 'FieldsDiffsSeparator'
