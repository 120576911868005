import {
    OpsVettingLabel,
    OpsVettingParagraph,
    OpsVettingQuestionsGrid,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {VettingSectionStatusSelector} from '@/features/ops-vetting/components/vetting-section-status-selector/VettingSectionStatusSelector.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {RemapExperienceVetting} from '@/features/experience-vetting/types.ts'
import {ExpVettingCriteriaCategorySelect} from '@/features/experience-vetting/components/exp-vetting-criteria-category-select/ExpVettingCriteriaCategorySelect.tsx'
import {useAddExperienceExpertiseAnswerFlags} from '@/features/experience-vetting/queries/useAddExperienceExpertiseAnswerFlags.ts'
import {useRemoveExperienceExpertiseAnswerFlags} from '@/features/experience-vetting/queries/useRemoveExperienceExpertiseAnswerFlags.ts'
import {ExperienceVettingCriteriaFlags} from '@/features/experience-vetting/components/experience-vetting-criteria-flags/ExperienceVettingCriteriaFlags.tsx'
import {useUpdateExperienceExpertiseStatus} from '@/features/experience-vetting/queries/useUpdateExperienceExpertiseStatus.ts'
import {categoryToLabel} from '@/features/experience-shell/utils.ts'
import {useAddExperienceExpertiseFlags} from '../../queries/useAddExperienceExpertiseFlags'
import {useRemoveExperienceExpertiseFlags} from '../../queries/useRemoveExperienceExpertiseFlags'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'
import {AlertTriangleIcon, CheckIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'

export const ExperienceVettingCriteriaExpertise = ({
    experience,
    isReadOnly
}: {
    experience: RemapExperienceVetting
    isReadOnly: boolean
}) => {
    const {t} = useTranslation()
    const errors = useExperienceVettingError(state => state.errors)

    const isBackToDraft = experience.expertise.status == 'back_to_draft'

    //render category label
    const renderCategoryLabel = (categoryKey?: string) => {
        if (!categoryKey || categoryKey === 'generic') return '-'
        return categoryToLabel[categoryKey as keyof typeof categoryToLabel]
            ? t(categoryToLabel[categoryKey as keyof typeof categoryToLabel])
            : categoryKey
    }

    const addExpertiseQuestionFlags = useAddExperienceExpertiseAnswerFlags({
        experienceId: experience.id,
        sectionId: experience.expertise.id
    })
    const removeExpertiseQuestionFlags = useRemoveExperienceExpertiseAnswerFlags({
        experienceId: experience.id,
        sectionId: experience.expertise.id
    })

    const updateExpertiseStatus = useUpdateExperienceExpertiseStatus({
        experienceId: experience.id,
        expertiseId: experience.expertise.id
    })

    const addExpertiseFlags = useAddExperienceExpertiseFlags({
        experienceId: experience.id,
        itemId: experience.expertise.id
    })

    const removeExpertiseFlags = useRemoveExperienceExpertiseFlags({
        experienceId: experience.id,
        itemId: experience.expertise.id
    })

    const isDeclined = experience.expertise.status == 'declined'

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align={'center'}>
                <Flexbox style={{flexShrink: 0}} gap={8} align={'center'}>
                    <OpsVettingSectionTitle>{t('vetting:criteria:service:expertise:title')}</OpsVettingSectionTitle>
                    <VettingSectionStatusSelector
                        value={experience.expertise.status}
                        onChange={status => updateExpertiseStatus.mutate({status})}
                        disabled={isReadOnly}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.expertiseErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
                    {isDeclined && (
                        <ExperienceVettingCriteriaFlags
                            flags={experience.expertise.flags}
                            addFlag={flag =>
                                addExpertiseFlags.mutate({
                                    flags: [flag]
                                })
                            }
                            removeFlag={flag =>
                                removeExpertiseFlags.mutate({
                                    flags: [flag]
                                })
                            }
                            flagPosition="left"
                            badgeVariant="danger"
                            hideAction={isReadOnly}
                            scopes={['decline_expertise']}
                        />
                    )}
                </Flexbox>
            </Flexbox>

            <Divider direction="horizontal" />

            <OpsVettingQuestionsGrid>
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:category')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {renderCategoryLabel(experience.category.category?.keyName)}
                        </OpsVettingParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:category_level_2')}</OpsVettingLabel>
                        {experience.category.subCategory?.keyName && experience.category.category?.keyName && (
                            <ExpVettingCriteriaCategorySelect
                                experienceId={experience.id}
                                defaultCategoryFormat={experience.category.subCategory.keyName}
                                category={experience.category.category.keyName}
                                disabled={isReadOnly || isDeclined}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:years_of_experience')}</OpsVettingLabel>
                        <OpsVettingParagraph>{experience.expertise.answers.expertise_years.answer}</OpsVettingParagraph>
                        {/* {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.expertise_years.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.expertise_years.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.expertise_years.id
                                    })
                                }
                                scopes={['host_expertise']}
                                hideAction={isReadOnly}
                            />
                        )} */}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:listing_title')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {experience.expertise.answers.experience_title.answer}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.experience_title.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.experience_title.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.experience_title.id
                                    })
                                }
                                scopes={['experience_title']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:listing_description')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {experience.expertise.answers.experience_description?.answer}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.experience_description.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.experience_description.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.experience_description.id
                                    })
                                }
                                scopes={['experience_description']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:host_title')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {experience.expertise.answers.host_experience_title?.answer}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.host_experience_title.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.host_experience_title.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.host_experience_title.id
                                    })
                                }
                                scopes={['host_title']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:host_expertise')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {experience.expertise.answers.host_expertise_description?.answer}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.host_expertise_description.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.host_expertise_description.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.host_expertise_description.id
                                    })
                                }
                                scopes={['host_expertise']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Divider direction={'vertical'} />
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:service:expertise:profile_picture')}</OpsVettingLabel>
                        <Avatar
                            size={'lg'}
                            name={experience.host.firstName ?? '-'}
                            imageUrl={experience.host.profilePhotoUrl || ''}
                        />
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.profile_photo.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.profile_photo.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.profile_photo.id
                                    })
                                }
                                scopes={['profile_picture']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:host_name')}</OpsVettingLabel>
                        <OpsVettingParagraph>{experience.host.firstName}</OpsVettingParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:can_airbnb_import_content')}</OpsVettingLabel>
                        {experience.canAirbnbImportContent ? (
                            <Flexbox gap={2} align={'center'}>
                                <CheckIcon size={16} />
                                <OpsVettingParagraph>
                                    {t('vetting:criteria:experience:can_airbnb_import_content_approved')}
                                </OpsVettingParagraph>
                            </Flexbox>
                        ) : (
                            <Flexbox gap={2} align={'center'}>
                                <XCloseIcon size={16} />
                                <OpsVettingParagraph>
                                    {t('vetting:criteria:experience:can_airbnb_import_content_not_approved')}
                                </OpsVettingParagraph>
                            </Flexbox>
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:meeting_point')}</OpsVettingLabel>
                        <Flexbox direction="column" gap={1}>
                            <OpsVettingParagraph>
                                {experience.expertise.answers.meeting_point_name?.answer}
                            </OpsVettingParagraph>
                            <OpsVettingParagraph>
                                {experience.expertise.answers.meeting_point_description?.answer}
                            </OpsVettingParagraph>
                        </Flexbox>
                        {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.meeting_point_name.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.meeting_point_name.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.meeting_point_name.id
                                    })
                                }
                                scopes={['location']}
                                hideAction={isReadOnly}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:criteria:experience:price_per_person')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {experience.expertise.answers.guest_individual_price?.answer}
                        </OpsVettingParagraph>
                        {/* {isBackToDraft && (
                            <ExperienceVettingCriteriaFlags
                                flags={experience.expertise.answers.guest_individual_price.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.guest_individual_price.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: experience.expertise.answers.guest_individual_price.id
                                    })
                                }
                                scopes={['host_expertise']}
                                hideAction={isReadOnly}
                            />
                        )} */}
                    </Flexbox>
                </Flexbox>
            </OpsVettingQuestionsGrid>
        </OpsVettingSectionWrapper>
    )
}
