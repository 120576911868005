import {useTranslation} from '@/translations/i18n'
import {Fieldset} from '../atoms/Atoms'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {SkillLevel} from '../../types'
import {StyledRadioAnswers} from './style'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export const SkillLevelSection = () => {
    const {t} = useTranslation()
    const {
        register,
        formState: {errors}
    } = useExperienceShellFormContext()

    return (
        <Fieldset title={t('experience_shell:skill_level')}>
            <StyledRadioAnswers>
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:skill_level_beginner')}
                    value={SkillLevel.enum.beginner}
                    {...register('settings.skill_level')}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:skill_level_intermediate')}
                    value={SkillLevel.enum.intermediate}
                    {...register('settings.skill_level')}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:skill_level_advanced')}
                    value={SkillLevel.enum.advanced}
                    {...register('settings.skill_level')}
                />
                <AnswerItem
                    inputSize="sm"
                    type="radio"
                    label={t('experience_shell:skill_level_expert')}
                    value={SkillLevel.enum.expert}
                    {...register('settings.skill_level')}
                />
            </StyledRadioAnswers>
            {errors.settings?.skill_level && <InputHelpText error={t('errors:field_required')} />}
        </Fieldset>
    )
}
