import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {useState, useRef, useMemo} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {ExperienceShellMerchOutputSchema, ExperienceVettingMerchOutputSchema} from '../../formSchemas'
import {
    StyledServiceMerchandisingVettingWrapper,
    StyledServiceVettingFormsWrapper
} from '@/pages/service-vetting/merchandising/style'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {EXPERIENCE_VETTING_PROMPT} from '@/features/ops-vetting/prompts/experienceVettingPrompt'
import {useAuthStore} from '@/features/auth/store/store'
import {ExpVettingMerchOutputForm} from '../exp-vetting-merch-output-form/ExpVettingMerchOutputForm'
import {AiPromptModal} from '@/components/commons/ai-prompt-modal/AiPromptModal'
import {Button} from '@/components/ui/button/Button'
import {Settings01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {RemapExperienceVetting} from '../../types'
import {ExpShellMerchOutputForm} from '../exp-shell-merch-output-form/ExpShellMerchOutputForm'
import {
    remapExperienceMerchCopyInputForm,
    remapExperienceMerchCopyOutputForm,
    remapExperienceMerchCopyOutputFormHostEdits,
    remapMerchCopyHostEditsDiffs
} from '../../utils'
import {Divider} from '@/components/ui/divider/Divider'
import {ExperienceVettingMerchCopyDiffs} from '../exp-vetting-merch-copy-diffs/ExperienceVettingMerchCopyDiffs'
import {ExperienceVettingMerchSubmitModal} from '../experience-vetting-merch-submit-modal/ExperienceVettingMerchSubmitModal'
import {toast} from 'react-hot-toast'
import {useSubmitExperienceVetting} from '../../queries/useSubmitExperienceVetting'
import {useTranslation} from '@/translations/i18n'
export const REMAP_DIFF_SAMPLE = {
    improved_host_experience_title: {
        hasDiff: true,
        fieldSize: 'sm' as const
    },
    improved_host_expertise_description: {
        hasDiff: true,
        fieldSize: 'md' as const
    },
    improved_host_recognition_title: {
        hasDiff: true,
        fieldSize: 'sm' as const
    },
    improved_host_recognition_subtitle: {
        hasDiff: true,
        fieldSize: 'sm' as const
    },
    improved_host_credential: {
        hasDiff: true,
        fieldSize: 'sm' as const
    },
    improved_experience_title: {
        hasDiff: true,
        fieldSize: 'sm' as const
    },
    improved_experience_description: {
        hasDiff: true,
        fieldSize: 'md' as const
    },
    activities: [
        [
            {
                hasDiff: true,
                fieldSize: 'sm' as const
            },
            {
                hasDiff: true,
                fieldSize: 'md' as const
            }
        ],
        [
            {
                hasDiff: true,
                fieldSize: 'sm' as const
            },
            {
                hasDiff: true,
                fieldSize: 'md' as const
            }
        ],
        [
            {
                hasDiff: true,
                fieldSize: 'sm' as const
            },
            {
                hasDiff: true,
                fieldSize: 'md' as const
            }
        ]
    ]
}

export const ExpVettingMerchForms = ({experience}: {experience: RemapExperienceVetting}) => {
    const user = useAuthStore(state => state.user)
    const isReadOnlyUser = user?.role === 'read_only'
    const {t} = useTranslation()
    const {
        aiResponse,
        isLoading: isAiLoading,
        onMerchCopyAiCall,
        resetAiResponse
    } = useMerchCopyAi<ExperienceVettingMerchOutputSchema>()
    const isApprovalRequired =
        experience.status === 'archived_pending_host_approval' ||
        experience.status === 'archived_update_required' ||
        experience.status === 'approval_required' ||
        experience.status === 'update_required'
    const isExperienceApproved =
        experience.status === 'publishing_enabled' ||
        experience.status === 'pending_publishing' ||
        experience.status === 'pending_export' ||
        experience.status === 'approved'
    const isReviewHostEdits = experience.isHostModified
    const isFormReadOnly = isExperienceApproved || isApprovalRequired || isReadOnlyUser
    //prompt controls
    const [currentPrompt, setCurrentPrompt] = useState<string>(EXPERIENCE_VETTING_PROMPT)
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    //submit utils
    const [isSubmitMerchModalOpen, setIsSubmitMerchModalOpen] = useState(false)
    const [requireHostApproval, setRequireHostApproval] = useState(true)

    const {mutate: submitExperienceVetting, isPending: isSubmitting} = useSubmitExperienceVetting(
        {experienceId: experience.id},
        {
            onSuccess: () => {
                toast.success(t('vetting:merch:experience:submit_success'))
            }
        }
    )

    //trigger ai-improvement submission
    const handleAiSubmission: SubmitHandler<ExperienceShellMerchOutputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }
    //final submission
    const handleFinalSubmission: SubmitHandler<ExperienceVettingMerchOutputSchema> = data => {
        const payload = {
            ...data,
            isHostApprovalNeeded: requireHostApproval,
            isWaitlisted: false
        }
        //TODO: FINAL SUBMIT TO SEND THE IMPROVED CONTENT TO HOST
        submitExperienceVetting(payload)
    }

    // // Memoize the input form default values
    const inputFormDefaultValues = useMemo(() => remapExperienceMerchCopyInputForm(experience), [experience])
    const inputFormHostEditsDefaultValues = useMemo(
        () => remapExperienceMerchCopyOutputFormHostEdits(experience),
        [experience, isReviewHostEdits]
    )
    // Memoize the output form default values
    const outputFormDefaultValues = useMemo(
        () =>
            aiResponse
                ? (aiResponse as ExperienceVettingMerchOutputSchema)
                : remapExperienceMerchCopyOutputForm(experience),
        [experience, aiResponse]
    )
    // Memoize the diffs map
    const experienceDiffsMap = useMemo(() => remapMerchCopyHostEditsDiffs(experience), [experience])

    return (
        <StyledServiceMerchandisingVettingWrapper fullWidth>
            <OpsVettingHero fullWidth justify="space-between" align="center">
                <h3>{t('vetting:merch:service:title')}</h3>
                {!isFormReadOnly && (
                    <Flexbox gap={2}>
                        {!isReviewHostEdits && !isApprovalRequired && (
                            <>
                                <AiPromptModal
                                    trigger={
                                        <Button variant="tertiary" size="sm" disabled={isAiLoading}>
                                            <Settings01Icon />
                                            {t('vetting:merch:commons:customize_prompt')}
                                        </Button>
                                    }
                                    currentPrompt={currentPrompt}
                                    onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                    isLoading={isAiLoading}
                                    promptType="exp_vetting"
                                />
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => aiSubmissionRef.current?.click()}
                                    disabled={isAiLoading}
                                >
                                    {isAiLoading ? <Spinner size={16} /> : <Stars01Icon />}
                                    {t('vetting:merch:commons:improve_with_ai')}
                                </Button>
                            </>
                        )}
                        <Button
                            variant="primary"
                            size="sm"
                            disabled={isAiLoading}
                            onClick={() => setIsSubmitMerchModalOpen(true)}
                        >
                            {t('vetting:merch:commons:submit')}
                        </Button>

                        {isSubmitMerchModalOpen && (
                            <ExperienceVettingMerchSubmitModal
                                isSubmitting={isAiLoading || isSubmitting}
                                type={isReviewHostEdits ? 'host-edits' : 'ai'}
                                onClose={() => setIsSubmitMerchModalOpen(false)}
                                submissionRef={finalSubmitRef}
                                setRequireHostApprovalCb={setRequireHostApproval}
                                isOpen={isSubmitMerchModalOpen}
                            />
                        )}
                    </Flexbox>
                )}
            </OpsVettingHero>

            <StyledServiceVettingFormsWrapper fullWidth>
                {isReviewHostEdits ? (
                    <ExpVettingMerchOutputForm
                        isOriginalContent={true}
                        isReadOnly={isFormReadOnly}
                        isHostEdits={isReviewHostEdits}
                        defaultValues={inputFormHostEditsDefaultValues}
                        finalSubmitRef={aiSubmissionRef}
                        handleFinalSubmissionCb={undefined}
                    />
                ) : (
                    <ExpShellMerchOutputForm
                        isOriginalContent={true}
                        isReadOnly={isFormReadOnly}
                        isHostEdits={isReviewHostEdits}
                        isLoading={isSubmitting}
                        defaultValues={inputFormDefaultValues}
                        finalSubmitRef={aiSubmissionRef}
                        handleFinalSubmissionCb={handleAiSubmission}
                    />
                )}

                {isExperienceApproved || isReviewHostEdits ? (
                    <ExperienceVettingMerchCopyDiffs experienceDiffsMap={experienceDiffsMap} />
                ) : (
                    <Divider direction="vertical" />
                )}
                <ExpVettingMerchOutputForm
                    isHostEdits={isReviewHostEdits}
                    isReadOnly={isFormReadOnly}
                    isLoading={isAiLoading || isSubmitting}
                    defaultValues={outputFormDefaultValues}
                    finalSubmitRef={finalSubmitRef}
                    handleFinalSubmissionCb={handleFinalSubmission}
                />
            </StyledServiceVettingFormsWrapper>
        </StyledServiceMerchandisingVettingWrapper>
    )
}
ExpVettingMerchForms.displayName = 'ExpVettingMerchForms'
