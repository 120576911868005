import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {CSSProperties} from 'react'
import styled, {css} from 'styled-components'
import {InputText} from '@components/commons/input-text/InputText.tsx'

export const StyledContent = styled.main<{$gap?: number; $fullWidth?: boolean}>(
    ({theme: {spacing}, $gap, $fullWidth = false}) => css`
        padding: ${spacing * 8}px;
        height: 100%;
        overflow: auto;
        & > div {
            display: flex;
            flex-direction: column;
            gap: ${spacing * ($gap ?? 12)}px;
            width: ${$fullWidth ? '100%' : '600px'};
            max-width: 100%;
            margin: 0 auto;
        }
    `
)

export const StyledFooter = styled.footer<{$justify: CSSProperties['justifyContent']}>(
    ({theme: {spacing, palette}, $justify}) => css`
        display: flex;
        justify-content: ${$justify ?? 'space-between'};
        align-items: center;
        padding: ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral[300]};
    `
)

export const StyledTitle = styled.h1(
    ({theme: {typography}}) => css`
        ${typography.displayXs};
        font-weight: 500;
    `
)

export const StyledSectionDescription = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
    `
)

export const StyledFieldTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
        & a {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)

export const StyledColumnFields = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
    `
)

export const StyledYesNoFieldTitle = styled.h4(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        & b {
            font-weight: 500;
        }
    `
)

export const StyledYesNoFieldDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
        & b {
            font-weight: 500;
        }
    `
)

export const StyledYesNoFieldAnswerItem = styled(AnswerItem)`
    width: 60px;
    text-align: center;
`

export const StyledInputPrice = styled(InputText)(
    ({theme: {spacing}}) => css`
        width: 100%;
        & div {
            gap: ${2.5 * spacing}px;
        }
        & input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        & input[type='number'] {
            -moz-appearance: textfield;
        }
    `
)
