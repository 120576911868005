import {Dialog, DialogHeading, DialogDescription} from '@ariakit/react'
import {CSSProperties} from 'react'
import styled, {css} from 'styled-components'

export const StyledDialog = styled(Dialog)<{$width?: CSSProperties['width']}>(
    ({$width, theme: {palette, zIndex, shadows, spacing}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${$width};
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow-y: auto;
        box-shadow: ${shadows.lg};
        color: ${palette.neutral[900]};
    `
)

export const StyledDialogHeading = styled(DialogHeading)(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledDialogDescription = styled(DialogDescription)(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)

export const StyledDialogHeader = styled.div(
    ({theme: {spacing, palette}}) => css`
        display: grid;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        border-bottom: 1px solid ${palette.neutral[300]};
    `
)

export const StyledDialogBody = styled.div<{$gap: number}>(
    ({theme: {spacing}, $gap}) => css`
        padding: ${spacing * 6}px;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * $gap}px;
    `
)

export const StyledDialogFooter = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral[300]};
        display: flex;
        justify-content: space-between;
    `
)
