import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const PrivateRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const location = useLocation()

    return accessToken ? (
        <Outlet />
    ) : (
        <Navigate
            to={{
                pathname: routes.LOGIN.path,
                search:
                    location.pathname != routes.WELCOME.path
                        ? `redirect_to=${location.pathname}${location.search}`
                        : undefined
            }}
            replace
        />
    )
}
