import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpRemoveExperienceItemFlags,
    HttpEditExperienceItemFlags
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience, ExperienceFlag} from '@/features/experience-shell/types.ts'

export const useRemoveExperienceItineraryImageFlags = (
    urlParams: Omit<HttpEditExperienceItemFlags['urlParams'], 'section' | 'item' | 'itemId'> & {
        itineraryId: number
    },
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ExperienceFlag[]; itemId: number}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpRemoveExperienceItemFlags({
                urlParams: {...urlParams, section: 'galleries', item: 'images', itemId: payload.itemId},
                payload: {flagIds: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        itineraries: previousData.itineraries.map(itinerary => {
                            if (itinerary.id == urlParams.itineraryId) {
                                return {
                                    ...itinerary,
                                    coverImage: itinerary.coverImage
                                        ? {
                                              ...itinerary.coverImage,
                                              flags: itinerary.coverImage.flags.filter(
                                                  flag =>
                                                      !variables.flags.some(flagToRemove => flagToRemove.id === flag.id)
                                              )
                                          }
                                        : null
                                }
                            }
                            return itinerary
                        })
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
