import {useDropzone} from 'react-dropzone'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {useUploadPhotos} from '@hooks/useUploadPhotos.ts'
import {GalleryItem, useExperienceShellPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {httpExperienceShellConfirmUploadPhoto} from '@/features/experience-shell/http/experienceShell.http.ts'
import {useBulkPresignedExperienceShellPhotos} from '@/features/experience-shell/queries/useBulkPresignedExperienceShellPhotos.ts'
import {useState} from 'react'
import {FileToUpload} from '@/types/commons.ts'
import {
    StyledGalleryDropzone,
    StyledGalleryGrid,
    StyledGalleryTrigger
} from '@/features/experience-shell/components/photos-step-gallery/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Image01Icon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {PhotosStepGalleryItem} from '@/features/experience-shell/components/photos-step-gallery-item/PhotosStepGalleryItem.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'

const MAX_FILE_TO_UPLOAD = 25
export const PhotoStepGallery = ({experienceId, galleryId}: {experienceId: number; galleryId: number}) => {
    const {t} = useTranslation()
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
    const setUploadingItemsStatus = useExperienceShellPhotoUpload(store => store.setUploadingItemsStatus)
    const addUploadProgressValues = useExperienceShellPhotoUpload(store => store.addUploadProgressValues)
    const addGalleryItems = useExperienceShellPhotoUpload(store => store.addGalleryItems)
    const galleryItems = useExperienceShellPhotoUpload(store => store.galleryItems)
    const galleryCounter = useExperienceShellPhotoUpload(store => store.galleryCounter)
    const setGalleryItemUrl = useExperienceShellPhotoUpload(store => store.setGalleryItemUrl)
    const queryClient = useQueryClient()

    const uploadPhotos = useUploadPhotos({
        storeAction: {setUploadingItemsStatus, addUploadProgressValues},
        onConfirm: async imageId => {
            const response = await httpExperienceShellConfirmUploadPhoto({
                experienceId,
                galleryId,
                mediaId: imageId
            })
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_SHELL_GALLERY_IMAGES]})

            setGalleryItemUrl({id: response.id, url: response.url})
            setUploadingItemsStatus({id: response.id, status: 'uploaded'})
        }
    })

    const bulkPresignedQuery = useBulkPresignedExperienceShellPhotos({
        urlParams: {experienceId, galleryId},
        options: {
            onSuccess: data => {
                const galleryItems: GalleryItem[] = data.map((item, index) => ({
                    id: item.media.id,
                    url: URL.createObjectURL(acceptedFiles[index]),
                    status: 'pending',
                    name: item.media.name,
                    thumbnails: null
                }))
                const uploadingItems: FileToUpload[] = data.map((item, index) => ({
                    id: item.media.id,
                    presignedUrl: item.presignedUrl,
                    file: acceptedFiles[index]
                }))
                addGalleryItems(galleryItems)
                uploadPhotos(uploadingItems)
            }
        }
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    return (
        <>
            {galleryItems.length == 0 ? (
                <StyledGalleryDropzone state={dropzoneState}>
                    <StyledGalleryTrigger direction="column" gap={1} align="center" justify="center" fullWidth>
                        <Flexbox direction="column" gap={4} align="center" justify="center" fullWidth>
                            {bulkPresignedQuery.isPending ? <Spinner size={32} /> : <Image01Icon size={32} />}
                            <h3>{t('experience_shell:dropzone_title')}</h3>
                        </Flexbox>
                        <Button variant={'tertiary'}>{t('experience_shell:dropzone_description')}</Button>
                    </StyledGalleryTrigger>
                </StyledGalleryDropzone>
            ) : (
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox fullWidth justify={'space-between'} align={'center'}>
                        <p>{t('experience_shell:x_photos_uploaded', {count: galleryCounter})}</p>
                        <Dropzone state={dropzoneState}>
                            <Button disabled={bulkPresignedQuery.isPending} variant={'secondary'}>
                                {t('commons:upload')}
                                {bulkPresignedQuery.isPending && <Spinner size={32} />}
                            </Button>
                        </Dropzone>
                    </Flexbox>
                    <StyledGalleryGrid>
                        {galleryItems.map(item => (
                            <PhotosStepGalleryItem
                                key={item.id}
                                image={item}
                                galleryId={galleryId}
                                experienceId={experienceId}
                            />
                        ))}
                    </StyledGalleryGrid>
                </Flexbox>
            )}
        </>
    )
}
