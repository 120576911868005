import {
    OpsVettingDisabledContainer,
    OpsVettingGalleryGrid,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useTranslation} from '@/translations/i18n'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {ServiceGalleryImage} from '../../types'
import {useGalleryNeedsReviewShortcuts} from '@/features/photoset/hooks/useGalleryNeedsReviewShortcuts'
import {ServiceVettingCriteriaRejectedImageItem} from '../service-vetting-criteria-rejected-image-item/ServiceVettingCriteriaRejectedImageItem'

interface ServiceVettingCriteriaRejectedImagesProps {
    rejectedImages: ServiceGalleryImage[]
    hideAction: boolean
    isExpertiseDeclined: boolean
    galleryId: number
}

export const ServiceVettingCriteriaRejectedImages: FC<ServiceVettingCriteriaRejectedImagesProps> = ({
    rejectedImages,
    hideAction,
    isExpertiseDeclined,
    galleryId
}) => {
    const {t} = useTranslation()
    const [expandedImageIndex, setExpandedImageIndex] = useState<number | null>(null)
    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const errors = useServiceVettingError(state => state.errors)

    const handleClickExpandedImage = (imageId: ServiceGalleryImage['id']) => {
        const index = rejectedImages.findIndex(image => image.id === imageId)
        setExpandedImageIndex(index)
        setShowGalleryModal(true)
    }

    const handleCloseModal = () => {
        setShowGalleryModal(false)
        setExpandedImageIndex(null)
    }

    const {palette} = useTheme()

    useGalleryNeedsReviewShortcuts({
        selectableImages: rejectedImages,
        currentDetailImageIndex: expandedImageIndex || 0,
        setCurrentDetailImageIndex: setExpandedImageIndex,
        onClose: handleCloseModal
    })

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <OpsVettingSectionTitle>{t('vetting:criteria:commons:rejected_images:title')}</OpsVettingSectionTitle>
                <Flexbox gap={4} align={'center'}>
                    {errors.rejectedImagesErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
                    <OpsVettingParagraph>
                        {rejectedImages.filter(image => image.flags.length === 0).length > 0 ? (
                            t('vetting:criteria:commons:rejected_images:images_remaining_flags', {
                                count: rejectedImages.filter(image => image.flags.length === 0).length
                            })
                        ) : (
                            <Flexbox render={'span'} gap={1}>
                                <CheckIcon size={20} color={palette.success[600]} />
                                {t('vetting:criteria:commons:rejected_images:images_remaining_no_flags')}
                            </Flexbox>
                        )}
                    </OpsVettingParagraph>
                </Flexbox>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingDisabledContainer $isDisabled={isExpertiseDeclined}>
                <OpsVettingGalleryGrid>
                    {rejectedImages.map((image, index) => (
                        <ServiceVettingCriteriaRejectedImageItem
                            image={image}
                            key={index}
                            galleryId={galleryId}
                            hideAction={hideAction}
                            onClickExpand={() => handleClickExpandedImage(image.id)}
                        />
                    ))}
                </OpsVettingGalleryGrid>
            </OpsVettingDisabledContainer>

            {showGalleryModal && expandedImageIndex !== null && (
                <GalleryModal
                    currentImage={rejectedImages[expandedImageIndex]}
                    thumbnails={{
                        l: rejectedImages[expandedImageIndex].url || '',
                        m: '',
                        s: '',
                        xs: ''
                    }}
                    onClose={handleCloseModal}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={rejectedImages[expandedImageIndex]}
                            imagesCount={rejectedImages.length}
                            onClose={handleCloseModal}
                        />
                    }
                    thumbnailsSlot={
                        <GalleryModalThumbnails
                            currentImageId={rejectedImages[expandedImageIndex].id}
                            thumbnails="thumbnails"
                            images={rejectedImages.map(image => ({
                                id: image.id,
                                name: image.name,
                                thumbnails: {
                                    l: image.url || '',
                                    m: image.url || '',
                                    s: image.url || '',
                                    xs: image.url || ''
                                }
                            }))}
                            onSelect={(_, index) => {
                                setExpandedImageIndex(index)
                            }}
                        />
                    }
                />
            )}
        </OpsVettingSectionWrapper>
    )
}
