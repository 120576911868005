import {ServiceMerchandisingInputSchema} from '../service-vetting/formSchemas'
import {
    ExperienceMerchandisingInputSchema,
    ExperienceShellMerchOutputSchema,
    ExperienceVettingMerchOutputSchema
} from '../experience-vetting/formSchemas'

/**
 * Generates a prompt for merchandising copy AI by replacing a placeholder with the stringified data
 * @param data - The data to be used in the prompt
 * @param prompt - The prompt to be used in the AI call
 * @returns The prompt with the data replaced
 */
export const generateMerchCopyAiPrompt = (
    data:
        | ServiceMerchandisingInputSchema
        | ExperienceMerchandisingInputSchema
        | ExperienceShellMerchOutputSchema
        | ExperienceVettingMerchOutputSchema,
    prompt: string
) => {
    return prompt.replace('[$FormInputData]', JSON.stringify(data, null, 2))
}

/**
 * Parses markdown text to JSON
 * @param markdownText - The markdown text to be parsed
 * @returns The parsed JSON
 */
export const parseMarkdownJson = (markdownText: string) => {
    try {
        // First, try to match JSON inside markdown code blocks
        const codeBlockMatch = markdownText.match(/```(?:json|JSON)?\s*([\s\S]*?)\s*```/)

        if (codeBlockMatch && codeBlockMatch[1]) {
            // Found JSON in a code block
            return JSON.parse(codeBlockMatch[1])
        }

        // If no code block match, try parsing the text directly as JSON
        try {
            return JSON.parse(markdownText)
        } catch (directParseError) {
            // If direct parsing fails, the input might be malformed
            throw new Error('Could not parse as direct JSON')
        }
    } catch (err) {
        console.error('JSON parsing error:', err)
        throw new Error('Failed to parse JSON from the provided text')
    }
}
