import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {Radio} from '@/components/commons/radio/Radio'
import {Button} from '@/components/ui/button/Button'
import {Dialog} from '@/components/ui/dialog-atoms/DialogAtoms'
import styled, {css} from 'styled-components'

export const StyledRadioAnswers = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: ${spacing * 3}px;
        & > * {
            text-align: center;
        }
    `
)

export const StyledAccessibilityFeatureAdded = styled.div(
    ({theme: {palette}}) => css`
        color: ${palette.neutral[700]};
    `
)

export const StyledCancellationAnswerItem = styled(AnswerItem)`
    font-weight: 400;
`

export const StyledDialog = styled(Dialog)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledAccessibilityCheckbox = styled.div<{$hasIcon?: boolean}>(
    ({theme: {spacing, palette, typography}, $hasIcon = true}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: ${$hasIcon ? 'auto 1fr auto' : '1fr auto'};
        gap: ${spacing * 4}px;
        & > div {
            display: flex;
            flex-direction: column;
            gap: ${spacing}px;
        }
        & svg {
            width: 24px;
            height: 24px;
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral[700]};
        }
    `
)

export const StyledItemField = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 40px;
        gap: ${spacing * 4}px;
        align-items: end;
    `
)

export const StyledPrimaryLanguageButton = styled(Button)(
    () => css`
        justify-content: space-between;
    `
)

export const StyledLanguageRadio = styled(Radio)(
    () => css`
        & label {
            width: 100%;
        }
    `
)
