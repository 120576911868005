import {CSSProperties, FC, forwardRef, HTMLAttributes, ReactNode} from 'react'
import {
    StyledColumnFields,
    StyledContent,
    StyledFieldTitle,
    StyledFooter,
    StyledInputPrice,
    StyledSectionDescription,
    StyledTitle,
    StyledYesNoFieldAnswerItem,
    StyledYesNoFieldDescription,
    StyledYesNoFieldTitle
} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from '@/translations/i18n'
import {InputTextProps} from '@components/commons/input-text/InputText.tsx'

export const Footer = ({
    justify,
    ...rest
}: HTMLAttributes<HTMLDivElement> & {justify?: CSSProperties['justifyContent']}) => (
    <StyledFooter $justify={justify} {...rest} />
)

export const Title = (props: HTMLAttributes<HTMLHeadElement>) => <StyledTitle {...props} />

export const SectionDescription = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledSectionDescription {...props} />
)

export const Content = ({
    children,
    gap,
    title,
    fullWidth,
    titleEndSlot,
    ...rest
}: HTMLAttributes<HTMLDivElement> & {
    gap?: number
    title: string
    fullWidth?: boolean
    titleEndSlot?: ReactNode
}) => (
    <StyledContent $gap={gap} $fullWidth={fullWidth} {...rest}>
        <div>
            <Flexbox justify="space-between" align="center">
                <Title>{title}</Title>
                {titleEndSlot}
            </Flexbox>
            {children}
        </div>
    </StyledContent>
)

export const ColumnFields = (props: HTMLAttributes<HTMLDivElement>) => <StyledColumnFields {...props} />

interface YesNoFieldProps {
    direction?: 'row' | 'column'
    labelTitle?: ReactNode
    labelDescription?: ReactNode
    name?: string
    value?: boolean
    onChange: (value: boolean) => void
}
export const YesNoField: FC<YesNoFieldProps> = ({direction, labelTitle, labelDescription, name, onChange, value}) => {
    const {t} = useTranslation()
    return (
        <Flexbox
            direction={direction}
            justify="space-between"
            align={direction == 'column' ? 'flex-start' : 'center'}
            gap={3}
            fullWidth
        >
            <Flexbox direction="column" gap={1.5}>
                {labelTitle && <StyledYesNoFieldTitle>{labelTitle}</StyledYesNoFieldTitle>}
                {labelDescription && <StyledYesNoFieldDescription>{labelDescription}</StyledYesNoFieldDescription>}
            </Flexbox>
            <Flexbox gap={3}>
                <StyledYesNoFieldAnswerItem
                    inputSize="sm"
                    type="radio"
                    value="yes"
                    name={name}
                    checked={value}
                    onChange={() => onChange(true)}
                    label={t('experience_shell:yes')}
                />
                <StyledYesNoFieldAnswerItem
                    inputSize="sm"
                    type="radio"
                    value="no"
                    name={name}
                    checked={value != null && !value}
                    onChange={() => onChange(false)}
                    label={t('experience_shell:no')}
                />
            </Flexbox>
        </Flexbox>
    )
}

interface FieldsetProps {
    title?: string
    children: ReactNode
    gap?: number
    contentGap?: number
}
export const Fieldset: FC<FieldsetProps> = ({title, gap = 5, contentGap = 4, children}) => (
    <Flexbox direction="column" gap={gap} fullWidth>
        {!!title && <FieldTitle>{title}</FieldTitle>}
        <Flexbox direction="column" gap={contentGap} fullWidth>
            {children}
        </Flexbox>
    </Flexbox>
)

export const FieldTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledFieldTitle {...props} />

export const InputPrice = forwardRef<HTMLInputElement, Omit<InputTextProps, 'type'>>((props, ref) => (
    <StyledInputPrice
        type="number"
        inputSize="sm"
        step="0.01"
        inputMode="decimal"
        typeIcon={<span>USD</span>}
        onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
        {...props}
        ref={ref}
    />
))
