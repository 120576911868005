import {useTranslation} from '@/translations/i18n'
import {Content, Footer} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {StepKeyName} from '../../types'
import {useChangeStep} from '@/features/experience-shell/hooks/useChangeStep'
import {FC} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {SizeSection} from './SizeSection'
import {MinAgeSection} from './MinAgeSection'
import {AccessibilityFeatureSection} from './AccessibilityFeatureSection'
import {ActivityLevelSection} from './ActivityLevelSection'
import {SkillLevelSection} from './SkillLevelSection'
import {CancellationPolicySection} from './CancellationPolicySection'
import {GuestNeedBringSomethingStep} from './GuestNeedBringSomethingStep'
import {AvailabilitySection} from './AvailabilitySection'
import {LanguagesSection} from './LanguagesSection'
import {checkIsLegalLocked} from '../../utils'
import {useWatch} from 'react-hook-form'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'

const previousStep = StepKeyName.enum.pricing_discounts

export const SettingsStep: FC<{experienceId: number}> = ({experienceId}) => {
    const {t} = useTranslation()
    const {control} = useExperienceShellFormContext()
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.settings
    })
    const experienceCategoryWatch = useWatch({control, name: 'experience.categoryKeyName'})
    const nextStep = checkIsLegalLocked(experienceCategoryWatch) ? StepKeyName.enum.experience : StepKeyName.enum.legal

    return (
        <>
            <Content title={t('experience_shell:step_settings')}>
                <Flexbox direction="column" gap={6}>
                    <LanguagesSection experienceId={experienceId} />
                    <Divider direction="horizontal" />
                    <SizeSection />
                    <Divider direction="horizontal" />
                    <AvailabilitySection />
                    <Divider direction="horizontal" />
                    <GuestNeedBringSomethingStep />
                    <Divider direction="horizontal" />
                    <MinAgeSection />
                    <Divider direction="horizontal" />
                    <AccessibilityFeatureSection experienceId={experienceId} />
                    <Divider direction="horizontal" />
                    <ActivityLevelSection />
                    <Divider direction="horizontal" />
                    <SkillLevelSection />
                    <Divider direction="horizontal" />
                    <CancellationPolicySection />
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => changeStep.handleChangeStep({isBack: true, stepToGoTo: previousStep})}
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    onClick={() => changeStep.handleChangeStep({stepToGoTo: nextStep})}
                    disabled={changeStep.isContinueLoading || changeStep.isBackLoading}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
