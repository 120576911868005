import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateExperienceVettingCriteriaItineraryCoveryImageOptions,
    httpUpdateExperienceVettingCriteriaItineraryCoveryImage
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience} from '@/features/experience-shell/types'

export const useUpdateExperienceVettingCriteriaItineraryCoveryImage = (
    {experienceId, itineraryId}: {experienceId: number; itineraryId: number},
    options?: UseMutationOptions<
        Experience,
        unknown,
        HttpUpdateExperienceVettingCriteriaItineraryCoveryImageOptions['payload']
    >
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExperienceVettingCriteriaItineraryCoveryImage({
                urlParams: {experienceId: experienceId, itineraryId: itineraryId},
                payload,
                params: {
                    responseType: 'extended'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
