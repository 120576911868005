import {
    OpsVettingCardBodyContainer,
    OpsVettingCardContainer,
    OpsVettingCardTitle,
    OpsVettingImgPlaceHolder,
    OpsVettingLabel,
    OpsVettingParagraph
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {RemappedOffering} from '@/features/service-vetting/types.ts'
import {formatCurrency} from '@utilities/helpers.ts'
import {AlertTriangleIcon, CheckIcon, Expand01Icon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {useUpdateAdminServiceOfferingStatus} from '@/features/service-vetting/query/useUpdateServiceOfferingsStatus.ts'
import {useAddServiceOfferingAnswerFlags} from '@/features/service-vetting/query/useAddServiceOfferingAnswerFlags.ts'
import {ServiceVettingCriteriaFlags} from '@/features/service-vetting/components/service-vetting-criteria-flags/ServiceVettingCriteriaFlags.tsx'
import {useRemoveServiceOfferingAnswerFlags} from '@/features/service-vetting/query/useRemoveServiceOfferingAnswerFlags.ts'
import {useAddServiceOfferingFlags} from '@/features/service-vetting/query/useAddServiceOfferingFlags.ts'
import {useRemoveServiceOfferingFlags} from '@/features/service-vetting/query/useRemoveServiceOfferingFlags.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {ServiceVettingCriteriaEditOfferingPhotoButton} from '@/features/service-vetting/components/service-vetting-criteria-edit-offering-photo-button/ServiceVettingCriteriaEditOfferingPhotoButton.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useRestoreMainPhotoOffering} from '@/features/service-vetting/query/useRestoreMainPhotoOffering.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {StyledTooltipContent} from '@/features/service-vetting/components/service-vetting-criteria-offering-card/style.ts'
import {VettingSectionStatusSelector} from '@/features/ops-vetting/components/vetting-section-status-selector/VettingSectionStatusSelector.tsx'
import {useState} from 'react'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {useHotkeys} from 'react-hotkeys-hook'

export const ServiceVettingCriteriaOfferingCard = ({
    offering,
    galleryId,
    index,
    hideAction
}: {
    offering: RemappedOffering
    index: number
    hideAction?: boolean
    galleryId: number
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const updateOfferingStatus = useUpdateAdminServiceOfferingStatus({
        serviceId: offering.submission_id,
        offeringId: offering.id
    })

    const restoreMainPhotoOffering = useRestoreMainPhotoOffering({
        offeringId: offering.id,
        serviceId: offering.submission_id
    })

    const errors = useServiceVettingError(state => state.errors)

    const addOfferingQuestionFlags = useAddServiceOfferingAnswerFlags({
        serviceId: offering.submission_id,
        sectionId: offering.id
    })
    const removeOfferingQuestionFlags = useRemoveServiceOfferingAnswerFlags({
        serviceId: offering.submission_id,
        sectionId: offering.id
    })

    const addOfferingFlags = useAddServiceOfferingFlags({
        serviceId: offering.submission_id,
        itemId: offering.id
    })
    const removeOfferingFlags = useRemoveServiceOfferingFlags({
        serviceId: offering.submission_id,
        itemId: offering.id
    })

    const isBackToDraft = offering.status == 'back_to_draft'
    const isDeclined = offering.status == 'declined'
    const isEditedImage = offering.image_id != offering.original_image_id

    const onClickRestore = () => {
        restoreMainPhotoOffering.mutate()
    }

    useHotkeys('Esc', () => setShowGalleryModal(false), {enabled: showGalleryModal})

    return (
        <OpsVettingCardContainer direction="column" gap={2}>
            <Flexbox fullWidth justify={'space-between'} align={'center'} gap={2}>
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingCardTitle>
                        {t(`vetting:criteria:service:offerings:offering_type:${offering.type}`, {count: index + 1})}
                    </OpsVettingCardTitle>
                    <Tooltip
                        maxWidth={'214px'}
                        content={
                            <StyledTooltipContent>
                                <h4>{t('vetting:criteria:service:offerings:edited_photo_tooltip:title')}</h4>{' '}
                                <p>{t('vetting:criteria:service:offerings:edited_photo_tooltip:description')}</p>
                            </StyledTooltipContent>
                        }
                        triggerProps={{asChild: true}}
                        rootProps={{open: !isEditedImage ? false : undefined}}
                    >
                        <div>
                            <VettingSectionStatusSelector
                                value={offering.status}
                                onChange={status => updateOfferingStatus.mutate({status})}
                                disabled={hideAction || isEditedImage}
                            />
                        </div>
                    </Tooltip>
                </Flexbox>
                {errors.itemErrors
                    .filter(error => error.itemId == offering.id)
                    .map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error.error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
            </Flexbox>
            <OpsVettingCardBodyContainer>
                <Flexbox direction="column" fullWidth gap={2.5}>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:criteria:service:offerings:offering_title')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.title.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingCriteriaFlags
                                flags={offering.question_answer.title.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.title.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.title.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:criteria:service:offerings:price')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {t('vetting:criteria:service:offerings:price_per_guest', {
                                price: formatCurrency(
                                    parseFloat(offering.question_answer.price_per_guest.answer),
                                    offering.question_answer.currency.answer
                                )
                            })}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingCriteriaFlags
                                flags={offering.question_answer.price_per_guest.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.price_per_guest.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.price_per_guest.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    {offering.question_answer.price_per_group && (
                        <Flexbox direction="column" gap={2} fullWidth>
                            <OpsVettingLabel>{t('vetting:criteria:service:offerings:private_group')}</OpsVettingLabel>
                            <Flexbox gap={2} align={'center'}>
                                <CheckIcon size={16} color={palette.success[600]} />

                                <OpsVettingParagraph>
                                    {t('vetting:criteria:service:offerings:price_per_guest', {
                                        price: formatCurrency(
                                            parseFloat(offering.question_answer.price_per_group.answer),
                                            offering.question_answer.currency.answer
                                        )
                                    })}
                                </OpsVettingParagraph>
                            </Flexbox>
                            {isBackToDraft && (
                                <ServiceVettingCriteriaFlags
                                    flags={offering.question_answer.price_per_group.flags}
                                    addFlag={flag =>
                                        offering.question_answer.price_per_group &&
                                        addOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.price_per_group.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        offering.question_answer.price_per_group &&
                                        removeOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.price_per_group.id
                                        })
                                    }
                                    types={['general']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    )}
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:criteria:service:offerings:duration')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.duration.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingCriteriaFlags
                                flags={offering.question_answer.duration.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.duration.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.duration.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:criteria:service:offerings:description')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.description.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingCriteriaFlags
                                flags={offering.question_answer.description.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.description.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.description.id
                                    })
                                }
                                types={['general', 'description']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox fullWidth direction={'column'} gap={2}>
                    {offering.question_answer.main_photo?.answer ? (
                        <>
                            <GalleryImageItem
                                src={offering.question_answer.main_photo.answer}
                                bottomRightSlot={
                                    !hideAction && (
                                        <Button
                                            variant="secondary"
                                            iconOnly
                                            className="show-on-hover"
                                            size="sm"
                                            onClick={() => setShowGalleryModal(true)}
                                        >
                                            <Expand01Icon />
                                        </Button>
                                    )
                                }
                            />
                            {isBackToDraft && (
                                <ServiceVettingCriteriaFlags
                                    flags={offering.question_answer.main_photo.flags}
                                    addFlag={flag =>
                                        offering.question_answer.main_photo &&
                                        addOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.main_photo.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        offering.question_answer.main_photo &&
                                        removeOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.main_photo.id
                                        })
                                    }
                                    types={['gallery_image']}
                                    hideAction={hideAction}
                                />
                            )}
                        </>
                    ) : (
                        <OpsVettingImgPlaceHolder />
                    )}
                    {offering.status == 'accepted' && !hideAction && (
                        <Flexbox justify={'space-between'} fullWidth>
                            <ServiceVettingCriteriaEditOfferingPhotoButton
                                currentSelectedImageId={offering.image_id || undefined}
                                offeringTitle={t(`vetting:criteria:service:offerings:offering_type:${offering.type}`, {
                                    count: index + 1
                                })}
                                offeringId={offering.id}
                                serviceId={offering.submission_id}
                                galleryId={galleryId}
                            />
                            {isEditedImage && (
                                <Button
                                    variant={'tertiary'}
                                    onClick={onClickRestore}
                                    disabled={restoreMainPhotoOffering.isPending}
                                >
                                    {t('vetting:criteria:service:offerings:restore_photo')}
                                    {restoreMainPhotoOffering.isPending && <Spinner size={20} />}
                                </Button>
                            )}
                        </Flexbox>
                    )}
                </Flexbox>
            </OpsVettingCardBodyContainer>
            {isDeclined && (
                <>
                    <Divider direction={'horizontal'} />
                    <ServiceVettingCriteriaFlags
                        flags={offering.flags}
                        addFlag={flag => addOfferingFlags.mutate({flags: [flag]})}
                        removeFlag={flag => removeOfferingFlags.mutate({flags: [flag]})}
                        badgeVariant={'danger'}
                        types={['decline_offering']}
                        hideAction={hideAction}
                    />
                </>
            )}
            {showGalleryModal && offering && (
                <GalleryModal
                    currentImage={{
                        id: offering.image_id || 0,
                        name: ''
                    }}
                    thumbnails={{l: offering.question_answer.main_photo?.answer || '', m: '', s: '', xs: ''}}
                    onClose={() => setShowGalleryModal(false)}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={{
                                id: offering.image_id || 0,
                                name: ''
                            }}
                            onClose={() => setShowGalleryModal(false)}
                        />
                    }
                />
            )}
        </OpsVettingCardContainer>
    )
}
