import {useTranslation} from 'react-i18next'
import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Expand01Icon, ThumbsDownIcon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'
import {memo} from 'react'
import {ExperienceGalleryImage} from '@/features/experience-shell/types.ts'
import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {useUpdateExperienceVettingImageIsApproved} from '@/features/experience-vetting/queries/useUpdateExperienceVettingImageIsApproved.ts'
import {useAddExperienceGalleryImageFlags} from '@/features/experience-vetting/queries/useAddExperienceGalleryImageFlags'
import {useRemoveExperienceGalleryImageFlags} from '@/features/experience-vetting/queries/useRemoveExperienceGalleryImageFlags'
import {ExperienceVettingCriteriaFlags} from '@/features/experience-vetting/components/experience-vetting-criteria-flags/ExperienceVettingCriteriaFlags.tsx'

export const ExperienceVettingCriteriaRejectedImageItem = memo(
    ({
        image,
        hideAction,
        experienceId,
        galleryId,
        onClickExpand
    }: {
        image: ExperienceGalleryImage
        hideAction?: boolean
        experienceId: number
        galleryId: number
        onClickExpand?: () => void
    }) => {
        const {t} = useTranslation()

        const updateExperienceVettingImageIsApproved = useUpdateExperienceVettingImageIsApproved({
            experienceId: experienceId,
            galleryId: galleryId,
            imageId: image.id
        })

        const addExperienceGalleryImageFlags = useAddExperienceGalleryImageFlags({
            experienceId: experienceId,
            sectionId: galleryId
        })
        const removeExperienceGalleryImageFlags = useRemoveExperienceGalleryImageFlags({
            experienceId: experienceId,
            sectionId: galleryId
        })

        return (
            <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                <GalleryImageItem
                    src={image.thumbnails?.xs || image.url}
                    bottomRightSlot={
                        !hideAction && (
                            <Button
                                className="show-on-hover"
                                variant="secondary"
                                iconOnly
                                size="sm"
                                onClick={onClickExpand}
                            >
                                <Expand01Icon />
                            </Button>
                        )
                    }
                />
                <Flexbox gap={2} direction="column" fullWidth>
                    <OpsVettingImageTitle>{image.name}</OpsVettingImageTitle>
                    <Flexbox gap={3} fullWidth>
                        <Button
                            variant={image.isApproved === true ? 'primarySuccess' : 'secondary'}
                            fullWidth
                            size={'sm'}
                            disabled={hideAction}
                            onClick={() => updateExperienceVettingImageIsApproved.mutate({isApproved: true})}
                        >
                            <ThumbsUpIcon />
                            {t('vetting:criteria:service:gallery:accept')}
                        </Button>
                        <Button
                            variant={image.isApproved === false ? 'primaryDanger' : 'secondary'}
                            fullWidth
                            size={'sm'}
                            disabled={hideAction}
                            onClick={() => updateExperienceVettingImageIsApproved.mutate({isApproved: false})}
                        >
                            <ThumbsDownIcon />
                            {t('vetting:criteria:service:gallery:reject')}
                        </Button>
                    </Flexbox>
                    {image.isApproved == false && (
                        <ExperienceVettingCriteriaFlags
                            flags={image.flags}
                            addFlag={flag => addExperienceGalleryImageFlags.mutate({flags: [flag], itemId: image.id})}
                            removeFlag={flag =>
                                removeExperienceGalleryImageFlags.mutate({flags: [flag], itemId: image.id})
                            }
                            scopes={['photo_gallery']}
                            hideAction={hideAction}
                        />
                    )}
                </Flexbox>
            </OpsVettingGalleryItemsContainer>
        )
    }
)
