import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetImagesMetadata,
    HttpGetImagesMetadataOptions,
    HttpGetImagesMetadataResponse
} from '@/features/photoset/http/photoset.http'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetImagesMetadata = (
    promiseArgs: HttpGetImagesMetadataOptions,
    options?: Omit<UseQueryOptions<HttpGetImagesMetadataResponse>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.IMAGE_METADATA, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetImagesMetadata(promiseArgs),
                responseShape: HttpGetImagesMetadataResponse,
                onZodError: captureException
            })
    })
