import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {
    httpGetServiceGalleryImages,
    HttpGetServiceGalleryImagesOptions,
    HttpGetServiceGalleryImagesResponse
} from '@/features/service-vetting/http/servicesVetting.http.ts'

export const useGetGalleryImages = (
    urlParams: HttpGetServiceGalleryImagesOptions['urlParams'],
    options?: Omit<UseQueryOptions<HttpGetServiceGalleryImagesResponse>, 'queryFn' & 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServiceGalleryImages({
                    urlParams,
                    params: {response_type: 'extended'}
                }),
                responseShape: HttpGetServiceGalleryImagesResponse,
                onZodError: captureException
            })
    })
}
