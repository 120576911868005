import {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {APP_LANGUAGES, changeAppLanguage} from '@/translations/i18n'
import {StyledEmptyState, StyledInputText, StyledLangModal, StyledLangModalHeader, StyledLangModalList} from './style'
import {Modal} from '@/components/ui/modal/Modal'
import {SearchLgIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTheme} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface LangModalProps {
    onCloseCb: () => void
}

export const LangSelectionModal: FC<LangModalProps> = ({onCloseCb}) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    const theme = useTheme()
    const currentLanguage = APP_LANGUAGES.find(lang => lang.code == language) ?? APP_LANGUAGES[0]
    const [search, setSearch] = useState('')

    const languagesList = useMemo(() => {
        return [currentLanguage, ...APP_LANGUAGES.filter(lang => lang.code != currentLanguage.code)].filter(
            lang =>
                lang.label.toLowerCase().includes(search.toLowerCase()) ||
                lang.code.toLowerCase().includes(search.toLowerCase())
        )
    }, [currentLanguage, search])

    return (
        <Modal width="850px" onOverlayClick={onCloseCb}>
            <StyledLangModal direction="column">
                <StyledLangModalHeader align="center">
                    <Flexbox align="center" gap={2}>
                        <Button size="lg" iconOnly variant="ghost" onClick={onCloseCb}>
                            <XCloseIcon />
                        </Button>
                        <h3>{t('commons:select_lang_title')}</h3>
                    </Flexbox>

                    {APP_LANGUAGES.length > 12 && (
                        <StyledInputText
                            inputSize="sm"
                            placeholder={String(t('commons:search_languages'))}
                            type="text"
                            typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['500']} />}
                            onChange={event => setSearch(event.target.value)}
                        />
                    )}
                </StyledLangModalHeader>

                {languagesList.length >= 1 ? (
                    <StyledLangModalList>
                        {languagesList.map(lang => (
                            <button
                                className={lang.code == currentLanguage.code ? 'current-language' : ''}
                                onClick={() => changeAppLanguage(lang.code)}
                                key={lang.code}
                            >
                                {lang.label}
                            </button>
                        ))}
                    </StyledLangModalList>
                ) : (
                    <StyledEmptyState justify="center" align="center">
                        {t('commons:no_language_results')}
                    </StyledEmptyState>
                )}
            </StyledLangModal>
        </Modal>
    )
}

LangSelectionModal.displayName = 'LangSelectionModal'
