import {useTranslation} from '@/translations/i18n'
import {DialogStore} from '@ariakit/react'
import {
    Dialog,
    DialogDismiss,
    DialogFooter,
    DialogHeader,
    DialogHeading,
    DialogProvider
} from '@components/ui/dialog-atoms/DialogAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledSubmissionDialogBody} from '../experience-vetting-merch-submit-modal/style'
import {useSubmitExperienceVetting} from '@/features/experience-vetting/queries/useSubmitExperienceVetting'
import {Spinner} from '@components/ui/spinner/Spinner'
import toast from 'react-hot-toast'
export const ExpVettingCriteriaConfirmationModal = ({
    store,
    isWaitlisted,
    description,
    experienceId,
    toastMessage
}: {
    store: DialogStore
    isWaitlisted: boolean
    description: string
    experienceId: number
    toastMessage: string
}) => {
    const {t} = useTranslation()

    const submitQuery = useSubmitExperienceVetting(
        {experienceId},

        {
            onSuccess: () => {
                toast.success(toastMessage)
                store.toggle()
            }
        }
    )

    const handleSubmit = () => {
        submitQuery.mutate({isWaitlisted, isHostApprovalNeeded: false})
    }
    return (
        <DialogProvider store={store}>
            <Dialog width={'480px'} hideOnInteractOutside={!submitQuery.isPending}>
                <DialogHeader>
                    <DialogDismiss disabled={submitQuery.isPending} />
                    <DialogHeading>{t('vetting:merch:commons:submission_modal_header')}</DialogHeading>
                </DialogHeader>
                <StyledSubmissionDialogBody gap={2}>
                    <h3>{description}</h3>
                    <p>{t('vetting:merch:service:confirmation_ai_modal:description')}</p>
                </StyledSubmissionDialogBody>
                <DialogFooter>
                    <Button variant={'tertiary'} onClick={() => store.toggle()} disabled={submitQuery.isPending}>
                        {t('commons:cancel')}
                    </Button>

                    <Button variant={'primary'} onClick={handleSubmit} disabled={submitQuery.isPending}>
                        {t('commons:confirm')}
                        {submitQuery.isPending && <Spinner size={20} />}
                    </Button>
                </DialogFooter>
            </Dialog>
        </DialogProvider>
    )
}
