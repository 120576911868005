import {useTranslation} from '@/translations/i18n'
import {Fieldset, SectionDescription} from '../atoms/Atoms'
import {StyledLanguageRadio, StyledPrimaryLanguageButton} from './style'
import {ChevronRightIcon} from '@/components/ui/icon/Icon'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {useWatch} from 'react-hook-form'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {
    DialogProvider,
    DialogDisclosure,
    Dialog,
    DialogHeader,
    DialogDismiss,
    DialogHeading,
    DialogFooter,
    DialogBody
} from '@/components/ui/dialog-atoms/DialogAtoms'
import {Button} from '@/components/ui/button/Button'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {useState} from 'react'
import {useEditExperience} from '@/features/experience-shell/queries/useEditExperience.ts'
import {useDialogStore} from '@ariakit/react'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const SUPPORTED_LANGUAGES = [
    {
        label: 'Català',
        value: 'ca'
    },
    {
        label: 'Deutsch',
        value: 'de'
    },
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Español',
        value: 'es'
    },
    {
        label: 'Français',
        value: 'fr'
    },
    {
        label: 'Italiano',
        value: 'it'
    },
    {
        label: '日本語',
        value: 'ja'
    },
    {
        label: '한국어',
        value: 'ko'
    },
    {
        label: 'Português',
        value: 'pt'
    },
    {
        label: 'Русский',
        value: 'ru'
    },
    {
        label: '中文 (简体)',
        value: 'zh'
    }
] as const

export const LanguagesSection = ({experienceId}: {experienceId: number}) => {
    const {t} = useTranslation()
    const {
        control,
        formState: {errors},
        resetField,
        setValue
    } = useExperienceShellFormContext()
    const mainLanguageDialogStore = useDialogStore()
    const additionalLanguagesDialogStore = useDialogStore()
    const mainLanguageWatch = useWatch({control, name: 'settings.main_language'})
    const additionalLanguagesWatch = useWatch({control, name: 'settings.additional_languages'})
    const [selectedMainLanguage, setSelectedMainLanguage] = useState(mainLanguageWatch)
    const [selectedAdditionalLanguage, setSelectedAdditionalLanguage] = useState(additionalLanguagesWatch)
    const editExperienceMutation = useEditExperience({
        experienceId,
        options: {
            onSuccess: (_, variables) => {
                if ('main_language' in variables) {
                    setValue('settings.main_language', variables.main_language)
                    setValue('settings.additional_languages', [])
                }
                'additional_languages' in variables &&
                    setValue('settings.additional_languages', variables.additional_languages)
                mainLanguageDialogStore.hide()
                additionalLanguagesDialogStore.hide()
            }
        }
    })

    const handleAdditionalLanguageChange = (languageValue: string, checked: boolean) => {
        setSelectedAdditionalLanguage(prev => {
            const currentLanguages = prev || []
            if (checked) {
                return [...currentLanguages, languageValue]
            } else {
                return currentLanguages.filter(value => value !== languageValue)
            }
        })
    }

    return (
        <Fieldset title={t('experience_shell:languages_title')} gap={1.5} contentGap={4}>
            <SectionDescription>{t('experience_shell:languages_description')}</SectionDescription>
            <Flexbox direction={'column'} gap={2} fullWidth>
                <DialogProvider store={mainLanguageDialogStore}>
                    <DialogDisclosure render={<StyledPrimaryLanguageButton variant={'secondary'} fullWidth />}>
                        {SUPPORTED_LANGUAGES.find(language => language.value == mainLanguageWatch)?.label ?? <span />}
                        <ChevronRightIcon />
                    </DialogDisclosure>
                    <Dialog
                        hideOnEscape={!editExperienceMutation.isPending}
                        hideOnInteractOutside={!editExperienceMutation.isPending}
                        width={'680px'}
                        onClose={() => setSelectedMainLanguage(mainLanguageWatch)}
                    >
                        <DialogHeader>
                            <DialogDismiss />
                            <DialogHeading>{t('experience_shell:select_main_language')}</DialogHeading>
                        </DialogHeader>
                        <DialogBody>
                            {SUPPORTED_LANGUAGES.map(language => (
                                <StyledLanguageRadio
                                    key={language.value}
                                    onChange={() => setSelectedMainLanguage(language.value)}
                                    checked={language.value == selectedMainLanguage}
                                    label={language.label}
                                    value={language.value}
                                />
                            ))}
                        </DialogBody>
                        <DialogFooter>
                            <DialogDismiss
                                onClick={() => resetField('settings.main_language')}
                                render={<Button variant="tertiary" />}
                                disabled={editExperienceMutation.isPending}
                            >
                                {t('commons:cancel')}
                            </DialogDismiss>
                            <Button
                                onClick={() => editExperienceMutation.mutate({main_language: selectedMainLanguage})}
                                disabled={editExperienceMutation.isPending}
                            >
                                {t('commons:confirm')}
                                {editExperienceMutation.isPending && <Spinner />}
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </DialogProvider>
                {errors.settings?.main_language && <InputHelpText error={t('errors:field_required')} />}
            </Flexbox>
            <Flexbox direction={'column'} gap={1} fullWidth>
                {SUPPORTED_LANGUAGES.filter(language => additionalLanguagesWatch?.includes(language.value))
                    .map(language => language.label)
                    .join(', ')}
                <DialogProvider store={additionalLanguagesDialogStore}>
                    <DialogDisclosure render={<Button variant={'tertiary'} size="sm" />} disabled={!mainLanguageWatch}>
                        {additionalLanguagesWatch && additionalLanguagesWatch.length >= 1
                            ? t('commons:edit')
                            : t('experience_shell:add_additional_languages')}
                    </DialogDisclosure>
                    <Dialog
                        hideOnEscape={!editExperienceMutation.isPending}
                        hideOnInteractOutside={!editExperienceMutation.isPending}
                        width={'680px'}
                        onClose={() => setSelectedAdditionalLanguage(additionalLanguagesWatch)}
                    >
                        <DialogHeader>
                            <DialogDismiss />
                            <DialogHeading>{t('experience_shell:add_additional_languages')}</DialogHeading>
                        </DialogHeader>
                        <DialogBody>
                            {SUPPORTED_LANGUAGES.filter(language => language.value != mainLanguageWatch).map(
                                language => (
                                    <Checkbox
                                        key={language.value}
                                        checked={selectedAdditionalLanguage?.includes(language.value)}
                                        label={language.label}
                                        value={language.value}
                                        onChange={e => handleAdditionalLanguageChange(language.value, e.target.checked)}
                                    />
                                )
                            )}
                        </DialogBody>
                        <DialogFooter>
                            <DialogDismiss
                                onClick={() => resetField('settings.additional_languages')}
                                render={<Button variant="tertiary" />}
                                disabled={editExperienceMutation.isPending}
                            >
                                {t('commons:cancel')}
                            </DialogDismiss>
                            <Button
                                onClick={() =>
                                    editExperienceMutation.mutate({additional_languages: selectedAdditionalLanguage})
                                }
                                disabled={editExperienceMutation.isPending}
                            >
                                {t('commons:confirm')}
                                {editExperienceMutation.isPending && <Spinner />}
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </DialogProvider>
            </Flexbox>
        </Fieldset>
    )
}
