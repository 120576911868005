import styled, {css} from 'styled-components'

export const StyledOnlineProfileField = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 4}px;
        align-items: end;
    `
)
