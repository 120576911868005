import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpSubmitExperienceVetting,
    HttpSubmitExperienceVettingOptions
} from '@/features/experience-vetting/http/experienceVetting.http.ts'

export const useSubmitExperienceVetting = (
    urlParams: HttpSubmitExperienceVettingOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpSubmitExperienceVettingOptions['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpSubmitExperienceVetting({
                urlParams,
                payload,
                params: {
                    responseType: 'small'
                }
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_VERSIONS]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
