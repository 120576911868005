import {useTranslation} from 'react-i18next'
import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Expand01Icon, ThumbsDownIcon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'
import {ServiceGalleryImage} from '@/features/service-vetting/types.ts'
import {useUpdateIsApprovedGalleryImg} from '@/features/service-vetting/query/useUpdateIsApprovedGalleryImg.ts'
import {useAddServiceGalleryImageFlags} from '@/features/service-vetting/query/useAddServiceGalleryImageFlags.ts'
import {useRemoveServiceGalleryImageFlags} from '@/features/service-vetting/query/useRemoveServiceGalleryImageFlags.ts'
import {ServiceVettingCriteriaFlags} from '@/features/service-vetting/components/service-vetting-criteria-flags/ServiceVettingCriteriaFlags.tsx'
import {memo} from 'react'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'

export const ServiceVettingCriteriaRejectedImageItem = memo(
    ({
        image,
        galleryId,
        hideAction,
        onClickExpand
    }: {
        image: ServiceGalleryImage
        galleryId: number
        hideAction?: boolean
        onClickExpand?: (image: ServiceGalleryImage) => void
    }) => {
        const {t} = useTranslation()

        const updateIsApprovedGalleryImg = useUpdateIsApprovedGalleryImg({
            serviceId: image.submission_id,
            imgId: image.id,
            galleryId
        })

        const addServiceGalleryImageFlags = useAddServiceGalleryImageFlags({
            serviceId: image.submission_id,
            sectionId: galleryId
        })
        const removeServiceGalleryImageFlags = useRemoveServiceGalleryImageFlags({
            serviceId: image.submission_id,
            sectionId: galleryId
        })

        return (
            <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                <GalleryImageItem
                    src={image.thumbnails?.xs || image.url}
                    bottomRightSlot={
                        !hideAction && (
                            <Button
                                variant="secondary"
                                iconOnly
                                className="show-on-hover"
                                size="sm"
                                onClick={() => onClickExpand?.(image)}
                            >
                                <Expand01Icon />
                            </Button>
                        )
                    }
                />
                <Flexbox gap={2} direction="column" fullWidth>
                    <OpsVettingImageTitle>{image.name}</OpsVettingImageTitle>
                    <Flexbox gap={3} fullWidth>
                        <Button
                            fullWidth
                            size={'sm'}
                            disabled={hideAction}
                            variant={image.is_approved === null || !image.is_approved ? 'secondary' : 'primarySuccess'}
                            onClick={() =>
                                image.is_approved != true && updateIsApprovedGalleryImg.mutate({is_approved: true})
                            }
                        >
                            <ThumbsUpIcon />
                            {t('vetting:criteria:service:gallery:accept')}
                        </Button>
                        <Button
                            fullWidth
                            size={'sm'}
                            disabled={hideAction}
                            variant={image.is_approved === null || image.is_approved ? 'secondary' : 'primaryDanger'}
                            onClick={() =>
                                image.is_approved != false && updateIsApprovedGalleryImg.mutate({is_approved: false})
                            }
                        >
                            <ThumbsDownIcon />
                            {t('vetting:criteria:service:gallery:reject')}
                        </Button>
                    </Flexbox>
                    {image.is_approved == false && (
                        <ServiceVettingCriteriaFlags
                            flags={image.flags}
                            addFlag={flag => addServiceGalleryImageFlags.mutate({flags: [flag], itemId: image.id})}
                            removeFlag={flag =>
                                removeServiceGalleryImageFlags.mutate({flags: [flag], itemId: image.id})
                            }
                            types={['gallery_image']}
                            hideAction={hideAction}
                        />
                    )}
                </Flexbox>
            </OpsVettingGalleryItemsContainer>
        )
    }
)
