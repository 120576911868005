import * as pages from '@/pages'
import i18next from 'i18next'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    RESET_PASSWORD: {
        path: '/reset-password',
        element: <pages.ResetPassword />
    },
    SET_PASSWORD: {
        path: '/set-password/:token',
        element: <pages.SetPassword />
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />
    },
    WELCOME: {
        path: `/`,
        element: <pages.Welcome />
    },
    PHOTOSET_REJECTED: {
        path: '/photoset/:id/editing/rejected',
        element: <pages.PhotosetRejected />,
        handle: {
            path: `/photoset/:id/editing/rejected`,
            paramName: 'id',
            name: ''
        }
    },
    // Editing Routes
    PHOTOSET_INITIAL_QA: {
        path: '/photoset/:id/editing/initial-qa',
        element: <pages.PhotosetInitialQaStep />,
        handle: {
            path: '/photoset/:id/editing/initial-qa',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_ALLOCATION: {
        path: '/photoset/:id/editing/allocation',
        element: <pages.PhotosetAllocationStep />,
        handle: {
            path: '/photoset/:id/editing/allocation',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_CURATION: {
        path: '/photoset/:id/editing/curation',
        element: <pages.PhotosetCurationStep />,
        handle: {
            path: '/photoset/:id/editing/curation',
            paramName: 'id',
            name: ''
        }
    },
    // Check this path ->
    PHOTOSET_CURATION_COMPLETED: {
        path: '/photoset/:id/editing/curation-completed',
        element: <pages.PhotosetCompletedSteps />,
        handle: {
            path: '/photoset/:id/editing/curation-completed',
            paramName: 'id',
            name: ''
        }
    },
    // <- Check this path
    PHOTOSET_AI_VETTING: {
        path: '/photoset/:id/editing/ai-vetting',
        element: <pages.PhotosetAiVettingStep />,
        handle: {
            path: '/photoset/:id/editing/ai-vetting',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_AI_VETTING_COMPLETED: {
        path: '/photoset/:id/editing/ai-vetting-completed',
        element: <pages.PhotosetAiVettingCompletedStep />,
        handle: {
            path: '/photoset/:id/editing/ai-vetting-completed',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_AI_MANUAL_RETOUCHING_UPLOAD: {
        path: '/photoset/:id/editing/ai-manual-retouching-upload',
        element: <pages.PhotosetAiManualRetouchingUploadStep />,
        handle: {
            path: '/photoset/:id/editing/ai-manual-retouching-upload',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_AI_MANUAL_RETOUCHING: {
        path: '/photoset/:id/editing/ai-manual-retouching',
        element: <pages.PhotosetAiManualRetouchingStep />,
        handle: {
            path: '/photoset/:id/editing/ai-manual-retouching',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_NEEDS_REVIEW: {
        path: '/photoset/:id/editing/needs-review',
        element: <pages.PhotosetNeedsReviewStep />,
        handle: {
            path: '/photoset/:id/editing/needs-review',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_RETOUCHING_REVISION_UPLOAD: {
        path: '/photoset/:id/editing/retouching-revision-upload',
        element: <pages.PhotosetRetouchingRevisionUploadStep />,
        handle: {
            path: '/photoset/:id/editing/retouching-revision-upload',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_RETOUCHING_REVISION: {
        path: '/photoset/:id/editing/retouching-revision',
        element: <pages.PhotosetRetouchingRevisionStep />,
        handle: {
            path: '/photoset/:id/editing/retouching-revision',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_RETOUCHING_REVISION_APPROVED: {
        path: '/photoset/:id/editing/retouching-revision-approved',
        element: <pages.PhotosetRetouchingRevisionApprovedStep />,
        handle: {
            path: '/photoset/:id/editing/retouching-revision-approved',
            paramName: 'id',
            name: ''
        }
    },
    // QA Routes
    PHOTOSET_EDITING_QA_SELECTION: {
        path: '/photoset/:id/qa/editing-qa-selection',
        element: <pages.PhotosetEditingQaSelectionStep />,
        handle: {
            path: '/photoset/:id/qa/editing-qa-selection',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_EDITING_QA_FLAGS: {
        path: '/photoset/:id/qa/editing-qa-flags',
        element: <pages.PhotosetEditingQaFlagsStep />,
        handle: {
            path: '/photoset/:id/qa/editing-qa-flags',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_EDITING_QA_COMPLETED: {
        path: '/photoset/:id/qa/editing-qa-completed',
        element: <pages.PhotosetEditingQaCompletedStep />,
        handle: {
            path: '/photoset/:id/qa/editing-qa-completed',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_VETTING_QA: {
        path: '/photoset/:id/qa/vetting-qa',
        element: <pages.PhotosetVettingQaStep />,
        handle: {
            path: '/photoset/:id/qa/vetting-qa',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_RETOUCHING_QA: {
        path: '/photoset/:id/qa/retouching-qa',
        element: <pages.PhotosetRetouchingQaStep />,
        handle: {
            path: '/photoset/:id/qa/retouching-qa',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_RETOUCHING_QA_COMPLETED: {
        path: '/photoset/:id/qa/retouching-qa-completed',
        element: <pages.PhotosetRetouchingQaCompletedStep />,
        handle: {
            path: '/photoset/:id/qa/retouching-qa-completed',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_UNAVAILABLE_QA: {
        path: '/photoset/:id/qa/unavailable-qa',
        element: <pages.PhotosetUnavailableQa />,
        handle: {
            path: '/photoset/:id/qa/unavailable-qa',
            paramName: 'id',
            name: ''
        }
    },
    // 2LA Routes
    PHOTOSET_2LA_INDEX: {
        path: '/photoset/:id/2la',
        element: <pages.Photoset2LaIndex />,
        index: true,
        handle: {
            path: `/photoset/:id/2la`,
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_2LA_EDITING_QA_SELECTION: {
        path: '/photoset/:id/2la/editing-qa-selection',
        element: <pages.PhotosetEditingQaSelectionStep />,
        handle: {
            path: '/photoset/:id/2la/editing-qa-selection',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_2LA_EDITING_QA_FLAGS: {
        path: '/photoset/:id/2la/editing-qa-flags',
        element: <pages.PhotosetEditingQaFlagsStep />,
        handle: {
            path: '/photoset/:id/2la/editing-qa-flags',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_2LA_VETTING_QA: {
        path: '/photoset/:id/2la/vetting-qa',
        element: <pages.PhotosetVettingQaStep />,
        handle: {
            path: '/photoset/:id/2la/vetting-qa',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_2LA_RETOUCHING_QA: {
        path: '/photoset/:id/2la/retouching-qa',
        element: <pages.PhotosetRetouchingQaStep />,
        handle: {
            path: '/photoset/:id/2la/retouching-qa',
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_UNAVAILABLE_2LA: {
        path: '/photoset/:id/2la/unavailable-2la',
        element: <pages.PhotosetUnavailable2La />,
        handle: {
            path: '/photoset/:id/2la/unavailable-2la',
            paramName: 'id',
            name: ''
        }
    },
    //todo: remove after go live
    PHOTOSET_CURATION_STEP_V1: {
        path: '/photoset/:id/curation-v1',
        element: <pages.PhotosetCurationStepV1 />,
        handle: {
            path: `/photoset/:id/curation-v1`,
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_IMAGEN_AI_STEP_V1: {
        path: '/photoset/:id/imagen-ai-v1',
        element: <pages.PhotosetImagenAiStep />,
        handle: {
            path: `/photoset/:id/imagen-ai-v1`,
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_PUBLIC_SHOWCASE: {
        path: '/photoset/:id/show',
        element: <pages.PhotosetPublicShowcase />,
        handle: {
            path: `/photoset/:id/show`,
            paramName: 'id',
            name: ''
        }
    },
    PHOTOSET_INITIAL_QA_V1: {
        path: '/photoset/:id/initial-qa-v1',
        element: <pages.PhotosetInitialQaStepV1 />,
        handle: {
            path: `/photoset/:id/initial-qa-v1`,
            paramName: 'id',
            name: ''
        }
    },
    //Copy preparation AI tool
    EXP_AI_COPY_PREP_SHELL_TRAINING: {
        path: '/experience/ai-copy-prep/shell-training',
        element: <pages.ExpShellCopyTraining />
    },
    EXP_AI_COPY_PREP_VETTING_TRAINING: {
        path: '/experience/ai-copy-prep/vetting-training',
        element: <pages.ExpVettingCopyTraining />
    },
    SERVICE_COPY_TRAINING: {
        path: '/service/ai-copy-prep/training',
        element: <pages.ServiceCopyTraining />
    },
    EXPERIENCES_SHELL: {
        path: '/experiences/:id/shell',
        element: <pages.ExperienceShell />
    },
    // Service Vetting Routes
    SERVICE_VETTING_MERCHANDISING: {
        path: '/service-vetting/:id/merchandising',
        element: <pages.ServiceMerchandisingVettingPage />
    },
    SERVICE_VETTING_CRITERIA: {
        path: '/service-vetting/:id/criteria',
        element: <pages.ServiceCriteriaVetting />
    },
    EXPERIENCE_VETTING_MERCHANDISING: {
        path: '/experiences-vetting/:id/merchandising',
        element: <pages.ExperienceMerchandisingVettingPage />
    },
    EXPERIENCE_VETTING_CRITERIA: {
        path: '/experiences-vetting/:id/criteria',
        element: <pages.ExperienceCriteriaVetting />
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}

//Utility routes
export const ServiceVettingSteps = [
    {
        label: i18next.t('vetting:criteria:commons:criteria'),
        path: routes.SERVICE_VETTING_CRITERIA.path
    },
    {
        label: i18next.t('vetting:merch:commons:merch'),
        path: routes.SERVICE_VETTING_MERCHANDISING.path
    }
]

export const ExperienceVettingSteps = [
    {
        label: i18next.t('vetting:criteria:commons:criteria'),
        path: routes.EXPERIENCE_VETTING_CRITERIA.path
    },
    {
        label: i18next.t('vetting:merch:commons:merch'),
        path: routes.EXPERIENCE_VETTING_MERCHANDISING.path
    }
]
