import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {
    OpsVettingDisabledContainer,
    OpsVettingDragButton,
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Expand01Icon, MoveIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useDeleteExperienceVettingPhotos} from '../../queries/useDeleteExperienceVettingPhotos.ts'
import {ExperienceGalleryImage} from '@/features/experience-shell/types.ts'
import {memo} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'

export const ExpVettingCriteriaGalleryUploadedItem = memo(
    ({
        image,
        galleryId,
        experienceId,
        hideAction,
        onClickExpand
    }: {
        image: ExperienceGalleryImage
        galleryId: number
        experienceId: number
        hideAction: boolean
        onClickExpand?: () => void
    }) => {
        const {t} = useTranslation()

        const deletePhoto = useDeleteExperienceVettingPhotos({galleryId, experienceId})

        const onClickDeletePhoto = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            deletePhoto.mutate({imageIds: [image.id]})
        }

        const {attributes, setNodeRef, transform, transition, isDragging, listeners} = useSortable({id: image.id})

        const style = {
            transform: CSS.Translate.toString(transform),
            transition
        }

        return (
            <OpsVettingDisabledContainer $isDisabled={isDragging} ref={setNodeRef} style={style} {...attributes}>
                <OpsVettingGalleryItemsContainer direction={'column'} gap={4}>
                    <GalleryImageItem
                        src={image.thumbnails?.xs || image.url}
                        bottomLeftSlot={
                            <Chip label={t('vetting:criteria:service:gallery:internal_upload')} variant={'neutral'} />
                        }
                        topRightSlot={
                            <Button
                                variant={'secondary'}
                                iconOnly
                                disabled={deletePhoto.isPending || hideAction}
                                onClick={onClickDeletePhoto}
                            >
                                {deletePhoto.isPending ? <Spinner /> : <Trash01Icon />}
                            </Button>
                        }
                        bottomRightSlot={
                            !hideAction && (
                                <Button
                                    variant="secondary"
                                    iconOnly
                                    disabled={deletePhoto.isPending}
                                    className="show-on-hover"
                                    size="sm"
                                    onClick={onClickExpand}
                                >
                                    <Expand01Icon />
                                </Button>
                            )
                        }
                        topLeftSlot={
                            !hideAction ? (
                                <OpsVettingDragButton
                                    disabled={deletePhoto.isPending}
                                    {...listeners}
                                    variant="secondary"
                                    $isDragging={isDragging}
                                    iconOnly
                                    className="show-on-hover"
                                    size="sm"
                                >
                                    <MoveIcon size={16} />
                                </OpsVettingDragButton>
                            ) : null
                        }
                    />
                    <OpsVettingImageTitle>{image.name}</OpsVettingImageTitle>
                </OpsVettingGalleryItemsContainer>
            </OpsVettingDisabledContainer>
        )
    }
)
