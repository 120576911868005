import {QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage} from '@/utilities/helpers'
import {
    httpGetExperienceVettingCategoryFormats,
    HttpGetExperienceVettingCategoryFormatsParams,
    HttpGetExperienceVettingCategoryFormatsResponse
} from '@/features/experience-vetting/http/experienceVetting.http'

export const useGetExperienceVettingCategoryFormats = (
    promiseArgs: Omit<HttpGetExperienceVettingCategoryFormatsParams, 'page'>,
    options?: Pick<UseInfiniteQueryOptions<HttpGetExperienceVettingCategoryFormatsResponse>, 'enabled'>
) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_CATEGORY_FORMATS, promiseArgs],
        queryFn: ({pageParam = 1}) => httpGetExperienceVettingCategoryFormats({...promiseArgs, page: pageParam}),
        ...options,
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.currentPage < meta.lastPage ? meta.currentPage + 1 : null)
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    }
}
