import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {Image, PhotosetSpace, Space} from '@/features/photoset/types'
import {useGalleryAiVettingShortcuts} from '@/features/photoset/hooks/useGalleryAiVettingShortcuts'
import {spaceNameToLabel} from '@/features/photoset/utils'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {Stars01Icon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {useImageSelectionApproval} from '@/features/photoset/queries/useImageSelectionApproval'
import {errorHandler} from '@/utilities/helpers'

type GalleryAiVettingModalProps = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    currentDetailSpaceIndex: number
    onClose: () => void
    currentSpaceId: PhotosetSpace['id']
    currentSpaceName: Space['key_name']
}

export const GalleryAiVettingModal: FC<GalleryAiVettingModalProps> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    currentDetailSpaceIndex,
    onClose,
    currentSpaceName
}) => {
    const {t} = useTranslation()
    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    const {mutate: imageSelectionApprovalToggle} = useImageSelectionApproval({
        photosetId: currentImage.photoset_id,
        photosetSpaceId: currentImage.photoset_space_id as number,
        options: {
            onError: errorHandler
        }
    })

    const onToggleSelection = () => {
        imageSelectionApprovalToggle({
            imageId: currentImage.id,
            need_manual_retouching: !currentImage.need_manual_retouching
        })
    }

    useGalleryAiVettingShortcuts({
        currentImage,
        currentSpaceIndex: currentDetailSpaceIndex,
        selectableImages,
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        onToggleSelection,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.ai_retouched_thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    iconTitle={<Stars01Icon size={20} />}
                    subtitle={`${spaceNameToLabel[currentSpaceName]} — ${t('commons:x_images_selected', {count: 1})}`}
                    currentImage={currentImage}
                    onClose={onClose}
                    endSlot={
                        <Button
                            tabIndex={-1}
                            variant="secondary"
                            onClick={e => {
                                onToggleSelection()
                                e.currentTarget.blur()
                            }}
                        >
                            <Checkbox readOnly tabIndex={-1} checked={currentImage.need_manual_retouching} />
                            {t('commons:select_space')}
                        </Button>
                    }
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImageId={currentImage.id}
                    thumbnails={'ai_retouched_thumbnails'}
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
        />
    )
}
