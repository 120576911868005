import {
    DialogDisclosure,
    DialogFooter,
    DialogDismiss,
    DialogHeading,
    DialogHeader,
    DialogProvider
} from '@/components/ui/dialog-atoms/DialogAtoms'
import {
    OpsVettingCheckIconContainer,
    OpsVettingChoosePhotoDialog,
    OpsVettingChoosePhotoDialogBody
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms'
import {FC, ReactElement, useState} from 'react'
import {useGetExperienceVettingGalleryImages} from '../../queries/useGetExperienceVettingGalleryImages'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {AlertTriangleIcon, CheckIcon} from '@/components/ui/icon/Icon'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {StyledGalleryItemSkeleton} from '@/components/commons/gallery-image-item/style'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {Button} from '@/components/ui/button/Button'
import {useTranslation} from '@/translations/i18n'
import {DialogStore} from '@ariakit/react'

interface ExpVettingCriteriaChoosePhotoDialogProps {
    onConfirm: (id: number) => void
    defaultSelectedImageId?: number
    experienceId: number
    galleryId: number
    trigger?: ReactElement
    store?: DialogStore
}

export const ExpVettingCriteriaChoosePhotoDialog: FC<ExpVettingCriteriaChoosePhotoDialogProps> = ({
    onConfirm,
    defaultSelectedImageId,
    experienceId,
    galleryId,
    trigger,
    store
}) => {
    const [selectedImageId, setSelectedImageId] = useState(defaultSelectedImageId)
    const {data: images, isPending, isError} = useGetExperienceVettingGalleryImages({experienceId, galleryId})
    const {t} = useTranslation()
    const disableConfirm = !selectedImageId || selectedImageId == defaultSelectedImageId

    return (
        <DialogProvider store={store} setOpen={() => setSelectedImageId(defaultSelectedImageId)}>
            {trigger && <DialogDisclosure render={trigger} />}
            <OpsVettingChoosePhotoDialog width="680px">
                <DialogHeader>
                    <DialogDismiss />
                    <DialogHeading>{t('commons:choose_a_photo')}</DialogHeading>
                </DialogHeader>
                <ScrollArea scrollbar={<Scrollbar />}>
                    <OpsVettingChoosePhotoDialogBody>
                        {isPending ? (
                            Array.from({length: 6}).map((_, index) => <StyledGalleryItemSkeleton key={index} />)
                        ) : isError ? (
                            <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                        ) : (
                            images?.map(image => (
                                <GalleryImageItem
                                    key={image.id}
                                    onClick={() => setSelectedImageId(image.id)}
                                    topLeftSlot={
                                        selectedImageId == image.id && (
                                            <OpsVettingCheckIconContainer>
                                                <CheckIcon />
                                            </OpsVettingCheckIconContainer>
                                        )
                                    }
                                    src={image.url}
                                />
                            ))
                        )}
                    </OpsVettingChoosePhotoDialogBody>
                </ScrollArea>
                <DialogFooter>
                    <DialogDismiss render={<Button variant="tertiary" />}>{t('commons:cancel')}</DialogDismiss>
                    <DialogDismiss
                        render={
                            <Button
                                disabled={disableConfirm}
                                onClick={() => selectedImageId && onConfirm(selectedImageId)}
                            />
                        }
                    >
                        {t('commons:confirm')}
                    </DialogDismiss>
                </DialogFooter>
            </OpsVettingChoosePhotoDialog>
        </DialogProvider>
    )
}
