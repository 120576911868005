import {
    DiffSection,
    FieldsDiffsSeparator,
    DiffSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-diffs/OpsVettingMerchDiffs'
import {Fragment} from 'react/jsx-runtime'
import {ExperienceDiffsMap} from '../../types'

interface ExperienceVettingMerchCopyDiffsProps {
    experienceDiffsMap: ExperienceDiffsMap
}
export const ExperienceVettingMerchCopyDiffs = ({experienceDiffsMap}: ExperienceVettingMerchCopyDiffsProps) => {
    return (
        <DiffSectionWrapper>
            <DiffSection
                size={experienceDiffsMap.improved_host_experience_title.fieldSize}
                hasDiff={experienceDiffsMap.improved_host_experience_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={experienceDiffsMap.improved_host_experience_title.hasDiff}
                hasLowerDiff={experienceDiffsMap.improved_host_expertise_description.hasDiff}
            />
            <DiffSection
                size={experienceDiffsMap.improved_host_expertise_description.fieldSize}
                hasDiff={experienceDiffsMap.improved_host_expertise_description.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={experienceDiffsMap.improved_host_expertise_description.hasDiff}
                hasLowerDiff={experienceDiffsMap.improved_host_recognition_title?.hasDiff ?? false}
            />
            <DiffSection size={'sm'} hasDiff={experienceDiffsMap.improved_host_recognition_title?.hasDiff ?? false} />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={experienceDiffsMap.improved_host_recognition_title?.hasDiff ?? false}
                hasLowerDiff={experienceDiffsMap.improved_host_recognition_subtitle?.hasDiff ?? false}
            />
            <DiffSection
                size={'sm'}
                hasDiff={experienceDiffsMap.improved_host_recognition_subtitle?.hasDiff ?? false}
            />
            <FieldsDiffsSeparator fieldType="section" />

            <DiffSection
                size={experienceDiffsMap.improved_experience_title.fieldSize}
                hasDiff={experienceDiffsMap.improved_experience_title.hasDiff}
            />
            <FieldsDiffsSeparator
                fieldType="consecutive"
                hasUpperDiff={experienceDiffsMap.improved_experience_title.hasDiff}
                hasLowerDiff={experienceDiffsMap.improved_experience_description.hasDiff}
            />
            <DiffSection
                size={experienceDiffsMap.improved_experience_description.fieldSize}
                hasDiff={experienceDiffsMap.improved_experience_description.hasDiff}
            />
            <FieldsDiffsSeparator fieldType="sub-section" />
            {experienceDiffsMap.activities.map((activity, index) => {
                const isLastItem = index === experienceDiffsMap.activities.length - 1
                return (
                    <Fragment key={index}>
                        <DiffSection size={activity[0].fieldSize} hasDiff={activity[0].hasDiff} />
                        <FieldsDiffsSeparator
                            fieldType="consecutive"
                            hasUpperDiff={activity[0].hasDiff}
                            hasLowerDiff={activity[1].hasDiff}
                        />
                        <DiffSection size={activity[1].fieldSize} hasDiff={activity[1].hasDiff} />
                        {!isLastItem && <FieldsDiffsSeparator fieldType="sub-section" />}
                    </Fragment>
                )
            })}
        </DiffSectionWrapper>
    )
}
ExperienceVettingMerchCopyDiffs.displayName = 'ExperienceVettingMerchCopyDiffs'
