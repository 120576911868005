import {useTranslation} from 'react-i18next'
import {
    SpaceImagesContainer,
    SpaceImageSelectionInfo
} from '@/features/photoset/components/space-images-atoms/SpaceImagesAtoms'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {Image01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Flag, Image, Photoset, PhotosetSpace, StepKey} from '@/features/photoset/types'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {StyledRetouchingQaImageInfo, StyledRetouchingQaImageInfoName, StyledSendBackCheckboxContainer} from './style'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {ImageCardFlags} from '@/features/photoset/components/image-card-flags/ImageCardFlags'
import {FC} from 'react'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {useRemoveRetouchingQaFlag} from '@/features/photoset/hooks/useRemoveRetouchingQaFlag'
import {usePhotosetStore} from '@/features/photoset/store/store'

interface RetouchingQaSpaceImagesProps {
    images: PhotosetSpace['images']
    photosetId: Photoset['id']
    photosetSpaceId: PhotosetSpace['id']
    allFlagsKeyName?: Flag['key_name'][]
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
    isQaView: boolean
}

export const RetouchingQaSpaceImages: FC<RetouchingQaSpaceImagesProps> = ({
    images,
    photosetId,
    photosetSpaceId,
    allFlagsKeyName,
    spaceIndex,
    onExpandImage,
    isQaView
}) => {
    const {t} = useTranslation()

    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectImages = usePhotosetStore(state => state.unselectImages)

    const {sendBackImage, onRemoveFlagCb} = useRemoveRetouchingQaFlag({
        photosetId,
        photosetSpaceId
    })

    const onToggleClick = (image: Omit<Image, 'space'>) => {
        sendBackImage({
            imageId: image.id,
            needs_to_review: !image.needs_to_review
        })
    }

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map((image, index) => {
                const isFlaggedImage = image.flags.some(flag => allFlagsKeyName?.includes(flag.key_name))
                const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)

                return (
                    <ImageCard
                        image={image}
                        key={image.id}
                        isSelected={isSelected}
                        onSelect={
                            isQaView ? () => (isSelected ? unselectImages([image.id]) : selectImages([image.id])) : null
                        }
                        thumbnail={image.retouched_thumbnails?.xs ?? image.ai_retouched_thumbnails?.xs}
                        hideImageInfo
                        imageNameIcon={
                            !image.is_manually_retouched ? <Stars01Icon size={20} /> : <Image01Icon size={20} />
                        }
                        endSlot={
                            <>
                                <SpaceImageSelectionInfo direction={'column'} gap={4} align="flex-start" fullWidth>
                                    <StyledRetouchingQaImageInfo
                                        gap={2}
                                        align="center"
                                        justify="space-between"
                                        render="section"
                                        fullWidth
                                    >
                                        <StyledRetouchingQaImageInfoName gap={2} align="center">
                                            {image.is_manually_retouched ? (
                                                <Image01Icon size={20} />
                                            ) : (
                                                <Stars01Icon size={20} />
                                            )}
                                            <h4>{image.name}</h4>
                                        </StyledRetouchingQaImageInfoName>
                                        <Tooltip
                                            maxWidth={258}
                                            rootProps={{open: isFlaggedImage ? false : undefined}}
                                            triggerProps={{className: 'tooltip-trigger'}}
                                            contentProps={{sideOffset: 8, align: 'center', side: 'top'}}
                                            variant="light"
                                            content={<p>{t('photoset:retouching_qa:send_back_tooltip')}</p>}
                                        >
                                            <StyledSendBackCheckboxContainer
                                                align="center"
                                                gap={1.5}
                                                $isDisabled={!isFlaggedImage || !isQaView}
                                            >
                                                <Checkbox
                                                    disabled={!isFlaggedImage || !isQaView}
                                                    onChange={() => onToggleClick(image)}
                                                    checked={image.needs_to_review}
                                                />
                                                <span>{t('photoset:retouching_qa:send_back')}</span>
                                            </StyledSendBackCheckboxContainer>
                                        </Tooltip>
                                    </StyledRetouchingQaImageInfo>
                                    <ImageCardFlags
                                        image={image}
                                        stepId={StepKey['retouching-qa']}
                                        photosetSpaceIds={[photosetSpaceId]}
                                        types={['visual_retouching']}
                                        hideFlagsDescriptions
                                        onRemoveFlagCb={onRemoveFlagCb}
                                        isReadOnly={isQaView == false}
                                    />
                                </SpaceImageSelectionInfo>
                                <ExpandImageButton onClick={() => onExpandImage(spaceIndex, index)} />
                            </>
                        }
                    />
                )
            })}
        </SpaceImagesContainer>
    )
}
