import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {Overlay} from '@/components/ui/overlay/Overlay'

export const StyledSpaceDetailModalContent = styled(Container)(
    ({theme: {spacing, zIndex}}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.modal};
        display: flex;
        align-items: center;
        padding: ${spacing * 8}px 0;
    `
)

export const StyledSpaceDetailModalCard = styled.div(
    ({theme: {palette, shadows}}) => css`
        height: 100%;
        width: 100%;
        background-color: ${palette.neutral.white};
        border-radius: 12px;
        box-shadow: ${shadows.xl};
        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
    `
)

export const StyledSpaceDetailModalCardHeader = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        position: relative;
        height: 56px;
        ${typography.textSm};
        font-weight: 700;
        color: ${palette.neutral['900']};

        & button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: ${spacing * 2}px;
        }
    `
)

export const StyledImagesSelectedLabel = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['700']};
    `
)

export const StyledNavigationButton = styled(Button)(
    ({theme: {palette}}) => css`
        color: ${palette.neutral.white};
        flex-shrink: 0;
        width: 180px;
    `
)

export const StyledScrollbar = styled(Scrollbar)(
    ({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 3}px;
    `
)

export const StyledSpaceDetailModalOverlay = styled(Overlay)`
    backdrop-filter: blur(16px);
`
