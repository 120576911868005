import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledActivityCard, StyledActivityCardCover, StyledActivityCardCoverWrapper} from './style'
import {DialogProvider, SelectProvider} from '@ariakit/react'
import {SelectList, SelectPopover, SelectTrigger, SelectItem} from '@/components/ui/select-atoms/SelectAtoms'
import i18n, {useTranslation} from '@/translations/i18n'
import {Image03Icon} from '@/components/ui/icon/Icon'
import {DialogDisclosure} from '@/components/ui/dialog-atoms/DialogAtoms'
import {SelectItineraryPhotoDialogBody} from '@/features/experience-shell/components/select-itinerary-photo-dialog-body/SelectItineraryPhotoDialogBody.tsx'
import {Itinerary} from '@/features/experience-shell/types.ts'
import {useState} from 'react'
import {useEditExperienceItinerary} from '@/features/experience-shell/queries/useEditExperienceItinerary.ts'

const getDurationOptions = () => {
    const options = []
    for (let minutes = 15; minutes <= 120; minutes += 15) {
        options.push({
            label: i18n.t('experience_shell:x_minutes', {count: minutes}),
            value: minutes.toString()
        })
    }
    return options
}
const durationOptions = getDurationOptions()

export const ActivityCard = ({
    experienceId,
    galleryId,
    itinerary
}: {
    experienceId: number
    galleryId: number
    itinerary: Itinerary
}) => {
    const {t} = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const editItinerary = useEditExperienceItinerary({urlParams: {itineraryId: itinerary.id, experienceId}})

    const onConfirmSelectPhoto = (id: number) => {
        editItinerary.mutate({coverImageId: id})
    }
    const onChangeDuration = (durationInMinutes: string) => {
        editItinerary.mutate({durationInMinutes: parseInt(durationInMinutes)})
    }

    return (
        <StyledActivityCard>
            <DialogProvider setOpen={() => setIsOpen(true)}>
                <DialogDisclosure
                    disabled={editItinerary.isPending && !!editItinerary.variables.durationInMinutes}
                    render={
                        itinerary.coverImage ? (
                            <StyledActivityCardCoverWrapper
                                src={itinerary.coverImage.thumbnails?.xs || itinerary.coverImage.url}
                            />
                        ) : (
                            <StyledActivityCardCover>
                                <Image03Icon />
                                <p>{t('commons:choose_a_photo')}</p>
                            </StyledActivityCardCover>
                        )
                    }
                />
                {isOpen && (
                    <SelectItineraryPhotoDialogBody
                        defaultSelectedImageId={itinerary.coverImageId}
                        onClose={() => setIsOpen(false)}
                        galleryId={galleryId}
                        experienceId={experienceId}
                        onConfirm={onConfirmSelectPhoto}
                    />
                )}
            </DialogProvider>
            <Flexbox direction="column" gap={4}>
                <Flexbox direction="column" gap={2}>
                    <h3>{itinerary.title}</h3>
                    <p>{itinerary.description}</p>
                </Flexbox>
                <SelectProvider
                    setValue={onChangeDuration}
                    value={itinerary.durationInMinutes ? itinerary.durationInMinutes.toString() : ''}
                >
                    <SelectTrigger
                        disabled={editItinerary.isPending && !!editItinerary.variables.durationInMinutes}
                        displayValue={value => durationOptions.find(option => option.value == value)?.label}
                        placeholder={t('experience_shell:select_duration')}
                    />
                    <SelectPopover>
                        <SelectList>
                            {durationOptions.map(option => (
                                <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectList>
                    </SelectPopover>
                </SelectProvider>
            </Flexbox>
        </StyledActivityCard>
    )
}
