import {
    OpsVettingDisabledContainer,
    OpsVettingGalleryGrid,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {RemapExperienceVetting} from '@/features/experience-vetting/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ExperienceVettingCriteriaRejectedImageItem} from '@/features/experience-vetting/components/experience-vetting-criteria-rejected-image-item/ExperienceVettingCriteriaRejectedImageItem.tsx'
import {useMemo, useState} from 'react'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'
import {AlertTriangleIcon, CheckIcon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {useTheme} from 'styled-components'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {ExperienceGalleryImage} from '@/features/experience-shell/types'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {useGalleryNeedsReviewShortcuts} from '@/features/photoset/hooks/useGalleryNeedsReviewShortcuts'

export const ExperienceVettingCriteriaRejectedImages = ({
    experience,
    isReadOnly
}: {
    experience: RemapExperienceVetting
    isReadOnly: boolean
}) => {
    const {t} = useTranslation()
    const {gallery} = experience
    const {palette} = useTheme()
    const isDeclined = experience.expertise.status === 'declined'

    const [expandedImageIndex, setExpandedImageIndex] = useState<number | null>(null)
    const [showGalleryModal, setShowGalleryModal] = useState(false)

    const errors = useExperienceVettingError(state => state.errors)
    const filteredGalleryImages = useMemo(
        () => gallery.images.filter(image => !image.isCover && !image.isItineraryImage && image.isApproved === false),
        [gallery]
    )

    const pendingImagesCount = useMemo(
        () => filteredGalleryImages.filter(image => image.flags.length == 0).length,
        [filteredGalleryImages]
    )

    const handleClickExpandedImage = (imageId: ExperienceGalleryImage['id']) => {
        const index = filteredGalleryImages.findIndex(image => image.id === imageId)
        setExpandedImageIndex(index)
        setShowGalleryModal(true)
    }

    const handleCloseModal = () => {
        setExpandedImageIndex(null)
        setShowGalleryModal(false)
    }

    const renderStatusMessage = () => {
        if (pendingImagesCount > 0) {
            return t('vetting:criteria:commons:rejected_images:images_remaining_flags', {
                count: pendingImagesCount
            })
        }

        return (
            <Flexbox render="span" gap={1}>
                <CheckIcon size={20} color={palette.success[600]} />
                {t('vetting:criteria:commons:rejected_images:images_remaining_no_flags')}
            </Flexbox>
        )
    }

    useGalleryNeedsReviewShortcuts({
        selectableImages: filteredGalleryImages,
        currentDetailImageIndex: expandedImageIndex || 0,
        setCurrentDetailImageIndex: setExpandedImageIndex,
        onClose: handleCloseModal
    })

    return (
        <OpsVettingSectionWrapper fullWidth direction="column" gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <OpsVettingSectionTitle>{t('vetting:criteria:commons:rejected_images:title')}</OpsVettingSectionTitle>
                <Flexbox gap={4} align="center">
                    {errors.rejectedImagesErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant="danger"
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}

                    <OpsVettingParagraph>{renderStatusMessage()}</OpsVettingParagraph>
                </Flexbox>
            </Flexbox>

            <Divider direction="horizontal" />

            <OpsVettingDisabledContainer $isDisabled={isDeclined}>
                <OpsVettingGalleryGrid>
                    {filteredGalleryImages.map(image => (
                        <ExperienceVettingCriteriaRejectedImageItem
                            key={image.id}
                            image={image}
                            experienceId={experience.id}
                            galleryId={gallery.id}
                            hideAction={isReadOnly}
                            onClickExpand={() => handleClickExpandedImage(image.id)}
                        />
                    ))}
                </OpsVettingGalleryGrid>
            </OpsVettingDisabledContainer>

            {showGalleryModal && expandedImageIndex !== null && (
                <GalleryModal
                    currentImage={filteredGalleryImages[expandedImageIndex]}
                    thumbnails={{
                        l: filteredGalleryImages[expandedImageIndex].url || '',
                        m: '',
                        s: '',
                        xs: ''
                    }}
                    onClose={handleCloseModal}
                    headerSlot={
                        <GalleryModalHeader
                            currentImage={filteredGalleryImages[expandedImageIndex]}
                            imagesCount={filteredGalleryImages.length}
                            onClose={handleCloseModal}
                        />
                    }
                    thumbnailsSlot={
                        <GalleryModalThumbnails
                            currentImageId={filteredGalleryImages[expandedImageIndex].id}
                            thumbnails="thumbnails"
                            images={filteredGalleryImages.map(image => ({
                                id: image.id,
                                name: image.name,
                                thumbnails: {
                                    l: image.url || '',
                                    m: image.url || '',
                                    s: image.url || '',
                                    xs: image.url || ''
                                }
                            }))}
                            onSelect={(_, index) => {
                                setExpandedImageIndex(index)
                            }}
                        />
                    }
                />
            )}
        </OpsVettingSectionWrapper>
    )
}
