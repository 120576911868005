import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetExperienceShellGalleryImages,
    HttpGetExperienceShellGalleryImagesOptions
} from '@/features/experience-shell/http/experienceShell.http.ts'

export const useGetExperienceShellGalleryImages = (
    urlParams: HttpGetExperienceShellGalleryImagesOptions['urlParams']
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCE_SHELL_GALLERY_IMAGES, urlParams.experienceId],
        queryFn: () =>
            httpGetExperienceShellGalleryImages({
                urlParams,
                params: {response_type: 'extended'}
            })
    })
}
