import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '@utilities/helpers.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpEditExperienceShellPhotoCover,
    HttpEditExperienceShellPhotoCoverOptions
} from '@/features/experience-shell/http/experienceShell.http.ts'

export const useEditExperienceShellPhotoCover = (
    urlParams: HttpEditExperienceShellPhotoCoverOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpEditExperienceShellPhotoCoverOptions['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload => httpEditExperienceShellPhotoCover({urlParams, payload}),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
