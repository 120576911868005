import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetExperienceVettingGalleryImages,
    HttpGetExperienceVettingGalleryImagesOptions,
    HttpGetExperienceVettingGalleryImagesResponse
} from '../http/experienceVetting.http'

export const useGetExperienceVettingGalleryImages = (
    urlParams: HttpGetExperienceVettingGalleryImagesOptions['urlParams'],
    options?: Omit<UseQueryOptions<HttpGetExperienceVettingGalleryImagesResponse>, 'queryKey' | 'queryFn'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_GALLERY_IMAGES, urlParams.experienceId],
        queryFn: () =>
            httpGetExperienceVettingGalleryImages({
                urlParams,
                params: {response_type: 'extended'}
            })
    })
}
