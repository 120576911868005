import {useTranslation} from '@/translations/i18n'
import {Content, Fieldset, Footer} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Category, StepKeyName} from '../../types'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {StyledCategories} from './style'
import {categoryFormatToLabel, categoryToCategoryFormats, categoryToIcon, categoryToLabel} from '../../utils'
import {useWatch} from 'react-hook-form'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {FC} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'

export const ExperienceStep: FC<{experienceId: number}> = ({experienceId}) => {
    const {t} = useTranslation()
    const {
        control,
        register,
        resetField,
        setValue,
        formState: {errors}
    } = useExperienceShellFormContext()
    const categoryWatch = useWatch({name: 'experience.categoryKeyName', control})
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.experience
    })

    return (
        <>
            <Content title={t('experience_shell:step_experience')}>
                <Flexbox direction="column" gap={6}>
                    <Fieldset title={t('experience_shell:category')}>
                        <StyledCategories>
                            {Category.options.map(category => {
                                if (category == 'generic') return null
                                const Icon = categoryToIcon[category]
                                return (
                                    <AnswerItem
                                        label={
                                            <Flexbox direction="column" gap={2}>
                                                <Icon />
                                                {t(categoryToLabel[category])}
                                            </Flexbox>
                                        }
                                        type="radio"
                                        value={category}
                                        key={category}
                                        {...register('experience.categoryKeyName', {
                                            onChange: () => {
                                                resetField('experience.subCategoryKeyName')
                                                setValue('legal.categoryKeyName', category)
                                                resetField('legal.categoryKeyName')
                                            }
                                        })}
                                    />
                                )
                            })}
                        </StyledCategories>
                        {errors.experience?.categoryKeyName && (
                            <InputHelpText error={t('experience_shell:category_error')} />
                        )}
                    </Fieldset>
                    {!!categoryWatch && categoryWatch != 'generic' && (
                        <Fieldset title={t('experience_shell:format')}>
                            <StyledCategories>
                                {categoryToCategoryFormats[categoryWatch].map(categoryFormat => (
                                    <AnswerItem
                                        label={t(categoryFormatToLabel[categoryFormat])}
                                        type="radio"
                                        value={categoryFormat}
                                        key={categoryFormat}
                                        {...register('experience.subCategoryKeyName')}
                                    />
                                ))}
                            </StyledCategories>
                            {errors.experience?.subCategoryKeyName && (
                                <InputHelpText error={t('experience_shell:category_format_error')} />
                            )}
                        </Fieldset>
                    )}
                </Flexbox>
            </Content>
            <Footer justify="flex-end">
                <Button
                    size="sm"
                    onClick={() => changeStep.handleChangeStep({stepToGoTo: StepKeyName.enum.background})}
                    disabled={changeStep.isContinueLoading}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
