import {z} from 'zod'

export const EXPERIENCE_OUTPUT_CHARACTER_LIMITS = {
    EXPERIENCE: {
        TITLE: 50,
        DESCRIPTION: 90
    },
    ACTIVITY: {
        TITLE: 20,
        DESCRIPTION: 60
    },
    HOST: {
        TITLE: 50,
        EXPERITSE: 90,
        RECOGNITION_TITLE: 35,
        RECOGNITION_SUBTITLE: 50,
        BIO: 360
    }
} as const

//Schema Atoms
const ExperienceActivitySchema = z.object({
    id: z.coerce.number().optional(),
    title: z.string().optional(),
    description: z.string().optional(),
    duration: z.string().optional()
})

const ExperienceHostDetailsBaseSchema = z.object({
    host_first_name: z.string(),
    expertise_summary: z.string(),
    recognition_summary: z.string().optional()
})

export const ExperienceMerchBaseSchema = z.object({
    listing_title: z.string().min(1, {message: 'Listing title is required'}),
    byline: z.string(),
    location_description: z.string(),
    guest_activities: z.string(),
    activities: z.array(ExperienceActivitySchema).optional()
})

//Schema Molecules: Input
export const ExperienceMerchandisingInputSchema = z.object({
    ...ExperienceHostDetailsBaseSchema.shape,
    ...ExperienceMerchBaseSchema.shape
})

// Schema Molecules: Output
export const ExperienceShellMerchOutputSchema = z.object({
    host_experience_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE, {
            message: `Experience title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE} characters`
        })
        .min(1, {
            message: 'Host title is required'
        }),
    host_expertise_description: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.BIO, {
            message: `Host bio must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.BIO} characters`
        })
        .min(1, {
            message: 'Host bio is required'
        }),
    host_recognition_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_TITLE, {
            message: `Host recognition title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_TITLE} characters`
        })
        .min(1, {
            message: 'Host recognition title is required'
        }),
    host_recognition_subtitle: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_SUBTITLE, {
            message: `Host recognition subtitle must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_SUBTITLE} characters`
        })
        .min(1, {
            message: 'Host recognition subtitle is required'
        }),
    experience_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE, {
            message: `Experience title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE} characters`
        })
        .min(1, {
            message: 'Host education title is required'
        }),
    experience_description: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.DESCRIPTION, {
            message: `Experience description must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Host education description is required'
        }),
    activities: z.array(
        ExperienceActivitySchema.extend({
            title: z
                .string()
                .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.TITLE, {
                    message: `Activity title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.TITLE} characters`
                })
                .min(1, {
                    message: 'Offering title is required'
                }),
            description: z
                .string()
                .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.DESCRIPTION, {
                    message: `Offering description must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.DESCRIPTION} characters`
                })
                .min(30, {
                    message: 'Offering description must be at least 30 characters'
                })
        })
    )
})

export const ExperienceVettingMerchOutputSchema = z.object({
    improved_host_experience_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE, {
            message: `Experience title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE} characters`
        })
        .min(1, {
            message: 'Host title is required'
        }),
    improved_host_expertise_description: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.BIO, {
            message: `Host expertise description must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.BIO} characters`
        })
        .min(1, {
            message: 'Service description is required'
        }),
    improved_host_recognition_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_TITLE, {
            message: `Host recognition title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_TITLE} characters`
        })
        .optional(),
    improved_host_recognition_subtitle: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_SUBTITLE, {
            message: `Host recognition subtitle must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_SUBTITLE} characters`
        })
        .optional(),
    improved_experience_title: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE, {
            message: `Experience title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE} characters`
        })
        .min(1, {
            message: 'Host education title is required'
        }),
    improved_experience_description: z
        .string()
        .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.DESCRIPTION, {
            message: `Experience description must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Host education description is required'
        }),
    activities: z.array(
        ExperienceActivitySchema.extend({
            improved_title: z
                .string()
                .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.TITLE, {
                    message: `Activity title must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.TITLE} characters`
                })
                .min(1, {
                    message: 'Offering title is required'
                }),
            improved_description: z
                .string()
                .max(EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.DESCRIPTION, {
                    message: `Offering description must not exceed ${EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.DESCRIPTION} characters`
                })
                .min(30, {
                    message: 'Offering description must be at least 30 characters'
                })
        })
    )
})

export type ExperienceActivitySchema = z.infer<typeof ExperienceActivitySchema>
export type ExperienceMerchandisingInputSchema = z.infer<typeof ExperienceMerchandisingInputSchema>
export type ExperienceShellMerchOutputSchema = z.infer<typeof ExperienceShellMerchOutputSchema>
export type ExperienceVettingMerchOutputSchema = z.infer<typeof ExperienceVettingMerchOutputSchema>
