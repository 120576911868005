import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledStepsNavigation = styled.aside(
    ({theme: {palette, spacing}}) => css`
        border-right: 1px solid ${palette.neutral[300]};
        padding: ${spacing * 8}px ${spacing * 4}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
    `
)

export const StyledStepButton = styled(Button)(
    ({theme: {spacing, palette}}) => css`
        justify-content: space-between;
        color: ${palette.neutral[600]};
        font-weight: 400;
        transition-property: background;
        height: 44px;
        padding: 0 ${spacing * 2}px;
        & svg {
            width: ${spacing * 6}px;
            height: ${spacing * 6}px;
        }
        & > svg {
            height: initial !important;
            width: initial !important;
        }
        &.active {
            background-color: ${palette.neutral[50]};
            color: ${palette.neutral[900]};
            font-weight: 500;
            outline: 2px solid ${palette.neutral[900]};
        }
        &:disabled {
            color: ${palette.neutral[600]};
        }
    `
)
