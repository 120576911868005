import {useState} from 'react'
import {useAsync} from '@/hooks/useAsync'
import {httpGenerateMerchVettingCopy} from '@/features/ops-vetting/http/ops-vetting.http'
import {generateMerchCopyAiPrompt, parseMarkdownJson} from '@/features/ops-vetting/utils'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '@/features/service-vetting/formSchemas'
import {
    ExperienceMerchandisingInputSchema,
    ExperienceShellMerchOutputSchema,
    ExperienceVettingMerchOutputSchema
} from '@/features/experience-vetting/formSchemas'

export type AiModel = 'palmyra-x-004'
export type MerchInput =
    | ServiceMerchandisingInputSchema
    | ExperienceMerchandisingInputSchema
    | ExperienceShellMerchOutputSchema
export type MerchOutput =
    | ServiceMerchandisingOutputSchema
    | ExperienceShellMerchOutputSchema
    | ExperienceVettingMerchOutputSchema

export const useMerchCopyAi = <T extends MerchOutput>() => {
    const [aiResponse, setAiResponse] = useState<T | undefined>(undefined)
    const {run, isLoading, isError} = useAsync()

    const onMerchCopyAiCall = async (data: MerchInput, customPrompt: string = '') => {
        const formattedPrompt = generateMerchCopyAiPrompt(data, customPrompt)
        try {
            const {data: responseData} = await run(
                httpGenerateMerchVettingCopy({
                    prompt: formattedPrompt,
                    model: 'palmyra-x-004'
                })
            )
            const parsedResponse = parseMarkdownJson(responseData) as T
            setAiResponse(parsedResponse)
            return responseData
        } catch (e) {
            console.error('Error generating merchandising copy:', e)
            throw e
        }
    }

    const resetAiResponse = () => {
        setAiResponse(undefined)
    }

    return {
        aiResponse,
        isLoading,
        aiError: isError,
        onMerchCopyAiCall,
        resetAiResponse
    }
}
