import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {
    httpDeleteServicePhoto,
    HttpDeleteServicePhotoOptions
} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {QUERY_KEYS} from '@/queryClient.ts'

export const useDeleteServicePhotos = (urlParams: HttpDeleteServicePhotoOptions, options?: UseMutationOptions) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: () => httpDeleteServicePhoto(urlParams),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
