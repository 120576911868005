import {
    SelectItem,
    SelectList,
    SelectPopover,
    SelectTrigger,
    SelectVirtualList
} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {SelectProvider} from '@ariakit/react'
import {Category, CategoryFormat} from '@/features/experience-shell/types.ts'
import {FC} from 'react'
import {categoryFormatToLabel} from '@/features/experience-shell/utils.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {useGetExperienceVettingCategoryFormats} from '../../queries/useGetExperienceVettingCategoryFormats'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useUpdateCategorySubCategory} from '../../queries/useUpdateCategorySubCategory'

interface ExpVettingCriteriaCategorySelectProps {
    defaultCategoryFormat: CategoryFormat
    category: Category
    experienceId: number
    disabled?: boolean
}

export const ExpVettingCriteriaCategorySelect: FC<ExpVettingCriteriaCategorySelectProps> = ({
    defaultCategoryFormat,
    category,
    experienceId,
    disabled
}) => {
    const {
        remappedData: categoryFormats,
        isLoading,
        isError,
        fetchNextPage
    } = useGetExperienceVettingCategoryFormats({categoryKeyName: category, limit: 100})
    const updateCategoryFormat = useUpdateCategorySubCategory({experienceId})
    const {t} = useTranslation()
    const onChangeCategory = (value: CategoryFormat) => {
        updateCategoryFormat.mutate({categoryKeyName: category, subCategoryKeyName: value})
    }
    return (
        <SelectProvider setValue={onChangeCategory} value={defaultCategoryFormat}>
            <SelectTrigger
                disabled={disabled || updateCategoryFormat.isPending}
                displayValue={() => <>{t(categoryFormatToLabel[defaultCategoryFormat])}</>}
            />
            <SelectPopover style={{width: '200px'}}>
                <SelectList>
                    {isLoading ? (
                        <Flexbox direction="column" gap={1}>
                            {Array.from({length: 6}, (_, index) => (
                                <Skeleton key={index} height={22} />
                            ))}
                        </Flexbox>
                    ) : isError ? (
                        <InputHelpText error={t('errors:default')} />
                    ) : categoryFormats.length == 0 ? (
                        <InputHelpText helpText={t('commons:no_results')} />
                    ) : (
                        <SelectVirtualList
                            data={categoryFormats}
                            totalCount={categoryFormats.length}
                            overscan={100}
                            endReached={fetchNextPage}
                            itemContent={(_, option) => (
                                <SelectItem value={option.keyName} key={option.id}>
                                    {t(categoryFormatToLabel[option.keyName])}
                                </SelectItem>
                            )}
                        />
                    )}
                </SelectList>
            </SelectPopover>
        </SelectProvider>
    )
}
