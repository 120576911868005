import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useMemo, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {ExpVettingCriteriaConfirmationModal} from '../exp-vetting-criteria-confirmation-modal/ExpVettingCriteriaConfirmationModal'
import {useDialogStore} from '@ariakit/react/dialog'
import {RemapExperienceVetting} from '../../types'
import {checkExperienceErrors} from '@/features/experience-vetting/utils'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'

const MIN_PHOTO_ACCEPTED = 4
export const ExperienceVettingCriteriaHero = ({experience}: {experience: RemapExperienceVetting}) => {
    const {t} = useTranslation()
    const [isWaitlisted, setIsWaitlisted] = useState(false)
    const hideAction = experience.status != 'under_review'
    const store = useDialogStore()

    const setIsTouched = useExperienceVettingError(state => state.setIsTouched)
    const setErrors = useExperienceVettingError(state => state.setErrors)

    const isDeclined = useMemo(() => experience.expertise.status == 'declined', [experience])
    const isBackToDraft = useMemo(
        () =>
            experience.expertise.status == 'back_to_draft' ||
            experience.itineraries.some(item => item.status == 'declined' || item.status == 'back_to_draft') ||
            experience.gallery.images.filter(img => !img.isHostApprovalNeeded && img.isApproved).length <
                MIN_PHOTO_ACCEPTED,
        [experience]
    )

    const getSubtitleByService = () => {
        if (isDeclined) {
            return t('vetting:criteria:commons:confirmation_modal:submission_rejected')
        } else if (isBackToDraft) {
            return t('vetting:criteria:commons:confirmation_modal:submission_back_to_draft')
        } else {
            return t('vetting:criteria:commons:confirmation_modal:submission_accepted')
        }
    }

    const getToastMessage = () => {
        if (isDeclined) {
            return t('vetting:criteria:commons:confirmation_modal:submit_rejected_toast')
        } else if (isBackToDraft) {
            return t('vetting:criteria:commons:confirmation_modal:submit_back_to_draft_toast')
        } else {
            return t('vetting:criteria:commons:confirmation_modal:submit_accepted_toast')
        }
    }

    const onClickSubmit = () => {
        setIsTouched(true)
        const errorCheck = checkExperienceErrors(experience)
        setErrors(errorCheck.errors)
        if (errorCheck.isValid) {
            store.toggle()
        }
    }

    return (
        <OpsVettingHero fullWidth justify={'space-between'}>
            <h3>{t('vetting:criteria:commons:criteria')}</h3>
            {!hideAction && (
                <Flexbox gap={4} align="center">
                    <Checkbox
                        checked={isWaitlisted}
                        id="waitlisted"
                        label={t('vetting:criteria:commons:waitlisted')}
                        onChange={() => setIsWaitlisted(!isWaitlisted)}
                    />
                    <Button variant="primary" size={'sm'} onClick={onClickSubmit}>
                        {t('commons:submit')}
                    </Button>
                </Flexbox>
            )}

            <ExpVettingCriteriaConfirmationModal
                store={store}
                isWaitlisted={isWaitlisted}
                description={getSubtitleByService()}
                experienceId={experience.id}
                toastMessage={getToastMessage()}
            />
        </OpsVettingHero>
    )
}
