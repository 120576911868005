import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledOpsVettingMerchFormHeader} from './style'

interface OpsVettingMerchFormHeaderProps {
    icon: React.ReactNode
    title: string | React.ReactNode
    description: string | React.ReactNode
    variant?: 'base' | 'blue'
    endSlot?: React.ReactNode
}

export const OpsVettingMerchFormHeader = ({
    icon,
    title,
    description,
    variant = 'base',
    endSlot
}: OpsVettingMerchFormHeaderProps) => {
    return (
        <StyledOpsVettingMerchFormHeader direction={'column'} gap={3} $variant={variant}>
            {icon}
            <Flexbox direction={'row'} gap={1} align={'end'} justify={'space-between'} fullWidth>
                <Flexbox direction={'column'} gap={1}>
                    <h3>{title}</h3>
                    <small>{description}</small>
                </Flexbox>
                {endSlot}
            </Flexbox>
        </StyledOpsVettingMerchFormHeader>
    )
}
