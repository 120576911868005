import {Image} from '@/features/photoset/types'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {useTranslation} from 'react-i18next'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {useGalleryCurationShortcuts} from '@/features/photoset/hooks/useGalleryCurationShortcuts'
import {GalleryMetadata} from '@/features/photoset/components/gallery-photoset-modal/gallery-metadata/GalleryMetadata'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'

type GalleryShowcaseModal = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
}

export const GalleryShowcaseModal: FC<GalleryShowcaseModal> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose
}) => {
    const {t} = useTranslation()

    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    useGalleryCurationShortcuts({
        currentDetailImageIndex,
        currentImage,
        setCurrentDetailImageIndex,
        selectableImages,
        currentSpaceIndex: null,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    currentImage={currentImage}
                    imagesCount={selectableImages.length}
                    onClose={onClose}
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImageId={currentImage.id}
                    thumbnails={'thumbnails'}
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('photoset:curation:sidebar_title')}
                    content={<GalleryMetadata imageMetadata={currentImage.metadata} />}
                />
            }
        />
    )
}
