import {FC, useCallback, useMemo} from 'react'
import toast from 'react-hot-toast'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {useSubmitService} from '@/features/service-vetting/query/useSubmitService.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    StyledModalBody,
    StyledModalHeader
} from '@/features/service-vetting/components/service-vetting-submit-modal/style.ts'
import {ModalFooter, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {useGetGalleryImages} from '@/features/service-vetting/query/useGetGalleryImages.ts'
import {useTranslation} from '@/translations/i18n'

interface AdminServiceVettingConfirmationModalProps {
    onClose: () => void
    service: RemappedService
    isWaitlisted: boolean
}
const MINIMUM_REQUIRED_PHOTOS = 4
export const ServiceVettingSubmitModal: FC<AdminServiceVettingConfirmationModalProps> = ({
    service,
    onClose,
    isWaitlisted
}) => {
    const {t} = useTranslation()
    const resetErrors = useServiceVettingError(state => state.reset)
    const submitQuery = useSubmitService(
        {serviceId: service.id},
        {
            onSettled: () => onClose(),
            onSuccess: () => {
                toast.success(getToastMessage())
                resetErrors()
            }
        }
    )
    const imagesQuery = useGetGalleryImages({serviceId: service.id, galleryId: service.gallery.id})

    const galleryImages = useMemo(
        () => imagesQuery.data?.filter(img => !img.is_offering_image) || [],
        [imagesQuery.data]
    )
    const validPhotosCount = useMemo(
        () => galleryImages.filter(img => !img.is_host_approval_needed && img.is_approved).length,
        [galleryImages]
    )
    const isDeclined = useMemo(() => service.expertise.status == 'declined', [service])
    const hasBackToDraftExpertise = service.expertise.status === 'back_to_draft'
    const hasDeclinedOrBackToDraftOfferings = service.offerings.some(
        offering => offering.status === 'declined' || offering.status === 'back_to_draft'
    )
    const hasInsufficientPhotos = validPhotosCount < MINIMUM_REQUIRED_PHOTOS

    const getSubtitleByService = useCallback(() => {
        // Early return if expertise is declined
        if (isDeclined) {
            return t('vetting:criteria:commons:confirmation_modal:submission_rejected')
        }

        // Check if service should go back to draft
        if (hasBackToDraftExpertise || hasDeclinedOrBackToDraftOfferings || hasInsufficientPhotos) {
            return t('vetting:criteria:commons:confirmation_modal:submission_back_to_draft')
        }

        // Default case: service is accepted
        return t('vetting:criteria:commons:confirmation_modal:submission_accepted')
    }, [service, imagesQuery.data])

    const getToastMessage = () => {
        if (isDeclined) {
            return t('vetting:criteria:commons:confirmation_modal:submit_rejected_toast')
        } else if (hasBackToDraftExpertise || hasDeclinedOrBackToDraftOfferings || hasInsufficientPhotos) {
            return t('vetting:criteria:commons:confirmation_modal:submit_back_to_draft_toast')
        } else {
            return t('vetting:criteria:commons:confirmation_modal:submit_accepted_toast')
        }
    }

    return (
        <Modal width={480}>
            <StyledModalHeader>
                <ModalXCloseButton onClick={onClose} />
                <p>{t('vetting:criteria:commons:confirmation_modal:title')}</p>
            </StyledModalHeader>
            <StyledModalBody>
                <h3>{getSubtitleByService()}</h3>
                <p>{t('vetting:criteria:commons:confirmation_modal:description')}</p>
            </StyledModalBody>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose}>
                    {t('commons:cancel')}
                </Button>

                <Button
                    variant={'primary'}
                    onClick={() => submitQuery.mutate({is_waitlisted: isWaitlisted, is_host_approval_needed: false})}
                    disabled={submitQuery.isPending}
                >
                    {t('commons:confirm')}
                    {submitQuery.isPending && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
