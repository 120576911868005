import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import bg from '@assets/images/dot-pattern-bg.png'

export const StyledGalleryModal = styled(Container)(
    ({theme: {zIndex, palette}}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.modal};
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto auto;
        grid-template-rows: minmax(0, 1fr);
        background-color: ${palette.neutral.white};
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-rows: minmax(0, 1fr) auto;
        grid-template-columns: minmax(0, 1fr);
        gap: ${spacing * 4}px;
        padding: ${spacing * 4}px 0;

        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & h4 {
            ${typography.textMd};
            color: ${palette.neutral['900']};
            margin-bottom: ${spacing * 2}px;
        }
        & h5 {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
        & button {
            flex-shrink: 0;
        }
    `
)

export const StyledCurrentImage = styled.img(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px;
        border: 1px solid ${palette.neutral.black}33;
        background-color: ${palette.neutral.white};
    `
)

export const StyledCurrentImagePlaceholder = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid ${palette.neutral.black}33;
        background-color: ${palette.neutral.white};
        font-weight: 500;
        background-image: url(${bg});
    `
)

export const StyledContentMainBody = styled.div<{
    $hasSidebar: boolean
}>(
    ({theme: {spacing}, $hasSidebar}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing * 4}px;
        ${$hasSidebar &&
        css`
            padding-inline-end: ${spacing * 6}px;
        `}
    `
)
