import {captureException} from '@sentry/react'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {MUTATION_KEYS} from 'src/queryClient'
import {httpBulkUploadPhotos, HttpBulkUploadPhotosOptions} from '../http/photoset.http'
import {PhotoPresignedData} from '@/features/photoset/types'
import {errorHandler, parseAxiosPromise} from '@/utilities/helpers'
import {z} from 'zod'

export type UseBulkUploadPhotos = {
    photosetId: number
    options?: UseMutationOptions<PhotoPresignedData[], unknown, HttpBulkUploadPhotosOptions['payload']>
}

export const useBulkUploadPhotos = ({photosetId, options}: UseBulkUploadPhotos) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.BULK_UPLOAD_PHOTOS],
        mutationFn: (payload: HttpBulkUploadPhotosOptions['payload']) =>
            parseAxiosPromise({
                axiosPromise: httpBulkUploadPhotos({
                    urlParams: {photosetId},
                    payload
                }),
                responseShape: z.array(PhotoPresignedData),
                onZodError: captureException
            }),
        ...options,
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
