import {FC, ReactNode} from 'react'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Divider} from '@/components/ui/divider/Divider'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {DefaultNamespace} from 'i18next'
import {StyledGalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/style'

interface GalleryModalSidebarProps {
    title: string | DefaultNamespace
    content: ReactNode
}

export const GalleryModalSidebar: FC<GalleryModalSidebarProps> = ({title, content}) => {
    return (
        <StyledGalleryModalSidebar>
            <h3>{title}</h3>

            <Divider direction="horizontal" />

            <ScrollArea style={{overflow: 'hidden'}} scrollbar={<Scrollbar />}>
                {content}
            </ScrollArea>
        </StyledGalleryModalSidebar>
    )
}
