import styled, {css} from 'styled-components'

export const StyledAnswerContainer = styled.label<{$isReadonly: boolean; $size?: 'sm' | 'md'}>(
    ({theme: {palette, shadows, spacing, typography, transition}, $isReadonly, $size}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral[700]};
        transition: ${transition};
        padding: ${$size == 'sm' ? `${spacing * 2}px ${spacing * 3}px` : `${spacing * 3}px ${spacing * 4}px`};
        box-shadow: ${`${shadows.xs}, 0px 0px 0px 1px ${palette.neutral[300]}`};
        border-radius: ${$size == 'sm' ? '8px' : '12px'};

        ${!$isReadonly &&
        css`
            cursor: pointer;
        `}

        &:has(input:checked) {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px ${$size == 'sm' ? 2 : 3}px ${palette.neutral[700]}`};
        }
        &:has(input:disabled) {
            background-color: ${palette.neutral[100]};
            cursor: not-allowed;
            color: ${palette.neutral[500]};
        }
    `
)

export const StyledExperiencesHostVideoAnswerInput = styled.input`
    display: none;
`
