import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetPhotosetImages,
    HttpGetPhotosetImagesOptions,
    HttpGetPhotosetImagesResponse
} from '@/features/photoset/http/photoset.http'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetPhotosetImages = (
    promiseArgs: HttpGetPhotosetImagesOptions,
    options?: Omit<UseQueryOptions<HttpGetPhotosetImagesResponse>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.IMAGES, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPhotosetImages(promiseArgs),
                responseShape: HttpGetPhotosetImagesResponse,
                onZodError: captureException
            })
    })
