import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useEffect, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {checkErrors} from '@/features/service-vetting/utils.ts'
import {ServiceVettingSubmitModal} from '@/features/service-vetting/components/service-vetting-submit-modal/ServiceVettingSubmitModal.tsx'
import {useGetGalleryImages} from '@/features/service-vetting/query/useGetGalleryImages.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const ServiceVettingCriteriaHero = ({service}: {service: RemappedService}) => {
    const {t} = useTranslation()
    const setIsTouched = useServiceVettingError(state => state.setIsTouched)
    const setErrors = useServiceVettingError(state => state.setErrors)
    const resetErrors = useServiceVettingError(state => state.reset)
    const isTouched = useServiceVettingError(state => state.isTouched)
    const setShowBanner = useServiceVettingError(state => state.setShowBanner)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isWaitlisted, setIsWaitlisted] = useState(false)
    const imagesQuery = useGetGalleryImages({serviceId: service.id, galleryId: service.gallery.id})
    const user = useAuthStore(state => state.user)
    const onClickSubmit = () => {
        if (imagesQuery.data) {
            setIsTouched(true)
            const errorCheck = checkErrors({service: service, images: imagesQuery.data})
            setErrors(errorCheck.errors)
            if (errorCheck.isValid) {
                setShowConfirmationModal(true)
            }
        }
    }
    useEffect(() => {
        if (isTouched && service && imagesQuery.data) {
            const errorCheck = checkErrors({service: service, images: imagesQuery.data})
            setShowBanner(!errorCheck.isValid)
            setErrors(errorCheck.errors)
        }
    }, [service, isTouched, imagesQuery.data])

    useEffect(() => {
        return () => resetErrors()
    }, [])

    const hideAction = service.status != 'under_review' || user?.role == 'read_only' || service.is_host_modified

    return (
        <OpsVettingHero justify={'space-between'} fullWidth>
            <h3>{t('vetting:criteria:service:title')}</h3>
            {!hideAction && (
                <Flexbox gap={4} align="center">
                    <Checkbox
                        checked={isWaitlisted}
                        id="waitlisted"
                        label={t('vetting:criteria:commons:waitlisted')}
                        onChange={() => setIsWaitlisted(!isWaitlisted)}
                    />
                    <Button onClick={onClickSubmit} disabled={imagesQuery.isPending} variant="primary" size={'sm'}>
                        {t('commons:submit')}
                    </Button>
                </Flexbox>
            )}
            {showConfirmationModal && (
                <ServiceVettingSubmitModal
                    onClose={() => setShowConfirmationModal(false)}
                    service={service}
                    isWaitlisted={isWaitlisted}
                />
            )}
        </OpsVettingHero>
    )
}
