import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {useTranslation} from 'react-i18next'
import {Image, StepKey} from '@/features/photoset/types'
import {useGalleryInitialQaShortcuts} from '@/features/photoset/hooks/useGalleryInitialQaShortcuts'
import {GalleryMetadata} from '@/features/photoset/components/gallery-photoset-modal/gallery-metadata/GalleryMetadata'
import {GalleryFlags} from '@/features/photoset/components/gallery-photoset-modal/gallery-flags/GalleryFlags'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'

type GalleryInitialQaModal = {
    images: Image[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
}

export const GalleryInitialQaModal: FC<GalleryInitialQaModal> = ({
    images,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose
}) => {
    const {t} = useTranslation()

    const currentImage = useMemo(() => images[currentDetailImageIndex], [currentDetailImageIndex, images])

    useGalleryInitialQaShortcuts({
        images: [...images.values()],
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader currentImage={currentImage} imagesCount={images.length} onClose={onClose} />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImageId={currentImage.id}
                    thumbnails={'thumbnails'}
                    images={[...images.values()]}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('photoset:curation:sidebar_title')}
                    content={
                        <>
                            <GalleryMetadata imageMetadata={currentImage.metadata} />
                            <GalleryFlags image={currentImage} stepId={StepKey['initial-qa']} />
                        </>
                    }
                />
            }
        />
    )
}
