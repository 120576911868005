import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpEditExperienceSectionFlags,
    httpRemoveExperienceSectionFlags
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience, ExperienceFlag} from '@/features/experience-shell/types.ts'

export const useRemoveExperienceGalleryFlags = (
    urlParams: Omit<HttpEditExperienceSectionFlags['urlParams'], 'section'>,
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ExperienceFlag[]}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpRemoveExperienceSectionFlags({
                urlParams: {...urlParams, section: 'galleries'},
                payload: {flagIds: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        gallery: {
                            ...previousData.gallery,
                            flags: previousData.gallery.flags.filter(
                                flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                            )
                        }
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
