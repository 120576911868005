import {
    StyledBlur,
    StyledBottomLeftSlotWrapper,
    StyledBottomRightSlotWrapper,
    StyledCenterSlotWrapper,
    StyledImage,
    StyledImageContainer,
    StyledGalleryItemSkeleton,
    StyledTopLeftSlotWrapper,
    StyledTopRightSlotWrapper
} from '@components/commons/gallery-image-item/style.ts'
import {HTMLAttributes, ReactNode, useState, forwardRef, ForwardedRef} from 'react'
import {EmptyImagePlaceholder} from '@components/commons/gallery-image-item/EmptyImagePlaceholder.tsx'

interface GalleryImageItemProps extends HTMLAttributes<HTMLDivElement> {
    src: string
    hasBlur?: boolean
    topLeftSlot?: ReactNode
    topRightSlot?: ReactNode
    bottomLeftSlot?: ReactNode
    bottomRightSlot?: ReactNode
    centerSlot?: ReactNode
}

export const GalleryImageItem = forwardRef<HTMLDivElement, GalleryImageItemProps>(
    (
        {src, hasBlur, centerSlot, bottomRightSlot, topRightSlot, bottomLeftSlot, topLeftSlot, ...rest},
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const [imageState, setImageState] = useState<'loading' | 'error' | 'loaded'>('loading')
        return (
            <StyledImageContainer $hasOnclick={!!rest.onClick} {...rest} ref={ref}>
                <StyledImage
                    hidden={imageState == 'loading' || imageState == 'error'}
                    onError={() => setImageState('error')}
                    onLoad={() => setImageState('loaded')}
                    src={src}
                />
                {imageState == 'loading' ? (
                    <StyledGalleryItemSkeleton />
                ) : imageState == 'error' ? (
                    <EmptyImagePlaceholder />
                ) : (
                    <>
                        {topLeftSlot && <StyledTopLeftSlotWrapper>{topLeftSlot}</StyledTopLeftSlotWrapper>}
                        {topRightSlot && <StyledTopRightSlotWrapper>{topRightSlot}</StyledTopRightSlotWrapper>}
                        {bottomLeftSlot && <StyledBottomLeftSlotWrapper>{bottomLeftSlot}</StyledBottomLeftSlotWrapper>}
                        {bottomRightSlot && (
                            <StyledBottomRightSlotWrapper>{bottomRightSlot}</StyledBottomRightSlotWrapper>
                        )}
                        {hasBlur && <StyledBlur />}
                        {centerSlot && <StyledCenterSlotWrapper>{centerSlot}</StyledCenterSlotWrapper>}
                    </>
                )}
            </StyledImageContainer>
        )
    }
)
