import {CSSProperties, forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {StyledAnswerContainer, StyledExperiencesHostVideoAnswerInput} from '@components/commons/answer-item/style.ts'

export interface AnswerItemProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    className?: string
    style?: CSSProperties
    label: ReactNode
    type: 'radio' | 'checkbox'
    inputSize?: 'sm' | 'md'
}
export const AnswerItem = forwardRef<HTMLInputElement, AnswerItemProps>(
    ({className, style, label, inputSize, ...rest}, ref) => {
        return (
            <StyledAnswerContainer $isReadonly={!!rest.readOnly} className={className} style={style} $size={inputSize}>
                {label}
                <StyledExperiencesHostVideoAnswerInput ref={ref} {...rest} />
            </StyledAnswerContainer>
        )
    }
)
