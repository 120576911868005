import styled, {css} from 'styled-components'

export const StyledCharCounterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4px;
`

export const StyledCharCounter = styled.span<{$isExceeded: boolean; $isDisabled: boolean}>(
    ({theme: {typography, palette}, $isExceeded, $isDisabled}) => css`
        ${typography.textXs};
        color: ${$isExceeded ? palette.danger[600] : palette.neutral[600]};
        ${$isDisabled &&
        css`
            color: transparent;
        `}
    `
)
