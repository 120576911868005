import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpUpdateOfferingMainPhoto,
    HttpUpdateOfferingMainPhoto
} from '@/features/service-vetting/http/servicesVetting.http.ts'

export const useUpdateMainPhotoOffering = (
    urlParams: Omit<HttpUpdateOfferingMainPhoto['urlParams'], 'imgId'>,
    options?: UseMutationOptions<unknown, unknown, {imgId: number}>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateOfferingMainPhoto({
                urlParams: {...urlParams, imgId: payload.imgId},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_GALLERY_IMAGES, urlParams.serviceId]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
