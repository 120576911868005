import {zodResolver} from '@hookform/resolvers/zod'
import {FormProvider, useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchOutputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {ArrowRightIcon, PlusIcon, Stars01Icon, Trash01Icon, User01Icon} from '@/components/ui/icon/Icon'
import {OpsVettingMerchFormHeader} from '../../../ops-vetting/components/ops-vetting-merch-form-header/OpsVettingMerchFormHeader'
import {RefObject, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CharCounter} from '@/components/commons/char-counter/CharCounter'
import {Button} from '@/components/ui/button/Button'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {EXPERIENCE_OUTPUT_CHARACTER_LIMITS, ExperienceVettingMerchOutputSchema} from '../../formSchemas'
import toast from 'react-hot-toast'
import {ServiceVettingMerchFormSkeleton} from '@/features/service-vetting/components/service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {useExperienceVettingSaveMerchCopy} from '../../queries/useExperienceVettingSaveMerchCopy'
import {Spinner} from '@/components/ui/spinner/Spinner'

interface ExpVettingMerchOutputFormProps {
    isTraining?: boolean
    isOriginalContent?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isHostEdits?: boolean
    defaultValues?: ExperienceVettingMerchOutputSchema
    finalSubmitRef: RefObject<HTMLButtonElement>
    handleFinalSubmissionCb?: SubmitHandler<ExperienceVettingMerchOutputSchema>
}

export const ExpVettingMerchOutputForm = ({
    isTraining = false,
    isOriginalContent = false,
    isLoading = false,
    isReadOnly = false,
    isHostEdits = false,
    defaultValues,
    finalSubmitRef,
    handleFinalSubmissionCb
}: ExpVettingMerchOutputFormProps) => {
    const {t} = useTranslation()
    const params = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const experienceId = params?.urlParams.id
    const methods = useForm<ExperienceVettingMerchOutputSchema>({
        resolver: !isOriginalContent ? zodResolver(ExperienceVettingMerchOutputSchema) : undefined,
        defaultValues: defaultValues
    })

    const {
        register,
        control,
        reset,
        getValues,
        formState: {errors}
    } = methods
    const {fields, remove, append} = useFieldArray({control, name: 'activities'})

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues])

    const {mutate: saveMerchCopy, isPending: isSavingMerchCopy} = useExperienceVettingSaveMerchCopy(
        {experienceId},
        {
            onSuccess: () => toast.success(t('vetting:merch:experience:generated_content_saved_tooltip'))
        }
    )
    //final submission
    const handleSaveServiceMerchVetting = () => {
        const payload = {
            ...getValues(),
            isHostApprovalNeeded: false,
            isWaitlisted: false
        }
        saveMerchCopy(payload)
    }

    return (
        <FormProvider {...methods}>
            <StyledServiceVettingMerchOutputForm
                $bgStyle={isOriginalContent ? 'light' : 'dark'}
                onSubmit={methods.handleSubmit(handleFinalSubmissionCb || (() => Function.prototype))}
            >
                {!isOriginalContent && (
                    <OpsVettingMerchFormHeader
                        endSlot={
                            !isTraining ? (
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    disabled={isSavingMerchCopy || isReadOnly || isLoading}
                                    onClick={handleSaveServiceMerchVetting}
                                >
                                    {t('commons:save')}
                                    {isSavingMerchCopy && <Spinner size={16} />}
                                </Button>
                            ) : undefined
                        }
                        variant={'blue'}
                        icon={isHostEdits ? <User01Icon /> : <Stars01Icon />}
                        title={
                            isHostEdits
                                ? t('vetting:merch:experience:input_vetting_form_title')
                                : t('vetting:merch:experience:output_shell_form_title')
                        }
                        description={t('vetting:merch:experience:output_shell_form_description')}
                    />
                )}
                {isOriginalContent && (
                    <OpsVettingMerchFormHeader
                        variant={'base'}
                        icon={isHostEdits ? <ArrowRightIcon /> : <User01Icon />}
                        title={
                            isHostEdits
                                ? t('vetting:merch:experience:output_vestting_edits_title')
                                : t('vetting:merch:experience:input_vetting_form_title')
                        }
                        description={
                            isHostEdits
                                ? t('vetting:merch:experience:output_vestting_edits_description')
                                : t('vetting:merch:experience:input_vetting_form_description')
                        }
                    />
                )}

                {isLoading ? (
                    <ServiceVettingMerchFormSkeleton />
                ) : (
                    <Flexbox direction={'column'} gap={2} fullWidth>
                        <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                            <h4>{t('commons:host')}</h4>
                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:experience:expertise__tile_label')}
                                    {...register('improved_host_experience_title')}
                                    errorMessage={errors?.improved_host_experience_title?.message}
                                />
                                <CharCounter<ExperienceVettingMerchOutputSchema>
                                    isDisabled={isOriginalContent}
                                    name="improved_host_experience_title"
                                    maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:experience:expertise_label')}
                                    {...register('improved_host_expertise_description')}
                                    errorMessage={errors?.improved_host_expertise_description?.message}
                                />
                                <CharCounter<ExperienceVettingMerchOutputSchema>
                                    isDisabled={isOriginalContent}
                                    name="improved_host_expertise_description"
                                    maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.BIO}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:experience:recognition_title_label')}
                                    {...register('improved_host_recognition_title')}
                                    errorMessage={errors?.improved_host_recognition_title?.message}
                                />
                                <CharCounter<ExperienceVettingMerchOutputSchema>
                                    isDisabled={isOriginalContent}
                                    name="improved_host_recognition_title"
                                    maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:experience:recognition_description_label')}
                                    {...register('improved_host_recognition_subtitle')}
                                    errorMessage={errors?.improved_host_recognition_subtitle?.message}
                                />
                                <CharCounter<ExperienceVettingMerchOutputSchema>
                                    isDisabled={isOriginalContent}
                                    name="improved_host_recognition_subtitle"
                                    maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.HOST.RECOGNITION_SUBTITLE}
                                />
                            </Flexbox>
                        </StyledServiceMerchVettingFormHostSection>

                        <Divider direction={'horizontal'} />

                        <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                            <h4>{t('commons:experience')}</h4>
                            <Flexbox direction="column" fullWidth>
                                <Flexbox direction="column" fullWidth>
                                    <InputText
                                        disabled={isReadOnly}
                                        type={'text'}
                                        label={t('vetting:merch:experience:listing_title_label')}
                                        errorMessage={errors?.improved_experience_title?.message}
                                        {...register('improved_experience_title')}
                                    />
                                    <CharCounter<ExperienceVettingMerchOutputSchema>
                                        isDisabled={isOriginalContent}
                                        name="improved_experience_title"
                                        maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.TITLE}
                                    />
                                </Flexbox>
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:experience:listing_description_label')}
                                    {...register('improved_experience_description')}
                                    errorMessage={errors?.improved_experience_description?.message}
                                />
                                <CharCounter<ExperienceVettingMerchOutputSchema>
                                    isDisabled={isOriginalContent}
                                    name="improved_experience_description"
                                    maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.EXPERIENCE.DESCRIPTION}
                                />
                            </Flexbox>

                            {fields.map((activity, index) => (
                                <Flexbox fullWidth direction={'column'} gap={3} key={activity.id}>
                                    <Flexbox align={'center'} justify={'space-between'} gap={4}>
                                        <p>
                                            {t('vetting:merch:experience:activity_input_label', {
                                                index: index + 1
                                            })}
                                        </p>
                                        {isTraining && isOriginalContent && fields.length > 1 && (
                                            <Button variant="ghost" size="xs" onClick={() => remove(index)}>
                                                <Trash01Icon />
                                            </Button>
                                        )}
                                    </Flexbox>

                                    <Flexbox direction="column" fullWidth>
                                        <InputText
                                            disabled={isReadOnly}
                                            type={'text'}
                                            label={t('vetting:merch:experience:activity_title_label')}
                                            errorMessage={errors?.activities?.[index]?.improved_title?.message}
                                            {...register(`activities.${index}.improved_title`)}
                                        />
                                        <CharCounter<ExperienceVettingMerchOutputSchema>
                                            isDisabled={isOriginalContent}
                                            name={`activities.${index}.improved_title`}
                                            maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.TITLE}
                                        />
                                    </Flexbox>

                                    <Flexbox direction="column" fullWidth>
                                        <TextArea
                                            disabled={isReadOnly}
                                            rows={3}
                                            label={t('vetting:merch:experience:activity_description_label')}
                                            errorMessage={errors?.activities?.[index]?.improved_description?.message}
                                            {...register(`activities.${index}.improved_description`)}
                                        />
                                        <CharCounter<ExperienceVettingMerchOutputSchema>
                                            isDisabled={isOriginalContent}
                                            name={`activities.${index}.improved_description`}
                                            maxLength={EXPERIENCE_OUTPUT_CHARACTER_LIMITS.ACTIVITY.DESCRIPTION}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            ))}
                            {isTraining && isOriginalContent && fields.length < 10 && (
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => append({improved_title: '', improved_description: ''})}
                                >
                                    <PlusIcon />
                                    {t('vetting:merch:experience:add_activity_label')}
                                </Button>
                            )}
                        </StyledServiceMerchVettingFormOfferingSection>
                    </Flexbox>
                )}
                <button type="submit" ref={finalSubmitRef} style={{display: 'none'}} />
            </StyledServiceVettingMerchOutputForm>
        </FormProvider>
    )
}
