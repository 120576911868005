import {useTranslation} from '@/translations/i18n'
import {ColumnFields, Content, Fieldset, Footer} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {InputText} from '@/components/commons/input-text/InputText'
import {Controller} from 'react-hook-form'
import {CountriesSelect} from '../countries-select/CountriesSelect'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {useChangeStep} from '@/features/experience-shell/hooks/useChangeStep'
import {FC} from 'react'
import {StepKeyName} from '../../types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'

export const LocationStep: FC<{experienceId: number}> = ({experienceId}) => {
    const {t} = useTranslation()
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.location
    })
    const {
        control,
        register,
        formState: {errors}
    } = useExperienceShellFormContext()

    return (
        <>
            <Content title={t('experience_shell:step_location')}>
                <Flexbox direction="column" gap={6}>
                    <Fieldset title={t('experience_shell:meeting_point')}>
                        <Controller
                            control={control}
                            name="location.country_region"
                            render={({field}) => (
                                <CountriesSelect
                                    errorMessage={
                                        errors.location?.country_region ? t('errors:field_required') : undefined
                                    }
                                    onChange={field.onChange}
                                    value={field.value ?? ''}
                                />
                            )}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:street_address')}
                            errorMessage={errors.location?.location_address ? t('errors:field_required') : undefined}
                            {...register('location.location_address')}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:apt_suite')}
                            {...register('location.location_address_extras')}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:city')}
                            errorMessage={errors.location?.location_city ? t('errors:field_required') : undefined}
                            {...register('location.location_city')}
                        />
                        <ColumnFields>
                            <InputText
                                inputSize="sm"
                                type="text"
                                label={t('experience_shell:state_territory')}
                                errorMessage={errors.location?.location_state ? t('errors:field_required') : undefined}
                                {...register('location.location_state')}
                            />
                            <InputText
                                inputSize="sm"
                                type="text"
                                label={t('experience_shell:zip_code')}
                                errorMessage={errors.location?.location_zip ? t('errors:field_required') : undefined}
                                {...register('location.location_zip')}
                            />
                        </ColumnFields>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:meeting_point_details')}>
                        <InputText
                            inputSize="sm"
                            type="text"
                            placeholder={t('experience_shell:enter_name')}
                            label={t('experience_shell:name')}
                            {...register('location.meeting_point_name')}
                        />
                        <TextArea
                            label={t('experience_shell:description')}
                            placeholder={t('experience_shell:enter_description')}
                            {...register('location.meeting_point_description')}
                        />
                    </Fieldset>
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() =>
                        changeStep.handleChangeStep({isBack: true, stepToGoTo: StepKeyName.enum.merchandising})
                    }
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    onClick={() => changeStep.handleChangeStep({stepToGoTo: StepKeyName.enum.photos})}
                    disabled={changeStep.isContinueLoading || changeStep.isBackLoading}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
