import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateExperienceExpertiseStatus,
    httpUpdateExperienceExpertiseStatus
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {Experience} from '@/features/experience-shell/types.ts'

export const useUpdateExperienceExpertiseStatus = (
    {experienceId, expertiseId}: {experienceId: number; expertiseId: number},
    options?: UseMutationOptions<unknown, unknown, HttpUpdateExperienceExpertiseStatus['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExperienceExpertiseStatus({
                urlParams: {experienceId: experienceId, expertiseId: expertiseId},
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, experienceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        expertise: {...previousData.expertise, status: variables.status}
                    }
                )
            }
            return previousData
        }
    })
}
