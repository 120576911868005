import {useTranslation} from '@/translations/i18n'
import {Content, Footer} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {PhotoStepGallery} from '@/features/experience-shell/components/photos-step-gallery/PhotoStepGallery.tsx'
import {Experience, StepKeyName} from '@/features/experience-shell/types.ts'
import {useLayoutEffect} from 'react'
import {useExperienceShellPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {useChangeStep} from '@/features/experience-shell/hooks/useChangeStep.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {checkIsItineraryLocked} from '../../utils'

export const PhotosStep = ({
    gallery,
    itineraries
}: {
    gallery: Experience['gallery']
    itineraries: Experience['itineraries']
}) => {
    const changeStep = useChangeStep({
        experienceId: gallery.submissionId,
        currentStep: StepKeyName.enum.photos
    })
    const addGalleryItems = useExperienceShellPhotoUpload(store => store.addGalleryItems)
    const clearGallery = useExperienceShellPhotoUpload(store => store.clearGallery)
    const {t} = useTranslation()
    useLayoutEffect(() => {
        addGalleryItems(
            gallery.images.map(item => ({
                id: item.id,
                status: 'uploaded',
                url: item.url,
                thumbnails: item.thumbnails,
                isCover: item.isCover,
                name: item.name
            }))
        )
        return () => clearGallery()
    }, [gallery])

    return (
        <>
            <Content title={t('experience_shell:step_photos')}>
                <PhotoStepGallery galleryId={gallery.id} experienceId={gallery.submissionId} />
            </Content>
            <Footer>
                <Button
                    disabled={changeStep.isBackLoading}
                    size="sm"
                    variant="ghost"
                    onClick={() => changeStep.handleChangeStep({isBack: true, stepToGoTo: StepKeyName.enum.location})}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    disabled={changeStep.isContinueLoading}
                    onClick={() =>
                        changeStep.handleChangeStep({
                            stepToGoTo: checkIsItineraryLocked(itineraries)
                                ? StepKeyName.enum.pricing_discounts
                                : StepKeyName.enum.itinerary
                        })
                    }
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
