import {Fieldset, YesNoField} from '../atoms/Atoms'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {useTranslation} from '@/translations/i18n'
import {Controller, useWatch} from 'react-hook-form'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {StyledAccessibilityFeatureAdded} from './style'
import {AccessibilityFeaturesModal} from './AccessibilityFeaturesModal'

export const AccessibilityFeatureSection = ({experienceId}: {experienceId: number}) => {
    const {t} = useTranslation()
    const {
        control,
        setValue,
        formState: {errors}
    } = useExperienceShellFormContext()

    const accessibilityFeaturesWatch = useWatch({control, name: 'settings.accessibility_features'})
    const accessibilityFeatureWatch = useWatch({control, name: 'settings.accessibility_feature'})

    return (
        <Fieldset>
            <Controller
                control={control}
                name="settings.accessibility_feature"
                render={({field}) => (
                    <Flexbox direction="column" gap={2} fullWidth>
                        <YesNoField
                            labelTitle={<b>{t('experience_shell:accessibility_features')}</b>}
                            name={field.name}
                            value={field.value}
                            onChange={value => {
                                field.onChange(value)
                                setValue('settings.accessibility_features', [])
                                setValue('settings.accessibility_additional_information', '')
                                setValue('settings.accessibility_additional_qualifications', '')
                            }}
                        />
                        {errors.settings?.accessibility_feature && <InputHelpText error={t('errors:field_required')} />}
                    </Flexbox>
                )}
            />
            {!!accessibilityFeatureWatch && (
                <Flexbox align="center">
                    <StyledAccessibilityFeatureAdded>
                        {t('experience_shell:x_added', {count: accessibilityFeaturesWatch?.length ?? 0})}
                    </StyledAccessibilityFeatureAdded>
                    <AccessibilityFeaturesModal experienceId={experienceId} />
                </Flexbox>
            )}
        </Fieldset>
    )
}
