import {SelectItem, SelectPopover, SelectArrow, SelectList, ComboboxList} from '@ariakit/react'
import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button'
import {Virtuoso} from 'react-virtuoso'

export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

export const StyledSelectPopover = styled(SelectPopover)(
    ({theme: {palette, shadows, spacing, zIndex}}) => css`
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        padding: ${spacing}px ${spacing * 1.5}px;
        max-height: 328px;
        max-width: var(--popover-available-width);
        overflow-y: hidden;
        display: grid;
        grid-template-rows: minmax(0, 1fr);
        z-index: ${zIndex.dropdown};
    `
)

export const StyledSelectComboboxPopover = styled(StyledSelectPopover)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing}px;
    `
)

export const StyledSelectList = styled(SelectList)`
    overflow-y: auto;
`

export const StyledSelectComboboxList = styled(ComboboxList)`
    overflow-y: auto;
`

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {palette, spacing, typography, transition}}) => css`
        ${typography.textMd};
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        cursor: pointer;
        border-radius: 6px;
        margin: 1px 0;
        color: ${palette.neutral[900]};
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: ${transition};

        &[aria-disabled='true'] {
            color: ${palette.neutral['400']};
        }

        &[aria-selected='true'] {
            background-color: ${palette.primary['100']};
        }

        &:not([aria-selected='true'])[data-active-item='true'] {
            background-color: ${palette.neutral['50']};
        }
    `
)

export const StyledSelectTrigger = styled(Button)<{$hasChildren: boolean}>(
    ({theme: {palette}, $hasChildren}) => css`
        justify-content: space-between;
        ${!$hasChildren &&
        css`
            font-weight: 400;
            color: ${palette.neutral['400']};
        `};

        &:not(:disabled):hover,
        &:focus,
        &[aria-expanded='true'] {
            outline: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[100]};
        }
        &:disabled {
            background-color: ${palette.neutral['100']};
        }
    `
)

export const StyledSelectArrow = styled(SelectArrow)<{$isOpen: boolean}>(
    ({theme: {transition, palette}, $isOpen}) => css`
        fill: ${palette.neutral[500]};
        transition: ${transition};
        margin-left: auto;
        ${$isOpen &&
        css`
            transform: rotate(180deg);
        `}
    `
)

export const StyledSelectVirtualList: typeof Virtuoso = styled(Virtuoso)(
    ({theme: {palette}}) => css`
        height: 200px !important;
        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `
)
