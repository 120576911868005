import {GalleryImageItem} from '@components/commons/gallery-image-item/GalleryImageItem.tsx'
import {OpsVettingGalleryItemsContainer} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {CircularProgressBar} from '@/features/service-vetting/components/circular-progress-bar/CircularProgressBar.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {GalleryItem, useExperienceVettingPhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
export const ExpVettingCriteriaGalleryUploadingItem = ({
    image,
    hideAction
}: {
    image: GalleryItem
    hideAction: boolean
}) => {
    const {t} = useTranslation()
    const uploadProgressValues = useExperienceVettingPhotoUpload(store => store.uploadProgressValues)
    const deleteGalleryItem = useExperienceVettingPhotoUpload(store => store.deleteGalleryItem)

    const onClickDeletePhoto = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        deleteGalleryItem(image.id)
    }

    return (
        <OpsVettingGalleryItemsContainer>
            <GalleryImageItem
                src={image.thumbnails?.xs || image.url}
                hasBlur={image.status != 'uploaded'}
                centerSlot={
                    image.status == 'pending' ? (
                        <CircularProgressBar progress={uploadProgressValues[image.id] || 0} />
                    ) : (
                        image.status == 'error' && <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                    )
                }
                bottomLeftSlot={
                    <Chip label={t('vetting:criteria:service:gallery:internal_upload')} variant={'neutral'} />
                }
                topRightSlot={
                    <Button variant={'secondary'} iconOnly disabled={hideAction} onClick={onClickDeletePhoto}>
                        <Trash01Icon />
                    </Button>
                }
            />
        </OpsVettingGalleryItemsContainer>
    )
}
