import {
    DialogProvider as AriakitDialogProvider,
    DialogDisclosure as AriakitDialogDisclosure,
    DialogProps,
    DialogDismiss as AriakitDialogDismiss,
    DialogDismissProps,
    DialogHeadingProps,
    DialogDescriptionProps
} from '@ariakit/react'
import {
    StyledDialog,
    StyledDialogDescription,
    StyledDialogHeader,
    StyledDialogHeading,
    StyledDialogBody,
    StyledDialogFooter
} from './style'
import {Overlay} from '../overlay/Overlay'
import {XCloseIcon} from '../icon/Icon'
import {Button} from '../button/Button'
import {CSSProperties, HTMLAttributes} from 'react'

export const DialogProvider = AriakitDialogProvider
export const DialogDisclosure = AriakitDialogDisclosure
export const Dialog = ({width, ...rest}: DialogProps & {width?: CSSProperties['width']}) => (
    <StyledDialog backdrop={<Overlay />} unmountOnHide portal $width={width} {...rest} />
)
export const DialogDismiss = (props: DialogDismissProps) => (
    <AriakitDialogDismiss
        render={
            <Button variant="ghost" size="sm" iconOnly>
                <XCloseIcon />
            </Button>
        }
        {...props}
    />
)
export const DialogHeading = (props: DialogHeadingProps) => <StyledDialogHeading {...props} />
export const DialogDescription = (props: DialogDescriptionProps) => <StyledDialogDescription {...props} />
export const DialogHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledDialogHeader {...props} />
export const DialogBody = ({gap = 6, ...rest}: HTMLAttributes<HTMLDivElement> & {gap?: number}) => (
    <StyledDialogBody $gap={gap} {...rest} />
)
export const DialogFooter = (props: HTMLAttributes<HTMLDivElement>) => <StyledDialogFooter {...props} />
