import {forwardRef, InputHTMLAttributes} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {StyledRadio, StyledRadioInputWrapper} from '@components/commons/radio/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string | React.ReactNode
    labelPosition?: 'left' | 'right'
    errorMessage?: string
    helpText?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({className, label, labelPosition = 'right', helpText, ...rest}, ref) => {
        return (
            <Flexbox direction="column" gap={2} className={className}>
                <StyledRadioInputWrapper>
                    <StyledRadio labelPosition={labelPosition} type="radio" ref={ref} {...rest} />
                    {typeof label === 'string' ? <p>{label}</p> : label}
                </StyledRadioInputWrapper>
                <InputHelpText helpText={helpText} />
            </Flexbox>
        )
    }
)

Radio.displayName = 'Radio'
