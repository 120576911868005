import {useEditStep} from '../queries/useEditStep'
import {useEditExperience} from '../queries/useEditExperience'
import {errorHandler} from '@/utilities/helpers'
import {ShellStepKeyName} from '../types'
import {useState} from 'react'
import {useExperienceShellFormContext} from './useExperienceShellFormContext'
import {httpEditExperienceOptions} from '../http/experienceShell.http'

export const useChangeStep = ({experienceId, currentStep}: {experienceId: number; currentStep: ShellStepKeyName}) => {
    const [isBackPending, setIsBackPending] = useState(false)
    const {getValues} = useExperienceShellFormContext()
    const editStepMutation = useEditStep({
        experienceId,
        options: {onError: errorHandler}
    })
    const editExperienceMutation = useEditExperience({
        experienceId,
        options: {onError: errorHandler}
    })
    const isBackLoading = isBackPending && (editStepMutation.isPending || editExperienceMutation.isPending)
    const isContinueLoading = !isBackPending && (editStepMutation.isPending || editExperienceMutation.isPending)

    const stepToEditStepKeyName = (step: ShellStepKeyName) => {
        if (step == ShellStepKeyName.enum.pricing_discounts) {
            return 'pricing-discounts'
        }
        return step
    }

    const getPayload = (): httpEditExperienceOptions['payload'] => {
        if (currentStep == ShellStepKeyName.enum.photos) {
            return {}
        }
        if (currentStep == ShellStepKeyName.enum.background) {
            const values = getValues(currentStep)
            return {
                ...values,
                online_profile_links: values.online_profile_links?.filter(link => !!link.link).map(link => link.link)
            }
        }
        if (currentStep == ShellStepKeyName.enum.settings) {
            const values = getValues(currentStep)
            return {
                ...values,
                guest_bringing_items: values.guest_bringing_items?.filter(item => !!item.item).map(item => item.item)
            }
        }
        return getValues(currentStep)
    }

    const handleChangeStep = async ({isBack, stepToGoTo}: {isBack?: boolean; stepToGoTo: ShellStepKeyName}) => {
        setIsBackPending(!!isBack)
        await editExperienceMutation.mutateAsync(getPayload())
        await editStepMutation.mutateAsync(stepToEditStepKeyName(stepToGoTo))
        setIsBackPending(false)
    }

    return {
        editStepMutation,
        editExperienceMutation,
        isBackLoading,
        isContinueLoading,
        handleChangeStep
    }
}
