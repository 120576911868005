import styled, {css} from 'styled-components'

export const StyledExperienceShell = styled.div(
    ({theme: {palette}}) => css`
        height: 100%;
        display: grid;
        grid-template-columns: 280px minmax(0, 1fr);
        grid-template-rows: auto minmax(0, 1fr) auto;
        overflow: hidden;
        color: ${palette.neutral[900]};
        & header {
            grid-column: 1 / -1;
            border-bottom: 1px solid ${palette.neutral[300]};
        }
        & aside {
            grid-row: 2 / -1;
        }
    `
)
