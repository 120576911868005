import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpUpdateExperienceVettingGalleryImagesPositionsOptions,
    httpUpdateExperienceVettingGalleryImagesPositions
} from '@/features/experience-vetting/http/experienceVetting.http.ts'
import {AxiosResponse} from 'axios'
import {Experience} from '@/features/experience-shell/types'

export const useUpdateExperienceVettingGalleryImagesPositions = (
    urlParams: HttpUpdateExperienceVettingGalleryImagesPositionsOptions['urlParams'],
    options?: UseMutationOptions<
        AxiosResponse<unknown>,
        unknown,
        HttpUpdateExperienceVettingGalleryImagesPositionsOptions['payload']
    >
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: payload =>
            httpUpdateExperienceVettingGalleryImagesPositions({
                urlParams,
                payload,
                params: {
                    responseType: 'small'
                }
            }),
        onError: async (error, variables, context) => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]
            const previousData = await queryClient.getQueryData(queryKey)
            await queryClient.cancelQueries({queryKey})
            queryClient.setQueriesData({queryKey}, previousData)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.EXPERIENCE_VETTING, urlParams.experienceId]

            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Experience>(queryKey)

            // Update cached data with new positions
            if (previousData) {
                const updatedImages = previousData.gallery.images

                // Create a map of id to new position
                const positionMap = variables.reduce(
                    (acc, item) => {
                        acc[item.id] = item.position
                        return acc
                    },
                    {} as Record<number, number>
                )

                // Sort images based on new positions
                updatedImages.sort((a, b) => {
                    const posA = positionMap[a.id]
                    const posB = positionMap[b.id]
                    return posA - posB
                })

                queryClient.setQueriesData<Experience>(
                    {queryKey},
                    {
                        ...previousData,
                        gallery: {
                            ...previousData.gallery,
                            images: updatedImages
                        }
                    }
                )
            }

            return previousData
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.EXPERIENCE_VETTING_GALLERY_IMAGES, urlParams.experienceId]
            })
            options?.onSuccess?.(data, variables, context)
        },
        ...options
    })
}
