import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {
    BulkPresignedExperienceShellPhotosResponse,
    httpStorePresignBulkExperienceShellPhotos,
    HttpStorePresignBulkExperienceShellPhotosOptions
} from '@/features/experience-shell/http/experienceShell.http.ts'

export const useBulkPresignedExperienceShellPhotos = ({
    urlParams,
    options
}: {
    urlParams: HttpStorePresignBulkExperienceShellPhotosOptions['urlParams']
    options?: UseMutationOptions<
        BulkPresignedExperienceShellPhotosResponse,
        unknown,
        Omit<HttpStorePresignBulkExperienceShellPhotosOptions['payload'], 'mutationFn'>
    >
}) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.EXPERIENCES_SHELL_PHOTO_PRESIGNED, urlParams],
        mutationFn: payload => httpStorePresignBulkExperienceShellPhotos({urlParams, payload}),
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
