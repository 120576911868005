import {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {StyledInitialQaImagesPool} from '@/features/photoset/components/initial-qa-images-pool/style.ts'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {useGetPhotosetImagesList} from '@/features/photoset/queries/useGetPhotosetImagesList'
import {AlertCircleIcon, LayoutRightIcon} from '@components/ui/icon/Icon.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ListHeader} from '@components/ui/list-header/ListHeader'
import {ImagesList} from '@/features/photoset/components/images-list/ImagesList'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams, StepKey} from '@/features/photoset/types.ts'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {ImageCardFlags} from '@/features/photoset/components/image-card-flags/ImageCardFlags'
import {handleImagesRangeSelect} from '@/features/photoset/utils.ts'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {GalleryInitialQaModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-initial-qa-modal/GalleryInitialQaModal'
import {MultipleFlagsPopoverTrigger} from '@/features/photoset/components/flags-popover/MultipleFlagsPopoverTrigger'
import {ImagesCounter} from '@/features/photoset/components/images-counter/ImagesCounter'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'

interface InitialQaImagesPoolProps {
    hasSidebar: boolean
    showSidebar: () => void
}

export const InitialQaImagesPool: FC<InitialQaImagesPoolProps> = ({hasSidebar, showSidebar}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const images = usePhotosetStore(state => state.images)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const selectAll = usePhotosetStore(state => state.selectAll)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const unselectImages = usePhotosetStore(state => state.unselectImages)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const [lastSelectedImageId, setLastSelectedImageId] = useState<number | null>(null)

    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    const imagesQuery = useGetPhotosetImagesList(
        {
            urlParams: {photosetId: urlParams.id}
        },
        {enabled: false}
    )

    const remappedImages = [...images.values()]

    const {currentDetailImageIndex, setCurrentDetailImageIndex, onExpandImage} = useExpandPhotosetImage()

    const bedroomsCount = useMemo(() => photosetQuery.data?.assignment.task.entity.bedrooms, [photosetQuery])

    return (
        <StyledInitialQaImagesPool>
            <ListHeader
                size="sm"
                title={
                    imagesQuery.isLoading ? (
                        <Skeleton height={30} width={96} />
                    ) : selectedImages.length >= 1 ? (
                        t('commons:x_selected_images', {
                            selected: selectedImages.length,
                            count: remappedImages.length
                        })
                    ) : (
                        t('commons:x_images', {count: remappedImages.length})
                    )
                }
                startSlot={!imagesQuery.isLoading && <p>{t('commons:x_bedrooms', {count: bedroomsCount ?? 0})}</p>}
                endSlot={
                    <Flexbox align="center">
                        {selectedImages.length >= 1 ? (
                            <Flexbox align="center" gap={2}>
                                <ImagesCounter count={selectedImages.length} />

                                <FlagsPopover
                                    stepId={StepKey['initial-qa']}
                                    selectedImagesIds={selectedImages}
                                    trigger={<MultipleFlagsPopoverTrigger />}
                                />

                                <Button
                                    size="sm"
                                    variant="tertiary"
                                    onClick={() => {
                                        unselectAll()
                                        setLastSelectedImageId(null)
                                    }}
                                >
                                    {t('commons:unselect_all')}
                                </Button>
                            </Flexbox>
                        ) : imagesQuery.data?.length ? (
                            <Button
                                size="sm"
                                variant="tertiary"
                                onClick={() => {
                                    selectAll()
                                    setLastSelectedImageId(null)
                                }}
                            >
                                {t('commons:select_all')}
                            </Button>
                        ) : null}

                        {!hasSidebar && (
                            <Button variant="tertiary" onClick={showSidebar} iconOnly>
                                <LayoutRightIcon />
                            </Button>
                        )}
                    </Flexbox>
                }
            />
            {imagesQuery.isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <ScrollArea scrollbar={<Scrollbar />}>
                    <ImagesList>
                        {imagesQuery.isPending
                            ? Array.from({length: 20}).map((_, index) => <ImageCard asSkeleton key={index} />)
                            : photosetQuery.data &&
                              remappedImages.map((image, index, array) => {
                                  const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)
                                  return (
                                      <ImageCard
                                          image={image}
                                          thumbnail={image.thumbnails?.xs}
                                          key={image.id}
                                          isSelected={isSelected}
                                          onSelect={isShiftPressed => {
                                              handleImagesRangeSelect({
                                                  array,
                                                  image,
                                                  index,
                                                  isSelected,
                                                  isShiftPressed,
                                                  lastSelectedImageId,
                                                  selectImages: selectImages,
                                                  setLastSelectedImageId,
                                                  unselectImages: unselectImages
                                              })
                                          }}
                                          endSlot={
                                              <>
                                                  <ExpandImageButton onClick={() => onExpandImage(null, index)} />
                                                  <ImageCardFlags image={image} stepId={StepKey['initial-qa']} />
                                              </>
                                          }
                                      />
                                  )
                              })}
                    </ImagesList>
                </ScrollArea>
            )}

            {currentDetailImageIndex != null && currentDetailImageIndex >= 0 && (
                <GalleryInitialQaModal
                    images={remappedImages}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    onClose={() => setCurrentDetailImageIndex(null)}
                />
            )}
        </StyledInitialQaImagesPool>
    )
}

InitialQaImagesPool.displayName = 'InitialQaImagesPool'
