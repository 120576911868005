import styled, {css} from 'styled-components'
import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator
} from '@radix-ui/react-dropdown-menu'

export const StyledDropdownContent = styled(DropdownMenuContent)(
    ({theme: {palette, spacing, shadows}}) => css`
        width: 200px;
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
        padding: ${spacing}px 0;
        box-shadow: ${shadows.lg};
        max-height: 400px;
        overflow: auto;
    `
)

export const StyledDropdownLabel = styled(DropdownMenuLabel)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textXs};
        font-weight: 500;
        color: ${palette.neutral['500']};
        padding: ${spacing}px ${spacing * 3}px;
    `
)

export const StyledDropdownSeparator = styled(DropdownMenuSeparator)(
    ({theme: {palette, spacing}}) => css`
        height: 1px;
        width: 100%;
        background-color: ${palette.neutral['300']};
        margin: ${spacing}px 0;
    `
)

export const StyledDropdownItem = styled(DropdownMenuItem)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['700']};
        padding: ${spacing * 2.5}px ${spacing * 2.5}px;
        transition: ease-in-out 150ms;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:not([data-disabled]):hover,
        &:not([data-disabled]):focus {
            cursor: pointer;
            background-color: ${palette.neutral['50']};
        }
        &[data-disabled] {
            pointer-events: none;
            cursor: not-allowed;
        }
    `
)
