import {CSSProperties, HTMLAttributes, ImgHTMLAttributes, forwardRef} from 'react'
import {
    StyledOpsVettingDisabledContainer,
    StyledOpsVettingLabel,
    StyledOpsVettingParagraph,
    StyledOpsVettingCardTitle,
    StyledOpsVettingSectionTitle,
    StyledOpsVettingHero,
    StyledOpsVettingQuestionsGrid,
    StyledOpsVettingSectionWrapper,
    StyledOpsVettingCardContainer,
    StyledOpsVettingImage,
    StyledOpsVettingImageTitle,
    StyledOpsVettingCardGrid,
    StyledOpsVettingGalleryGrid,
    StyledOpsVettingCardBodyContainer,
    StyledOpsVettingImagePlaceholderBox,
    StyledOpsVettingGalleryItemsContainer,
    StyledVettingFlagsContainer,
    StyledOpsVettingCheckIconContainer,
    StyledOpsVettingChoosePhotoDialog,
    StyledOpsVettingChoosePhotoDialogBody,
    StyledOpsVettingNoCoverPhotoContainer,
    StyledOpsVettingRejectDialogBody,
    StyledOpsVettingRejectDialogBodyFlagsItem,
    StyledOpsVettingRejectDialog,
    StyledDragButton
} from './style'
import {FlexboxProps} from '@components/ui/flexbox/FlexBox.tsx'
import {Image01Icon} from '@components/ui/icon/Icon.tsx'
import {DialogProps} from '@ariakit/react/dialog'
import {ButtonProps} from '@/components/ui/button/Button'

export const OpsVettingSectionTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingSectionTitle {...props} />
)
export const OpsVettingCardTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingCardTitle {...props} />
)
export const OpsVettingLabel = (props: HTMLAttributes<HTMLSpanElement>) => <StyledOpsVettingLabel {...props} />
export const OpsVettingParagraph = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingParagraph {...props} />
)

export const OpsVettingDisabledContainer = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & {$isDisabled: boolean}
>((props, ref) => {
    return <StyledOpsVettingDisabledContainer {...props} ref={ref} />
})
export const OpsVettingHero = (props: FlexboxProps<'div'>) => {
    return <StyledOpsVettingHero {...props} />
}

export const OpsVettingQuestionsGrid = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingQuestionsGrid {...props} />
)

export const OpsVettingSectionWrapper = (props: FlexboxProps<'div'>) => <StyledOpsVettingSectionWrapper {...props} />

export const OpsVettingCardContainer = (props: FlexboxProps<'div'>) => <StyledOpsVettingCardContainer {...props} />

export const OpsVettingImage = (props: ImgHTMLAttributes<HTMLImageElement>) => <StyledOpsVettingImage {...props} />

export const OpsVettingImageTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingImageTitle {...props} />
)

export const OpsVettingCardGrid = (props: HTMLAttributes<HTMLDivElement>) => <StyledOpsVettingCardGrid {...props} />

export const OpsVettingGalleryGrid = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingGalleryGrid {...props} />
)

export const OpsVettingCardBodyContainer = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingCardBodyContainer {...props} />
)
export const OpsVettingGalleryItemsContainer = (props: FlexboxProps<'div'>) => (
    <StyledOpsVettingGalleryItemsContainer {...props} />
)

export const OpsVettingFlagsContainer = (props: FlexboxProps<'div'> & {$flagPosition: 'left' | 'right'}) => (
    <StyledVettingFlagsContainer {...props} />
)

export const OpsVettingImgPlaceHolder = (props: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => (
    <StyledOpsVettingImagePlaceholderBox {...props}>
        <Image01Icon />
    </StyledOpsVettingImagePlaceholderBox>
)

export const OpsVettingCheckIconContainer = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingCheckIconContainer {...props} />
)

export const OpsVettingChoosePhotoDialog = (props: DialogProps & {width?: CSSProperties['width']}) => (
    <StyledOpsVettingChoosePhotoDialog {...props} />
)

export const OpsVettingChoosePhotoDialogBody = (props: HTMLAttributes<HTMLDivElement> & {gap?: number}) => (
    <StyledOpsVettingChoosePhotoDialogBody {...props} />
)

export const OpsVettingNoCoverPhotoContainer = (props: FlexboxProps<'div'>) => (
    <StyledOpsVettingNoCoverPhotoContainer {...props} />
)

export const OpsVettingRejectDialogBody = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingRejectDialogBody {...props} />
)

export const OpsVettingRejectDialogBodyFlagsItem = (props: FlexboxProps<'div'>) => (
    <StyledOpsVettingRejectDialogBodyFlagsItem {...props} />
)

export const OpsVettingRejectDialog = (props: DialogProps & {width?: CSSProperties['width']}) => (
    <StyledOpsVettingRejectDialog {...props} />
)

export const OpsVettingDragButton = (props: ButtonProps & {$isDragging: boolean}) => <StyledDragButton {...props} />
