import {useTranslation} from 'react-i18next'
import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Expand01Icon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'
import {ServiceGalleryImage} from '@/features/service-vetting/types.ts'
import {useUpdateIsApprovedGalleryImg} from '@/features/service-vetting/query/useUpdateIsApprovedGalleryImg.ts'
import {memo} from 'react'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {ServiceVettingGalleryImageRejectDialog} from '../service-vetting-gallery-image-reject-dialog/ServiceVettingGalleryImageRejectDialog'

export const ServiceVettingCriteriaGalleryItem = memo(
    ({
        image,
        galleryId,
        hideAction,
        onClickExpand,
        serviceId
    }: {
        image: ServiceGalleryImage
        galleryId: number
        hideAction?: boolean
        serviceId: number
        onClickExpand?: (image: ServiceGalleryImage) => void
    }) => {
        const {t} = useTranslation()

        const updateIsApprovedGalleryImg = useUpdateIsApprovedGalleryImg({
            serviceId,
            imgId: image.id,
            galleryId
        })

        return (
            <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                <GalleryImageItem
                    src={image.thumbnails?.xs || image.url}
                    bottomRightSlot={
                        !hideAction && (
                            <Button
                                variant="secondary"
                                iconOnly
                                className="show-on-hover"
                                size="sm"
                                onClick={() => onClickExpand?.(image)}
                            >
                                <Expand01Icon />
                            </Button>
                        )
                    }
                />
                <Flexbox gap={2} direction="column" fullWidth>
                    <OpsVettingImageTitle>{image.name}</OpsVettingImageTitle>
                    <Flexbox gap={3} fullWidth>
                        <Button
                            fullWidth
                            size={'sm'}
                            disabled={hideAction}
                            variant={image.is_approved === null || !image.is_approved ? 'secondary' : 'primarySuccess'}
                            onClick={() =>
                                image.is_approved != true && updateIsApprovedGalleryImg.mutate({is_approved: true})
                            }
                        >
                            <ThumbsUpIcon />
                            {t('vetting:criteria:service:gallery:accept')}
                        </Button>
                        <ServiceVettingGalleryImageRejectDialog
                            image={image}
                            serviceId={serviceId}
                            galleryId={galleryId}
                            currentFlags={image.flags}
                            disabled={hideAction}
                        />
                    </Flexbox>
                </Flexbox>
            </OpsVettingGalleryItemsContainer>
        )
    }
)
