import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {RedirectBox, RedirectCtaWrapper, RedirectWrapper} from '@pages/sso-redirect/style.ts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import {Link} from 'react-router-dom'

export const SsoRedirect = () => {
    //here we will perform all the logic needed after sso response
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Container>
            <RedirectWrapper>
                <RedirectBox>
                    <Spinner size={48} fill={theme.palette.neutral['500']} />
                    <h3>{t('redirect:title')}</h3>
                    <p>{t('redirect:subtitle')}</p>
                    <RedirectCtaWrapper>
                        <small>{t('redirect:cta_pre')}</small>
                        <Link to={'/redirect'}>{t('redirect:cta')}</Link>
                    </RedirectCtaWrapper>
                </RedirectBox>
            </RedirectWrapper>
        </Container>
    )
}

SsoRedirect.displayName = 'SsoRedirect'
