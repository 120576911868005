export const CircularProgress = ({
    progress,
    size,
    className,
    background = 'lightgray',
    strokeColor = 'black'
}: {
    progress: number
    size: number
    className?: string
    background?: string
    strokeColor?: string
}) => {
    const radius = size / 2 // Radius of the circle
    const stroke = radius * 0.25
    const normalizedRadius = radius - stroke * 0.5
    const circumference = normalizedRadius * 2 * Math.PI

    // Calculate stroke-dashoffset based on the progress
    const strokeDashoffset = circumference - (progress / 100) * circumference

    return (
        <svg height={radius * 2} width={radius * 2} className={className}>
            <circle
                stroke={background}
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke={strokeColor}
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={strokeDashoffset}
                strokeLinecap="round"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                style={{
                    transition: 'stroke-dashoffset 0.35s',
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%'
                }}
            />
        </svg>
    )
}
