import {Experience, ShellStepKeyName} from '../../types'
import {StyledStepButton, StyledStepsNavigation} from './style'
import {useTranslation} from '@/translations/i18n'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {checkIsItineraryLocked, checkIsLegalLocked, stepToIcon, stepToLabel} from '../../utils'
import {FC} from 'react'
import {useExperienceShellFormContext} from '@/features/experience-shell/hooks/useExperienceShellFormContext.ts'
import {useWatch} from 'react-hook-form'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Progress} from '../progress/Progress'

export const StepsNavigation: FC<{
    currentStep: ShellStepKeyName
    experienceId: number
    galleryImagesCount: number
    itineraries: Experience['itineraries']
}> = ({currentStep, experienceId, galleryImagesCount, itineraries}) => {
    const {t} = useTranslation()
    const {control} = useExperienceShellFormContext()
    const experienceCategoryWatch = useWatch({control, name: 'experience.categoryKeyName'})
    const changeStep = useChangeStep({experienceId, currentStep})

    return (
        <StyledStepsNavigation>
            {ShellStepKeyName.options.map(step => {
                const StepIcon = stepToIcon[step]
                const isItineraryLocked = step == 'itinerary' && checkIsItineraryLocked(itineraries)
                const isLegalLocked = step == 'legal' && checkIsLegalLocked(experienceCategoryWatch)

                return (
                    <StyledStepButton
                        className={currentStep == step ? 'active' : undefined}
                        variant="ghost"
                        onClick={() => changeStep.handleChangeStep({stepToGoTo: step})}
                        disabled={
                            isLegalLocked ||
                            isItineraryLocked ||
                            changeStep.editExperienceMutation.isPending ||
                            changeStep.editStepMutation.isPending
                        }
                        key={step}
                    >
                        <Flexbox align="center" gap={2}>
                            <StepIcon />
                            {t(stepToLabel[step])}
                        </Flexbox>
                        <Progress
                            experienceId={experienceId}
                            step={step}
                            isItineraryLocked={isItineraryLocked}
                            isLegalLocked={isLegalLocked}
                            galleryImagesCount={galleryImagesCount}
                        />
                    </StyledStepButton>
                )
            })}
        </StyledStepsNavigation>
    )
}
