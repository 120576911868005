import {useTranslation} from 'react-i18next'
import {StyledServiceMerchandisingVettingWrapper, StyledServiceVettingFormsWrapper} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useOutletContext} from 'react-router-dom'
import {useGetService} from '@/features/service-vetting/query/useGetService'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {ServiceVettingMerchFormSkeleton} from '@/features/service-vetting/components/service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {OpsVettingMerchFormHeader} from '@/features/ops-vetting/components/ops-vetting-merch-form-header/OpsVettingMerchFormHeader'
import {StyledServiceVettingMerchInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/style'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {ServiceVettingMerchForms} from '@/features/service-vetting/components/service-vetting-merch-forms/ServiceVettingMerchForms'

export const ServiceMerchandisingVettingPage = () => {
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {remappedData: service, isPending} = useGetService(urlParams.id)

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            {isPending || !service ? (
                <ServiceMerchandisingVettingPageSkeleton />
            ) : (
                <ServiceVettingMerchForms service={service} />
            )}
        </ScrollArea>
    )
}
ServiceMerchandisingVettingPage.displayName = 'ServiceMerchandisingVettingPage'

const ServiceMerchandisingVettingPageSkeleton = () => {
    const {t} = useTranslation()
    return (
        <StyledServiceMerchandisingVettingWrapper fullWidth>
            <OpsVettingHero fullWidth justify="space-between" align="center">
                <h3>{t('vetting:merch:service:title')}</h3>
                <Flexbox gap={2}>
                    <Skeleton width={100} height={24} />
                    <Skeleton width={100} height={24} />
                    <Skeleton width={100} height={24} />
                </Flexbox>
            </OpsVettingHero>
            <StyledServiceVettingFormsWrapper fullWidth>
                {[0, 1].map(index => (
                    <StyledServiceVettingMerchInputForm key={index}>
                        <OpsVettingMerchFormHeader
                            icon={<Skeleton width={24} height={24} />}
                            title={<Skeleton width={100} height={24} />}
                            description={<Skeleton width={200} height={24} />}
                        />
                        <ServiceVettingMerchFormSkeleton />
                    </StyledServiceVettingMerchInputForm>
                ))}
            </StyledServiceVettingFormsWrapper>
        </StyledServiceMerchandisingVettingWrapper>
    )
}
