import i18n, {useTranslation} from '@/translations/i18n'
import {Fieldset, YesNoField} from '../atoms/Atoms'
import {Controller} from 'react-hook-form'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {SelectItem, SelectList, SelectPopover, SelectTrigger} from '@/components/ui/select-atoms/SelectAtoms'
import {Label} from '@/components/ui/label/Label'
import {SelectProvider} from '@ariakit/react'

const generateHalfHourTimes = () => {
    const times = []
    for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 30) {
            const hour = (h < 10 ? '0' : '') + h
            const minute = (m < 10 ? '0' : '') + m
            times.push(`${hour}:${minute}`)
        }
    }
    return times
}

const cutoffOptions = [
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 0}),
        value: '0h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 1}),
        value: '1h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 2}),
        value: '2h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 3}),
        value: '3h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 4}),
        value: '4h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 6}),
        value: '6h'
    },
    {
        label: i18n.t('experience_shell:x_hours_before_start_time', {count: 8}),
        value: '8h'
    },
    {
        label: i18n.t('experience_shell:x_days_before_start_time', {count: 1}),
        value: '1d'
    },
    {
        label: i18n.t('experience_shell:x_days_before_start_time', {count: 2}),
        value: '2d'
    },
    {
        label: i18n.t('experience_shell:x_days_before_start_time', {count: 3}),
        value: '3d'
    },
    {
        label: i18n.t('experience_shell:x_days_before_start_time', {count: 6}),
        value: '6d'
    },
    {
        label: i18n.t('experience_shell:x_weeks_before_start_time', {count: 1}),
        value: '1w'
    }
] as const

export const AvailabilitySection = () => {
    const {t} = useTranslation()
    const {
        control,
        formState: {errors}
    } = useExperienceShellFormContext()

    return (
        <Fieldset title={t('experience_shell:availability')}>
            <Controller
                control={control}
                name="settings.experience_start_time"
                render={({field}) => (
                    <SelectProvider defaultValue="" value={field.value?.toString()} setValue={field.onChange}>
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('experience_shell:experience_start_time')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('experience_shell:choose_a_start_time')}
                                displayValue={value => value}
                            />
                            {errors.settings?.experience_start_time && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                        <SelectPopover>
                            <SelectList>
                                {generateHalfHourTimes().map(time => (
                                    <SelectItem key={time} value={time}>
                                        {time}
                                    </SelectItem>
                                ))}
                            </SelectList>
                        </SelectPopover>
                    </SelectProvider>
                )}
            />
            <Controller
                control={control}
                name="settings.additional_guests_cutoff_time"
                render={({field}) => (
                    <SelectProvider defaultValue="" value={field.value?.toString()} setValue={field.onChange}>
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('experience_shell:additional_guests_cutoff_time')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('experience_shell:select_cutoff_time')}
                                displayValue={value => cutoffOptions.find(option => option.value == value)?.label}
                            />
                            {errors.settings?.additional_guests_cutoff_time && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                        <SelectPopover>
                            <SelectList>
                                {cutoffOptions.map(time => (
                                    <SelectItem key={time.value} value={time.value}>
                                        {time.label}
                                    </SelectItem>
                                ))}
                            </SelectList>
                        </SelectPopover>
                    </SelectProvider>
                )}
            />
            <Controller
                control={control}
                name="settings.first_guests_cutoff_time"
                render={({field}) => (
                    <SelectProvider defaultValue="" value={field.value?.toString()} setValue={field.onChange}>
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('experience_shell:first_guests_cutoff_time')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('experience_shell:select_cutoff_time')}
                                displayValue={value => cutoffOptions.find(option => option.value == value)?.label}
                            />
                            {errors.settings?.first_guests_cutoff_time && (
                                <InputHelpText error={t('errors:field_required')} />
                            )}
                        </Flexbox>
                        <SelectPopover>
                            <SelectList>
                                {cutoffOptions.slice(1).map(time => (
                                    <SelectItem key={time.value} value={time.value}>
                                        {time.label}
                                    </SelectItem>
                                ))}
                            </SelectList>
                        </SelectPopover>
                    </SelectProvider>
                )}
            />
            <Controller
                control={control}
                name="settings.unlisted_datetimes_allowed"
                render={({field}) => (
                    <Flexbox direction="column" gap={2} fullWidth>
                        <YesNoField
                            labelDescription={t('experience_shell:unlisted_datetimes_allowed')}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                        />
                        {errors.settings?.accessibility_feature && <InputHelpText error={t('errors:field_required')} />}
                    </Flexbox>
                )}
            />
        </Fieldset>
    )
}
