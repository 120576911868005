import {Modal} from '@components/ui/modal/Modal.tsx'
import {FC, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTranslation} from 'react-i18next'
import {DefaultNamespace} from 'i18next'
import {StyledConfirmActionModalBody} from '@components/commons/confirm-action-modal/style.ts'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {ModalFooter, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

interface ConfirmActionModalProps {
    isLoading: boolean
    onClose: () => void
    onConfirm: () => void
    headerTitle: string | DefaultNamespace
    title: string | DefaultNamespace
    paragraph: ReactNode
}

export const ConfirmActionModal: FC<ConfirmActionModalProps> = ({
    isLoading,
    onClose,
    onConfirm,
    headerTitle,
    title,
    paragraph
}) => {
    const {t} = useTranslation()
    return (
        <Modal width={480} closeIconOffset="sm" onOverlayClick={onClose}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onClose}>
                    <XCloseIcon size={20} />
                </Button>
                <h3>{headerTitle}</h3>
            </ModalHeader>

            <Divider direction="horizontal" />

            <StyledConfirmActionModalBody>
                <Flexbox direction="column" gap={2}>
                    <h4>{title}</h4>
                    <p>{paragraph}</p>
                </Flexbox>
            </StyledConfirmActionModalBody>

            <ModalFooter>
                <Button variant="tertiary" onClick={onClose} disabled={isLoading}>
                    {t('commons:cancel')}
                </Button>
                <Button variant="primary" onClick={onConfirm} disabled={isLoading}>
                    {t('commons:confirm')}
                    {isLoading && <Spinner size={16} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

ConfirmActionModal.displayName = 'ConfirmActionModal'
