import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpEditStep, HttpEditStepOptions} from '../http/experienceShell.http'
import {Experience} from '../types'
import {QUERY_KEYS} from '@/queryClient'

export const useEditStep = ({
    experienceId,
    options
}: {
    experienceId: number
    options?: Omit<
        UseMutationOptions<Experience, unknown, HttpEditStepOptions['urlParams']['stepKeyName']>,
        'mutationFn'
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: stepKeyName =>
            httpEditStep({urlParams: {experienceId, stepKeyName}, params: {responseType: 'extended'}}),
        ...options,
        onSuccess: (data, variables, context) => {
            queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
