import styled, {css} from 'styled-components'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const StyledImageContainer = styled.div<{$hasOnclick: boolean}>(
    ({$hasOnclick}) => css`
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        width: 100%;
        border-radius: 8px;
        ${$hasOnclick &&
        css`
            cursor: pointer;
        `}

        & .show-on-hover {
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
        }

        &:hover {
            & .show-on-hover {
                opacity: 1;
            }
        }
    `
)

export const StyledImage = styled.img`
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
`

export const StyledGalleryItemSkeleton = styled(Skeleton)(
    () => css`
        aspect-ratio: 1 / 1;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    `
)

export const StyledGalleryItemEmptyPlaceholder = styled.div(
    ({theme: {palette}}) => css`
        aspect-ratio: 1 / 1;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: ${palette.neutral['200']};
        display: flex;
        align-items: center;
        justify-content: center;
    `
)

export const StyledTopLeftSlotWrapper = styled.div(
    ({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 2}px;
        left: ${spacing * 2}px;
    `
)

export const StyledTopRightSlotWrapper = styled.div(
    ({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
    `
)

export const StyledBottomLeftSlotWrapper = styled.div(
    ({theme: {spacing}}) => css`
        position: absolute;
        bottom: ${spacing * 3}px;
        left: ${spacing * 2}px;
    `
)

export const StyledBottomRightSlotWrapper = styled.div(
    ({theme: {spacing}}) => css`
        position: absolute;
        bottom: ${spacing * 3}px;
        right: ${spacing * 2}px;
    `
)

export const StyledBlur = styled.div(
    () => css`
        position: absolute;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    `
)

export const StyledCenterSlotWrapper = styled.div(
    () => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `
)
