import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Label} from '@/components/ui/label/Label'
import {
    SelectComboboxInput,
    SelectComboboxList,
    SelectComboboxPopover,
    SelectItem,
    SelectTrigger
} from '@/components/ui/select-atoms/SelectAtoms'
import {useTranslation} from '@/translations/i18n'
import {ComboboxItem, ComboboxProvider, SelectProvider} from '@ariakit/react'
import {FC, useState} from 'react'
import {useGetCountries} from '../../queries/useGetCountries'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {debounce} from '@utilities/helpers.ts'

interface CountriesSelectProps {
    errorMessage?: string
    onChange: (value: string) => void
    value: string
}
export const CountriesSelect: FC<CountriesSelectProps> = ({errorMessage, onChange, value}) => {
    const {t} = useTranslation()
    const countriesQuery = useGetCountries()
    const [search, setSearch] = useState('')

    const onSearch = debounce(setSearch, 300)
    const filteredData =
        countriesQuery.data?.data.filter(country => country.name.toLowerCase().includes(search.toLowerCase())) ?? []

    return (
        <ComboboxProvider setValue={onSearch}>
            <SelectProvider setValue={onChange} value={value} defaultValue="">
                <Flexbox direction="column" gap={1.5} fullWidth>
                    <Label>{t('experience_shell:country_region')}</Label>
                    <SelectTrigger
                        disabled={countriesQuery.isPending}
                        fullWidth
                        displayValue={value => countriesQuery.data?.data.find(country => country.code == value)?.name}
                        placeholder={t('experience_shell:select_country_region')}
                    />
                    <InputHelpText error={errorMessage} />
                </Flexbox>
                <SelectComboboxPopover>
                    <SelectComboboxInput autoFocus />
                    <SelectComboboxList>
                        {countriesQuery.isPending ? (
                            Array.from({length: 6}, (_, index) => <Skeleton key={index} height={32} />)
                        ) : countriesQuery.isError ? (
                            <InputHelpText error={t('errors:default')} />
                        ) : filteredData.length >= 1 ? (
                            filteredData.map(item => (
                                <SelectItem key={item.code} value={item.code} render={<ComboboxItem />}>
                                    {item.name}
                                </SelectItem>
                            ))
                        ) : (
                            <InputHelpText helpText={t('commons:no_results')} />
                        )}
                    </SelectComboboxList>
                </SelectComboboxPopover>
            </SelectProvider>
        </ComboboxProvider>
    )
}
