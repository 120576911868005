import styled, {css} from 'styled-components'

export const StyledSuccessView = styled.div(
    ({theme: {palette, spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        gap: ${spacing * 8}px;
        text-align: center;
        color: ${palette.neutral['900']};
        grid-column: 1 / -1;

        & svg {
            color: ${palette.success['600']};
        }
        & h1 {
            font-size: ${typography.displayXs};
            font-weight: 500;
        }
        & p {
            font-size: ${typography.textXl};
        }
    `
)
