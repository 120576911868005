import styled, {css} from 'styled-components'

export const StyledAiPromptInfo = styled.small`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral[700]};
        & > span {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`

export const StyledAiPromptModalBody = styled.div`
    ${({theme: {typography, palette}}) => css`
        max-height: 760px;
        overflow-y: auto;
        & p {
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
        & ul {
            & span {
                font-weight: 600;
            }
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
        & textarea {
            font-family: monospace;
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `}
`

export const StyledAiPromptModalDisclaimer = styled.small`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral[700]};
    `}
`
