import {useTranslation} from '@/translations/i18n'
import {ColumnFields, Content, Fieldset, Footer, YesNoField} from '../atoms/Atoms'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon, PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {InputText} from '@/components/commons/input-text/InputText'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useExperienceShellFormContext} from '../../hooks/useExperienceShellFormContext'
import {useFieldArray, Controller, useWatch} from 'react-hook-form'
import {StyledOnlineProfileField} from './style'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {CountriesSelect} from '../countries-select/CountriesSelect'
import {FC} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'
import {StepKeyName} from '../../types'
export const BackgroundStep: FC<{experienceId: number}> = ({experienceId}) => {
    const {t} = useTranslation()
    const {
        control,
        register,
        setValue,
        formState: {errors}
    } = useExperienceShellFormContext()
    const changeStep = useChangeStep({
        experienceId,
        currentStep: StepKeyName.enum.background
    })
    const {fields, append, remove} = useFieldArray({
        control: control,
        name: 'background.online_profile_links'
    })
    const hostingAsBusinessWatch = useWatch({control, name: 'background.hosting_as_business'})

    return (
        <>
            <Content title={t('experience_shell:step_background')}>
                <Flexbox direction="column" gap={6}>
                    <Fieldset title={t('experience_shell:expertise')}>
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:years_experience')}
                            placeholder={t('experience_shell:enter_number_years')}
                            errorMessage={errors.background?.expertise_years ? t('errors:integer_error') : undefined}
                            {...register('background.expertise_years')}
                        />
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:online_profiles')}>
                        {fields.map((field, index) => (
                            <Flexbox direction="column" gap={2} fullWidth key={field.id}>
                                <StyledOnlineProfileField>
                                    <InputText
                                        inputSize="sm"
                                        type="text"
                                        label={t('experience_shell:online_profile')}
                                        placeholder={t('experience_shell:enter_link')}
                                        {...register(`background.online_profile_links.${index}.link`)}
                                    />
                                    {fields.length >= 2 && (
                                        <Button variant="tertiary" iconOnly onClick={() => remove(index)}>
                                            <Trash01Icon />
                                        </Button>
                                    )}
                                </StyledOnlineProfileField>
                                {errors.background?.online_profile_links?.[index]?.link && (
                                    <InputHelpText error={t('experience_shell:online_profile_error')} />
                                )}
                            </Flexbox>
                        ))}
                        <Button size="sm" variant="tertiary" onClick={() => append({link: ''})}>
                            <PlusIcon />
                            {t('experience_shell:add_profile')}
                        </Button>
                    </Fieldset>
                    <Divider direction="horizontal" />
                    <Fieldset title={t('experience_shell:residential_address')}>
                        <Controller
                            control={control}
                            name="background.residential_country_region"
                            render={({field}) => (
                                <CountriesSelect
                                    errorMessage={
                                        errors.background?.residential_country_region
                                            ? t('errors:field_required')
                                            : undefined
                                    }
                                    onChange={field.onChange}
                                    value={field.value ?? ''}
                                />
                            )}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:street_address')}
                            errorMessage={
                                errors.background?.residential_address ? t('errors:field_required') : undefined
                            }
                            {...register('background.residential_address')}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:apt_suite')}
                            {...register('background.residential_address_extras')}
                        />
                        <InputText
                            inputSize="sm"
                            type="text"
                            label={t('experience_shell:city')}
                            errorMessage={errors.background?.residential_city ? t('errors:field_required') : undefined}
                            {...register('background.residential_city')}
                        />
                        <ColumnFields>
                            <InputText
                                inputSize="sm"
                                type="text"
                                label={t('experience_shell:state_territory')}
                                errorMessage={
                                    errors.background?.residential_state ? t('errors:field_required') : undefined
                                }
                                {...register('background.residential_state')}
                            />
                            <InputText
                                inputSize="sm"
                                type="text"
                                label={t('experience_shell:zip_code')}
                                errorMessage={
                                    errors.background?.residential_zip ? t('errors:field_required') : undefined
                                }
                                {...register('background.residential_zip')}
                            />
                        </ColumnFields>
                    </Fieldset>
                    <Fieldset>
                        <Controller
                            control={control}
                            name="background.hosting_as_business"
                            render={({field}) => (
                                <Flexbox direction="column" gap={2} fullWidth>
                                    <YesNoField
                                        labelTitle={<b>{t('experience_shell:hosting_as_business')}</b>}
                                        name={field.name}
                                        value={field.value}
                                        onChange={value => {
                                            field.onChange(value)
                                            if (!field.value) {
                                                setValue('background.business_name', undefined)
                                            }
                                        }}
                                    />
                                    {errors.background?.hosting_as_business && (
                                        <InputHelpText error={t('errors:field_required')} />
                                    )}
                                </Flexbox>
                            )}
                        />
                        {hostingAsBusinessWatch && (
                            <InputText
                                inputSize="sm"
                                type="text"
                                label={t('experience_shell:business_name')}
                                {...register('background.business_name')}
                            />
                        )}
                    </Fieldset>
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => changeStep.handleChangeStep({isBack: true, stepToGoTo: StepKeyName.enum.experience})}
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    size="sm"
                    onClick={() => changeStep.handleChangeStep({stepToGoTo: StepKeyName.enum.merchandising})}
                    disabled={changeStep.isContinueLoading || changeStep.isBackLoading}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading ? <Spinner /> : <ArrowRightIcon />}
                </Button>
            </Footer>
        </>
    )
}
