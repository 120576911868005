import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {Experience} from '../types'
import {QUERY_KEYS} from '@/queryClient'
import {httpStoreExperienceSubmission} from '../http/experienceShell.http'

export const useStoreExperienceSubmission = ({
    experienceId,
    options
}: {
    experienceId: number
    options?: Omit<UseMutationOptions<Experience>, 'mutationFn'>
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () =>
            httpStoreExperienceSubmission({params: {responseType: 'extended'}, urlParams: {experienceId}}),
        ...options,
        onSuccess: (data, variables, context) => {
            queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
