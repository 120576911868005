import {TKey} from '@/translations/i18n'
import {
    Category,
    CategoryFormat,
    Experience,
    SkillLevel,
    StepKeyName,
    QuestionKeyName,
    FormFieldKeyName,
    ShellStepKeyName
} from './types'
import {
    ArtIcon,
    BuildingsIcon,
    CurrencyDollarIcon,
    File05Icon,
    FoodIcon,
    GymIcon,
    IconProps,
    Image03Icon,
    ListIcon,
    MarkerPin01Icon,
    NatureIcon,
    Settings01Icon,
    Shield02Icon,
    TextInputIcon,
    User01Icon,
    WaveIcon
} from '@/components/ui/icon/Icon'
import {ForwardRefExoticComponent, RefAttributes} from 'react'
import {ShellFormDefaultValues, ShellPartialFormSchema} from './formSchemas'

export const stepToLabel = {
    [StepKeyName.enum.experience]: 'experience_shell:step_experience',
    [StepKeyName.enum.background]: 'experience_shell:step_background',
    [StepKeyName.enum.merchandising]: 'experience_shell:step_merchandising',
    [StepKeyName.enum.location]: 'experience_shell:step_location',
    [StepKeyName.enum.photos]: 'experience_shell:step_photos',
    [StepKeyName.enum.itinerary]: 'experience_shell:step_itinerary',
    [StepKeyName.enum.pricing_discounts]: 'experience_shell:step_pricing_discount',
    [StepKeyName.enum.settings]: 'experience_shell:step_settings',
    [StepKeyName.enum.legal]: 'experience_shell:step_legal'
} as const satisfies Record<ShellStepKeyName, TKey>

export const stepToIcon = {
    [StepKeyName.enum.experience]: File05Icon,
    [StepKeyName.enum.background]: User01Icon,
    [StepKeyName.enum.merchandising]: TextInputIcon,
    [StepKeyName.enum.location]: MarkerPin01Icon,
    [StepKeyName.enum.photos]: Image03Icon,
    [StepKeyName.enum.itinerary]: ListIcon,
    [StepKeyName.enum.pricing_discounts]: CurrencyDollarIcon,
    [StepKeyName.enum.settings]: Settings01Icon,
    [StepKeyName.enum.legal]: Shield02Icon
} as const satisfies Record<ShellStepKeyName, ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>>

export const categoryToLabel = {
    [Category.enum.history_culture]: 'experience_shell:category_history_culture',
    [Category.enum.food_drink]: 'experience_shell:category_food_drink',
    [Category.enum.art_design]: 'experience_shell:category_art_design',
    [Category.enum.nature_outdoors]: 'experience_shell:category_nature_outdoors',
    [Category.enum.entertainment]: 'experience_shell:category_entertainment',
    [Category.enum.fitness_wellness]: 'experience_shell:category_fitness_wellness'
} as const satisfies Record<Exclude<Category, 'generic'>, TKey>

export const categoryToIcon = {
    [Category.enum.history_culture]: BuildingsIcon,
    [Category.enum.food_drink]: FoodIcon,
    [Category.enum.art_design]: ArtIcon,
    [Category.enum.nature_outdoors]: NatureIcon,
    [Category.enum.entertainment]: WaveIcon,
    [Category.enum.fitness_wellness]: GymIcon
} as const satisfies Record<
    Exclude<Category, 'generic'>,
    ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>
>

export const categoryFormatToLabel = {
    [CategoryFormat.enum.landmark_tour]: 'experience_shell:category_format_landmark_tour',
    [CategoryFormat.enum.museum_tour]: 'experience_shell:category_format_museum_tour',
    [CategoryFormat.enum.cultural_tour]: 'experience_shell:category_format_cultural_tour',
    [CategoryFormat.enum.food_tour]: 'experience_shell:category_format_food_tour',
    [CategoryFormat.enum.tasting]: 'experience_shell:category_format_tasting',
    [CategoryFormat.enum.dining]: 'experience_shell:category_format_dining',
    [CategoryFormat.enum.gallery_tour]: 'experience_shell:category_format_gallery_tour',
    [CategoryFormat.enum.art_workshop]: 'experience_shell:category_format_art_workshop',
    [CategoryFormat.enum.architecture_tour]: 'experience_shell:category_format_architecture_tour',
    [CategoryFormat.enum.shopping_fashion]: 'experience_shell:category_format_shopping_fashion',
    [CategoryFormat.enum.concerts]: 'experience_shell:category_format_concerts',
    [CategoryFormat.enum.theater_dance]: 'experience_shell:category_format_theater_dance',
    [CategoryFormat.enum.nightlife]: 'experience_shell:category_format_nightlife',
    [CategoryFormat.enum.games]: 'experience_shell:category_format_games',
    [CategoryFormat.enum.workouts]: 'experience_shell:category_format_workouts',
    [CategoryFormat.enum.wellness]: 'experience_shell:category_format_wellness',
    [CategoryFormat.enum.beauty]: 'experience_shell:category_format_beauty',
    [CategoryFormat.enum.outdoor]: 'experience_shell:category_format_outdoor',
    [CategoryFormat.enum.water_sports]: 'experience_shell:category_format_water_sports',
    [CategoryFormat.enum.flying]: 'experience_shell:category_format_flying',
    [CategoryFormat.enum.wildlife]: 'experience_shell:category_format_wildlife',
    [CategoryFormat.enum.cooking_classes]: 'experience_shell:category_format_cooking_classes',
    [CategoryFormat.enum.wildfire_animals]: 'experience_shell:category_format_wildfire_animals',
    [CategoryFormat.enum.live_performances]: 'experience_shell:category_format_live_performances',
    [CategoryFormat.enum.catering]: 'experience_shell:category_format_catering',
    [CategoryFormat.enum.chef]: 'experience_shell:category_format_chef',
    [CategoryFormat.enum.prepared_meals]: 'experience_shell:category_format_prepared_meals',
    [CategoryFormat.enum.photography]: 'experience_shell:category_format_photography',
    [CategoryFormat.enum.massage]: 'experience_shell:category_format_massage',
    [CategoryFormat.enum.hair_styling]: 'experience_shell:category_format_hair_styling',
    [CategoryFormat.enum.makeup]: 'experience_shell:category_format_makeup',
    [CategoryFormat.enum.nails]: 'experience_shell:category_format_nails',
    [CategoryFormat.enum.personal_training]: 'experience_shell:category_format_personal_training',
    [CategoryFormat.enum.spa_treatments]: 'experience_shell:category_format_spa_treatments'
} as const satisfies Record<CategoryFormat, TKey>

export const categoryToCategoryFormats = {
    [Category.enum.history_culture]: [
        CategoryFormat.enum.landmark_tour,
        CategoryFormat.enum.museum_tour,
        CategoryFormat.enum.cultural_tour
    ],
    [Category.enum.food_drink]: [
        CategoryFormat.enum.food_tour,
        CategoryFormat.enum.cooking_classes,
        CategoryFormat.enum.tasting,
        CategoryFormat.enum.dining
    ],
    [Category.enum.art_design]: [
        CategoryFormat.enum.gallery_tour,
        CategoryFormat.enum.art_workshop,
        CategoryFormat.enum.architecture_tour,
        CategoryFormat.enum.shopping_fashion
    ],
    [Category.enum.nature_outdoors]: [
        CategoryFormat.enum.outdoor,
        CategoryFormat.enum.water_sports,
        CategoryFormat.enum.flying,
        CategoryFormat.enum.wildlife
    ],
    [Category.enum.entertainment]: [
        CategoryFormat.enum.concerts,
        CategoryFormat.enum.theater_dance,
        CategoryFormat.enum.nightlife,
        CategoryFormat.enum.games
    ],
    [Category.enum.fitness_wellness]: [
        CategoryFormat.enum.workouts,
        CategoryFormat.enum.wellness,
        CategoryFormat.enum.beauty
    ]
} as const satisfies Record<Exclude<Category, 'generic'>, CategoryFormat[]>

export const skillLevelToLabel = {
    [SkillLevel.enum.beginner]: 'experience_shell:skill_level_beginner',
    [SkillLevel.enum.intermediate]: 'experience_shell:skill_level_intermediate',
    [SkillLevel.enum.advanced]: 'experience_shell:skill_level_advanced',
    [SkillLevel.enum.expert]: 'experience_shell:skill_level_expert'
} as const satisfies Record<SkillLevel, TKey>

export const makeShellFormDefaultValues = (experience: Experience): ShellFormDefaultValues => {
    const findAnswer = <TQuestionKeyName extends QuestionKeyName>(questionKeyName: TQuestionKeyName) =>
        experience.expertise.answers.find(answer => answer.questionKeyName == questionKeyName)?.answer as
            | Extract<Experience['expertise']['answers'][number], {questionKeyName: TQuestionKeyName}>['answer']
            | undefined

    const category = experience.category.category?.keyName

    return {
        [StepKeyName.enum.experience]: {
            [FormFieldKeyName.enum.categoryKeyName]: category,
            [FormFieldKeyName.enum.subCategoryKeyName]: experience.category.subCategory?.keyName
        },
        [StepKeyName.enum.background]: {
            [FormFieldKeyName.enum.expertise_years]: findAnswer(QuestionKeyName.enum.expertise_years),
            [FormFieldKeyName.enum.online_profile_links]: findAnswer(QuestionKeyName.enum.online_profile_links)?.map(
                link => ({link})
            ) ?? [{link: ''}],
            [FormFieldKeyName.enum.residential_address]: findAnswer(QuestionKeyName.enum.residential_address),
            [FormFieldKeyName.enum.residential_country_region]: findAnswer(
                QuestionKeyName.enum.residential_country_region
            ),
            [FormFieldKeyName.enum.residential_address_extras]: findAnswer(
                QuestionKeyName.enum.residential_address_extras
            ),
            [FormFieldKeyName.enum.residential_city]: findAnswer(QuestionKeyName.enum.residential_city),
            [FormFieldKeyName.enum.residential_state]: findAnswer(QuestionKeyName.enum.residential_state),
            [FormFieldKeyName.enum.residential_zip]: findAnswer(QuestionKeyName.enum.residential_zip),
            [FormFieldKeyName.enum.hosting_as_business]: findAnswer(QuestionKeyName.enum.hosting_as_business),
            [FormFieldKeyName.enum.business_name]: findAnswer(QuestionKeyName.enum.business_name)
        },
        [StepKeyName.enum.pricing_discounts]: {
            [FormFieldKeyName.enum.guest_individual_price]: findAnswer(QuestionKeyName.enum.guest_individual_price),
            [FormFieldKeyName.enum.offer_large_group_discount]: findAnswer(
                QuestionKeyName.enum.offer_large_group_discount
            ),
            [FormFieldKeyName.enum.instance_private_groups]: findAnswer(QuestionKeyName.enum.instance_private_groups),
            [FormFieldKeyName.enum.group_minimum_price]: findAnswer(QuestionKeyName.enum.group_minimum_price),
            [FormFieldKeyName.enum.always_require_minimum_price]: findAnswer(
                QuestionKeyName.enum.always_require_minimum_price
            ),
            [FormFieldKeyName.enum.new_listing_discount]: findAnswer(QuestionKeyName.enum.new_listing_discount),
            [FormFieldKeyName.enum.early_bird_discount]: findAnswer(QuestionKeyName.enum.early_bird_discount),
            [FormFieldKeyName.enum.discounts]: experience.discounts.map(discount => ({
                groupSize: discount.groupSize,
                discountInPercentage: discount.discountInPercentage
            }))
        },
        [StepKeyName.enum.merchandising]: {
            [FormFieldKeyName.enum.host_first_name]: findAnswer(QuestionKeyName.enum.host_first_name),
            [FormFieldKeyName.enum.expertise_summary]: findAnswer(QuestionKeyName.enum.expertise_summary),
            [FormFieldKeyName.enum.recognition_summary]: findAnswer(QuestionKeyName.enum.recognition_summary),
            [FormFieldKeyName.enum.listing_title]: findAnswer(QuestionKeyName.enum.listing_title),
            [FormFieldKeyName.enum.byline]: findAnswer(QuestionKeyName.enum.byline),
            [FormFieldKeyName.enum.location_description]: findAnswer(QuestionKeyName.enum.location_description),
            [FormFieldKeyName.enum.guest_activities]: findAnswer(QuestionKeyName.enum.guest_activities),
            [FormFieldKeyName.enum.host_experience_title]: findAnswer(QuestionKeyName.enum.host_experience_title),
            [FormFieldKeyName.enum.host_expertise_description]: findAnswer(
                QuestionKeyName.enum.host_expertise_description
            ),
            [FormFieldKeyName.enum.host_recognition_title]: findAnswer(QuestionKeyName.enum.host_recognition_title),
            [FormFieldKeyName.enum.host_recognition_subtitle]: findAnswer(
                QuestionKeyName.enum.host_recognition_subtitle
            ),
            [FormFieldKeyName.enum.experience_title]: findAnswer(QuestionKeyName.enum.experience_title),
            [FormFieldKeyName.enum.experience_description]: findAnswer(QuestionKeyName.enum.experience_description),
            activities: experience.itineraries.map(itinerary => ({
                id: itinerary.id,
                title: itinerary.title,
                description: itinerary.description,
                coverImage: itinerary.coverImage
            }))
        },
        [StepKeyName.enum.location]: {
            [FormFieldKeyName.enum.country_region]: findAnswer(QuestionKeyName.enum.country_region),
            [FormFieldKeyName.enum.location_address]: findAnswer(QuestionKeyName.enum.location_address),
            [FormFieldKeyName.enum.location_address_extras]: findAnswer(QuestionKeyName.enum.location_address_extras),
            [FormFieldKeyName.enum.location_city]: findAnswer(QuestionKeyName.enum.location_city),
            [FormFieldKeyName.enum.location_state]: findAnswer(QuestionKeyName.enum.location_state),
            [FormFieldKeyName.enum.location_zip]: findAnswer(QuestionKeyName.enum.location_zip),
            [FormFieldKeyName.enum.meeting_point_name]: findAnswer(QuestionKeyName.enum.meeting_point_name),
            [FormFieldKeyName.enum.meeting_point_description]: findAnswer(
                QuestionKeyName.enum.meeting_point_description
            )
        },
        [StepKeyName.enum.itinerary]: {},
        [StepKeyName.enum.settings]: {
            [FormFieldKeyName.enum.skill_level]: findAnswer(QuestionKeyName.enum.skill_level),
            [FormFieldKeyName.enum.activity_level]: findAnswer(QuestionKeyName.enum.activity_level),
            [FormFieldKeyName.enum.minimum_age]: findAnswer(QuestionKeyName.enum.minimum_age),
            [FormFieldKeyName.enum.parents_kids_under_2]: findAnswer(QuestionKeyName.enum.parents_kids_under_2),
            [FormFieldKeyName.enum.maximum_public_group_size]: findAnswer(
                QuestionKeyName.enum.maximum_public_group_size
            ),
            [FormFieldKeyName.enum.maximum_private_group_size]: findAnswer(
                QuestionKeyName.enum.maximum_private_group_size
            ),
            [FormFieldKeyName.enum.cancellation_policy]: findAnswer(QuestionKeyName.enum.cancellation_policy),
            [FormFieldKeyName.enum.accessibility_feature]: findAnswer(QuestionKeyName.enum.accessibility_feature),
            [FormFieldKeyName.enum.accessibility_additional_qualifications]: findAnswer(
                QuestionKeyName.enum.accessibility_additional_qualifications
            ),
            [FormFieldKeyName.enum.accessibility_additional_information]: findAnswer(
                QuestionKeyName.enum.accessibility_additional_information
            ),
            [FormFieldKeyName.enum.accessibility_features]:
                findAnswer(QuestionKeyName.enum.accessibility_features) ?? [],
            [FormFieldKeyName.enum.guest_bringing_item]: findAnswer(QuestionKeyName.enum.guest_bringing_item),
            [FormFieldKeyName.enum.guest_bringing_items]:
                findAnswer(QuestionKeyName.enum.guest_bringing_items)?.map(item => ({item})) ?? [],
            [FormFieldKeyName.enum.unlisted_datetimes_allowed]: findAnswer(
                QuestionKeyName.enum.unlisted_datetimes_allowed
            ),
            [FormFieldKeyName.enum.experience_start_time]: findAnswer(QuestionKeyName.enum.experience_start_time),
            [FormFieldKeyName.enum.additional_guests_cutoff_time]: findAnswer(
                QuestionKeyName.enum.additional_guests_cutoff_time
            ),
            [FormFieldKeyName.enum.first_guests_cutoff_time]: findAnswer(QuestionKeyName.enum.first_guests_cutoff_time),
            [FormFieldKeyName.enum.main_language]: findAnswer(QuestionKeyName.enum.main_language),
            [FormFieldKeyName.enum.additional_languages]: findAnswer(QuestionKeyName.enum.additional_languages) ?? []
        },
        [StepKeyName.enum.legal]:
            category == Category.enum.food_drink
                ? {
                      [FormFieldKeyName.enum.categoryKeyName]: category,
                      [FormFieldKeyName.enum.involves_national_park]: findAnswer(
                          QuestionKeyName.enum.involves_national_park
                      ),
                      [FormFieldKeyName.enum.involves_point_of_interest]: findAnswer(
                          QuestionKeyName.enum.involves_point_of_interest
                      ),
                      [FormFieldKeyName.enum.host_discusses_poi_significance]: findAnswer(
                          QuestionKeyName.enum.host_discusses_poi_significance
                      ),
                      [FormFieldKeyName.enum.host_transports_guests]: findAnswer(
                          QuestionKeyName.enum.host_transports_guests
                      ),
                      [FormFieldKeyName.enum.host_transporting_guests_method]: findAnswer(
                          QuestionKeyName.enum.host_transporting_guests_method
                      ),
                      [FormFieldKeyName.enum.host_serve_alcohol]: findAnswer(QuestionKeyName.enum.host_serve_alcohol),
                      [FormFieldKeyName.enum.host_serve_food]: findAnswer(QuestionKeyName.enum.host_serve_food),
                      [FormFieldKeyName.enum.prepared_in_restaurant]: findAnswer(
                          QuestionKeyName.enum.prepared_in_restaurant
                      ),
                      [FormFieldKeyName.enum.is_licensed_establishment]: findAnswer(
                          QuestionKeyName.enum.is_licensed_establishment
                      )
                  }
                : category == Category.enum.entertainment
                  ? {
                        [FormFieldKeyName.enum.categoryKeyName]: category,
                        [FormFieldKeyName.enum.host_serve_alcohol]: findAnswer(QuestionKeyName.enum.host_serve_alcohol),
                        [FormFieldKeyName.enum.host_transports_guests]: findAnswer(
                            QuestionKeyName.enum.host_transports_guests
                        ),
                        [FormFieldKeyName.enum.host_transporting_guests_method]: findAnswer(
                            QuestionKeyName.enum.host_transporting_guests_method
                        ),
                        [FormFieldKeyName.enum.involves_national_park]: undefined,
                        [FormFieldKeyName.enum.involves_point_of_interest]: undefined,
                        [FormFieldKeyName.enum.host_discusses_poi_significance]: undefined,
                        [FormFieldKeyName.enum.host_serve_food]: undefined,
                        [FormFieldKeyName.enum.prepared_in_restaurant]: undefined,
                        [FormFieldKeyName.enum.is_licensed_establishment]: findAnswer(
                            QuestionKeyName.enum.is_licensed_establishment
                        )
                    }
                  : {
                        [FormFieldKeyName.enum.categoryKeyName]: category,
                        [FormFieldKeyName.enum.involves_national_park]: findAnswer(
                            QuestionKeyName.enum.involves_national_park
                        ),
                        [FormFieldKeyName.enum.involves_point_of_interest]: findAnswer(
                            QuestionKeyName.enum.involves_point_of_interest
                        ),
                        [FormFieldKeyName.enum.host_discusses_poi_significance]: findAnswer(
                            QuestionKeyName.enum.host_discusses_poi_significance
                        ),
                        [FormFieldKeyName.enum.host_transports_guests]: findAnswer(
                            QuestionKeyName.enum.host_transports_guests
                        ),
                        [FormFieldKeyName.enum.host_transporting_guests_method]: findAnswer(
                            QuestionKeyName.enum.host_transporting_guests_method
                        ),
                        [FormFieldKeyName.enum.host_serve_alcohol]: undefined,
                        [FormFieldKeyName.enum.host_serve_food]: undefined,
                        [FormFieldKeyName.enum.prepared_in_restaurant]: undefined,
                        [FormFieldKeyName.enum.is_licensed_establishment]: undefined
                    }
    }
}

export const checkIsItineraryLocked = (itineraries: Experience['itineraries']): boolean => itineraries.length == 0
export const checkIsLegalLocked = (category: ShellPartialFormSchema['experience']['categoryKeyName']): boolean =>
    !category || category == Category.enum.generic
