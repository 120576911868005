import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18next from 'i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {PhotosetRoute} from '@route-guards/photoset-route/PhotosetRoute'
import {PhotosetLayout} from '@/layouts/photoset-layout/PhotosetLayout'
import {PhotosetEditingRoute} from '@/route-guards/photoset-editing-route/PhotosetEditingRoute'
import {PhotosetQaRoute} from '@/route-guards/photoset-qa-route/PhotosetQaRoute'
import {Photoset2LaRoute} from '@/route-guards/photoset-2la-route/Photoset2LaRoute'
import {PrivateVettingLayout} from '@layouts/private-vetting-layout/PrivateVettingLayout.tsx'
import {ExperienceVetting, ServiceVetting} from '@/pages'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            {/* Authenticated user routes */}
            <Route element={<PrivateRoute />}>
                <Route {...routes.WELCOME} />
                <Route {...routes.EXPERIENCES_SHELL} />
                <Route element={<PrivateLayout />}>
                    <Route element={<PhotosetRoute />}>
                        <Route element={<PhotosetLayout />}>
                            <Route element={<PhotosetEditingRoute />}>
                                <Route {...routes.PHOTOSET_REJECTED} />
                                <Route {...routes.PHOTOSET_INITIAL_QA} />
                                <Route {...routes.PHOTOSET_ALLOCATION} />
                                <Route {...routes.PHOTOSET_CURATION} />
                                <Route {...routes.PHOTOSET_CURATION_COMPLETED} />
                                <Route {...routes.PHOTOSET_AI_VETTING} />
                                <Route {...routes.PHOTOSET_AI_VETTING_COMPLETED} />
                                <Route {...routes.PHOTOSET_AI_MANUAL_RETOUCHING_UPLOAD} />
                                <Route {...routes.PHOTOSET_AI_MANUAL_RETOUCHING} />
                                <Route {...routes.PHOTOSET_NEEDS_REVIEW} />
                                <Route {...routes.PHOTOSET_RETOUCHING_REVISION_UPLOAD} />
                                <Route {...routes.PHOTOSET_RETOUCHING_REVISION} />
                                <Route {...routes.PHOTOSET_RETOUCHING_REVISION_APPROVED} />
                            </Route>
                            <Route element={<PhotosetQaRoute />}>
                                <Route {...routes.PHOTOSET_UNAVAILABLE_QA} />
                                <Route {...routes.PHOTOSET_EDITING_QA_SELECTION} />
                                <Route {...routes.PHOTOSET_EDITING_QA_FLAGS} />
                                <Route {...routes.PHOTOSET_EDITING_QA_COMPLETED} />
                                <Route {...routes.PHOTOSET_VETTING_QA} />
                                <Route {...routes.PHOTOSET_RETOUCHING_QA} />
                                <Route {...routes.PHOTOSET_RETOUCHING_QA_COMPLETED} />
                            </Route>
                            <Route element={<Photoset2LaRoute />}>
                                <Route {...routes.PHOTOSET_2LA_INDEX} />
                                <Route {...routes.PHOTOSET_UNAVAILABLE_2LA} />
                                <Route {...routes.PHOTOSET_2LA_EDITING_QA_SELECTION} />
                                <Route {...routes.PHOTOSET_2LA_EDITING_QA_FLAGS} />
                                <Route {...routes.PHOTOSET_2LA_VETTING_QA} />
                                <Route {...routes.PHOTOSET_2LA_RETOUCHING_QA} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            {/* Anonymous user routes */}
            <Route
                element={<PublicRoute />}
                errorElement={
                    <ErrorBox
                        icon={<AlertCircleIcon />}
                        title={i18next.t('errors:default')}
                        cta={
                            <Button variant="primary" onClick={() => window.location.reload()}>
                                {i18next.t('commons:reload_the_page')}
                            </Button>
                        }
                    />
                }
            >
                <Route element={<PublicLayout />}>
                    <Route {...routes.LOGIN} />
                    <Route {...routes.SSO_REDIRECT} />
                    <Route {...routes.RESET_PASSWORD} />
                    <Route {...routes.SET_PASSWORD} />
                </Route>
            </Route>

            {/* Copy Preparation/Training AI public route */}
            <Route
                element={<PublicRoute />}
                errorElement={
                    <ErrorBox
                        icon={<AlertCircleIcon />}
                        title={i18next.t('errors:default')}
                        cta={
                            <Button variant="primary" onClick={() => window.location.reload()}>
                                {i18next.t('commons:reload_the_page')}
                            </Button>
                        }
                    />
                }
            >
                <Route element={<PrivateVettingLayout />}>
                    <Route {...routes.EXP_AI_COPY_PREP_SHELL_TRAINING} />
                    <Route {...routes.EXP_AI_COPY_PREP_VETTING_TRAINING} />
                    <Route {...routes.SERVICE_COPY_TRAINING} />
                </Route>
            </Route>

            {/* OPS Vetting private route */}
            <Route
                element={<PrivateRoute />}
                errorElement={
                    <ErrorBox
                        icon={<AlertCircleIcon />}
                        title={i18next.t('errors:default')}
                        cta={
                            <Button variant="primary" onClick={() => window.location.reload()}>
                                {i18next.t('commons:reload_the_page')}
                            </Button>
                        }
                    />
                }
            >
                <Route element={<PrivateVettingLayout />}>
                    <Route element={<ServiceVetting />}>
                        <Route {...routes.SERVICE_VETTING_MERCHANDISING} />
                        <Route {...routes.SERVICE_VETTING_CRITERIA} />
                    </Route>

                    <Route element={<ExperienceVetting />}>
                        <Route {...routes.EXPERIENCE_VETTING_MERCHANDISING} />
                        <Route {...routes.EXPERIENCE_VETTING_CRITERIA} />
                    </Route>
                </Route>
            </Route>

            {/* Temporary public routes for initial QA v1 and curation v1 */}
            <Route
                element={<PhotosetRoute />}
                errorElement={
                    <ErrorBox
                        icon={<AlertCircleIcon />}
                        title={i18next.t('errors:default')}
                        cta={
                            <Button variant="primary" onClick={() => window.location.reload()}>
                                {i18next.t('commons:reload_the_page')}
                            </Button>
                        }
                    />
                }
            >
                <Route element={<PhotosetLayout />}>
                    <Route {...routes.PHOTOSET_INITIAL_QA_V1} />
                    <Route {...routes.PHOTOSET_CURATION_STEP_V1} />
                    <Route {...routes.PHOTOSET_IMAGEN_AI_STEP_V1} />
                    <Route {...routes.PHOTOSET_PUBLIC_SHOWCASE} />
                </Route>
            </Route>
            <Route {...routes.NOT_FOUND} />
        </Route>
    )
)

export default router
