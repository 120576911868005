import {
    OpsVettingGalleryItemsContainer,
    OpsVettingNoCoverPhotoContainer
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Edit02Icon, Expand01Icon, Image01Icon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Fragment, useState} from 'react'
import {ServiceVettingCriteriaChoosePhotoModal} from '@/features/service-vetting/components/service-vetting-criteria-choose-photo-modal/ServiceVettingCriteriaChoosePhotoModal.tsx'
import {useUpdateIsCoverGalleryImg} from '@/features/service-vetting/query/useUpdateIsCoverGalleryImg.ts'
import {ServiceGalleryImage} from '@/features/service-vetting/types.ts'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'

export const ServiceVettingCriteriaCoverPhoto = ({
    coverPhoto,
    hideAction,
    serviceId,
    galleryId,
    onClickExpand
}: {
    coverPhoto?: ServiceGalleryImage
    hideAction: boolean
    galleryId: number
    serviceId: number
    onClickExpand?: (image: ServiceGalleryImage) => void
}) => {
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const updateIsCoverGalleryImg = useUpdateIsCoverGalleryImg(
        {galleryId, serviceId},
        {onSuccess: () => setShowModal(false)}
    )

    const onConfirm = (id: number) => {
        updateIsCoverGalleryImg.mutate({is_cover: true, imgId: id})
    }
    return (
        <Fragment>
            <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                {coverPhoto ? (
                    <GalleryImageItem
                        src={coverPhoto.thumbnails?.xs || coverPhoto.url}
                        topRightSlot={
                            !hideAction && (
                                <Button
                                    onClick={() => {
                                        setShowModal(true)
                                    }}
                                    variant={'secondary'}
                                    iconOnly
                                >
                                    <Edit02Icon />
                                </Button>
                            )
                        }
                        bottomRightSlot={
                            !hideAction && (
                                <Button
                                    variant="secondary"
                                    className="show-on-hover"
                                    iconOnly
                                    onClick={() => onClickExpand?.(coverPhoto)}
                                >
                                    <Expand01Icon />
                                </Button>
                            )
                        }
                    />
                ) : (
                    <OpsVettingNoCoverPhotoContainer direction={'column'} gap={2} align={'center'} justify={'center'}>
                        <Image01Icon size={32} />
                        <Flexbox direction={'column'} align={'center'}>
                            <h4>{t('vetting:criteria:service:gallery:no_cover_photo')}</h4>
                            {!hideAction && (
                                <Button onClick={() => setShowModal(true)} variant={'tertiary'} size={'sm'}>
                                    {t('commons:choose_a_photo')}
                                </Button>
                            )}
                        </Flexbox>
                    </OpsVettingNoCoverPhotoContainer>
                )}
            </OpsVettingGalleryItemsContainer>
            {showModal && (
                <ServiceVettingCriteriaChoosePhotoModal
                    title={t('vetting:criteria:service:gallery:cover_photo')}
                    onClose={() => setShowModal(false)}
                    onConfirm={onConfirm}
                    isPending={updateIsCoverGalleryImg.isPending}
                    currentSelectedImageId={coverPhoto?.id}
                    serviceId={serviceId}
                    galleryId={galleryId}
                />
            )}
        </Fragment>
    )
}
