import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {ArrowRightIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {spaceNameToLabel} from '@/features/photoset/utils.ts'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {DropdownItem, DropdownLabel, DropdownSeparator} from '@components/ui/dropdown-atoms/DropdownAtoms'
import {DropdownMenuPortalProps} from '@radix-ui/react-dropdown-menu'
import {forwardRef, useMemo} from 'react'
import {ObjectValues} from '@utilities/helpers.ts'
import {OnAllocateAction, PhotosetSpace} from '@/features/photoset/types.ts'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {StyledDropdownContent} from '@/features/photoset/components/allocate-dropdown/style.ts'

export const AllocateDropdown = forwardRef<
    HTMLDivElement,
    {
        container?: DropdownMenuPortalProps['container']
        excludedSpaces?: PhotosetSpace['space']['key_name'][]
        onAllocate: (values: OnAllocateAction) => void
    }
>(({container, onAllocate, excludedSpaces}, ref) => {
    const {t} = useTranslation()
    const photosetSpacesDict = usePhotosetStore(state => state.photosetSpacesDict)
    const selectedImages = usePhotosetStore(store => store.selectedImages)
    const photosetSpaces = useMemo(() => ObjectValues(photosetSpacesDict), [photosetSpacesDict])
    const otherSpace = photosetSpaces.find(space => space.space.key_name == 'other')

    const onSelectSpace = (photosetSpaceId: PhotosetSpace['id']) => {
        onAllocate({
            photosetSpaceId,
            imagesIds: selectedImages,
            trackInHistory: true,
            isShortcut: false,
            keepSelected: false
        })
    }

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild disabled={!ObjectValues(photosetSpaces).length}>
                <Button size="sm" variant="secondary">
                    <ArrowRightIcon />
                    {t('commons:move_to')}
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal container={container}>
                <StyledDropdownContent side="bottom" align="end" sideOffset={8} ref={ref}>
                    <DropdownLabel>{t('commons:move_to')}</DropdownLabel>
                    <ScrollArea scrollbar={<Scrollbar />}>
                        <DropdownMenu.Group>
                            {photosetSpaces.map(photosetSpace => {
                                if (
                                    photosetSpace.space.key_name == 'other' ||
                                    excludedSpaces?.includes(photosetSpace.space.key_name)
                                ) {
                                    return false
                                }

                                return (
                                    <DropdownItem
                                        key={photosetSpace.space.key_name}
                                        onClick={() => onSelectSpace(photosetSpace.id)}
                                    >
                                        {spaceNameToLabel[photosetSpace.space.key_name]}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu.Group>
                        {!!otherSpace && (
                            <>
                                <DropdownSeparator />
                                <DropdownMenu.Group>
                                    <DropdownItem onClick={() => onSelectSpace(otherSpace.id)}>
                                        {spaceNameToLabel['other']}
                                    </DropdownItem>
                                </DropdownMenu.Group>
                            </>
                        )}
                    </ScrollArea>
                </StyledDropdownContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
})

AllocateDropdown.displayName = 'AllocateDropdown'
