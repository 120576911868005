import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Container} from '@/components/ui/container/Container'

export const StyledCopyTrainingWrapper = styled.div`
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    overflow: hidden;
    height: 100%;
`

export const StyledCopyTrainingContainer = styled(Container)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
`

export const StyledCopyTrainingFormsWrapper = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        border: 1px solid ${palette.neutral[200]};
        border-radius: 16px;
        overflow: hidden;
        margin: ${spacing * 6}px 0 ${spacing * 10}px;
    `
)
