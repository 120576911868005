import {FC, RefObject, useState} from 'react'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {useTranslation} from '@/translations/i18n'

import {ModalFooter, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Radio} from '@/components/commons/radio/Radio'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledModalBody,
    StyledModalHeader
} from '@/features/service-vetting/components/service-vetting-merch-submit-modal/style.ts'

interface ServiceVettingMerchSubmitModalProps {
    isSubmitting: boolean
    type: 'ai' | 'host-edits'
    onClose: () => void
    submissionRef: RefObject<HTMLButtonElement>
    setRequireHostApprovalCb: (value: boolean) => void
}

export const ServiceVettingMerchSubmitModal: FC<ServiceVettingMerchSubmitModalProps> = ({
    isSubmitting,
    onClose,
    submissionRef,
    type,
    setRequireHostApprovalCb
}) => {
    const {t} = useTranslation()
    const [hasSelectedOption, setHasSelectedOption] = useState(false)

    const handleRadioChange = (value: boolean) => {
        setHasSelectedOption(true)
        setRequireHostApprovalCb(value)
    }

    switch (type) {
        case 'ai':
            return (
                <Modal width={480} onOverlayClick={onClose}>
                    <StyledModalHeader>
                        <ModalXCloseButton onClick={onClose} />
                        <p>{t('vetting:merch:commons:submission_modal_header')}</p>
                    </StyledModalHeader>
                    <StyledModalBody>
                        <h3>{t('vetting:merch:service:confirmation_ai_modal:title')}</h3>
                        <p>{t('vetting:merch:service:confirmation_ai_modal:description')}</p>
                    </StyledModalBody>
                    <ModalFooter>
                        <Button variant={'tertiary'} onClick={onClose}>
                            {t('commons:cancel')}
                        </Button>

                        <Button
                            variant={'primary'}
                            onClick={() => submissionRef.current?.click()}
                            disabled={isSubmitting}
                        >
                            {t('commons:confirm')}
                            {isSubmitting && <Spinner size={20} />}
                        </Button>
                    </ModalFooter>
                </Modal>
            )

        case 'host-edits':
            return (
                <Modal width={480} onOverlayClick={onClose}>
                    <StyledModalHeader>
                        <ModalXCloseButton onClick={onClose} />
                        <p>{t('vetting:merch:commons:submission_modal_header')}</p>
                    </StyledModalHeader>
                    <StyledModalBody>
                        <Flexbox direction="column" gap={4}>
                            <h3>{t('vetting:merch:service:confirmation_edits_modal:title')}</h3>
                            <Radio
                                name="require-host-approval"
                                onChange={() => handleRadioChange(true)}
                                label={
                                    <Flexbox direction="column" gap={1}>
                                        <p>
                                            {t(
                                                'vetting:merch:service:confirmation_edits_modal:radio_label_require_host_approval'
                                            )}
                                        </p>
                                        <small>
                                            {t(
                                                'vetting:merch:service:confirmation_edits_modal:radio_label_require_host_approval_description'
                                            )}
                                        </small>
                                    </Flexbox>
                                }
                            />
                            <Radio
                                name="require-host-approval"
                                onChange={() => handleRadioChange(false)}
                                label={
                                    <Flexbox direction="column" gap={1}>
                                        <p>
                                            {t('vetting:merch:service:confirmation_edits_modal:radio_label_no_edits')}
                                        </p>
                                        <small>
                                            {t(
                                                'vetting:merch:service:confirmation_edits_modal:radio_label_no_edits_description'
                                            )}
                                        </small>
                                    </Flexbox>
                                }
                            />
                        </Flexbox>
                    </StyledModalBody>
                    <ModalFooter>
                        <Button variant={'tertiary'} onClick={onClose}>
                            {t('commons:cancel')}
                        </Button>

                        <Button
                            variant={'primary'}
                            onClick={() => submissionRef.current?.click()}
                            disabled={isSubmitting || !hasSelectedOption}
                        >
                            {t('commons:confirm')}
                            {isSubmitting && <Spinner size={20} />}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
    }
}
