import {
    StyledCopyTrainingContainer,
    StyledCopyTrainingFormsWrapper,
    StyledCopyTrainingWrapper
} from '@/pages/copy-training/style'
import {ScrollArea} from '@/components/ui/scroll-area/ScrollArea'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Stars01Icon} from '@/components/ui/icon/Icon'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {ServiceVettingMerchOutputForm} from '@/features/service-vetting/components/service-vetting-merch-output-form/ServiceVettingMerchOutputForm'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '@/features/service-vetting/formSchemas'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {useRef, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AiPromptModal} from '@/components/commons/ai-prompt-modal/AiPromptModal'
import {SERVICES_PROMPT} from '@/features/ops-vetting/prompts/servicePrompt'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {ServiceVettingMercTrainingInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/ServiceVettingMerchTrainingInputForm'

export const ServiceCopyTraining = () => {
    const {t} = useTranslation()
    const {aiResponse, isLoading, onMerchCopyAiCall, resetAiResponse} =
        useMerchCopyAi<ServiceMerchandisingOutputSchema>()
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    const [currentPrompt, setCurrentPrompt] = useState<string>(SERVICES_PROMPT)

    const handleAiSubmission: SubmitHandler<ServiceMerchandisingInputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }

    const handleFinalSubmission: SubmitHandler<ServiceMerchandisingOutputSchema> = data => {
        console.log('handleFinalSubmission', data)
    }

    return (
        <StyledCopyTrainingWrapper>
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledCopyTrainingContainer fullWidth>
                    <OpsVettingHero fullWidth justify="space-between" align="center">
                        <h3>Service Copy Training</h3>
                        <Flexbox gap={2}>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => aiSubmissionRef.current?.click()}
                                disabled={isLoading}
                            >
                                <Stars01Icon />
                                {t('vetting:merch:commons:improve_with_ai')}
                            </Button>
                            <AiPromptModal
                                trigger={
                                    <Button variant="tertiary" size="sm" disabled={isLoading}>
                                        Customize prompt
                                    </Button>
                                }
                                currentPrompt={currentPrompt}
                                onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                isLoading={isLoading}
                                promptType="services"
                            />
                        </Flexbox>
                    </OpsVettingHero>

                    <StyledCopyTrainingFormsWrapper fullWidth>
                        <ServiceVettingMercTrainingInputForm
                            aiSubmissionRef={aiSubmissionRef}
                            handleAiSubmissionCb={handleAiSubmission}
                        />

                        <ServiceVettingMerchOutputForm
                            isLoading={isLoading}
                            defaultValues={aiResponse as ServiceMerchandisingOutputSchema}
                            finalSubmitRef={finalSubmitRef}
                            handleFinalSubmissionCb={handleFinalSubmission}
                        />
                    </StyledCopyTrainingFormsWrapper>
                </StyledCopyTrainingContainer>
            </ScrollArea>
        </StyledCopyTrainingWrapper>
    )
}
