import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types.ts'
import {useTranslation} from 'react-i18next'
import {Container} from '@components/ui/container/Container.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon} from '@components/ui/icon/Icon.tsx'
import {useEffect, useMemo} from 'react'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner.tsx'
import {ExperienceVettingCriteriaExpertise} from '@/features/experience-vetting/components/experience-vetting-criteria-expertise/ExperienceVettingCriteriaExpertise.tsx'
import {useGetExperienceVetting} from '@/features/experience-vetting/queries/useGetExperienceVetting.ts'
import {ExperienceVettingCriteriaItinerary} from '@/features/experience-vetting/components/experience-vetting-criteria-itinerary/ExperienceVettingCriteriaItinerary.tsx'
import {ExperienceVettingCriteriaGallery} from '@/features/experience-vetting/components/experience-vetting-criteria-gallery/ExperienceVettingCriteriaGallery.tsx'
import {ExperienceVettingCriteriaHero} from '@/features/experience-vetting/components/experience-vetting-criteria-hero/ExperienceVettingCriteriaHero.tsx'
import {useExperienceVettingError} from '@/features/service-vetting/store/useServiceVettingError'
import {checkExperienceErrors} from '@/features/experience-vetting/utils'
import {useAuthStore} from '@/features/auth/store/store'
import {ExperienceVettingCriteriaRejectedImages} from '@/features/experience-vetting/components/experience-vetting-criteria-rejected-images/ExperienceVettingCriteriaRejectedImages'
import {ExperienceVettingCriteriaSkeleton} from '@/features/experience-vetting/components/experience-vetting-criteria-skeleton/ExperienceVettingCriteriaSkeleton'

export const ExperienceCriteriaVetting = () => {
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {t} = useTranslation()
    const {isPending, isError, remappedData} = useGetExperienceVetting(urlParams.id)
    const isTouched = useExperienceVettingError(state => state.isTouched)
    const setShowBanner = useExperienceVettingError(state => state.setShowBanner)
    const showBanner = useExperienceVettingError(state => state.showBanner)
    const setErrors = useExperienceVettingError(state => state.setErrors)
    const resetErrors = useExperienceVettingError(state => state.reset)
    const user = useAuthStore(state => state.user)
    useEffect(() => {
        if (isTouched && remappedData) {
            const errorCheck = checkExperienceErrors(remappedData)
            setShowBanner(!errorCheck.isValid)
            setErrors(errorCheck.errors)
        }
    }, [remappedData, isTouched])

    useEffect(() => {
        return () => resetErrors()
    }, [])

    const rejectedImages = useMemo(() => {
        return remappedData?.gallery.images.filter(image => image.isApproved == false)
    }, [remappedData])

    if (isPending) {
        return <ExperienceVettingCriteriaSkeleton />
    }

    if (isError) {
        return (
            <Container fullWidth>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }
    const isReadOnly =
        remappedData?.status != 'under_review' || user?.role == 'read_only' || !!remappedData?.isHostModified

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            {remappedData && (
                <Container fullWidth>
                    <ExperienceVettingCriteriaHero experience={remappedData} />
                </Container>
            )}
            {showBanner && (
                <SubHeaderBanner
                    title={t('vetting:criteria:commons:error_banner:title')}
                    subtitle={t('vetting:criteria:commons:error_banner:subtitle')}
                    type={'danger'}
                />
            )}
            <Container fullWidth>
                {remappedData && (
                    <Flexbox direction="column" gap={6} fullWidth>
                        <ExperienceVettingCriteriaExpertise experience={remappedData} isReadOnly={isReadOnly} />
                        <ExperienceVettingCriteriaItinerary experience={remappedData} isReadOnly={isReadOnly} />
                        <ExperienceVettingCriteriaGallery experience={remappedData} isReadOnly={isReadOnly} />
                        {rejectedImages && rejectedImages.length > 0 && (
                            <ExperienceVettingCriteriaRejectedImages
                                experience={remappedData}
                                isReadOnly={isReadOnly}
                            />
                        )}
                    </Flexbox>
                )}
            </Container>
        </ScrollArea>
    )
}
ExperienceCriteriaVetting.displayName = 'ExperienceCriteriaVetting'
